import React from 'react';
import styled, { css } from 'styled-components';

import { useConferenceConfigurations } from 'calls/hooks/index.js';
import LightTheme from 'calls/styles/LightTheme.js';
import DarkTheme from 'calls/styles/DarkTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"div", any, { $isDarkMode: boolean, $isGridView: boolean }, never>}
 */
const StyledParticipant = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	margin: 0;
	width: 100%;
	font-size: 14px;
	box-shadow: none;
	overflow: hidden;

	> header {
		position: absolute;
		display: flex;
		align-items: center;
		width: 100%;
		bottom: 0;
		left: 0;
		z-index: 1;
		padding: ${LightTheme.spacing[2]}px;
		border: none;
		background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);

		> p {
			margin: 0;
			padding: 0;
			white-space: nowrap;
			flex: 1;
			display: flex;
			align-items: center;
			font-size: 14px;
			color: ${LightTheme.colors.grayZero};
			text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
			overflow: hidden;

			> span {
				&:last-of-type {
					color: ${LightTheme.colors.grayZero};
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}
	}

	> main {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		aspect-ratio: 16/9;
		background: ${props => (props.$isDarkMode ? DarkTheme.colors.grayThree : LightTheme.colors.grayFourteen)};
		overflow: hidden;

		p {
			color: ${props => (props.$isDarkMode ? DarkTheme.colors.grayFive : LightTheme.colors.grayTwo)};
		}
	}

	> footer {
		position: absolute;
		bottom: ${LightTheme.spacing[2]}px;
		right: ${LightTheme.spacing[2]}px;
		transition: 0.2s;
		opacity: 0;
		z-index: 1;
	}

	&:hover {
		> footer {
			opacity: 1;
		}
	}

	@media (max-width: 992px) {
		${props =>
			props.$isGridView &&
			css`
				aspect-ratio: 1;
				> main {
					border-radius: ${LightTheme.borderRadius.base}px;
				}

				> header {
					border-bottom-left-radius: ${LightTheme.borderRadius.base}px;
					border-bottom-right-radius: ${LightTheme.borderRadius.base}px;
				}
			`}
	}
`;

/**
 * @param {object} props
 * @param {any} props.children
 * @param {import('react').CSSProperties} [props.style=null]
 * @param {() => void} [props.onClick=null]
 * @returns
 */
const Participant = ({ children, style = null, onClick = null }) => {
	const conferenceConfigs = useConferenceConfigurations();
	return (
		<StyledParticipant
			style={style}
			onClick={onClick}
			$isGridView={conferenceConfigs.isGridView}
			$isDarkMode={conferenceConfigs.isDarkMode}>
			{children}
		</StyledParticipant>
	);
};

const Info = ({ children }) => {
	return <header>{children}</header>;
};

/**
 * @param {object} props
 * @param {any} props.children
 * @param {import('react').CSSProperties} [props.style=null]
 * @param {string} [props.className=null]
 */
const Main = ({ children, style, className }) => (
	<main className={className} style={style}>
		{children}
	</main>
);

const Controls = ({ children }) => {
	return <footer>{children}</footer>;
};

Participant.Info = Info;
Participant.Main = Main;
Participant.Controls = Controls;

export default Participant;
