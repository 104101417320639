import { PatientActionTypes } from 'constants/action-types.js';

export const actionCreators = {
	setFetchingPatients: () => async dispatch => {
		dispatch({ type: PatientActionTypes.FETCHING_MY_PATIENTS });
	},
	setPatients: patients => async dispatch => {
		dispatch({
			type: PatientActionTypes.FETCH_MY_PATIENTS_SUCCESS,
			payload: patients,
		});
	},
	setSelectedPatient: patientDevice => dispatch => {
		dispatch({
			type: PatientActionTypes.SET_SELECTED_PATIENT,
			payload: patientDevice,
		});
	},
	setSearchedPatients: patients => dispatch => {
		dispatch({
			type: PatientActionTypes.SET_SEARCHED_PATIENTS,
			payload: patients,
		});
	},
	changeAdvancedSearchActiveStatus: status => dispatch => {
		dispatch({
			type: PatientActionTypes.CHANGE_ADVANCED_SEARCH_STATUS,
			payload: status,
		});
	},
	setSearchedLetters: letters => dispatch => {
		dispatch({
			type: PatientActionTypes.SET_SEARCHED_LETTERS,
			payload: letters,
		});
	},
	updateDeviceCallStatus: (deviceId, activeConferences) => async dispatch => {
		dispatch({
			type: PatientActionTypes.UPDATE_DEVICE_CALL_STATUS,
			payload: { deviceId, activeConferences },
		});
	},
	updateDeviceStatus: (deviceId, isOnline) => async dispatch => {
		dispatch({
			type: PatientActionTypes.UPDATE_DEVICE_STATUS,
			payload: { deviceId, isOnline },
		});
	},
	updateDeviceAIPrivacyStatus: (deviceId, aiPrivacyStatus) => async dispatch => {
		dispatch({
			type: PatientActionTypes.UPDATE_DEVICE_PRIVACY_STATUS,
			payload: { deviceId, aiPrivacyStatus },
		});
	},
	setHasPatientRpmProgram: hasRpmProgram => async dispatch => {
		dispatch({
			type: PatientActionTypes.SET_HAS_RPM_PROGRAM,
			payload: hasRpmProgram,
		});
	},
	setSelectedPatientDevice: selectedDevice => async dispatch => {
		dispatch({
			type: PatientActionTypes.SET_SELECTED_PATIENT_DEVICE,
			payload: selectedDevice,
		});
	},
};
