import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import ReactDOM from 'react-dom';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { LOCALES } from 'i18n-translations/locales.js';

const Dropdown = props => {
	const wrapperRef = useRef(null);
	const dropdownBtnRef = useRef(null);
	const dropdownChildrenRef = useRef(null);
	const setIsPortalOpen = useRef(props.isPortal ? props.setIsPortalOpen : null);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const locale = getPreferredLanguageLocale();
	const isRightToLeft = locale === LOCALES.ARABIC;

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	useEffect(() => {
		if (props.isPortal) {
			if (isDropdownOpen) {
				setIsPortalOpen.current(true);
				return;
			}
			setIsPortalOpen.current(false);
		}
	}, [isDropdownOpen, props.isPortal]);

	const handleClickOutside = event => {
		if (!wrapperRef.current || wrapperRef.current.contains(event.target)) {
			return;
		}
		if (props.isPortal && dropdownChildrenRef.current && dropdownChildrenRef.current.contains(event.target)) {
			return;
		}
		setIsDropdownOpen(false);
	};

	const toggleDropdown = () => {
		if (props.stayOpenOnClick && isDropdownOpen) {
			return;
		}
		setIsDropdownOpen(prevState => !prevState);
	};

	const getDropdownItems = style => (
		<div
			className={classNames('dropdown__items', isRightToLeft ? 'direction-rtl-wrapper' : '')}
			onClick={toggleDropdown}
			style={style}
			ref={dropdownChildrenRef}
		>
			{props.children}
		</div>
	);

	const dropdownRefProperties = dropdownBtnRef.current?.getBoundingClientRect();

	return (
		<div
			ref={wrapperRef}
			data-test-id='drop-down-item'
			id={props.id}
			className={classNames(
				'dropdown',
				props.className,
				props.position,
				props.reverse ? 'dropdown--reverse' : '',
				isDropdownOpen ? 'dropdown--open' : '',
				props.isDropdownStatic ? 'dropdown--static' : ''
			)}
			onClick={props.onWrapperClick}
		>
			<button
				ref={dropdownBtnRef}
				type='button'
				data-test-id='drop-down-clicker'
				onClick={toggleDropdown}
				data-position={props.toolTipPosition}
				data-tooltip={props.toolTip}
			>
				{props.hasImageWrapper && (
					<div className='dropdown-img-wrapper'>{props.imageUrl && <img src={props.imageUrl} alt='ddl-img' />}</div>
				)}
				{!props.hasImageWrapper && props.imageUrl && <img src={props.imageUrl} alt='ddl-img' />}
				{props.title}
				{props.icon && !props.imgIcon && (
					<i className={props.isSymbol ? 'material-symbols-outlined' : 'material-icons-outlined'}>{props.icon}</i>
				)}
				{((isDropdownOpen && props.imgIcon && !props.activeImgIcon) ||
					(!isDropdownOpen && props.imgIcon && props.activeImgIcon)) && (
					<img className='dropdown-icon-img' src={props.imgIcon} alt='icon' />
				)}
				{isDropdownOpen && props.activeImgIcon && (
					<img className='dropdown-icon-img dropdown-icon-img-active' src={props.activeImgIcon} alt='icon' />
				)}
				{props.unreadNotificationCount > 0 && (
					<div className='header-notifications-counter'>
						<span className='cursor-pointer'>{props.unreadNotificationCount}</span>
					</div>
				)}
				{props.haveWarnings && <i className='dropdown-warning material-icons'>fiber_manual_record</i>}
			</button>
			{!props.isPortal && getDropdownItems()}
			{props.isPortal &&
				isDropdownOpen &&
				ReactDOM.createPortal(
					getDropdownItems({
						zIndex: 999999,
						position: 'absolute',
						...(isRightToLeft
							? { left: dropdownRefProperties?.left }
							: { right: window.innerWidth - dropdownRefProperties?.right }),
						...(dropdownRefProperties.top < window.innerHeight / 2 && {
							top: dropdownRefProperties?.top + 50,
						}),
						...(dropdownRefProperties.top > window.innerHeight / 2 && {
							bottom: window.innerHeight - dropdownRefProperties?.bottom + 30,
						}),
					}),
					document.getElementById('dropdown-root')
				)}
		</div>
	);
};

export default Dropdown;
