import React, { useContext } from 'react';

import SocketEvents from 'constants/socket-events.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import { MeasureDeviceType } from 'constants/enums.js';

/**
 * @typedef {{ toggleAiTools: (...args: any) => void,
 * startMeasuringIoTDevice:(data: object) => void,
 * sendFallDetected:(data: object) => void,
 * cancelPersonUpAlert:(...args: any) => void,
 * toggleHealthData:(data: object) => void,
 * toggleCameraMeasurements:(data: object) => void,
 * toggleStethoscope:(data: object) => void,
 * sendRecordMonitoringEvent: (data: object) => void,
 * setIotDeviceExaminationPoint: (data: object) => void,
 * sendInterpreterAssignmentRequest: (data: object) => void }
 * } ContextData
 * @type {React.Context<ContextData>}
 */
const SocketFunctionsContext = React.createContext(null);

const withSocketFunctionsContext = Component => props => (
	<SocketFunctionsContext.Consumer>
		{context => <Component socketFunctions={context} {...props} />}
	</SocketFunctionsContext.Consumer>
);

const SocketFunctionsProvider = ({ children }) => {
	const socket = useContext(SocketContext);

	const toggleAiTools = ({ isEnabled, aiOption, helloDeviceId, conferenceId, participantId }) => {
		return socket.emit(SocketEvents.HelloDevice.TOGGLE_AI_TOOLS, {
			isEnabled,
			aiOption,
			helloDeviceId,
			conferenceId,
			participantId,
		});
	};

	const startMeasuringIoTDevice = ({
		iotDevice,
		helloDeviceId,
		conferenceId,
		participantId,
		iotDeviceType = '',
		objectType,
		measureDeviceType = MeasureDeviceType.VITAL_KIT,
		startMeasure = true,
		doctorId = null,
		deviceId = null,
	}) => {
		return socket.emit(SocketEvents.HelloDevice.START_MEASURING_IOT_DEVICES, {
			iotDevice,
			helloDeviceId,
			conferenceId,
			participantId,
			iotDeviceType,
			objectType,
			measureDeviceType,
			startMeasure,
			doctorId,
			deviceId,
		});
	};

	const sendFallDetected = data => {
		return socket.emit(SocketEvents.HelloDevice.SEND_FALL_DETECTED, data);
	};

	const cancelPersonUpAlert = (helloDeviceId, conferenceId, participantId, type) => {
		return socket.emit(SocketEvents.HelloDevice.CANCEL_AI_ALERT, {
			helloDeviceId: helloDeviceId,
			conferenceId: conferenceId,
			participantId: participantId,
			type: type,
		});
	};

	const toggleHealthData = ({
		isEnabled,
		helloDeviceId,
		conferenceId,
		participantId,
		toolType = SocketEvents.HelloDevice.TOOLTYPE_PATIENTOVERVIEW,
		measurementType = '',
		measuringPoint = null,
		measureDeviceType = '',
		measureDeviceState = '',
		doctorId = null,
		patientId = null,
	}) => {
		return socket.emit(SocketEvents.HelloDevice.TOGGLE_HEALTH_DATA, {
			isEnabled,
			toolType,
			helloDeviceId,
			conferenceId,
			participantId,
			measurementType,
			measuringPoint,
			measureDeviceType,
			measureDeviceState,
			doctorId,
			patientId,
		});
	};

	const toggleCameraMeasurements = ({ isEnabled, conferenceId, participantId }) => {
		return socket.emit(SocketEvents.Conference.ON_CAMERA_MEASUREMENTS_TOGGLE, {
			isEnabled: isEnabled,
			conferenceId: conferenceId,
			participantId: participantId,
		});
	};

	const toggleStethoscope = ({ isEnabled, helloDeviceId, conferenceId, participantId }) => {
		return socket.emit(SocketEvents.HelloDevice.TOGGLE_STETHOSCOPE, {
			isEnabled: isEnabled,
			toolType: SocketEvents.HelloDevice.TOOLTYPE_STETHOSCOPE,
			helloDeviceId: helloDeviceId,
			conferenceId: conferenceId,
			participantId: participantId,
		});
	};

	const sendRecordMonitoringEvent = data => {
		return socket.emit(SocketEvents.Conference.RECORD_MONITORING, data);
	};

	const setIotDeviceExaminationPoint = ({ conferenceId, participantId, deviceType, examPoint, actioneeParticipantIds }) => {
		return socket.emit(SocketEvents.Conference.SET_IOT_DEVICE_EXAMINATION_POINT, {
			conferenceId,
			participantId,
			deviceType,
			examPoint,
			actioneeParticipantIds,
		});
	};

	/**
	 * @param {object} data
	 * @param {string} data.conferenceId
	 * @param {string} data.participantId
	 * @param {'create' | 'update' | 'cancel'} data.methodType
	 * @param {object} data.data
	 */
	const sendInterpreterAssignmentRequest = data => {
		return socket.emitWithAck(SocketEvents.Conference.INTERPRETER_ASSINGMENT_REQUEST, data);
	};

	return (
		<SocketFunctionsContext.Provider
			value={{
				toggleAiTools,
				startMeasuringIoTDevice,
				sendFallDetected,
				cancelPersonUpAlert,
				toggleHealthData,
				toggleCameraMeasurements,
				toggleStethoscope,
				sendRecordMonitoringEvent,
				setIotDeviceExaminationPoint,
				sendInterpreterAssignmentRequest,
			}}
		>
			{children}
		</SocketFunctionsContext.Provider>
	);
};

export { SocketFunctionsProvider, SocketFunctionsContext, withSocketFunctionsContext };
