import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import Callback from 'components/auth/callback.jsx';
import Logout from 'components/auth/logout.jsx';
import LogoutCallback from 'components/auth/logoutCallback.jsx';
import PrivateRoute from 'routes/privateRoute.jsx';
import SilentRenew from 'components/auth/silentRenew.jsx';
import { RouteTypes, UserRoles } from 'constants/enums.js';
// import Recover from 'views/Recover.jsx';
// import ResetPassword from 'views/ResetPassword.jsx';
// import VerifyChangedEmail from 'views/VerifyChangedEmail.jsx';
import ConferenceCall from 'calls/Conference.jsx';
import conferenceRouteRender from 'calls/conferenceRouteRender.jsx';
import JoinAsGuest from 'calls/views/JoinAsGuest.jsx';
// import ContinueAs from 'containers/ContinueAs.jsx';
import Home from 'views/Partials/Home.jsx';
import CallPatientOptions from 'views/ehr/CallPatientOptions.jsx';
import CallRedirect from 'calls/CallRedirect.jsx';
import EHRCallPatientJoinAsGuest from 'views/Partials/EHRCallPatientJoinAsGuest.jsx';

const DefaultRoutes = (
	<Switch>
		<Route exact={true} path='/signin-oidc' component={Callback} />
		<Route exact={true} path='/logout' component={Logout} />
		<Route exact={true} path='/logout/callback' component={LogoutCallback} />
		<Route exact={true} path='/silent-renew' component={SilentRenew} />
		{/* <Route exact={true} path='/recover' component={Recover} />
		<Route exact={true} path='/validate/recoverPassword' component={ResetPassword} /> */}
		{/* <Route exact={true} path='/validate/changeEmail' component={VerifyChangedEmail} /> */}

		<Route path='/call-session/:conferenceId/invitation/:invitationSecret' component={JoinAsGuest} />
		<PrivateRoute
			roles={[UserRoles.GUEST]}
			path='/virtual-session/:conferenceId/invitation/:invitationSecret/:refToken'
			component={JoinAsGuest}
		/>
		<PrivateRoute
			roles={[UserRoles.GUEST]}
			path='/virtual-session/:startConferenceId/:callType/:deviceId/:name'
			component={JoinAsGuest}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE]}
			path='/virtual-care-session/:conferenceId/invitation/:invitationSecret/:refToken/:isDirect'
			component={EHRCallPatientJoinAsGuest}
		/>
		<PrivateRoute
			roles={[UserRoles.NURSE]}
			path='/virtual-care-session/:startConferenceId/:callType/:deviceId/:isDirect'
			component={EHRCallPatientJoinAsGuest}
		/>

		<Route exact={true} path='/' component={Home} />

		{/* <PrivateRoute exact path='/continue-as' component={ContinueAs} /> */}

		<PrivateRoute
			exact={true}
			roles={[UserRoles.NURSE, UserRoles.DOCTOR]}
			path='/call-patient-options'
			component={CallPatientOptions}
		/>

		<PrivateRoute
			roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.GUEST]}
			path='/call'
			component={ConferenceCall}
			render={conferenceRouteRender}
		/>
		<PrivateRoute
			roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN]}
			path='/patient-feed'
			component={ConferenceCall}
			render={conferenceRouteRender}
		/>
		<PrivateRoute
			roles={[UserRoles.DOCTOR, UserRoles.PATIENT, UserRoles.NURSE, UserRoles.DIGITAL_CLINICIAN, UserRoles.GUEST]}
			path='/start-call/:objectId/:objectType/:name/:type/:conferenceId'
			component={CallRedirect}
		/>
		<PrivateRoute path='*' component={Home} />
	</Switch>
);

export default DefaultRoutes;
