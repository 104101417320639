import React, { useState, useEffect } from 'react';
import Grid from 'components/Grid.jsx';
import { getUserRoles } from 'api/roles.js';
import { getUserId } from 'infrastructure/auth.js';
import ContinueAs from 'components/ContinueAs.jsx';
import Button from 'components/Button.jsx';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { healthCareCdnUrl } from 'constants/global-variables.js';

const Unauthorized = props => {
	const [showSwitchRole, setShowSwitchRole] = useState(false);
	const [canChangeRole, setCanChangeRole] = useState(false);
	const intl = useIntl();
	const history = useHistory();

	const translator = id => intl.formatMessage({ id });

	useEffect(() => {
		const getRoles = async () => {
			const rolesResponse = await getUserRoles(getUserId());
			if (!rolesResponse.error && rolesResponse.roles.length > 1) {
				setCanChangeRole(true);
			}
		};
		getRoles();
	}, []);

	const signOut = () => history.push('/logout');

	if (showSwitchRole) {
		return <ContinueAs />;
	}

	return (
		<Grid columns='1fr' rows='1fr' stretch='100vh' horizAlign='center' vertAlign='center'>
			<div className='member-error'>
				<h3>
					{translator(
						props.featureFlagsDisabled && !props.isAuthorized ? 'allRoleCustomizationsDisabled' : 'notAuthorizedToAccessThisPage'
					)}
				</h3>
				<img src={`${healthCareCdnUrl}empty-state/member-error.svg`} alt='member-error' />
				<p>{translator('ifYouThinkThereAreMistakes')}</p>
				{!canChangeRole && (
					<span className='logout-link' onClick={signOut}>
						{translator('clickToLoginAnotherAcc')}
					</span>
				)}
				{canChangeRole && (
					<>
						<Button text={translator('switchRole')} onClick={() => setShowSwitchRole(true)} variant='white' />{' '}
						<Button text={translator('logOut')} onClick={signOut} />
					</>
				)}
			</div>
		</Grid>
	);
};

export default Unauthorized;
