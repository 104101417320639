import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { isIOS } from 'react-device-detect';

import { enums, participant as CallsParticipant } from '@solaborate/calls';
import { Mic, Cam, ScreenShare } from '@solaborate/calls/webrtc';
import LocalParticipant from 'calls/LocalParticipant.js';
import RemoteHelloParticipant from 'calls/RemoteHelloParticipant.js';
import {
	ParticipantAudio,
	ParticipantVideo,
	PrivacyNotification,
	Loader,
	Avatar,
	CameraControls,
	Icon,
	Dropdown,
	List,
	Modal,
	Button,
	IconButton,
	ParticipantAudioLevel,
} from 'calls/components/index.js';
import {
	useConference,
	useConferenceConfigurations,
	useControllerTracks,
	useLocalParticipant,
	useHelloDeviceState,
	useScreenType,
} from 'calls/hooks/index.js';
import { CameraTypes, UserRoles } from 'calls/enums/index.js';
import { isMedicalInfoModalsOpen } from 'calls/helpers/index.js';
import {
	PinIcon,
	UnPinIcon,
	VolumeUpIcon,
	VolumeOffIcon,
	VideocamIcon,
	VideocamOffIcon,
	ClosedCaptionsIcon,
	ClosedCaptionsDisabledIcon,
} from 'calls/icons/index.js';
import LightTheme from 'calls/styles/LightTheme.js';

import { getCallsButtonColor, getSomeRoleConfigurationsValues } from 'infrastructure/helpers/commonHelpers.js';
import { buildProfilePic } from 'infrastructure/helpers/thumbnailHelper.js';
import { getUserRole } from 'infrastructure/auth.js';
import translate from 'i18n-translations/translate.jsx';

import SpeechToTextOverlay from 'SpeechToTextOverlay.jsx';
import { RoundingSettings } from 'constants/configurationEnums.js';
import { ButtonType } from 'constants/enums.js';

const StyledLoadingMainParticipant = styled.div`
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;

	p {
		color: var(--gray-0);
	}
`;

const FullScreenButton = styled.button`
	position: fixed;
	top: ${LightTheme.spacing[2]}px;
	left: ${LightTheme.spacing[2]}px;
	padding: 0;
	line-height: 0;
	background: var(--gray-0);
	z-index: 10;
`;

const StyledParticipantActions = styled.div`
	position: absolute;
	top: ${LightTheme.spacing[2]}px;
	right: ${LightTheme.spacing[2]}px;
	z-index: 1;
`;

const StyledMainParticipantInfo = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: flex;
	padding: ${LightTheme.spacing[2]}px;
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.35) 100%);
	z-index: 1;

	> div {
		display: flex;
		align-items: center;

		> svg {
			margin-right: 6px;
		}

		button {
			margin-right: 6px;
			padding: ${LightTheme.spacing[1]}px;
			span {
				font-size: 12px;
			}
		}
	}

	> p {
		margin: 0;
		padding: 0;
		white-space: nowrap;
		font-size: 16px;
		color: var(--gray-0);
		text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
	}
`;

const LimitedVisitorSideView = styled.div`
	position: absolute;
	z-index: 1;
	top: 0;
	height: 100%;
	width: 20%;
	left: ${props => (props.$position === 'left' ? '0' : 'auto')};
	right: ${props => (props.$position === 'right' ? '0' : 'auto')};
	background: ${LightTheme.colors.graySix};

	@media (max-width: 768px) {
		display: none;
	}
`;

const DragToZoomVideo = forwardRef(
	/**
	 * @param {object} props
	 * @param {HTMLElement} props.mainRefElement
	 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.participant Local or remote participant
	 * @param {import('@solaborate/calls/webrtc').Cam | import('@solaborate/calls/webrtc').ScreenShare} props.activeTrackType Main participant active track type {VIDEO | SCREEN}
	 * @param {React.RefObject<HTMLVideoElement>} ref
	 */
	(
		{ mainRefElement, participant, activeTrackType },
		/** @type {React.RefObject<HTMLVideoElement>} */
		ref
	) => {
		const conferenceConfigs = useConferenceConfigurations();
		const localParticipant = useLocalParticipant();

		const tracks = useControllerTracks(
			participant instanceof LocalParticipant ? localParticipant.localTrackController : participant.remoteTrackController
		);
		const deviceState = useHelloDeviceState(participant);

		const [isDrawing, setIsDrawing] = useState(false);
		const [coordinates, setCoordinates] = useState({
			x1: 0,
			x2: 0,
			y1: 0,
			y2: 0,
		});

		const selectedCamera = deviceState?.mediaDevices?.find(
			mediaDevice => mediaDevice.type === enums.MediaTypes.CAMERA && mediaDevice.isActive
		);

		const getDifferenceVideoAspectRatio = () => {
			const aspectRatio = 16 / 9;
			const containerWidth = ref.current?.clientWidth;
			const containerHeight = ref.current?.clientHeight;
			const originalWidth = containerHeight * aspectRatio;
			const widthDifference = originalWidth - containerWidth;
			const originalHeight = containerWidth / aspectRatio;
			const heightDifference = originalHeight - containerHeight;
			return {
				widthDifference,
				heightDifference,
			};
		};

		const handleMouseDown = e => {
			const container = e.currentTarget.getBoundingClientRect();
			const offsetX = e.clientX - container.left;
			const offsetY = e.clientY - container.top;

			setIsDrawing(true);
			setCoordinates({
				x1: offsetX,
				x2: offsetX,
				y1: offsetY,
				y2: offsetY,
			});
		};

		const handleMouseMove = e => {
			if (!isDrawing) return;

			const container = e.currentTarget.getBoundingClientRect();

			const offsetX = e.clientX - container.left;
			const offsetY = e.clientY - container.top;

			setCoordinates(prevState => ({
				...prevState,
				x2: offsetX,
				y2: offsetY,
			}));
		};

		const handleMouseUp = () => {
			setIsDrawing(false);

			if (
				!ref ||
				!shouldAllowDragOrClickToMove ||
				coordinates.x1.toFixed(2) === coordinates.x2.toFixed(2) ||
				coordinates.y1.toFixed(2) === coordinates.y2.toFixed(2)
			) {
				return;
			}

			let { x1, x2, y1, y2 } = coordinates;

			const container = ref.current;
			const containerRect = container?.getBoundingClientRect();

			const containerWidth = containerRect.width;
			const containerHeight = containerRect.height;

			let width = containerWidth;
			let height = containerHeight;

			if (!conferenceConfigs.isFitScreen) {
				if (getDifferenceVideoAspectRatio().widthDifference > 0) {
					x1 += getDifferenceVideoAspectRatio().widthDifference / 2;
					x2 += getDifferenceVideoAspectRatio().widthDifference / 2;
					width = containerWidth + getDifferenceVideoAspectRatio().widthDifference;
				}
				if (getDifferenceVideoAspectRatio().widthDifference <= 0 && getDifferenceVideoAspectRatio().heightDifference > 0) {
					y1 += getDifferenceVideoAspectRatio().heightDifference / 2;
					y2 += getDifferenceVideoAspectRatio().heightDifference / 2;
					height = containerHeight + getDifferenceVideoAspectRatio().heightDifference;
				}
			}

			const x1Percentage = (x1 / width) * 100;
			const y1Percentage = (y1 / height) * 100;
			const x2Percentage = (x2 / width) * 100;
			const y2Percentage = (y2 / height) * 100;

			participant.sendMediaControlsEvent(
				enums.MediaControlsCommands.DRAG_TO_ZOOM,
				enums.MediaTypes.CAMERA,
				JSON.stringify({
					x1: x1Percentage > 0 ? x1Percentage.toFixed(2) : 0,
					y1: y1Percentage > 0 ? y1Percentage.toFixed(2) : 0,
					x2: x2Percentage > 0 ? x2Percentage.toFixed(2) : 0,
					y2: y2Percentage > 0 ? y2Percentage.toFixed(2) : 0,
				})
			);
		};

		const handleMouseLeave = e => {
			const { clientX, clientY } = e;
			const rect = mainRefElement?.getBoundingClientRect();

			if (clientX >= rect.left && clientX <= rect.right && clientY >= rect.top && clientY <= rect.bottom) {
				return;
			}

			setIsDrawing(false);
			setCoordinates({
				x1: 0,
				x2: 0,
				y1: 0,
				y2: 0,
			});
		};

		const shouldAllowDragOrClickToMove =
			participant instanceof RemoteHelloParticipant &&
			![UserRoles.PATIENT, UserRoles.VISITOR, UserRoles.GUEST].includes(getUserRole()) &&
			!conferenceConfigs.isGridView &&
			!conferenceConfigs.isEmbeddedView;

		const getCoordinates = e => {
			const rect = ref.current?.getBoundingClientRect();
			const containerWidth = rect.width;
			const containerHeight = rect.height;
			const aspectRatio = 16 / 9;
			const originalWidth = containerHeight * aspectRatio;
			const widthDifference = originalWidth - containerWidth;
			const originalHeight = containerWidth / aspectRatio;
			const heightDifference = originalHeight - containerHeight;
			let width = containerWidth;
			let height = containerHeight;
			let offsetX = e.clientX - rect.left;
			let offsetY = e.clientY - rect.top;
			if (!conferenceConfigs.isFitScreen) {
				if (widthDifference > 0) {
					width = containerWidth + widthDifference;
					offsetX += widthDifference / 2;
				}
				if (widthDifference <= 0 && heightDifference > 0) {
					height = containerHeight + heightDifference;
					offsetY += heightDifference / 2;
				}
			}
			const x = (offsetX / width) * 100;
			const y = (offsetY / height) * 100;
			const xFloat = parseFloat(x.toFixed(2));
			const yFloat = parseFloat(y.toFixed(2));
			return {
				x: xFloat > 0 ? xFloat : 0,
				y: yFloat > 0 ? yFloat : 0,
			};
		};

		const sendCoordinates = e => {
			if (!ref || !shouldAllowDragOrClickToMove || conferenceConfigs.isGridView) {
				return;
			}

			e.stopPropagation();

			const { x, y } = getCoordinates(e);
			participant.sendMediaControlsEvent(
				enums.MediaControlsCommands.MOVE_TO_POSITION,
				enums.MediaTypes.CAMERA,
				JSON.stringify({
					x,
					y,
				})
			);
		};

		const videoTrack = tracks[activeTrackType];

		let dragToZoomStyle = {
			left: `${coordinates.x2 > coordinates.x1 ? coordinates.x1 : coordinates.x2}px`,
			top: `${coordinates.y2 > coordinates.y1 ? coordinates.y1 : coordinates.y2}px`,
			width: `${coordinates.x2 > coordinates.x1 ? coordinates.x2 - coordinates.x1 : coordinates.x1 - coordinates.x2}px`,
			height: `${coordinates.y2 > coordinates.y1 ? coordinates.y2 - coordinates.y1 : coordinates.y1 - coordinates.y2}px`,
		};

		const leftOffset = ref?.current?.offsetLeft;
		const topOffset = ref?.current?.offsetTop;

		if (conferenceConfigs.isFitScreen) {
			const x1 = leftOffset > 0 ? coordinates.x1 + leftOffset : coordinates.x1;
			const x2 = leftOffset > 0 ? coordinates.x2 + leftOffset : coordinates.x2;
			const y1 = topOffset > 0 ? coordinates.y1 + topOffset : coordinates.y1;
			const y2 = topOffset > 0 ? coordinates.y2 + topOffset : coordinates.y2;

			dragToZoomStyle = {
				left: `${coordinates.x2 > coordinates.x1 ? x1 : x2}px`,
				top: `${coordinates.y2 > coordinates.y1 ? y1 : y2}px`,
				width: `${coordinates.x2 > coordinates.x1 ? coordinates.x2 - coordinates.x1 : coordinates.x1 - coordinates.x2}px`,
				height: `${coordinates.y2 > coordinates.y1 ? coordinates.y2 - coordinates.y1 : coordinates.y1 - coordinates.y2}px`,
			};
		}

		return (
			<>
				<ParticipantVideo
					ref={ref}
					track={videoTrack}
					onDoubleClick={shouldAllowDragOrClickToMove ? sendCoordinates : null}
					onMouseDown={shouldAllowDragOrClickToMove && selectedCamera?.name === CameraTypes.HUDDLE ? handleMouseDown : null}
					onMouseMove={shouldAllowDragOrClickToMove && selectedCamera?.name === CameraTypes.HUDDLE ? handleMouseMove : null}
					onMouseUp={shouldAllowDragOrClickToMove && selectedCamera?.name === CameraTypes.HUDDLE ? handleMouseUp : null}
					onMouseLeave={shouldAllowDragOrClickToMove && selectedCamera?.name === CameraTypes.HUDDLE ? handleMouseLeave : null}
					muted
				/>
				{isDrawing && <div className='drag-zoom-square' style={dragToZoomStyle} />}
			</>
		);
	}
);

const MainParticipantView = forwardRef(
	/**
	 * @param {object} props
	 * @param {import('calls/LocalParticipant.js').default | import('calls/RemoteParticipant.js').default} props.participant Local or remote participant
	 * @param {import('@solaborate/calls/webrtc').Cam | import('@solaborate/calls/webrtc').ScreenShare} [props.activeTrackType] Main participant active track type {VIDEO | SCREEN}
	 * @param {React.RefObject<HTMLVideoElement>} ref
	 */ ({ participant, activeTrackType }, ref) => {
		const conference = useConference();
		const conferenceConfigs = useConferenceConfigurations();
		const localParticipant = useLocalParticipant();
		const [loadingConference, setLoadingConference] = useState(true);
		const [userInterActivityModal, setUserInteractivityModal] = useState(false);
		const [isPatientView] = useState(conference.callType === enums.CallTypes.SECURITYCAM);
		const isHelloParticipant = participant instanceof RemoteHelloParticipant;

		const intl = useIntl();
		const tracks = useControllerTracks(
			participant instanceof LocalParticipant ? localParticipant.localTrackController : participant.remoteTrackController
		);
		const deviceState = useHelloDeviceState(participant);
		const isCurrentlyPinnedParticipant = conferenceConfigs.pinnedParticipantId === participant.id;

		const [showControls, setShowControls] = useState(false);

		const isCameraPrivacyOn = deviceState?.isCameraPrivacyOn;
		const isMicPrivacyOn = deviceState?.isMicPrivacyOn;
		const isTvMuted = conferenceConfigs.tvVolumeRange <= 0 && conferenceConfigs.tvVolumeRange >= -100 && deviceState?.tvPowerOn;

		const mainRef = useRef(null);
		const audioRef = useRef(null);

		const screenType = useScreenType();

		const [isCCEnabled, setIsCCEnabled] = useState(isHelloParticipant ? participant.isCCEnabled : false);

		const videoTrack = tracks[activeTrackType];

		const toggleTrack = trackType => {
			participant.remoteTrackController.toggle(trackType);
			setShowControls(false);
		};

		const togglePinnedParticipant = () => {
			conferenceConfigs.setPinnedParticipantId(isCurrentlyPinnedParticipant ? '' : participant.id);
		};

		const showClosedCaptionsButton = () =>
			getSomeRoleConfigurationsValues(conferenceConfigs.roundingConfigurations, [RoundingSettings.ClosedCaptions]) &&
			isHelloParticipant &&
			localParticipant.isOwner;

		const onToggleCC = isEnabled => {
			if (isHelloParticipant) {
				participant.toggleCC(isEnabled);
			}
		};

		const onRemoveParticipant = participantId => {
			if (participant instanceof LocalParticipant || !localParticipant.isOwner) {
				return;
			}
			conferenceConfigs.setRemoveParticipantModal({
				isOpen: true,
				modalMessage: `${intl.formatMessage({ id: 'confirmationOfRemovalParticipant' }, { value: participantDisplayedName })}`,
				onSubmit: () => {
					if (!localParticipant.isOwner) {
						return;
					}
					conference.removeParticipant(participantId);
					conferenceConfigs.setRemoveParticipantModal({
						isOpen: false,
						message: '',
						onSubmit: () => {},
					});
				},
			});
		};

		const getMainAvatarSize = () => {
			let avatarSize = 96;

			const gridCount = conferenceConfigs.gridCount;
			const isInviteParticipantsModalViewOpen = conferenceConfigs.isInviteParticipantsModalViewOpen;
			const isMedicalInfoOpen = isMedicalInfoModalsOpen(conferenceConfigs.medicalDataControls);
			const isPatientMeasurementsOpen =
				conferenceConfigs.isLiveExaminationOpen ||
				conferenceConfigs.isPatientHistoryOpen ||
				conferenceConfigs.isTelemetryModalOpen;
			const isWhiteboardVisible = conferenceConfigs.isWhiteboardVisible;
			if (
				gridCount === 2 &&
				(isInviteParticipantsModalViewOpen || isMedicalInfoOpen || isPatientMeasurementsOpen || isWhiteboardVisible)
			) {
				avatarSize = 48;
			}

			return avatarSize;
		};

		const [participantDisplayedName, setParticipantDisplayedName] = useState(participant.alias || participant.name);
		const [participantNameWithInterpreter, setParticipantNameWithInterpreter] = useState('');

		useEffect(() => {
			const displayName = participant.alias || participant.name;
			setParticipantDisplayedName(displayName);

			const nameWithInterpreter = `${displayName} ${
				participant.interpreterId ? `(${intl.formatMessage({ id: 'interpreter' })})` : ''
			}`;
			setParticipantNameWithInterpreter(nameWithInterpreter);
		}, [participant, intl]);

		useEffect(() => {
			setLoadingConference(false);
		}, [tracks]);

		useEffect(() => {
			const onLoaded = ({ target }) => {
				if (target) {
					const { videoHeight, videoWidth } = target;
					conferenceConfigs.setIsMainParticipantPortraitVideo(videoWidth <= videoHeight);
				}
			};

			if (ref.current) {
				ref.current.addEventListener('loadedmetadata', onLoaded);
			}

			return () => {
				if (ref.current) {
					// eslint-disable-next-line react-hooks/exhaustive-deps
					ref.current.removeEventListener('loadedmetadata', onLoaded);
				}
			};
		}, [conferenceConfigs, ref]);

		useEffect(() => {
			if (audioRef.current && tracks[Mic]) {
				audioRef.current.play().catch(error => {
					console.error(error);
					setUserInteractivityModal(true);
				});
			}
		}, [tracks]);

		useEffect(() => {
			return participant.on(event => {
				if (event instanceof CallsParticipant.CCStateChanged) {
					setIsCCEnabled(event.isCCEnabled);
				}
			});
		}, [participant]);

		return (
			<main ref={mainRef}>
				{loadingConference && !videoTrack && (
					<StyledLoadingMainParticipant>
						<Loader $color='white' />
						<p>
							{conference.callType === enums.CallTypes.SECURITYCAM
								? translate('connectingToHello', { value: conferenceConfigs.helloName })
								: translate('loadingCamera')}
						</p>
					</StyledLoadingMainParticipant>
				)}
				{videoTrack && (
					<DragToZoomVideo
						ref={ref}
						mainRefElement={mainRef.current}
						participant={participant}
						activeTrackType={activeTrackType}
					/>
				)}
				{!videoTrack && !loadingConference && (
					<Avatar
						size={getMainAvatarSize()}
						src={participant.picture?.includes('user') ? null : buildProfilePic(participant.picture)}
						name={participantDisplayedName}
					/>
				)}
				{conference.callType === enums.CallTypes.SECURITYCAM && isHelloParticipant && (
					<ParticipantAudio track={tracks[Mic]} ref={audioRef} autoPlay={false} />
				)}
				{isHelloParticipant &&
					(isCameraPrivacyOn || isMicPrivacyOn || isTvMuted) &&
					!conferenceConfigs.isGridView &&
					!screenType.isSmall && <PrivacyNotification participant={participant} />}
				{!isPatientView && conferenceConfigs.gridCount > 2 && (
					<StyledParticipantActions>
						<Dropdown isOpen={showControls} onClickOutside={() => setShowControls(false)}>
							<Dropdown.Button onClick={() => setShowControls(prevState => !prevState)} />
							<Dropdown.Items renderIntoPortal={true}>
								<List>
									{isHelloParticipant && !localParticipant.isGuest && (
										<>
											<List.Item onClick={() => toggleTrack(Cam)}>
												{tracks[Cam] && (
													<VideocamIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
												)}
												{!tracks[Cam] && (
													<VideocamOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
												)}
												{translate(tracks[Cam] ? 'cameraOff' : 'cameraOn')}
											</List.Item>
											<List.Item onClick={() => toggleTrack(Mic)}>
												{tracks[Mic] && (
													<VolumeUpIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
												)}
												{!tracks[Mic] && (
													<VolumeOffIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={16} height={16} />
												)}
												{translate(tracks[Mic] ? 'muteAudio' : 'unMuteAudio')}
											</List.Item>
										</>
									)}
									<List.Item
										onClick={() => {
											togglePinnedParticipant();
											setShowControls(false);
										}}>
										{isCurrentlyPinnedParticipant && (
											<UnPinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
										)}
										{!isCurrentlyPinnedParticipant && (
											<PinIcon color={getCallsButtonColor(conferenceConfigs.isDarkMode)} width={18} height={18} />
										)}
										{translate(isCurrentlyPinnedParticipant ? 'unpinFeed' : 'pinFeed')}
									</List.Item>
									{!(participant instanceof LocalParticipant) && localParticipant.isOwner && (
										<List.Item onClick={() => onRemoveParticipant(participant.id)}>
											<Icon name='close' size={18} />
											{translate('removeParticipant')}
										</List.Item>
									)}
									{showClosedCaptionsButton() && (
										<List.Item
											onClick={() => {
												onToggleCC(!isCCEnabled);
												setShowControls(false);
											}}>
											{isCCEnabled && (
												<ClosedCaptionsIcon height={18} width={18} color={getCallsButtonColor(conferenceConfigs.isDarkMode)} />
											)}
											{!isCCEnabled && (
												<ClosedCaptionsDisabledIcon
													height={18}
													width={18}
													color={getCallsButtonColor(conferenceConfigs.isDarkMode)}
												/>
											)}
											{translate(isCCEnabled ? 'disableClosedCaptions' : 'enableClosedCaptions')}
										</List.Item>
									)}
								</List>
							</Dropdown.Items>
						</Dropdown>
					</StyledParticipantActions>
				)}
				{!isHelloParticipant && conferenceConfigs.gridCount > 2 && (
					<StyledMainParticipantInfo>
						<div>
							{isCurrentlyPinnedParticipant && <PinIcon color={LightTheme.colors.grayZero} width={16} height={16} />}
							{!tracks[Mic] && (
								<IconButton icon='mic_off' background={LightTheme.colors.redOne} color={LightTheme.colors.grayZero} />
							)}
							{tracks[Mic] && <ParticipantAudioLevel track={tracks[Mic].track} />}
						</div>
						<p>
							{participant instanceof LocalParticipant ? translate('you') : participantNameWithInterpreter}
							{tracks[ScreenShare] && activeTrackType === ScreenShare && ` (${intl.formatMessage({ id: 'presenting' })})`}
						</p>
					</StyledMainParticipantInfo>
				)}
				{videoTrack && screenType.isSmall && [UserRoles.VISITOR, UserRoles.FAMILY_MEMBER].includes(getUserRole()) && (
					<FullScreenButton
						onClick={() => {
							if (document.fullscreenElement) {
								document.exitFullscreen();
								return;
							}
							if (isIOS) {
								ref.current.webkitEnterFullScreen();
								return;
							}
							ref.current.requestFullscreen();
						}}>
						<span className='material-icons'>fullscreen</span>
					</FullScreenButton>
				)}
				{conferenceConfigs.showClosedCaptions && tracks[Mic] && (
					<SpeechToTextOverlay
						setShowClosedCaptions={conferenceConfigs.setShowClosedCaptions}
						setClosedCaptionsError={conferenceConfigs.setConferenceErrorMessages}
						track={tracks[Mic] ? tracks[Mic].track : null}
					/>
				)}
				{isHelloParticipant && conferenceConfigs.isMultiBed && getUserRole() === UserRoles.VISITOR && (
					<>
						<LimitedVisitorSideView $position='left'></LimitedVisitorSideView>
						<LimitedVisitorSideView $position='right'></LimitedVisitorSideView>
					</>
				)}
				{userInterActivityModal && (
					<Modal onDismiss={() => setUserInteractivityModal(false)} title={intl.formatMessage({ id: 'patientAudio' })}>
						<Modal.Content>
							<p>{translate('allowPatientAudio')}</p>
						</Modal.Content>
						<Modal.Actions>
							<Button
								type='submit'
								variant={ButtonType.SUBMIT}
								onClick={() => {
									setUserInteractivityModal(false);
									if (audioRef.current) {
										audioRef.current.play();
									}
								}}>
								Okay
							</Button>
						</Modal.Actions>
					</Modal>
				)}
				{isHelloParticipant && conferenceConfigs.isCameraControlsOpen && (
					<CameraControls helloDevice={participant} activeTrackType={activeTrackType} />
				)}
			</main>
		);
	}
);
MainParticipantView.displayName = 'MainParticipantView';

export default MainParticipantView;
