import React from 'react';
import classNames from 'classnames';

const Alert = props => (
	<div
		data-cy={props.alertSelector}
		className={classNames(
			'alert',
			props.variant,
			props.fixed ? 'fixed' : '',
			props.display && !props.persist ? 'show' : '',
			props.display && props.persist ? 'persist' : '',
			props.className
		)}
	>
		<span>{props.message}</span>
		{!props.hideCloseButton && (
			<i onClick={props.onClose} className='material-icons close-icon'>
				close
			</i>
		)}
	</div>
);

Alert.defaultProps = {
	persist: false,
	message: '',
};

export default Alert;
