import React from 'react';

const Select = props => (
	<label className={props.labelClassName ?? ''}>
		{props.label}
		<select
			name={props.name}
			value={props.value}
			onChange={props.onSelect}
			onBlur={props.onBlur}
			className={props.className ? props.className : ''}
		>
			<option value='' disabled={!props.shouldNotDisable}>
				{props.placeholder}
			</option>
			{props.items.map(item => (
				<option key={item[props.valueField]} value={item[props.valueField]} selected={item.selected}>
					{item[props.textField]}
				</option>
			))}
		</select>
		{props.error && (props.touched || props.validateOnSubmit) && <span className='red-error'>{props.error}</span>}
	</label>
);

export default Select;
