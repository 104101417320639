import React from 'react';
import classNames from 'classnames';

const Button = props => (
	<button
		type={props.type ? props.type : 'button'}
		disabled={props.isDisabled}
		className={classNames('button', props.className, props.variant, props.size, props.display, props.isLoading ? 'loading' : '')}
		onClick={props.onClick}
		style={{
			justifySelf: props.horizAlign,
			backgroundColor: props.background,
			borderColor: props.background,
			borderRadius: props.borderRadius,
			marginRight: props.marginRight,
			color: props.color,
		}}
	>
		{props.imgIcon && <img src={props.imgIcon} alt='icon' />}
		{!props.imgIcon && props.icon && <i className='material-icons-outlined'>{props.icon}</i>}
		{props.svgIcon && props.svgIcon}
		{props.text}
		{props.nextStepIcon && <img src={props.nextStepIcon} alt='icon' />}
	</button>
);

export default Button;
