import { LanguageActionTypes } from 'constants/action-types.js';
import { UserLanguage } from 'constants/enums.js';

const initialState = {
	locale: UserLanguage.ENGLISH,
};

export const language = (state = initialState, action) => {
	switch (action.type) {
		case LanguageActionTypes.SET_USER_LANGUAGE:
			return {
				...state,
				locale: action.payload,
			};

		default:
			return state;
	}
};
