import { LOCALES } from 'i18n-translations/locales.js';

const keys = {
	[LOCALES.ARABIC]: {
		conversation: 'محادثة،',
		files: 'الملفات',
		health: 'صحة',
		visits: 'الزيارات',
		roundingVisits: 'الزيارات',
		healthRecords: 'السجلات الصحية',
		hello: 'مرحبًا',
		hideProfile: 'إخفاء ملف تعريف (القيمة)',
		showProfile: 'إظهار ملف تعريف (القيمة)',
		familyPhysician: 'طبيب الأسرة',
		assignedToMe: 'محدد في داخلي',
		otherRequests: 'طلبات أخرى',
		requestSentFrom: 'طلب مرسل من',
		healthConcernsDescription: 'وصف المخاوف الصحية',
		symptomsSigns: 'الأعراض والعلامات',
		viewProfile: 'عرض الصفحة الشخصية',
		viewAlerts: 'عرض التنبيهات',
		requestedAppointment: 'المصطلح المطلوب',
		meetingPlace: 'مكان الالتقاء',
		location: 'الموقع',
		dateOfBirth: 'تاريخ الولادة',
		sex: 'الجنس',
		accepted: 'وافقت',
		acceptRequest: 'قبول',
		wrapUp: 'يتم إحتوائه',
		rejected: 'مرفوض',
		canceled: 'ألغيت',
		completed: 'مكتمل',
		unassigned: 'غير معين',
		rejectRequest: 'رفض الطلب',
		male: 'رجل',
		female: 'إناثا',
		nonBinary: 'آخر',
		gender: 'جنس',
		helloHealthAppointmentRequest: 'طلب موعد hellocare',
		appointmentConfirmation: 'تأكيد الموعد',
		areYouSureAcceptFollowingAppointment: 'هل أنت متأكد أنك تريد قبول الموعد التالي؟',
		addToGoogleCalendar: 'إضافة إلى تقويم جوجل',
		addToOutlookCalendar: 'أضف إلى تقويم التوقعات',
		clientNotAuthenticated: 'العميل غير مصدق',
		appointments: 'المواعيد',
		calendarOverview: 'نظرة عامة على التقويم',
		appointmentRequests: 'طلبات المواعيد',
		appointmentsHistory: 'تاريخ المواعيد',
		nextAppointmentAt: 'الموعد التالي في',
		noUpcomingAppointment: 'ليس لديك موعد قادم',
		yesterday: 'في الامس',
		today: 'اليوم',
		lastAmountOfDays: 'آخر {value} يوم',
		lastMonth: 'الشهر الماضي',
		lastYear: 'العام الماضي',
		monthToDate: 'بقى شهر حتى المواعدة',
		quarterToDate: 'الربع حتى الآن',
		yearToDate: 'منذ بدلية العام ز حتى اليوم',
		firstQuarterOf: 'الربع الأول من {value}',
		secondQuarterOf: 'الربع الثاني من {value}',
		thirdQuarterOf: 'الربع الثالث من {value}',
		fourthQuarterOf: 'الربع الرابع من {value}',
		customRange: 'نطاق مخصص',
		selectRange: 'اختر نطاقا',
		jul: 'تموز',
		unauthorize: 'غير مصرح به',
		authorize: 'مخول',
		connectGoogleAccount: 'ربط حساب جوجل',
		connectOutlookAccount: 'ربط حساب Outlook',
		googleAccount: 'حساب جوجل',
		outlookAccount: 'حساب آوت لوك',
		calendarAccounts: 'حسابات التقويم',
		waitingRoom: 'غرفة الانتظار',
		primaryCare: 'الرعاية الأولية',
		healthcareProgressAndDiagnosisChecking: 'تقدم الرعاية الصحية وفحص التشخيص',
		yourMobileDevice: 'جهازك المحمول',
		clinic: 'عيادة',
		days: 'أيام',
		anErrorOccurred: 'حدث خطأ',
		assignCompanionDeviceError: 'يمكن تحديد جهاز واحد فقط لكل وضع مصاحب في المرة الواحدة.',
		recaptchaLoadError: 'خطأ في تحميل برنامج ري كابتشا النصي',
		noAppointmentsToShowClickAuth: 'لا توجد مواعيد للعرض. الرجاء النقر فوق "تفويض" للحصول على المواعيد المرتبطة بحسابك.',
		noAppointmentsToShowClickCreateAppointment: 'ليس لديك مواعيد نهائية. الرجاء النقر فوق "إنشاء مصطلح" لإنشاء المصطلح.',
		createAppointment: 'إنشاء موعد',
		inAMoment: 'في لحظة',
		personsProfile: 'ملفّ {value} الشخصي',
		topic: 'الموضوع',
		inviteMember: 'إرسال دعوة لعضو',
		fullName: 'الاسم الكامل',
		facility: 'منشأة',
		profession: 'مهنة',
		familyDoctor: 'طبيب الأسرة',
		birthday: 'عيد الميلاد',
		address: 'العنوان',
		phone: 'هاتف',
		audioCall: 'مكالمة صوتية',
		videoCall: 'مكالمة فيديو',
		cameraFeed: 'تغذية الكاميرا',
		writeMessage: 'اكتب رسالة',
		rapidCall: 'نداء سريع',
		rapidCallDescription: 'عند تمكين هذا الخيار، سيكون خيار بدء مكالمة سريعة متاحًا على جانب سلة التسوق.',
		regularCall: 'دعوة العادية',
		regularCallDescription: 'عند تمكين هذا الخيار، سيكون خيار بدء مكالمة عادية متاحًا على جانب سلة التسوق.',
		admissionCall: 'دعوة القبول',
		admissionCallDescription: 'عند تمكين هذا، سيكون خيار بدء مكالمة القبول متاحًا على جانب سلة التسوق.',
		submitNewCase: 'إرسال حالة جديدة',
		submitNewCaseDescription: 'عند تمكين هذا، سيظهر على الشاشة خيار واحد فقط لبدء جلسة.',
		type: 'اكتب',
		name: 'الاسم',
		dateTime: 'التاريخ / الوقت',
		sentBy: 'مرسل من',
		noFilesFound: 'لم تشارك أي ملفات مع (القيمة)',
		noFilesParagraph: 'حاول بإرسال صورة أو مقطع فيديو أو مستند',
		deleteMsgTitle: 'حذف الرسالة لنفسي',
		deleteMsgWarning: 'هل أنت متأكد أنك تريد حذف هذه الرسالة؟',
		deleteMediaTitle: 'حذف الوسائط لنفسي',
		deleteMediaWarning: 'هل أنت متأكد أنك تريد حذف هذه الوسائط؟',
		actionUndone: 'لا يمكن التراجع عن هذا الإجراء',
		summary: 'ملخص',
		heart: 'قلب',
		heartRate: 'معدل ضربات القلب',
		beatsPerMinute: 'نبضة في الدقيقة',
		mmOfMercury: 'ملليمترات من الزئبق',
		percentage: 'النسبة المئوية',
		bloodPressure: 'ضغط الدم',
		perfusionIndex: 'مؤشر الإرواء',
		activity: 'نشاط',
		steps: 'خطوات',
		flightsClimbed: 'سلالم متصلة',
		walkingRunningDistance: 'مسافة المشي والجري',
		bodyMeasurements: 'قياسات الجسم',
		weight: 'وزن',
		scale: 'مقياس',
		pinFeed: 'تثبيت في العرض الرئيسي',
		unpinFeed: 'قم بإلغاء التثبيت من الشاشة الرئيسية',
		bodyTemperature: 'درجة حرارة الجسم',
		leanBodyMass: 'كتلة الجسم النحيل',
		bodyFatPercentage: 'نسبة الدهون في الجسم',
		bmi: 'مؤشر كتلة الجسم',
		bMI: 'BMI',
		respiratory: 'تنفسي',
		oxygenSaturation: 'تشبع الأكسجين',
		respiratoryRate: 'معدل التنفس',
		sleep: 'ينام',
		sleepAnalysis: 'تحليل النوم',
		bloodGlucose: 'جلوكوز الدم',
		stethoscope: 'سماعة الطبيب',
		noVisits: 'لا توجد زيارات لهذه الغرفة',
		hasVisited: '(القيمة 1) زار (القيمة 2)',
		selectVisitsDate: 'حدد تاريخ الزيارات',
		enterDetailsOfVisitReason: 'اكتب سبب وجود المريض هنا',
		allergy: 'الحساسية',
		medication: 'دواء',
		condition: 'حالة',
		observation: 'الملاحظة',
		immunization: 'تحصين',
		procedure: 'إجراء',
		device: 'جهاز',
		carePlan: 'خطة الرعاية',
		manifestation: 'مظهر',
		criticality: 'الحرجة',
		substance: 'مستوى',
		status: 'حالة',
		medicationName: 'اسم الدواء',
		dateWritten: 'تاريخ الكتابة',
		startDate: 'تاريخ البدء',
		endDate: 'تاريخ الانتهاء',
		prescriber: 'إرشاد',
		diagnosis: 'التشخيص',
		dateRecorded: 'تاريخ التسجيل',
		severity: 'درجة الجدية',
		category: 'فئة',
		value: 'قيمة',
		report: 'تقرير',
		performer: 'المؤدي',
		date: 'تاريخ',
		vaccineType: 'نوع اللقاح',
		procedureName: 'اسم الإجراء',
		deviceType: 'نوع الجهاز',
		deviceModel: 'طراز الجهاز',
		deviceUDI: 'جهاز UDI',
		deviceStatus: 'حالة الجهاز',
		expiryDate: 'تاريخ الانتهاء',
		diseases: 'الأمراض',
		categories: 'فئات',
		goals: 'أهداف',
		appointmentDate: 'تاريخ الموعد',
		noAppointmentsAvailableSelectDifferentDate: 'لا توجد مواعيد متاحة ، الرجاء تحديد تاريخ مختلف',
		noAppointmentsAvailableSelectDifferentDoctor: 'لا توجد مواعيد متاحة ،  الرجاء اختيار طبيب مختلف',
		setUpAvailabilityHours: 'يرجى إعداد ساعات التوفر قبل تحديد موعد',
		whereMeetingPlace: 'أين تريد أن ينعقد هذا الاجتماع',
		recurringMeeting: 'اجتماع متكرر',
		recurrenceEndDate: 'تاريخ انتهاء التكرار',
		description: 'وصف',
		unavailable: 'غير متوفره',
		selectAnotherColor: 'حدد لونًا آخر',
		interfaceThemeColor: 'لون سمة الواجهة',
		authNone: 'لا أحد',
		authExternalProvider: 'المصادقة',
		authEmail: 'البريد الإلكتروني',
		invalidCode: 'الرمز غير صحيح. حاول مرة اخرى',
		emailChanged: 'تم تغيير بريدك الإلكتروني hellocare بنجاح. الرجاء تسجيل الدخول مرة أخرى باستخدام بريدك الإلكتروني الجديد',
		goToLoginPage: 'انقر هنا للذهاب إلى صفحة تسجيل الدخول',
		currentEmail: 'الايميل الحالي',
		newEmail: 'بريد إلكتروني جديد',
		enableMultipleFeedAudio: 'تمكين الصوت لخلاصات متعددة',
		available: 'متوفرة',
		changeLanguage: 'تغيير اللغة',
		english: 'إنجليزي',
		albanian: 'الألبانية',
		editProfile: 'تعديل الملف الشخصي',
		accountSettings: 'إعدادت الحساب',
		logOut: 'تسجيل خروج',
		noNotifications: 'ليس لديك إخطارات',
		noConversations: 'ليس لديك محادثات مع المرضى',
		conversationWithPatient: 'محادثة مع المريض',
		toggledPatientCamera: 'لقد قمت بتبديل كاميرا المريض',
		turnCameraOn: 'قم بتشغيل الكاميرا',
		turnCameraOff: 'أغلق الكاميرا',
		initiatingCall: 'بدء الاتصال',
		endCall: 'إنهاء المكالمة',
		turnOnMic: 'قم بتشغيل الميكروفون',
		turnOffMic: 'قم بإيقاف تشغيل الميكروفون',
		hidePTZ: 'إخفاء PTZ',
		showPTZ: 'عرض PTZ',
		showHealth: 'عرض الصحة',
		hideHealth: 'إخفاء الصحة',
		takeScreenshot: 'خذ لقطة شاشة',
		otoscopeCamera: 'منظار الأذن',
		selectBackground: 'اختر الخلفية',
		streamSettings: 'إعدادات البث',
		streamSettingsModalTitle: 'تحقق من إعدادات الصوت والفيديو',
		usingDifferentMic: 'قد يؤدي استخدام أجهزة ميكروفون ومكبرات صوت مختلفة إلى حدوث صدى',
		streamSettingsSpeaker: 'ألتوبارلانت',
		filterNone: 'لا أحد',
		filterBlur: 'طمس',
		tvControls: 'ضوابط التلفزيون',
		startScreenSharing: 'ابدأ مشاركة الشاشة',
		stopScreenSharing: 'وقف مشاركة الشاشة',
		presenting: 'تقديم',
		uploadHospitalImage: 'تحميل صورة المستشفى',
		you: 'أنت',
		patient: 'صبور',
		disableNightvision: 'تعطيل الرؤية الليلية',
		enableNightvision: 'تمكين الرؤية الليلية',
		aiControls: 'آل كونترولز',
		connect: 'الاتصال',
		measure: 'يقيس',
		connecting: 'توصيل',
		measuring: 'قياس',
		overview: 'ملخص',
		seeMoreData: 'انظر المزيد من البيانات',
		historicalData: 'البيانات التاريخية',
		first: 'أول',
		second: 'ثانيا',
		third: 'الثالث',
		daily: 'يوميًا',
		weeklyOn: 'أسبوعيًا في ،',
		monthlyThe: 'شهريًا ،',
		annuallyOn: 'سنويًا في ،',
		everyWorkday: 'كل يوم عمل (من الاثنين إلى الجمعة)',
		prev: 'السابق',
		next: 'التالي',
		switchHealthSystem: 'نظام التبديل الصحي',
		createNewHealthSystem: 'إنشاء نظام صحي جديد',
		editHealthSystem: 'تحرير النظام الصحي',
		createHospital: 'أنشئ مستشفى',
		communicateWithARoom: 'من أجل التواصل مع الغرفة',
		monitorRoom: 'من أجل مراقبة الغرف',
		clickAddIconInTree: 'في عرض الشجرة ، انقر فوق رمز الإضافة بجوار اسم الغرفة',
		openHospitalChooseDepartment: 'الرجاء فتح مستشفى واختيار القسم',
		clickFloorSelectRoom: 'انقر على أرضية واختر غرفة',
		scheduleMeeting: 'جدولة اجتماع',
		makeAppointment: 'إحجز موعد',
		switchHSorRegion: 'تبديل نظام الصحة أو المنطقة',
		selectHealthSystem: 'حدد النظام الصحي ،',
		selectIntegrationType: 'حدد نوع التكامل ،',
		selectGroupRole: 'حدد دور المجموعة ،',
		selectHealthSystemApply: 'حدد نظامًا صحيًا لتطبيق الدليل النشط ،',
		selectGroupRoleApply: 'حدد الدور لتطبيق الدليل النشط ،',
		selectRegion: 'اختر المنطقة',
		newDevice: 'جهاز جديد',
		enterSerialNumber: 'أدخل الرقم التسلسلي',
		deviceName: 'اسم الجهاز',
		deviceMode: 'وضع الجهاز',
		deviceFamilyName: 'اسم عائلة الجهاز',
		deviceLocation: 'موقع الجهاز',
		activationCode: 'رمز التفعيل',
		savedSessions: 'طرق العرض المحفوظة',
		updateSession: 'جلسة التحديث',
		saveSession: 'احفظ هذا العرض',
		currentSession: 'العرض الحالي',
		loadingSession: 'تحميل الجلسة',
		alreadyMonitoringPatients: 'أنت بالفعل في مكالمة أو تراقب المرضى.',
		tryAgainAfterSessionEnds: 'يرجى المحاولة مرة أخرى بعد إنهاء تلك الجلسة. ،',
		disconnectedFromMonitoring: 'لقد تم قطع اتصالك بمراقبة "،',
		youCantSwitchCam:
			'لا يمكنك الرجوع مرة أخرى إلى {value} لأنه تم تمكين أزرار الخصوصية الفعلية. لا يمكن تعطيل هذه الأزرار إلا يدويًا. يرجى الاتصال بالمسؤول الخاص بك.',
		monitoringEndSoon: 'ستنتهي مشاهدات المراقبة أو الممرضة عند مغادرة هذه الصفحة. هل انتمتاكد انك تريد ان تغادر؟',
		cameraBlockedTitle: 'اسمح لـ hellocare باستخدام الكاميرا',
		microphoneBlockedTitle: 'اسمح لبرنامج hellocare باستخدام ميكروفونك',
		cameraAndMicrophoneBlockedTitle: 'اسمح لبرنامج hellocare باستخدام الكاميرا والميكروفون',
		cameraBlockedMessage:
			'يحتاج hellocare إلى الوصول إلى الكاميرا الخاصة بك حتى يتمكن المرضى من رؤيتك. تحقق من الجزء العلوي الأيسر من الشاشة وانقر فوق السماح.',
		microphoneBlockedMessage:
			'يحتاج hellocare إلى الوصول إلى ميكروفونك حتى يتمكن المرضى من سماعك. تحقق من الجزء العلوي الأيسر من الشاشة وانقر فوق السماح.',
		cameraAndMicrophoneBlockedMessage:
			'يحتاج hellocare إلى الوصول إلى الكاميرا والميكروفون حتى يتمكن المرضى من سماعك. تحقق من الجزء العلوي الأيسر من الشاشة وانقر فوق السماح.',
		microphoneBlockedTitleGeneric: 'الميكروفون محظور',
		microphoneBlockedMessageGeneric:
			'يتطلب hellocare الوصول إلى الميكروفون الخاص بك. انقر فوق رمز حظر الكاميرا في شريط عنوان المتصفح الخاص بك ، ثم قم بالتحديث.',
		cameraBlockedTitleGeneric: 'تم حظر الكاميرا',
		cameraBlockedMessageGeneric: 'رسالة عامة للكاميرا المحظورة',
		cantFindMicrophoneTitle: 'لا يمكن العثور على الميكروفون الخاص بك',
		cantFindMicrophoneMessage:
			'يرجى التحقق من نظامك إذا تم توصيل ميكروفون وسمحت له بأذونات الميكروفون. إذا لم يكن كذلك ، فقم بتوصيل واحد أو السماح بالأذونات وإعادة تشغيل المتصفح. لن يتمكن المرضى من سماعك حتى تفعل ذلك.',
		cantFindCameraTitle: 'لا يمكن العثور على الكاميرا الخاصة بك',
		cantFindCameraMessage:
			'يرجى التحقق من النظام الخاص بك إذا تم توصيل كاميرا وقمت بالسماح لأذونات الكاميرا. إذا لم يكن كذلك ، فقم بتوصيل واحد أو السماح بالأذونات وإعادة تشغيل المتصفح. لن يتمكن المرضى من رؤيتك حتى تفعل ذلك.',
		popUpCameraBlockedTitle: 'تم حظر الكاميرا الخاصة بك',
		popUpCameraBlockedMessage: 'انقر فوق أيقونة الكاميرا المحظورة أعلى يمين الشاشة',
		popUpMicrophoneBlockedTitle: 'تم حظر الميكروفون الخاص بك',
		popUpMicrophoneBlockedMessage: 'انقر فوق رمز حظر الميكروفون أعلى يمين الشاشة',
		popUpCameraAndMicrophoneBlockedTitle: 'تم حظر الكاميرا الخاصة بك',
		popUpCameraAndMicrophoneBlockedMessage: 'انقر فوق أيقونة الكاميرا المحظورة أعلى يمين الشاشة',
		deviceUnavailable: 'الجهاز غير متوفر',
		virtualCaseProviderIsInCall:
			'يُجري موفر رعاية افتراضية مكالمة مع هذا الجهاز. ستتمكن من استخدام عناصر التحكم هذه بمجرد انتهاء المكالمة.',
		browserPeerConnectionsLimitReached:
			'تم الوصول إلى الحد الأقصى لعدد الاتصالات في المتصفح. يرجى إغلاق وإعادة فتح كل علامات التبويب hellocare.',
		sessionName: 'عرض الاسم',
		enterSessionName: 'أدخل اسم العرض',
		delete: 'حذف',
		deleteSession: 'حذف هذا العرض',
		sureDeleteSession: 'هل أنت متأكد أنك تريد حذف هذا العرض؟',
		failedToReconnect: 'فشل إعادة الاتصال بالشبكة',
		sessionEndedByAdmin: 'تم إنهاء هذه الجلسة بواسطة مسؤول',
		disconnectedByCall: 'تم قطع هذه الخلاصة من خلال جلسة مكالمة ، ستتم إعادة الاتصال بمجرد انتهاء المكالمة',
		maximumNumberOfMonitoringSessions: 'يتم رصد الجهاز بواسطة {value}. تم الوصول إلى الحد الأقصى لعدد هذه الغرفة.',
		beingMonitoredBy: 'هذا المريض يخضع للمراقبة من قبل',
		pleaseRefreshThePage: 'يرجى تحديث الصفحة.',
		sessionUpdatedSuccessfully: 'تم تحديث الجلسة بنجاح',
		save: 'يحفظ',
		cancel: 'يلغي',
		apply: 'يتقدم',
		suspiciousBehavior: 'سلوك مشبوه',
		suicideWatch: 'مشاهدة الانتحار',
		watch: 'الساعة',
		aIWithCamera: 'AI مع الكاميرا',
		falls: 'السقوط',
		behavioral: 'سلوكية',
		isolation: 'عزل',
		seizure: 'نوبة',
		hospice: 'تكية',
		detox: 'التخلص من السموم',
		fallRiskCheckPatient: 'خطر السقوط. الرجاء فحص المريض. ،',
		multiPersonFallDetection: 'كشف السقوط متعدد الأشخاص ،',
		fallDetection: 'الكشف عن السقوط ،',
		turnOffPrivacyMode: 'قم بإيقاف تشغيل وضع الخصوصية ،',
		turnOnPrivacyMode: 'قم بتشغيل وضع الخصوصية ،',
		allowPermission: 'السماح للفجوة',
		muteAudio: 'وقف الصوت',
		unMuteAudio: 'الافراج عن الصوت',
		reinitiateCallPermissions: 'لإعادة بدء هذه المكالمة ، تحتاج إلى السماح بأذونات الصوت',
		for: 'إلى عن على',
		myRequests: 'طلباتي',
		newRequest: 'طلب جديد',
		acceptedRequests: 'الطلبات المقبولة',
		pendingRequests: 'طلبات معلقة',
		rejectedRequests: 'الطلبات المرفوضة',
		pending: 'قيد الانتظار',
		requestSentTo: 'تم إرسال الطلب إلى',
		viewRequestHistory: 'عرض محفوظات الطلب',
		healthConcern: 'مخاوف صحية',
		reassignRequest: 'طلب إعادة التعيين',
		welcomeMessage: 'رسالة ترحيب',
		visitCost: 'زيارة التكلفة',
		languagesSpoken: 'اللغات التي تتكلمها',
		professionalEducation: 'التعليم المهني',
		yearsOfExperience: 'سنوات من الخبرة',
		fellowship: 'الزمالة',
		requestHistory: 'طلب التاريخ',
		youHaveAcceptedRequest: 'لقد قبلت طلب (القيمة)',
		acceptedYourRequest: 'أنا أقبل طلبك',
		assignedTheRequestTo: 'أسند الطلب إلى',
		rejectedYourRequest: 'رفض طلبك',
		sentRequestToAllDoctors: 'أرسل هذا الطلب إلى جميع الأطباء',
		general: 'عام',
		profileInformation: 'معلومات الملف الشخصي',
		passwordProtection: 'حماية كلمة المرور',
		notifications: 'إشعارات',
		measurementUnits: 'وحدات القياس',
		integratedApps: 'تطبيقات متكاملة',
		dependents: 'المعالون',
		insurance: 'تأمين',
		paymentInformation: 'معلومات الدفع',
		activityHistory: 'تاريخ النشاط',
		noPageFound: 'لم يتم العثور على صفحة',
		saveChanges: 'حفظ التغييرات',
		phoneNumber: 'رقم الهاتف',
		emailAddress: 'عنوان البريد الالكترونى',
		language: 'لغة',
		cameraNames: 'أسماء الكاميرات',
		customRolesDisplayName: 'اسم العرض للأدوار المخصصة',
		enterCustomRoleNameHere: 'أدخل اسم الدور المخصص هنا',
		customRoleNameRequired: 'اسم الدور المخصص مطلوب',
		informationMessageIsRequired: 'رسالة المعلومات مطلوبة',
		informationMessageForMonitoring: 'رسالة معلومات للمراقبة',
		enterAMessageHere: 'أدخل رسالة هنا',
		displayAs: 'عرض كـ',
		externalCamera: 'كاميرا خارجية',
		edit: 'تعديل',
		timeTrackerValue: '{value} متتبع الوقت',
		enable: 'يُمكَِن',
		disable: 'إبطال',
		disabled: 'عاجز',
		recent: 'مؤخرًا',
		myDevices: 'أجهزتي',
		online: 'متصل',
		offline: 'غير متصل على الانترنت',
		send: 'إرسال',
		healthCategories: 'فئات الصحة',
		addTab: 'أضف علامة تبويب',
		addFavTabQuickerAccess: 'أضف تطبيقاتك أو ملفاتك المفضلة كعلامات تبويب للوصول إليها بشكل أسرع',
		searchAppsFiles: 'تطبيقات البحث أو الملفات',
		myApps: 'تطبيقاتي',
		otherApps: 'تطبيقات أخرى',
		addNewHealthSystem: 'إضافة نظام صحي جديد',
		resendInvite: 'إعادة إرسال دعوة',
		cancelInvite: 'إلغاء الدعوة',
		addNewField: 'أضف حقل جديد',
		viewYourBaby: 'انظري لطفلك',
		enablePrivacyButtons: 'تم تمكين أزرار الخصوصية على جهاز {value}. يمكنك رؤية الطفل عند تعطيل أزرار الخصوصية.',
		somethingWentWrong: 'هناك خطأ ما! حاول مرة اخرى',
		nurseAdded: 'تمت إضافة ممرضة بنجاح إلى النظام الصحي.',
		doctorAdded: 'تم إضافة الطبيب بنجاح إلى النظام الصحي.',
		successfullySaved: 'تم الحفظ بنجاح.',
		usersAddedOnHelloHealth: 'تمت دعوة المستخدمين بنجاح إلى hellocare!',
		patientAssignedAsDeviceOwner: 'تم تعيين المريض بنجاح كمالك للجهاز',
		manageHealthSystems: 'إدارة النظم الصحية',
		selectHealthSystemNurse: 'يرجى تحديد النظام الصحي الذي تريد تعيين هذه الممرضة له.',
		enterHealthSystem: 'أدخل نظام الصحة',
		healthSystem: 'نظام الصحة',
		assignation: 'التنازل',
		unAssignableLevel: 'مستوى غير قابل للتخصيص',
		downloadCSV: 'تنزيل ملف CSV',
		watchRecording: 'مشاهدة التسجيل',
		recordedBy: 'مسجل بواسطة',
		assignLevel: 'تحديد المستوى',
		signsSymptoms: 'العلامات والأعراض',
		selectSignsSymptomsAssociatedWithHealthConcerns: 'يرجى تحديد العلامات والأعراض المرتبطة بمخاوفك الصحية',
		mostRecentMeasurementByDate: 'تعرض طريقة العرض هذه أحدث قياس تم فرزها حسب التاريخ والساعة.',
		searchForSymptoms: 'ابحث عن الأعراض',
		noSymptomsFound: 'لم يتم العثور على أعراض',
		mySymptoms: 'الأعراض الخاصة بي',
		searchDoctors: 'ابحث عن الأطباء',
		pleaseWriteYourHealthConcerns: 'من فضلك اكتب مخاوفك الصحية!',
		errorOccurred: 'حدث خطأ',
		pleaseSelectTheRequiredFields: 'الرجاء تحديد الحقول المطلوبة!',
		symptomsAssociatedWithGeneralDoctors: 'ترتبط الأعراض الخاصة بك بفئة الأطباء العامين',
		sendRequest: 'ارسل طلب',
		scheduleAppointment: 'جدولة موعد',
		writeYourFirstName: 'الرجاء كتابة اسمك الأول هنا',
		writeYourLastName: 'من فضلك اكتب اسم عائلتك هنا ..',
		writeYourEmail: 'example@gmail.com',
		confirmEmail: 'يرجى تأكيد بريدك الإلكتروني هنا ..',
		writePassword: 'من فضلك اكتب كلمة السر الخاصة بك هنا,,,',
		search: 'بحث',
		selectSerialNumber: 'حدد الرقم التسلسلي',
		writeCurrentLocation: 'من فضلك اكتب موقعك الحالي هنا',
		writeHeight: 'من فضلك اكتب طولك هنا ...',
		writeHeightUnit: 'وحدة الارتفاع',
		writeWeight: 'من فضلك اكتب وزنك هنا ...',
		writeWeightUnit: 'وحدة الوزن',
		healthSystemName: 'اسم النظام الصحي',
		regionName: 'اسم المنطقة',
		confirmPassword: 'تأكيد كلمة المرور',
		firstName: 'الاسم الاول',
		lastName: 'الاسم الاخير',
		password: 'كلمة المرور',
		email: 'البريد الإلكتروني',
		enterYourInfo: 'معلوماتك',
		verifyCellPhone: 'الهاتف الخلوي',
		licenseNumber: 'رقم الرخصة',
		medicalSchool: 'مدرسة الطب',
		chooseADate: 'اختر التاريخ',
		companyName: 'اسم الشركة',
		positionTitle: 'المسمى الوظيفي',
		specify: 'حدد',
		suffix: 'لاحقة',
		maidenName: 'الاسم قبل الزواج',
		npiNumber: 'رقم NPI',
		internshipInstitutionName: 'اسم مؤسسة التدريب',
		internshipSpecialty: 'تخصص التدريب',
		residencyInstitutionName: 'اسم مؤسسة الإقامة',
		residencySpecialty: 'تخصص الإقامة',
		fellowshipInstitutionName: 'اسم مؤسسة الزمالة',
		fellowshipSpecialty: 'تخصص الزمالة',
		searchForBoard: 'اكتب للبحث عن لوحة هنا ..',
		medicaidProviderNumber: 'رقم مزود ميديكيد',
		medicareProviderNumber: 'رقم مزود ميديكير',
		birthDate: 'تاريخ الميلاد',
		socialSecurityNumber: 'رقم الحماية الاجتماعية',
		yourAnswer: 'اجابتك',
		noResultsFound: 'لم يتم العثور على نتائج!',
		moreThanCharacters: 'تحتاج إلى وضع أحرف (قيمة) على الأقل لإجراء بحث',
		patientNotFound: 'المريض الذي كنت تبحث عنه غير موجود!',
		showAllResults: 'عرض كل النتائج',
		advancedSearch: 'البحث المتقدم',
		filterType: 'نوع فلتر',
		all: 'الجميع',
		patients: 'مرضى',
		lowercaseToday: 'اليوم',
		lowercaseTomorrow: 'الغد',
		myHealthConcerns: 'مخاوفي الصحية',
		describeYourHealthConcerns: 'صِف مخاوفك الصحية',
		thereAreAppointmentsFor: 'هناك (قيمة} المواعيد المتاحة لـ',
		typeAppointmentTopic: 'اكتب موضوع الموعد',
		whereWouldYouLikeMeetingTakePlace: 'أين تريد أن ينعقد هذا الاجتماع؟',
		firstNameCannotBeEmpty: 'لا يمكن أن يكون الاسم الأول فارغًا',
		parentNameCannotBeEmpty: 'لا يمكن أن يكون اسم ولي الأمر فارغًا',
		idNumberCannotBeEmpty: 'رقم الهوية لا يمكن أن يكون فارغا',
		patientHealthCardNumberCannotBeEmpty: 'لا يمكن أن يكون رقم بطاقة المريض الصحية فارغًا',
		lastNameCannotBeEmpty: 'لا يمكن أن يكون الاسم الأخير فارغًا',
		professionCannotBeEmpty: 'لا يمكن أن تكون المهنة فارغة',
		emailCannotBeEmpty: 'لا يمكن أن يكون عنوان البريد الإلكتروني فارغًا',
		rpmCannotBeEmpty: 'لا يمكن أن يكون برنامج دورة في الدقيقة فارغًا',
		enterFirstName: 'أدخل الاسم الأول',
		enterLastName: 'إدخال اسم آخر',
		virtualNurse: 'ممرضة افتراضية',
		virtualCaseManager: 'مدير الحالة الافتراضية',
		virtualIntensivist: 'المكثف الظاهري',
		virtualPatient: 'المريض الظاهري',
		manualPatient: 'المريض اليدوي',
		ehrPatient: 'إيه المريض',
		memberSuccessfullyModified: 'تم تعديل العضو بنجاح!',
		somethingWentWrongTryAgain: 'هناك خطأ ما! حاول مرة اخرى.',
		modifyVirtualCareProviderFirstName: 'تعديل الاسم الأول لمقدم الرعاية الافتراضية',
		modifyVirtualCareProviderLastName: 'تعديل الاسم الأخير لمقدم الرعاية الافتراضية',
		assignRoleVirtualCareProvider: 'يرجى تعيين دور لمقدم الرعاية الافتراضية.',
		errorWhileSavingThePhotos: 'خطأ أثناء حفظ الصور.',
		fileReadingWasAborted: 'تم إحباط قراءة الملف',
		fileReadingHasFailed: 'فشلت قراءة الملف',
		errorDeletingPhoto: 'خطأ في حذف الصورة.',
		dragAndDropOrSelectPhotos: 'قم بسحب وإسقاط بعض الصور هنا ، أو انقر لتحديد الصور',
		dragAndDropOrSelectAttachments: 'قم بسحب وإسقاط بعض الملفات هنا ، أو انقر لتحديد الملفات',
		photos: 'الصور',
		attachments: 'المرفقات',
		fiveAttachmentsMaximumUpload: 'يمكنك تحميل ما يصل إلى ٥ ملفات كحد أقصى.',
		fileUploadMaximumSize: 'يجب أن يكون الحد الأقصى لحجم الملف ٥ ميغا بايت',
		noPhotosForThisUser: 'لا توجد صور لهذا المستخدم.',
		uploadPhotos: 'تحميل الصور',
		dateSent: 'تاريخ إرسالها',
		invitedBy: 'مدعو من قبل',
		role: 'دور',
		addVirtualCareProvider: 'إضافة موفر رعاية افتراضية',
		addAdministrator: 'إضافة المسؤول',
		addAdminUsers: 'إضافة المستخدمين المسؤولين',
		addSuperUsers: 'إضافة مستخدمين ممتازين',
		addSuperAdminUsers: 'إضافة المستخدمين المشرفين المتميزين',
		deleteAlertSubscription: 'حذف الاشتراك في التنبيهات',
		areYouSureYouWantToDeleteSubscription: 'هل أنت متأكد أنك تريد حذف الاشتراك لـ {value}',
		setupDeviceAlerts: 'إعداد تنبيهات الجهاز',
		deviceAlerts: 'تنبيهات الجهاز',
		receiveAlertsFrom: 'استقبال التنبيهات من',
		addAnotherEmailAddress: 'إضافة عنوان بريد إلكتروني آخر',
		setupAlertsFor: 'إعداد التنبيهات ل',
		recipients: 'المستلمين',
		assignationLevel: 'مستوى التعيين',
		alertType: 'نوع التنبيه',
		invitationDate: 'تاريخ الدعوة',
		deviceIsOffline: 'الجهاز غير متصل',
		anAlertMustBeSelected: 'يجب تحديد تنبيه',
		couldNotFindThisEmail: 'تعذر العثور على هذا البريد الإلكتروني',
		alreadyAddedThisEmail: 'لقد قمت بإضافة هذا البريد الإلكتروني بالفعل',
		confirmation: 'التأكيد',
		areYouSureCancelInvitation:
			'هل أنت متأكد أنك تريد إلغاء هذه الدعوة؟ سيتم إلغاء تنشيط الرابط المرسل إلى هذا المستخدم المدعو ولا يمكن استخدامه بعد الآن.',
		overwrite: 'الكتابة فوق',
		warning: 'تحذير',
		featureOverwrite: 'ميزة الكتابة فوق',
		exclude: 'استبعاد',
		applyToAll: 'تنطبق على الجميع',
		endedTime: 'وقت الانتهاء',
		startTime: 'وقت البدء',
		roomsWithDifferentConfigs: 'الغرف التي تحتوي على مجموعة مختلفة من الأعلام المميزة',
		waringOverwriteDescription:
			'أنت تحاول إجراء تغييرات على قطاع به بالفعل أجهزة بها مجموعة مختلفة من الميزات غير التي تحاول تعيينها.',
		bySavingThisDescription:
			'إذا كنت ترغب في تجاوز أعلام الميزات الموجودة على الغرف أعلاه ، فيرجى تحديد تطبيق على الكل.إذا كنت ترغب في استبعاد تلك الغرف ، يرجى تحديد استبعاد.',
		areYouSureResendInvitation:
			'هل أنت متأكد أنك تريد إعادة إرسال هذه الدعوة؟ سيتلقى المستخدم الذي تقوم بدعوته بريدًا إلكترونيًا آخر يطلب منه الانضمام إلى hellocare.',
		areYouSureTwoFaReset:
			'هل أنت متأكد أنك تريد إعادة تعيين المصادقة الثنائية؟ سيتلقى المستخدم بريدًا إلكترونيًا يطلب إعادة تعيين المصادقة الثنائية.',
		userName: 'اسم االمستخدم،',
		dateInvited: 'تاريخ الدعوة ،',
		dateAdded: 'تم إضافة التاريخ،',
		healthSystems: 'النظم الصحية ،',
		areYouSureDeleteMember: 'هل أنت متأكد من حذف الأعضاء',
		userInfo: 'معلومات المستخدم',
		userPhotos: 'صور المستخدم',
		enterEmail: 'أدخل البريد الإلكتروني',
		writeEmailOfVcpToInvite: 'يرجى كتابة رسائل البريد الإلكتروني لمقدمي الرعاية الافتراضية الذين ترغب في دعوتهم',
		writeEmailOfVSToInvite: 'يرجى كتابة رسائل البريد الإلكتروني الخاصة بالمستخدمين الافتراضيين الذين ترغب في دعوتهم',
		writeEmailOfDCToInvite: 'يرجى كتابة رسائل البريد الإلكتروني الخاصة بالأطباء الرقميين الذين ترغب في دعوتهم',
		writeEmailOfDoctorsToInvite: 'يرجى كتابة رسائل البريد الإلكتروني الخاصة بالأطباء الذين ترغب في دعوتهم.',
		writeEmailOfAdminToInvite: 'يرجى كتابة رسائل البريد الإلكتروني للمستخدمين الإداريين الذين ترغب في دعوتهم.',
		inviteMax5PeopleAtOnce: 'يمكنك فقط دعوة ٥ أشخاص كحد أقصى في وقت واحد',
		selectAtLeastOneHSToAddRole: 'يرجى تحديد نظام صحي واحد على الأقل لإضافة (قيمة)',
		writeEmailsYouWishToInvite: 'يرجى كتابة رسائل البريد الإلكتروني الخاصة بـ (القيمة) التي ترغب في دعوتها',
		virtualCareProviders: 'مقدمو الرعاية الافتراضية',
		addRole: 'إضافة (القيمة)',
		userManagement: 'إدارةالمستخدم',
		fleetManagement: 'إدارة الأسطول',
		fleetDashboardDescription:
			'عند تمكين هذا، سيكون المستخدم قادرًا على رؤية حالات الأجهزة في الوقت الفعلي على النظام الصحي المحدد.',
		fleetDashboard: 'إدارة الأسطول',
		setupAlerts: 'إعداد التنبيهات',
		ofTheRooms: 'من الغرف',
		deviceAssignation: 'تعيين الجهاز',
		connection: 'الاتصال',
		clearFilter: 'مسح الفلتر',
		searchByRoomOrSN: 'البحث حسب اسم الغرفة أو SN',
		ethernet: 'إيثرنت',
		wireless: 'لاسلكي',
		devicesAssigned: 'الأجهزة المعينة',
		members: 'أعضاء',
		invitedMembers: 'الأعضاء المدعوين',
		invitedMember: 'عضو مدعو',
		active: 'نشط',
		notActive: 'غير نشيط',
		patientPosition: 'كان المريض خاملًا لمدة ١٠ ثوانٍ.',
		railDownMessage: 'انهار حامل السرير. الرجاء فحص المريض',
		alertCheckThePatient: 'انذار! افحص المريض.',
		fallRiskCheckThePatient: 'خطر السقوط. الرجاء فحص المريض.',
		pleaseCheckPatient: 'الرجاء فحص المريض.',
		concerningMeasurements: 'المريض لديه القياسات فيما يتعلق.',
		alertCheckTheBaby: 'انذار! افحص الطفل.',
		alertBabyExitingCrib: 'انذار! سرير طفل الخروج.',
		missingTools: 'أدوات مفقودة',
		surgicalToolsDetection: 'كشف الأدوات الجراحية',
		objectDetection: 'كشف الكائن',
		babyDetection: 'الكشف عن الطفل',
		patientPositionDetection: 'كشف موقف المريض',
		hospitalTimeZone: 'التوقيت في المستشفى',
		localTimeZone: 'التوقيت المحلي',
		bedRailDetection: 'كشف السرير والسكك الحديدية',
		actionRecognition: 'التعرف على العمل',
		skeletonFallDetection: 'كشف سقوط الهيكل العظمي',
		privacyOnSkeletonDetection: 'الخصوصية في الكشف عن الهيكل العظمي',
		otoscopeConnected: 'يتم توصيل منظار الأذن',
		switchView: 'تغيير العرض',
		areYouSureRebootHuddleCam:
			'هل أنت متأكد أنك تريد إعادة تشغيل الكاميرا {value}؟ في غضون 30 ثانية، ستعود العرض إلى منظور {value}.',
		chooseHealthSystemYouWantToManage: 'اختر النظام الصحي الذي تريد إدارته',
		chooseRegionFromHSManageHospitals: 'اختر منطقة من النظام الصحي أعلاه لإدارة المستشفيات عليها',
		togglePatientTVPowerOn: 'تبديل تشغيل تلفزيون المريض ،',
		volume: 'مقدار،',
		switchPatientHDMI: 'تبديل HDMI المريض ،',
		heartRateTooltip:
			'معدل ضربات القلب هو سرعة ضربات القلب التي يتم قياسها بعدد الانقباضات (نبضات) القلب في الدقيقة (نبضة في الدقيقة). تنص جمعية القلب الأمريكية على أن معدل ضربات القلب الطبيعي للبالغين عند الراحة هو 60-100 نبضة في الدقيقة.',
		bloodPressureTooltip:
			'ضغط الدم هو ضغط الدم المنتشر على جدران الأوعية الدموية. يعود معظم هذا الضغط إلى العمل الذي يقوم به القلب عن طريق ضخ الدم عبر الدورة الدموية. يستخدم مصطلح "ضغط الدم" بدون مزيد من المواصفات ، وعادة ما يشير إلى الضغط في الشرايين الكبيرة للدورة الدموية الجهازية.',
		perfusionIndexTooltip:
			'مؤشر التروية أو PI هو نسبة تدفق الدم النابض إلى تدفق الدم الساكن غير النابض في الأنسجة الطرفية للمريض مثل طرف الإصبع أو إصبع القدم أو شحمة الأذن. مؤشر الإرواء هو مؤشر على قوة النبض في موقع المستشعر.',
		stepsTooltip:
			'عدد الخطوات هو عدد الخطوات التي تقوم بها على مدار اليوم. يمكن أن تساعدك عدادات الخطى ومتعقبات النشاط الرقمي في تحديد عدد خطواتك. تحسب هذه الأجهزة الخطوات لأي نشاط يتضمن حركة تشبه الخطوات ، بما في ذلك المشي والجري وصعود الدرج والتزلج الريفي على الثلج وحتى الحركة أثناء قيامك بأعمالك اليومية.',
		flightsClimbedTooltip: 'تحسب رحلة السلالم بحوالي 10 أقدام (3 أمتار) من كسب الارتفاع (حوالي 16 خطوة).',
		leanBodyMassTooltip:
			'كتلة الجسم النحيل هي مقدار الوزن الذي تحمله وليس دهون الجسم. وهي تشمل العضلات ولكن أيضًا العظام والماء والأنسجة الضامة ، من بين مواد أخرى.',
		bodyFatPercentageTooltip:
			'نسبة الدهون في الجسم هي وزن الدهون في جسمك ، مقارنة بوزن الجسم الإجمالي ، والذي يشمل العضلات والعظام والماء وما إلى ذلك.',
		bodyMassIndexTooltip:
			'مؤشر كتلة الجسم هو حساب لمقاسك يأخذ في الاعتبار طولك ووزنك. يمكن أن يخبرك ما إذا كنت تعاني من نقص الوزن أو الوزن الطبيعي أو زيادة الوزن أو السمنة. يمكنه أيضًا قياس خطر الإصابة بالأمراض التي يمكن أن تحدث مع زيادة الدهون في الجسم.',
		bodyTemperatureTooltip:
			'تختلف درجة حرارة الجسم الطبيعية حسب الشخص والعمر والنشاط والوقت من اليوم. يُقبل متوسط درجة حرارة الجسم الطبيعية عمومًا على أنه 98.6 درجة فهرنهايت (37 درجة مئوية). غالبًا ما تعني درجة الحرارة التي تزيد عن 100.4 درجة فهرنهايت (38 درجة مئوية) أنك تعاني من حمى ناتجة عن عدوى أو مرض. تتغير درجة حرارة الجسم عادة على مدار اليوم.',
		oxygenSaturationTooltip:
			'يُعد تشبع الأكسجين مقياسًا لكمية الأكسجين في البروتين (الهيموغلوبين) في خلايا الدم الحمراء. لكي يعمل جسمك بشكل صحيح ، يحتاج إلى مستوى معين من الأكسجين المنتشر في الدم. يتم تحميل خلايا الدم الحمراء (مشبعة) بالأكسجين في الرئتين وتحمله في جميع أنحاء الجسم.',
		sleepAnalysisTooltip:
			'يوفر تحليل النوم نظرة ثاقبة لعادات نومك. يمكن أن تساعدك أجهزة تتبع النوم والشاشات في تحديد مقدار الوقت الذي تقضيه في السرير والنوم. تقدر هذه الأجهزة الوقت الذي تقضيه في السرير ووقت النوم من خلال تحليل التغيرات في النشاط البدني ، بما في ذلك الحركة أثناء الليل. يمكنك أيضًا تتبع نومك عن طريق إدخال تقديرك الخاص لوقت نومك ووقت نومك يدويًا.  n فترة "In Bed" تعكس الفترة الزمنية التي تستلقي فيها على السرير بنية النوم. بالنسبة لمعظم الناس ، يبدأ عند إطفاء الأنوار وينتهي عند النهوض من السرير. تعكس فترة "النوم" الفترة (الفترات) التي تكون فيها نائمًا.',
		bloodGlucoseTooltip:
			'جلوكوز الدم ، ويسمى أيضًا سكر الدم ، هو السكر الرئيسي الموجود في الدم. يأتي من الطعام الذي تتناوله ، وهو مصدر الطاقة الرئيسي لجسمك.',
		stethoscopeTooltip:
			'السماعة الطبية هي جهاز طبي صوتي للاستماع أو الاستماع إلى الأصوات الداخلية لحيوان أو جسم بشري. يمكن استخدام سماعة الطبيب للاستماع إلى أصوات القلب أو الرئتين أو الأمعاء ، وكذلك لسماع تدفق الدم في الشرايين والأوردة.',
		respiratoryRateTooltip: 'معدل التنفس هو معدل تنفسك (الشهيق والزفير) خلال فترة زمنية معينة (عادة 60 ثانية).',
		noDataFound: 'لا توجد بيانات متاحة لهذا النطاق الزمني',
		tryOtherDateRange: 'حاول بتحديد نطاق تاريخ مختلف',
		healthSystemDoesNotHaveCreatedHospitals:
			'لا يوجد في هذا النظام الصحي أي مستشفيات تم إنشاؤها. تأكد من إنشائها قبل تعيين الممرضات!',
		requestAccepted: 'طلب مقبول',
		requestRejected: 'طلب رفض',
		clickAllowToMakeCall: 'انقر ؟ السماح  ؟ لإجراء أتصال',
		allowPermissionToMakeCall: 'لإجراء مكالمة على hellocare ، تحتاج إلى السماح بمنح أذونات للميكروفون والكاميرا',
		participantHearAndSeeYou: 'سيسمح هذا للمشاركين الآخرين بسماعك ورؤيتك',
		canTurnOffMicCameraLater: 'يمكنك دائمًا إيقاف تشغيل الميكروفون أو الكاميرا في وقت متأخر',
		turnOnMicCamera: 'قم بتشغيل الميكروفون والكاميرا',
		deviceNotFound: 'لم يتم العثور علي الجهاز',
		videoCallTurnOnCamera: 'لإجراء مكالمة فيديو على hellocare ، تحتاج إلى تشغيل الكاميرا',
		audioCallTurnOnMicRefreshPage: 'لإجراء مكالمة صوتية ، تحتاج إلى تشغيل الميكروفون. حاول مرة أخرى عن طريق تحديث الصفحة',
		deviceMayBeInUse: 'قد يكون الجهاز قيد الاستخدام',
		camOrMicAnotherApp: 'قد تكون الكاميرا أو الميكروفون قيد الاستخدام بواسطة تطبيق آخر',
		closeOtherAppRefresh: 'الرجاء إغلاق التطبيق الآخر وتحديث الصفحة',
		noData: 'لايوجد بيانات',
		pleaseReachAdmin: 'يرجى التواصل مع المسؤول لتوفير هذه التكوينات.',
		overviewDescription: 'عند تمكين هذا، سيتم عرض بيانات عامة للمراقبة، والتقريب، والمعتمدة على الأدوار.',
		providerCentricReportDescription: 'عند تمكين هذا، سيتم عرض بيانات مقدمي الخدمة عن عدد الجلسات، والمرضى والجلسات',
		patientCentricReportDescription: 'عند تمكين هذا، سيتم عرض بيانات المريض مثل عدد الجلسات، والتدخلات، والإجراءات المتخذة.',
		plsReachAdministratorToHaveThisFeatureAvailable: 'يرجى التواصل مع مسؤولك لتتوفر هذه الميزة',
		selectOneOfConnectedDoctors: 'حدد أحد الأطباء المتصلين!',
		noConnectedDoctor: 'لا يوجد لديك أطباء متصلون!',
		noActionsFound: 'لم يتم العثور على أي إجراءات',
		noHelloIsSelected: 'ليس لديك جهاز {value} متصل!',
		tryBySelectingOne: 'جربه عن طريق اختيار واحد!',
		optionInitiateAudioCall:
			'سيؤدي هذا الخيار إلى بدء مكالمة صوتية للمريض ، وسيتمكن من سماعك وستكون قادرًا على سماعها. أثناء المكالمة ، يمكنك تشغيل الكاميرا الخاصة بك أو كاميرا المريض ، أو حتى مشاركة شاشتك.',
		myPatients: 'مرضاي',
		myHellos: 'مرحبا بك في {value}',
		noConnectedPatientsYet: 'ليس لديك مرضى متصلون حتى الآن!',
		noConnectedDoctorsYet: 'ليس لديك أطباء متصلون حتى الآن!',
		editDepartmentDetails: 'تحرير تفاصيل القسم',
		deleteDepartment: 'حذف القسم',
		floorsInThisDepartment: 'الطوابق في هذا القسم',
		roomsInThisDepartment: 'غرف في هذا القسم',
		enrolledDevices: 'الأجهزة المسجلة',
		enrolledCompanionDevices: 'الأجهزة المرافقة',
		devices: 'الأجهزة',
		rooms: 'غرف',
		floors: 'طوابق',
		deviceSerialNumber: 'الرقم التسلسلي للجهاز',
		tvSerialNumber: 'الرقم التسلسلي للتلفزيون',
		deviceEnrolled: 'تم تسجيل الجهاز',
		actions: 'أجراءات',
		roomNames: 'أسماء الغرف',
		roomNumber: 'رقم الغرفة',
		writeRoomNumber: 'اكتب رقم الغرفة',
		floorNames: 'أسماء الطوابق',
		unassign: 'إلغاء التعيين',
		unassignDevice: 'إلغاء تعيين الجهاز',
		unassignPatient: 'إلغاء تعيين المريض',
		sureToUnassignDevice: 'هل أنت متأكد من أنك تريد إلغاء تعيين الجهاز {value}؟ لن تتمكن أنت أو المريض من إجراء أي مكالمات.',
		sureToUnassignPatient:
			'هل أنت متأكد من أنك تريد إلغاء تعيين {value} من هذا الجهاز؟ لن تتمكن أنت أو المريض من إجراء أي مكالمات.',
		sureToDeleteOrg:
			'هل أنت متأكد أنك تريد حذف {value}؟ سيتم حذف أي أجهزة مرتبطة بها ولن يتمكن أي من مقدمي الرعاية الافتراضية ولا المرضى من إجراء أي مكالمات.',
		editFloorDetails: 'تحرير تفاصيل الطابق ،',
		deleteFloor: 'حذف الكلمة "،',
		roomsInThisFloor: 'غرف في هذا الطابق ،',
		signInToYourAccount: 'تسجيل الدخول إلى حسابك،',
		editHospitalDetails: 'تحرير تفاصيل المستشفى ،',
		deleteHospital: 'حذف المستشفى ،',
		departmentsInThisHospital: 'الأقسام في هذا المستشفى ،',
		floorsInThisHospital: 'الطوابق في هذا المستشفى ،',
		roomsInThisHospital: 'غرف في هذا المستشفى ،',
		departmentNames: 'أسماء الأقسام ،',
		departments: 'الإدارات ،',
		editRoomDetails: 'تحرير تفاصيل الغرفة ،',
		familyMember: 'فرد من العائلة ،',
		familyMembers: 'أفراد الأسرة،',
		assignFamilyMember: 'تعيين فرد من العائلة ،',
		babyRoom: 'غرفة الطفل ،',
		roomDetails: 'تفاصيل الغرفة ،',
		roomType: 'نوع الغرفة،',
		deleteRoom: 'حذف الغرفة ،',
		addDevice: 'اضف جهاز،',
		addCompanionDevice: 'إضافة جهاز مصاحب',
		roomDevice: 'جهاز الغرفة ،',
		assignDevice: 'تعيين الجهاز "،',
		assignCompanionDevice: 'تعيين جهاز مصاحب',
		selectSerialNumberAssociateWithRoom: 'حدد رقمًا تسلسليًا لربطه بهذه الغرفة',
		selectCompanionMode: 'حدد الوضع المصاحب',
		selectCompanionDevice: 'حدد الجهاز المرافق',
		theSectorTypeRoomLocatedAt: 'تقع هذه الغرفة في (القيمة)',
		download: 'تحميل،',
		document: 'وثيقة،',
		errorWhileFetchingPatients: 'خطأ أثناء إحضار المرضى',
		selectPatientHelloDevice: 'اختر أحد أجهزة المرضى/{value}!',
		noConnectedPatients: 'لا يوجد مرضى متصلون',
		joinHELLOHealth: 'انضم إلى هيلوكير',
		haveVisitInMinutes: 'قم بزيارة لبضع دقائق',
		createUserAccount: 'إنشاء حساب مستخدم',
		createProviderAccount: 'إنشاء حساب مزود',
		createHealthSystemProfile: 'إنشاء ملف تعريف النظام الصحي',
		addRegionsForThisHealthSystem: 'أضف مناطق لهذا النظام الصحي',
		addNew: 'اضف جديد',
		healthSystemCreatedSuccessfully: 'تم إنشاء ملف تعريف نظامك الصحي بنجاح',
		accountBeingCreatedMayTakeMinutes: 'ملفك الشخصي قيد الإنشاء. قد يستغرق هذا بضع دقائق!',
		invalidInvitation: 'هذه الدعوة غير صالحة',
		inviteAlreadyAccepted: 'تم بالفعل قبول "الدعوة',
		inviteCancelled: 'تم إلغاء الدعوة من قبل مسؤول النظام',
		inviteExpired: 'لقد انتهت صلاحية الدعوة',
		checkInvitationLinkValidity:
			'ارجع إلى بريدك الإلكتروني وتأكد من أن الرابط الذي نقرت عليه صالح. أيضًا ، اتصل بالمسؤول للتأكد من أنك لم تقبل هذه الدعوة بالفعل.',
		anotherNursePickedUpCall: 'التقطت ممرضة أخرى هذه المكالمة',
		waitAttemptingReconnect: 'الرجاء الانتظار ... جاري محاولة إعادة الاتصال بالشبكة',
		manageHealthSystem: 'إدارة النظام الصحي',
		changesWillBeAppliedToThisHS: 'سيتم تطبيق أي تغييرات تجريها على هذا النظام. يمكنك إعادة تسمية أو حذف هذا النظام.',
		pressEnterToSave: 'اضغط دخول للحفظ',
		regions: 'المناطق',
		availableRegionsInThisHS: 'هذه هي المناطق المتوفرة في هذا النظام الصحي. إنشاء المزيد أو إعادة تسمية أو حذف منطقة',
		addNewRegion: 'أضف منطقة جديدة',
		areYouSureToDeleteRegion: 'هل أنت متأكد أنك تريد حذف هذه المنطقة',
		areYouSureToDeleteHS: 'هل أنت متأكد أنك تريد حذف هذا النظام الصحي؟ ',
		sectorIsLocatedAt: 'هذه (القيمة 2) تقع في (القيمة}',
		boardType: 'نوع اللوحة',
		selectBoard: ' اختر لوحة',
		rowsPerPage: 'عدد الصفوف في الصفحة',
		minttiStethoscope: 'سماعة الطبيب مينتي',
		littmannStethoscope: 'سماعة الطبيب ليتمان',
		manuallyAdded: 'أضيفت يدويا',
		addData: 'إضافة البيانات',
		systolic: 'الانقباضي',
		diastolic: 'الانبساطي',
		selectStartDate: 'حدد تاريخ البدء',
		selectEndDate: 'حدد تاريخ الانتهاء',
		allRecordedData: 'كافة البيانات المسجلة',
		deleteAllDataRecords: 'حذف كافة سجلات البيانات',
		add: 'يضيف',
		addDataManually: 'قم بإضافة البيانات يدويًا',
		dateAndTime: 'التاريخ و الوقت',
		inBed: 'في السرير',
		asleep: 'نائمين',
		selectState: 'اختر ولايه',
		done: 'منتهي',
		deleteThisDataRecord: 'احذف سجل البيانات هذا',
		dataDetails: 'تفاصيل البيانات',
		dateOfMeasurement: 'تاريخ القياس',
		source: 'مصدر',
		manufacturer: 'الصانع',
		model: 'نموذج',
		areYouSureDeleteThisRecord: 'هل أنت متأكد أنك تريد حذف هذا السجل',
		areYouSureDeleteAllRecords: 'هل أنت متأكد أنك تريد حذف كافة سجلات البيانات؟',
		documentName: 'اسم الملف،',
		creationDate: 'تاريخ الإنشاء،',
		attachment: 'المرفق،',
		reboot: 'اعادة التشغيل،',
		rebootCamera: 'إعادة تشغيل الكاميرا ،',
		patientsTV: 'تلفزيون المريض ،',
		togglePatientTVtoHDMI: 'تبديل تلفزيون المريض إلى HDMI ،',
		maxCharacterLengthExceeded: 'تم تجاوز الحد الأقصى لعدد الأحرف',
		firstNameMustStartWithCapitalLetter: 'يجب أن يبدأ الاسم الأول بحرف كبير',
		pleaseWriteFirstName: 'الرجاء كتابة اسمك الأول',
		lastNameMustStartWithCapitalLetter: 'الاسم الأخير يجب أن يبدأ بحرف كبير',
		pleaseWriteLastName: 'الرجاء كتابة اسمك الأخير',
		emailAlreadyRegistered: 'عنوان البريد الإلكتروني المقدم مسجل بالفعل.',
		newEmailDifferent: 'يجب أن يكون البريد الإلكتروني الجديد مختلفًا عن البريد الإلكتروني القديم.',
		invalidEmail: 'البريد الإلكتروني المقدم غير صالح',
		pleaseWriteEmail: 'الرجاء كتابة البريد الإلكتروني الخاص بك',
		emailsMustMatch: 'يجب أن يكون بريدك الإلكتروني الجديد مختلفًا عن بريدك القديم.',
		passwordsMustMatch: 'البريد الالكتوني يجب ان يتطابق',
		pleaseConfirmYourEmail: 'يجب ان تتطابق كلمات المرور',
		pleaseWriteDateOfBirth: 'يرجى تأكيد عنوان البريد الإلكتروني الخاص بك',
		passwordCannotBeLessThan8Chart:
			'يجب أن تتكون كلمة مرورك من 8 أحرف على الأقل وأن تحتوي على حرف كبير واحد على الأقل وحرف صغير واحد ورقم واحد ورمز خاص',
		pleaseWritePassword: 'الرجاء كتابة كلمة المرور الخاصة بك',
		pleaseWriteConfirmPassword: 'يرجى التأكد من صحة كلمة المرور الخاصة بك',
		haveToAgreeToTermsAndConditions: 'يجب أن توافق على الشروط والأحكام الخاصة بنا!',
		haveToAgreePrivacyPolicy: 'يجب أن توافق على سياسة الخصوصية الخاصة بنا!',
		locationMustStartWithCapitalLetter: 'يجب أن يبدأ الموقع بحرف كبير',
		pleaseWriteCurrentLocation: 'الرجاء كتابة موقعك الحالي',
		pleaseSelectDateOfBirth: 'الرجاء تحديد تاريخ ميلادك',
		pleaseSelectSex: 'الرجاء تحديد الجنس',
		pleaseEnterHeight: 'الرجاء إدخال طولك',
		pleaseEnterWeight: 'من فضلك ادخل وزنك',
		pleaseSelectOne: 'رجاءا اختر واحدة',
		pleaseSelectHealthInsurancePlan: 'الرجاء تحديد خطة التأمين الصحي الخاصة بك',
		pleaseWriteSubscriberId: 'يرجى كتابة معرف المشترك الخاص بك',
		pleaseWritePrimarySubscriberFirstName: 'الرجاء كتابة الاسم الأول للمشترك الأساسي الخاص بك',
		pleaseWritePrimarySubscriberLastName: 'يرجى كتابة الاسم الأخير للمشترك الأساسي الخاص بك',
		pleaseWritePrimarySubscriberBirthDate: 'يرجى كتابة تاريخ ميلاد المشترك الأساسي الخاص بك',
		serviceKeyLearnMore:
			'قد تتلقى مفتاح الخدمة من صاحب العمل أو الخطة الصحية أو مقدم الرعاية الصحية لتنشيط خدمة جديدة. إنه اختياري وليس مطلوبًا.',
		numberTimesFallen: 'عدد مرات السقوط',
		lowest: 'أدنى',
		average: 'متوسط',
		highest: 'ارتفاعات',
		changePresence: 'تغيير التواجد',
		height: 'الارتفاع',
		bodyMassIndex: 'مؤشر كتلة الجسم',
		primaryDiagnosis: 'التشخيص الأساسي',
		headInjury: 'إصابة بالرأس',
		basicInformation: 'معلومات اساسية',
		fillUpFormOnRightSide: 'املأ النموذج على الجانب الأيمن باستخدام بياناتك الحقيقية!',
		currentLocation: 'الموقع الحالي',
		back: 'عودة',
		continue: 'استمر',
		signUp: 'قم بالتسجيل',
		alreadyHaveAnAccount: 'هل لديك حساب',
		clickHereToSignIn: 'اضغط هنا لتسجيل الدخول',
		personalInformation: 'معلومات شخصية',
		agreeToThe: 'أنا أوافق على',
		termsAndConditions: 'الأحكام والشروط',
		privacyPolicy: 'سياسة الخصوصية',
		healthInsurance: 'تأمين صحي',
		doYouHaveHealthInsurance: 'هل لديك تأمين صحي؟',
		yes: 'نعم',
		no: 'ليس',
		skipPlanMyPlanIsNotListed: 'تخطي هذه الخطوة / لم يتم سرد خطتي',
		insuranceMayCoverAllOrPart: 'قد يغطي التأمين كل زياراتك أو جزء منها. إذا لم تكن خطتك مدرجة ، فلا يزال بإمكانك الزيارة.',
		healthInsurancePlan: 'خطة التأمين الصحي',
		subscriberId: 'معرف المشترك',
		planIsNotListedFileClaim: 'إذا لم تكن خطتك مدرجة ، فلا يزال بإمكانك تقديم مطالبة بعد الزيارة',
		areYouPrimarySubscriber: 'هل أنت المشترك الأساسي؟',
		primarySubscriberFirstName: 'الاسم الأول للمشترك الأساسي',
		primarySubscriberLastName: 'الاسم الأخير للمشترك الأساسي',
		primarySubscriberDateOfBirth: 'تاريخ الميلاد الأساسي للمشترك',
		relationshipToSubscriber: 'العلاقة بالمشترك',
		serviceKey: 'مفتاح الخدمة',
		enterReceivedKey: 'إذا تلقيت مفتاحًا من صاحب العمل أو مجموعة أخرى ، أدخله هنا.',
		learnMore: 'يتعلم أكثر',
		finish: 'ينهي',
		yourAccountHasBeenSuccessfullyCreated: 'تم إنشاء حسابك بنجاح!',
		checkEmailToActivateAccount: 'تحقق من بريدك الإلكتروني لتفعيل حسابك.',
		yourAccountActivated: 'تم تنشيط حسابك !',
		continueToSignIn: 'واصل تسجيل الدخول',
		accountActivationFailedTryAgain: 'فشل تفعيل الحساب! حاول مرة اخرى',
		nurse: 'ممرضة',
		virtualCareProvider: 'مزود رعاية افتراضية',
		outgoing: 'منفتح',
		incoming: 'واردة',
		viewAll: 'مشاهدة الكل',
		callInitiator: 'بادئ المكالمة',
		room: 'غرفة',
		dateSlashTime: 'التاريخ / الوقت',
		callOrigin: 'كول اوريجين',
		callStatus: 'حالة المكالمة',
		callDuration: 'مدة المكالمة',
		audioUsed: 'الصوت المستخدم',
		videoUsed: 'الفيديو المستخدم',
		contentSharing: 'مشاركة المحتوى',
		queue: 'يصطف',
		export: 'يصدّر',
		region: 'منطقة',
		hospital: 'مستشفى',
		department: 'قسم،',
		floor: 'أرضية،',
		queueOfNursesThatWereCalled: 'طابور الممرضات التي تم استدعاؤها',
		generalSettings: 'الاعدادات العامة',
		closeSession: 'اغلق الموسم',
		selectMemberToInvite: 'حدد عضوا لدعوته',
		selectMeetingPlaceDevice: 'حدد مكانًا أو جهازًا للاجتماع',
		deviceOnCall: 'الجهاز في مكالمة أخرى.',
		deviceOnCallWithNurse: 'تتم مراقبة هذا الجهاز بواسطة',
		deviceOffline: 'الجهاز في وضع عدم الاتصال',
		initiatingMonitoring: 'بدء المراقبة ...',
		notAnswering: 'فشل الاتصال! يرجى إغلاق النافذة وإعادة فتحها لمتابعة المراقبة.',
		leftCall: 'فشل إعادة الاتصال بالشبكة. الرجاء إضافة هذا الموجز مرة أخرى. ،',
		disconnected: 'تم قطع اتصال المريض بالمكالمة. ،',
		connected: 'متصل ،',
		reconnecting: 'الرجاء الانتظار ... جاري محاولة إعادة الاتصال بالشبكة.',
		participantRemoved: 'تم إنهاء هذه الجلسة بواسطة مسؤول.',
		addToMonitoringView: 'أضف إلى مساحة الرصد',
		organization: 'منظمة',
		callLogs: 'سجلات المكالمات',
		collapse: 'انهيار',
		recentLowerCase: 'مؤخرًا',
		hospitals: 'المستشفيات',
		rounding: 'المستشفيات',
		patientRequests: 'طلبات المريض',
		solutions: 'حلول',
		providers: 'الموفرون',
		monitoring: 'يراقب',
		close: 'قريب',
		addHospital: 'أضف مستشفى',
		create: 'خلق',
		createMeeting: 'إنشاء اجتماع',
		monday: 'الاثنين',
		tuesday: 'يوم الثلاثاء',
		wednesday: 'الأربعاء',
		thursday: 'يوم الخميس',
		friday: 'جمعة',
		saturday: 'السبت',
		sunday: 'الأحد',
		bloating: 'الانتفاخ',
		cough: 'سعال',
		diarrhea: 'إسهال',
		dizziness: 'دوخة',
		fatigue: 'إعياء',
		fever: 'حُمى',
		headache: 'صداع الراس',
		muscleCramp: 'تشنج العضلات',
		nausea: 'غثيان',
		throatIrritation: 'تهيج الحلق',
		runnyNose: 'سيلان الأنف',
		throwingUpVomiting: 'القيء (القيء)',
		soreThroat: 'إلتهاب الحلق',
		headPressure: 'ضغط الرأس',
		lowBodyTemperature: 'انخفاض درجة حرارة الجسم',
		highBodyTemperature: 'ارتفاع درجة حرارة الجسم',
		backPain: 'ألم في الظهر',
		appetiteChanges: 'تغيرات الشهية',
		earache: 'ألم الأذن',
		sweating: 'التعرق',
		sneezing: 'العطس',
		fainting: 'إغماء',
		skinRash: 'الطفح الجلدي',
		insomnia: 'أرق',
		itchySkin: 'حكة في الجلد',
		jointPainAndSwelling: 'ألم وتورم المفاصل',
		kneePain: 'ألم الركبة',
		legPain: 'الم الساق',
		limbNumbness: 'تنميل الأطراف ،',
		muscleAchesAndPains: 'أوجاع وآلام في العضلات ،',
		nosebleeds: 'نزيف الأنف ،',
		shoulderPain: 'الم الكتف،',
		stomachPain: 'آلام في المعدة ،',
		swellingInJoints: 'تورم (في المفاصل) ،',
		tummyPain: 'آلام البطن ،',
		addDepartment: 'إضافة قسم ،',
		addFloor: 'أضف طابق ،',
		addRoom: 'إضافة غرفة ،',
		goBackToHealthApp: 'ارجع إلى تطبيق hellocare ،',
		fullScreen: 'شاشة كاملة،',
		exitFullScreen: 'الخروج من الشاشة الكاملة',
		fitToScreen: 'تناسب الشاشة ،',
		screenshare: 'حصة الشاشة،',
		sound: 'الصوت ،',
		turnPatientTVOff: 'قم بإيقاف تشغيل تلفزيون المريض ،',
		turnPatientTVOn: 'قم بتشغيل تلفزيون المريض ،',
		changeEmail: 'تغيير الايميل،',
		changePassword: 'غير كلمة السر،',
		currentPassword: 'كلمة المرور الحالية،',
		enterCurrentPassword: 'إدخل كلمة السر الحالية،',
		enterYourCurrentPassword: 'أدخل كلمة المرور الحالية،',
		enterNewPassword: 'أدخل كلمة المرور الجديدة ،',
		reEnterNewPassword: 'أعد إدخال كلمة المرور الجديدة ،',
		updatePassword: 'تغيير معلومات حسابك ،',
		editChangeAccountInfo: 'أنت متواجد حاليا',
		statusCurrentlyOnline: 'أنت غير متوفر حاليا "،',
		statusCurrentlyOffline: 'ارجع إلى تطبيق hellocare ،',
		passwordChangedSuccessfully: 'تم تغيير كلمة المرور الخاصة بك بنجاح ،',
		emailChangedSuccessfully: 'تم تغيير بريدك الإلكتروني بنجاح ،',
		oldPasswordIncorrect: 'كلمة المرور الحالية غير صحيحة،',
		errorDuringRequestProcessing: 'حدث خطأ أثناء معالجة طلبك،',
		passwordRequired: 'يجب كتابة كلمة المرور "،',
		atLeastCharacters: 'يجب أن تحتوي كلمة المرور على الأقل',
		characters: 'شخصيات',
		notMatchCurrentPassword: 'يجب أن تكون كلمة مرورك الجديدة مختلفة عن كلمة مرورك السابقة',
		mustMatchPassword: 'يجب أن تكون مماثلة لكلمة المرور الجديدة "',
		passwordConfirmationRequired: 'مطلوب تأكيد كلمة المرور ،',
		participantCameraOff: 'تم إيقاف كاميرا (القيمة)',
		modifyUserInformation: 'تعديل معلومات المستخدم ،',
		zipCode: 'الرمز البريدي،',
		country: 'بلد،',
		city: 'مدينة،',
		state: 'بلد',
		visitCostCurrency: 'زيارة عملة التكلفة ،',
		dateOfBirthRequired: 'تاريخ الميلاد مطلوب،',
		countryRequired: 'الدولة مطلوبة،',
		stateRequired: 'الدولة مطلوبة ،',
		cityRequired: 'المدينة مطلوبة ،',
		zipCodeRequired: 'الرمز البريدي مطلوب ،',
		addressRequired: 'العنوان مطلوب،',
		enterCity: 'أدخل مدينتك،',
		enterZipCode: 'أدخل رمزك البريدي،',
		enterAddress: 'أدخل عنوانك ،',
		enterVisitCost: 'أدخل تكلفة الزيارة الخاصة بك ،',
		writeWelcomeMessage: 'اكتب رسالتك الترحيبية ،',
		profileEditedSuccessfully: 'تم تحرير ملف التعريف الخاص بك بنجاح ،',
		profilePicture: 'صورة الملف الشخصي ،',
		updatePicture: 'تحديث الصورة ،',
		shouldUploadProfilePicture: 'يجب عليك تحميل صورة الملف الشخصي ،',
		acceptedFilesTypesAre: 'أنواع الملفات المقبولة هي',
		select: 'يختار،',
		visitCostRequired: 'تكلفة الزيارة مطلوبة ،',
		visitCostCurrencyRequired: 'عملة تكلفة الزيارة مطلوبة ،',
		welcomeMessageRequired: 'رسالة الترحيب مطلوبة ،',
		from: 'من،',
		to: 'إلى،',
		requestDoctor: 'طلب دكتور،',
		thankYou: 'شكرًا لك،',
		requestSentSuccessfully: 'تم ارسال طلبك بنجاح',
		goToThe: 'اذهب إلى',
		toGetStarted: 'لتبدأ',
		requests: 'الطلبات',
		noConversation: 'ليس لديك محادثة مع (القيمة)',
		startConvo: 'ابدأ المحادثة بكتابة رسالة أو إرفاق ملف',
		noRequestsFound: 'لم يتم العثور على طلبات!',
		noMatchesFound: 'لم يتم العثور على تطابق',
		noDoctorsBasedOnSymptoms: 'لم يتم العثور على أطباء يطابقون أعراضك ومخاوفك الصحية',
		noHealthDataFound: 'لم يتم العثور على بيانات صحية! ،',
		noHealthDataCurrently: 'حاليًا ليس لديك أي بيانات صحية مضافة ،',
		newConnection: 'اتصال جديد،',
		isNowConnection: 'أصبح الآن اتصالاً. "،',
		removeConnection: 'إزالة الاتصال ،',
		callViewMessage: 'يمكنك الاتصال أو إرسال رسالة أو عرض البيانات الصحية (القيمة) عبر قائمة المرضى',
		goToPatient: 'اذهب إلى المريض ،',
		newConfiguration: 'التكوين الجديد ،',
		addPrivacyConfiguration: 'إضافة تكوين الخصوصية ،',
		editPrivacyConfiguration: 'تحرير تكوين الخصوصية ،',
		selectPrivacy: 'حدد الخصوصية ،',
		createdBy: 'انشأ من قبل،',
		dateCreated: 'تاريخ الإنشاء،',
		isHealthSystemActive: 'هل نظام الصحة نشط "،',
		isPrivateModeOn: 'هل وضع الخصوصية قيد التشغيل ،',
		healthCareId: 'معرف الرعاية الصحية ،',
		privacyModeIsOn: 'وضع الخصوصية قيد التشغيل "،',
		configurations: 'التكوينات ،',
		turnOffSurgicalTools: 'قم بإيقاف تشغيل الوضع الجراحي ،',
		turnOnSurgicalTools: 'تشغيل الوضع الجراحي ،',
		turnOffBabyDetection: 'قم بإيقاف تشغيل وضع الطفل ،',
		turnOnBabyDetection: 'تشغيل وضع الطفل ،',
		turnOffPatientPosition: 'قم بإيقاف تشغيل وضع المريض ،',
		turnOnPatientPosition: 'تشغيل وضع المريض ،',
		turnOffBedAndRails: 'قم بإيقاف تشغيل السرير والقضبان ،',
		turnOffSkeletonAndPrivacy: 'قم بإيقاف تشغيل وضع الهيكل العظمي ،',
		turnOnBedAndRails: 'قم بتشغيل السرير والقضبان ،',
		turnOnSkeletonAndPrivacy: 'قم بتشغيل وضع الهيكل العظمي ،',
		turnOnObjectDetection: 'تشغيل "اكتشاف الكائن" ،',
		turnOffObjectDetection: 'قم بإيقاف تشغيل اكتشاف الكائن ،',
		surgicalToolsMissing: 'الأدوات الجراحية مفقودة ،',
		railDown: 'السكك الحديدية أسفل',
		personSitting: 'شخص جالس',
		personStanding: 'شخص واقف',
		babyFaceDown: 'تم الكشف عن وجه الطفل',
		babyExitsCrib: 'تم الكشف عن سرير الطفل المخارج',
		personFaceDown: 'تم اكتشاف وجه شخص لأسفل',
		breathsPerMin: 'أنفاس / دقيقة ،',
		unknownDevice: 'جهاز غير معروف،',
		activities: 'أنشطة،',
		inputValue: 'قيمة،',
		privateMode: 'وضع الخاص،',
		doctorOnList: 'طبيب،',
		superUserRoleValidation: 'لا يمكن تعيين دور المستخدم المتميز لأنظمة صحية متعددة',
		superUserHealthSystemValidation: 'يجب تعيين المستخدم المميز إلى النظام الصحي',
		superUserOnList: 'المستخدم المتميز ،',
		superUser: 'المستخدم المتميز ،',
		superAdminOnList: 'مشرف فائق ،',
		ownerOnList: 'صاحب،',
		nurseOnList: 'ممرضة،',
		patientOnList: 'صبور،',
		adminOnList: 'مسؤل،',
		sentOnList: 'أرسلت،',
		registeredOnList: 'مسجل',
		pendingOnList: 'قيد الانتظار،',
		cancelledOnList: 'ألغيت،',
		failedOnList: 'باءت بالفشل،',
		virtualSitter: 'جليسة افتراضية ،',
		undefinedOnList: 'غير معرف،',
		successfulOnList: 'ناجح،',
		partiallySuccessfulOnList: 'ناجح جزئيًا "،',
		disruptedOnList: 'معطلة',
		partiallyDisruptedOnList: 'معطلة جزئياً ،',
		missedOnList: 'افتقد،',
		abortedOnList: 'أجهضت ،',
		droppedOnList: 'مسقطة "،',
		on: 'على،',
		off: 'طافئ،',
		fitnessTracker: 'متتبع اللياقة البدنية',
		bodyWeightScale: 'مقياس وزن الجسم',
		weightScale: 'مقياس الوزن',
		weightScaleDevice: 'مقياس الوزن',
		noEvents: 'لا توجد أحداث.',
		glucometer: 'غلوكمتر',
		infraredEarThermometer: 'ميزان حرارة الأذن بالأشعة تحت الحمراء',
		thermometer: 'ميزان الحرارة',
		iphone: 'ايفون',
		pulseOximeter: 'مقياس النبض',
		hours: 'ساعات',
		timeOfMeasurement: 'وقت القياس',
		fallDetected: 'تم الكشف عن السقوط',
		custom: 'مخصص',
		auto: 'تلقائي',
		audioSettings: 'إعدادات الصوت',
		audioTuning: 'ضبط الصوت',
		selectValue: 'اختر القيمة',
		selectOneOfTheOptions: 'اختر أحد الخيارات',
		selectCustomValue: 'اختر قيمة مخصصة',
		hdmiSyncDelay: 'تأخير نظام HDMI',
		detected: 'مُكتَشَف',
		hasMeasured: 'قام بقياس',
		callPatient: 'استدعاء المريض',
		callPatientDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من الاتصال بالمريض أثناء مراقبتهم.',
		patientCommunication: 'رسالة التتبع',
		patientCommunicationDescription:
			'عند تمكين هذا الخيار، سيتمكن المرضى من رؤية رسالة على الشاشة {helloName} تفيدهم بأنهم يتم مراقبتهم.',
		monitoringPrecautionsLegend: 'أسطورة مراقبة الاحتياطات',
		acknowledge: 'يُقرّ',
		hasAcceptedYourRequest: 'قام بقبول طلبك لفئة (القيمة)',
		hasRejectedYourRequest: 'رفض طلبك للفئة {value)',
		hasCanceledRequest: 'ألغى طلب فئة {القيمة)',
		hasSentRequest: 'لقد أرسل إليك طلبًا للفئة {القيمة)',
		unread: 'غير مقروء',
		earlier: 'في وقت سابق',
		alerts: 'تنبيهات',
		upload: 'رفع',
		zipCodeDigitsMax: 'يجب أن يكون الرمز البريدي أقل من ١٠ أرقام أو مساويًا له',
		quickOptions: 'خيارات سريعة',
		addDependents: 'أضف المعالين',
		measureYourVitalSigns: 'قياس العلامات الحيوية',
		updateProfile: 'تحديث الملف',
		upcomingAppointments: 'المواعيد القادمة',
		goToMyAppointments: 'اذهب إلى المواعيد الخاصة بي',
		goToMyRequests: 'عرض جميع الطلبات',
		vitalSigns: 'علامات حيوية',
		ewsAiVitalSigns: 'العلامات الحيوية لـ EWS AI',
		inputterFirstName: 'الاسم الأول للمدخل',
		inputterLastName: 'الاسم الأخير للمدخل',
		goToMyHealthData: 'انتقل إلى بياناتي الصحية',
		limitedSelectedSymptoms: 'لا يمكنك تحديد أكثر من ٥ أعراض',
		comingSoon: 'قريبا',
		pleaseTryAgainLater: 'الرجاء معاودة المحاولة في وقت لاحق',
		currentlyNoHealthDataMainAnalysis: 'لا يوجد حاليًا تحليل رئيسي للبيانات الصحية',
		currentlyNoVitalSigns:
			'لا تحتوي {value} حاليًا على أي بيانات صحية مضافة من التحليل الرئيسي. انقر فوق "الانتقال إلى العلامات الحيوية" لعرض كافة البيانات.',
		extremelyHighTemperature: 'يعاني المريض من ارتفاع شديد في درجة الحرارة!',
		highTemperature: 'المريض يعاني من ارتفاع في درجة الحرارة!',
		lowTemperature: 'المريض يعاني من حرارة منخفضة!',
		highBloodPressure: 'المريض يعاني من ارتفاع ضغط الدم!',
		extremelyHighPressure: 'المريض يعاني من ضغط مرتفع للغاية! ،',
		extremelyLowBloodPressure: 'المريض يعاني من ضغط منخفض للغاية! ،',
		lowBloodPressure: 'المريض يعاني من انخفاض في ضغط الدم! ،',
		lowHeartRate: 'المريض لديه معدل ضربات قلب منخفض جدا! ،',
		highHeartRate: 'المريض لديه معدل ضربات قلب مرتفع جدا! ،',
		highBloodGlucose: 'يعاني المريض من ارتفاع شديد في نسبة الجلوكوز في الدم! ،',
		overweight: 'المريض يعاني من زيادة الوزن!',
		obese: 'المريض يعاني من السمنة!',
		underweight: 'المريض يعاني من نقص الوزن!',
		sleepDeficient: 'المريض يعاني من قلة النوم!',
		privacyMode: 'وضع الخصوصية',
		microphoneMuted: 'تم كتم صوت الميكروفون "',
		microphoneOn: 'الميكروفون قيد التشغيل',
		cameraOn: 'الكاميرا قيد التشغيل',
		cameraOff: 'إيقاف تشغيل الكاميرا',
		privacyModeOff: 'تم إيقاف وضع الخصوصية',
		privacyModeOn: 'تم تشغيل وضع الخصوصية',
		checkDateOfBirth: 'يجب أن يكون عمرك ١٨ عامًا على الأقل للتسجيل',
		greaterThanEighteen: 'يجب ألا يقل عمرك عن ١٨ عامًا',
		homePage: 'مسكن',
		newPatientRequests: 'طلبات المرضى الجديدة',
		noNewRequests: 'ليس لديك طلبات جديدة',
		about: 'حول',
		termsOfUse: 'شروط الاستخدام',
		goTo: 'اذهب إلى',
		domain: 'اِختِصاص',
		domainRequired: 'المجال مطلوب',
		validGroupName: 'اسم مجموعة صالح',
		group: 'المجموعة',
		allCaughtUp: 'لقد رأيت كل شيء',
		noNewAppointmentsOrRequests: 'ليس لديك مواعيد قادمة جديدة أو ليس لديك طلبات جديدة',
		age: 'سن',
		failedToLoad: 'فشل في التحميل',
		hvrIndex: 'مؤشر HVR',
		stressLevel: 'مستوى الإجهاد',
		mildlyCalm: 'هادئ بشكل معتدل',
		highlyTense: 'مرهق جدا',
		slightlyTense: 'متوترة قليلا',
		quietlyRelaxed: 'استرخاء معتدل',
		deeplyRelaxed: 'استرخاء عميق',
		vitalsCamera: 'قياسات الكاميرا',
		cameraInUseHeader: 'الكاميرا قيد الاستخدام بالفعل - يُرجى إغلاق التطبيقات الأخرى',
		cameraInUseParagraph1: 'للمشاركة في الاجتماع بالكاميرا ، قم بتعطيل الكاميرا من التطبيق الآخر',
		cameraInUseParagraph2: 'قم بتحديث الصفحة لتهيئتها',
		noAppointmentRequests: 'ليس لديك طلبات موعد!',
		noAcceptedAppointments: 'ليس لديك طلبات مقبولة!',
		scheduleAvailability: 'توافر الجدول الزمني',
		schedulingAvailability: 'الجدولة على أساس التوافر الخاص بك',
		addTopic: 'أضف الموضوع',
		setupAccount: 'إعداد حساب',
		noSetupAccount: 'يبدو أنك لم تقم بإعداد حساب تقويم حتى الآن ، انقر فوق الزر أدناه للمتابعة',
		setupCalendarAccount: 'قم بإعداد حساب التقويم الخاص بك',
		viewSomeonesProfile: 'مشاهدة ملف (القيمة) الشخصي',
		reSchedule: 'إعادة الجدولة',
		chooseAvailableTime: 'اختر الوقت المتاح الخاص بك ل',
		letAvailbleSchedule: 'دع المرضى يعرفون متى تكون متاحًا لقبول الاجتماعات',
		setup: 'نصب',
		availability: 'التوفر',
		noUpcomingAppointments: 'ليس لديك مواعيد قادمة!',
		roomNoDeviceAssigned: 'لا تحتوي هذه الغرفة على أي معدات مخصصة',
		roomsNeedDevice: 'تحتاج الغرف إلى جهاز مخصص لها',
		inOrderToCall: 'من أجل الاتصال',
		createCompanyProfile: 'إنشاء ملف تعريف الشركة',
		companyWebsite: 'موقع شركة',
		createCompany: 'إنشاء شركة',
		owner: 'صاحب',
		companyType: 'نوع الشركة',
		website: 'موقع الكتروني',
		companyId: 'معرف الشركة',
		goBack: 'ارجع للخلف',
		companySuccessfullyCreated: 'تم تأسيس الشركة بنجاح',
		meetingTime: 'وقت الاجتماع',
		meetingDate: 'موعد الاجتماع',
		noPendingAppointments: 'ليس لديك طلبات معلقة!',
		noRejectedAppointments: 'لا توجد لديك طلبات مرفوضة!',
		patientEmail: 'البريد الإلكتروني للمريض',
		familyMemberEmail: 'البريد الإلكتروني لأفراد العائلة',
		patientFirstName: 'الاسم الأول للمريض',
		familyMemberFirstName: 'الاسم الأول لأحد أفراد الأسرة',
		patientLastName: 'اسم المريض الأخير',
		familyMemberLastName: 'اسم العائلة لأحد أفراد العائلة',
		assignPatient: 'كلف المريض',
		assignmentMethod: 'طريقة التعيين',
		modalLogsTitle: 'سجلات الجهاز',
		modalLogsFailure: 'فشل الحصول على سجلات الجهاز. الجهاز غير متصل بالإنترنت.',
		modalLogsWentWrong: 'حدث خطأ ما. أعد المحاولة من فضلك',
		modalLogsSuccess: 'سجلات الجهاز المسجلة في نظام Solaborate. يمكنك إغلاق هذه الرسالة.',
		modalLogsButton: 'احصل على سجلات الجهاز',
		rebootExportLogsButton: 'تصدير سجلات إعادة التشغيل',
		rebootExportLogsNoLogsYet: 'هذا الجهاز ليس لديه بيانات إعادة التشغيل',
		exportAsCSV: 'تصدير كملف CSV',
		searchPatient: 'بحث المريض',
		newPatient: 'مريض جديد',
		existingPatient: 'مريض موجود',
		requiredField: 'الحقل المطلوب',
		selectedPatient: 'المريض المختار',
		nextDays: 'أيام',
		nextTime: 'في اليوم التالي',
		nextHours: 'ساعات',
		nextHour: 'ساعة',
		nextMinutes: 'الدقائق',
		availableHours: 'الساعات المتاحة',
		availableDays: 'الأيام المتاحة',
		break: 'فاصل',
		checkSelectedBreaks: 'يرجى التحقق من أوقات الراحة المحددة',
		sundays: 'أيام الآحاد',
		mondays: 'أيام الإثنين',
		tuesdays: 'الثلاثاء',
		wednesdays: 'الأربعاء',
		thursdays: 'الخميس',
		fridays: 'الجمعة',
		saturdays: 'أيام السبت',
		month: 'شهر',
		week: 'أسبوع',
		day: 'يوم',
		remote: 'في المسافة',
		th: '',
		fileUploadError: 'الملف {القيمة} موجود بالفعل',
		conversationHistory: 'محفوظات المحادثة',
		noDevices: 'لا توجد أجهزة',
		oneDevice: '(القيمة) الجهاز',
		moreThanOneDevice: '(القيمة) الأجهز',
		healthSystemRequired: 'النظام الصحي مطلوب',
		hospitalRequired: 'المستشفى مطلوب',
		emailRequired: 'البريد الالكتروني مطلوب',
		userAlreadyExists: 'هذا المستخدم موجود بالفعل',
		privacy: 'خصوصية',
		enablePrivacyMode: 'قم بتمكين وضع الخصوصية',
		privacyDescription: 'عند تمكين هذا ، سيرى أي شخص يتصل بهذا الجهاز رؤية غير واضحة للكاميرا.',
		futureConfigurations: 'التكوينات المستقبلية',
		true: 'خاطئة',
		false: 'حقيقي',
		forwarded: 'تم توجيهه',
		acknowledged: 'تم الاعتراف به',
		rotate: 'استدارة',
		filter: 'منقي',
		appointment: 'ميعاد',
		editMeeting: 'تحرير الاجتماع',
		companyDetails: 'تفاصيل الشركة',
		companySettings: 'إعدادات الشركة',
		companyAddress: 'عنوان الشركة',
		remotePatientMonitoring: 'مراقبة المريض عن بعد',
		continuousPatientMonitoring: 'مراقبة مستمرة للمريض',
		numberOfPatients: 'عدد المرضى',
		highRisk: 'مخاطرة عالية',
		memberStatus: 'حالة العضو',
		disableAccess: 'تعطيل الوصول',
		moderateRisk: 'مخاطرة معتدلة',
		searchByNameOrHello: 'البحث عن المرضى حسب الاسم أو {value}',
		optional: 'اختيارية',
		uploadPicture: 'تحميل الصور',
		profileImageDescription:
			'سيتم استخدام هذه الصورة كصورة لملفك الشخصي وستكون قابلة للعرض على المرضى على موقعنا الإلكتروني وتطبيق الهاتف المحمول',
		pleaseUploadPicture: 'الرجاء تحميل صورة الملف الشخصي',
		maxSizePic: 'حجم الصورة لا يمكن أن يتجاوز  ٦٠٠ كيلو بايت',
		commandNotSupported: 'هذا الأمر غير مدعوم على تلفزيون هذا المريض',
		userIdleConfigurations: 'تكوينات المستخدم السلبية',
		minutes: 'الدقائق',
		editedBy: 'تغير من',
		dateEdited: 'تاريخ التغيير',
		historyFor: 'التاريخ ل',
		backToConfigs: 'العودة إلى الإعدادات',
		setTimeOut: 'اضبط وقت الانتظار',
		viewHistory: 'انظر إلى القصة',
		minutesGreaterThanZero: 'يجب أن تكون الدقائق أكبر من ٠',
		minutesRequired: 'يجب كتابة المحضر',
		userIdleFormTitle: 'يجب على المستخدم تسجيل الدخول مرة أخرى بعد فترة من الخمول',
		selectHealthSystemOrAll: 'حدد النظام الصحي أو حدد الكل لتطبيق فترة عدم النشاط',
		setInActivityPeriod: 'قم بتعيين فترة عدم النشاط',
		userIdleNotifedPeriod: 'سيتم إخطار المستخدم لمدة ٣٠ ثانية ، إذا لم يؤكد التواجد فسيتم قطع اتصاله تلقائيًا',
		configurationSuccessfullyAdded: 'تمت إضافة التكوين بنجاح',
		imHere: 'أنا هنا',
		idleMessage: 'لقد كنت خاملاً لمدة (قيمة) دقيقة.',
		idleMessageDescription: 'سيتم تسجيل خروجك في حالة عدم تأكيد وجودك في غضون الثواني (القيمة) التالية',
		seeDoctorNow: 'انظر الطبيب الآن',
		waitingRoomDoctor: 'من المتوقع أن تبدأ مكالمتك المباشرة مع (القيمة ١) في (القيمة ٢) أو أقل',
		doctorAvailableNow: 'متاح الآن',
		doctorUnAvailableNow: 'غير متوفر الآن',
		hasPatientsInWaitingList: '  هناك (قيمة) مرضى آخرون على قائمة الانتظار',
		goToWaitingRoom: 'اذهب إلى غرفة الانتظار',
		pleaseSpecifySymptoms: 'يرجى تحديد الأعراض الخاصة بك للعثور على الطبيب الأكثر ملاءمة لك.',
		pleaseSelectAvailableDoctor: 'يرجى تحديد أحد الأطباء المتاحين للمضي قدمًا في موعدك.',
		seeTheAvailableDoctors: 'راجع الأطباء المتاحين',
		pleaseWaitUntilDoctorCalls: 'يرجى الانتظار حتى يتصل بك الطبيب',
		liveCallExpectedToStart: 'مكالمتك مع د. من المتوقع أن تبدأ (القيمة) لـ',
		orLess: 'او اقل',
		searchForDoctors: 'بحث واختيار طبيب',
		additionalHealthData: 'بيانات صحية إضافية',
		additionalNotes: 'ملاحظات إضافية',
		clickHere: 'انقر هنا',
		orDragAndDrop: 'أو السحب والإفلات لتحميل ملف',
		leaveWaitingRoom: 'اترك غرفة الانتظار',
		liveCallRequest: 'طلب مكالمة حية',
		requestTime: 'وقت الطلب',
		notNow: 'ليس الان',
		hasRequestedAliveCall: 'طلب إجراء مكالمة مباشرة معك',
		haveRequestedAliveCall: 'طلبت إجراء مكالمة مباشرة معك',
		andNumberOthers: 'و {القيمة} الآخرين',
		typeHere: 'أكتب هنا',
		notSpecified: 'غير محدد',
		changeDoctor: 'تغيير الطبيب',
		changedYourMind: 'إذا غيرت رأيك بشأن طبيبك ، يمكنك اختيار طبيب آخر',
		diagnoses: 'التشخيصات',
		procedures: 'إجراءات',
		medicalInfoProcedures: 'إجراءات',
		notes: 'ملحوظات',
		medicalInfoNotes: 'ملحوظات',
		notesDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من كتابة ملاحظات أثناء مراقبة المريض.',
		showNotesOnExpand: 'إظهار الملاحظات فقط عند التوسيع',
		showNotesUnderTile: 'إظهار الملاحظات تحت كل بلاطة',
		defaultVideoMonitoring: 'مراقبة الفيديو الافتراضية',
		defaultAmbientMonitoring: 'مراقبة البيئة الافتراضية',
		customize: 'يعدل أو يكيف',
		prescriptions: 'الوصفات الطبية',
		medicalInfoPrescriptions: 'الوصفات الطبية',
		configurationExists: 'هذا التكوين موجود بالفعل!',
		deviceSettings: 'إعدادات الجهاز',
		languages: 'اللغات',
		aiModels: 'نماذج AI',
		selectMode: 'حدد الوضع',
		mainDisplay: 'الشاشة الرئيسية',
		secondaryDisplay: 'العرض الثانوي',
		approval: 'اعتماد',
		approvalNeeded: 'من أجل تبديل هذه الميزة بنجاح ، تحتاج إلى موافقة من المريض',
		changeSettingsRequestAccepted: 'المريض (المريض) قد (قبل أو رفض) طلبك',
		updateRequested: 'التحديث مطلوب',
		updateNow: 'تحديث الان',
		softwareUpdate: 'تحديث البرمجيات',
		markAllAsRead: 'وضع علامة "مقروءة" على الكل',
		selectDoctor: 'حدد الطبيب',
		tvMode: 'وضع التلفزيون',
		gamingMode: 'وضع الألعاب',
		cleanMode: 'الوضع النظيف',
		widgetMode: 'وضع القطعة',
		autoAnswer: 'رد ألي',
		appInstaller: 'مثبت التطبيق',
		audioLevel: 'مستوى الصوت',
		portraitMode: 'الوضع الرأسي',
		landscape: 'منظر طبيعي',
		portrait: 'صورة شخصية',
		portraitReverse: 'عكس الصورة',
		deviceOrientation: 'اتجاه العرض',
		connectPatientsHelloToWifi: 'اتصل بجهاز المريض {value} بشبكة الواي فاي',
		and: 'و',
		maxSymptomsNo: 'أقصى عدد للأعراض هو ١٠',
		haveCompleted: 'قد أكملت',
		haveCanceled: 'ألغيت',
		yourRequestWith: 'طلبك مع',
		yourLiveCallWith: 'مكالمتك الحية مع',
		doctorRequestResponse: 'الطبيب لديه {القيمة} طلبك',
		goBackToDoctors: 'يمكنك العودة وطلب مكالمة مع طبيب آخر',
		deleteHealthSystemMember: 'هل أنت متأكد أنك تريد حذف هذا النظام الصحي من هذا العضو؟',
		dateValidation: 'يرجى تحديد تاريخ البدء قبل تاريخ الانتهاء.',
		twentyHrsBetween: 'الرجاء تحديد تاريخ البدء ٢٣',
		notAvailableNow: 'للأسف ، الموفر غير متوفر في هذا الوقت. من فضلك ، اختر أحد أوقات التوفر أدناه:',
		notAvailableToday: 'لسوء الحظ ، المزود غير متوفر اليوم. الرجاء تحديد تاريخ مختلف',
		showForm: 'إظهار النموذج',
		priority: 'أولوية',
		selectPriority: 'اختر الأولوية',
		changesSaved: 'تم حفظ التغييرات.',
		poolRoleRequired: 'دور التجمع مطلوب.',
		requestSentToAllDoctors: 'تم إرسال الطلب لجميع الأطباء',
		firstNameMinLength: 'يجب أن يتكون الاسم الأول من حرفين على الأقل',
		lastNameMinLength: 'يجب أن يتكون اسم العائلة من حرفين على الأقل',
		pleaseWritePhoneNumber: 'يرجى كتابة رقم هاتفك الخلوي',
		pleaseSelectPrimarySpecialty: 'اختر من فضلك  تخصص أساسي',
		pleaseWriteLicenseNumber: 'الرجاء كتابة رقم الترخيص',
		pleaseWriteNPINumber: 'يرجى كتابة رقم NPI الخاص بك',
		npiMaxLength: 'يجب أن يتكون رقم NPI من ١٠ أحرف أو أقل',
		atLeastOneState: 'يجب تحديد ولاية واحدة على الأقل',
		selectedItems: 'عناصر مختارة',
		valueItemSelected: 'تم اختيار {value}',
		roles: 'الأدوار',
		activeDirectoryInvite: 'دعوة Active Directory',
		doctor: 'طبيب',
		superadmin: 'مشرف فائق',
		superuser: 'مستخدم ممتاز',
		noAdded: 'ليس لديك (قيمة} مضافة',
		addAnother: 'إضافة أخرى (قيمة}',
		cancelVisit: 'إلغاء الزيارة',
		submitWrapUp: 'أرسل الملخص',
		addNote: 'اضف ملاحظة',
		addDiagnose: 'أضف التشخيص',
		addProcedure: 'إضافة الإجراء',
		addedNotes: 'الملاحظات المضافة',
		title: 'عنوان',
		selectPharmacy: 'حدد الصيدلة',
		pharmacy: 'صيدلية',
		noMoreHealthSystems: 'لا توجد أنظمة صحية أخرى.',
		whereToSendPrescriptions: 'أين تريد أن ترسل الوصفات الطبية الخاصة بك؟',
		findPharmacy: 'ابحث عن صيدلية',
		miles: 'اميال',
		homeDelivery: 'توصيل منزلي',
		pickUp: 'يلتقط',
		errorLoading: 'مشكلة التحميل',
		searchPharmacies: 'صيدليات البحث',
		startCall: 'ابدأ المكالمة',
		changePharmacy: 'صيدلية التغيير',
		enterDescription: 'أدخل الوصف هنا',
		enterTopic: 'أدخل الموضوع هنا',
		editAppointment: 'تحرير موعد',
		discardChanges: 'تجاهل التغييرات؟',
		discard: 'تجاهل',
		discardDescription: 'سيؤدي إغلاق النموذج إلى حذف جميع البيانات التي قمت بإدخالها',
		dosage: 'الجرعة',
		frequency: 'تردد',
		duration: 'المدة الزمنية',
		dispense: 'الاستغناء',
		refills: 'عبوات',
		isRequired: 'هو مطلوب',
		maxLengthIs: 'أقصى طول هو',
		medicineSelection: 'اختيار الدواء',
		dispensedAsWritten: 'يتم الاستغناء عنها كما هو مكتوب إذا لزم الأمر طبيًا',
		noteToPharmacist: 'ملاحظة للصيدلي',
		patientInstructions: 'تعليمات للمريض',
		quantityToDispense: 'كمية الاستغناء',
		timesAday: 'مرات في اليوم',
		capsule: 'كبسولة',
		addedPrescriptions: 'الوصفات الطبية المضافة',
		typeOfDosage: 'نوع الجرعة',
		orUntilDiretedToStop: '(أو) حتى يتم توجيهه بالتوقف',
		onlyAsNeeded: 'حسب الحاجة فقط',
		default: 'الإفتراضي',
		numberOfDays: 'عدد الأيام',
		appointmentCreated: 'تم إنشاء الموعد بنجاح',
		appointmentUpdated: 'تم تحديث الموعد بنجاح',
		appointmentDetails: 'تفاصيل موعدك هي',
		change: 'يتغيرون',
		confirm: 'أؤكد',
		successfulSignIn: 'تسجيل دخول ناجح',
		successfulSignInDescription: 'تم توصيل {القيمة} الخاصة بك بنجاح إلى hellocare.',
		errorSignIn: 'خطأ في تسجيل الدخول',
		errorSignInDescription: 'تعذر الاتصال بـ (القيمة} ، يرجى المحاولة مرة أخرى',
		invalidFileType: 'نوع ملف غير صحيح.',
		hideForm: 'إخفاء النموذج',
		addSuperUser: 'إضافة مستخدم متميز',
		myWorkflow: 'سير العمل الخاص بي',
		directWorkflow: 'سير العمل المباشر',
		throughNurses: 'من خلال الممرضات',
		selectMyWorkflow: 'حدد سير العمل الخاص بي',
		nurses: 'الممرضات',
		clickHereAddNurse: 'انقر هنا لإضافة ممرضة جديدة',
		addNurse: 'إضافة ممرضة',
		inviteNurses: 'ادعُ ممرضات',
		sectorIsRequired: 'لا يمكن أن يكون اسم القطاع فارغًا',
		timeZoneMustBeSet: 'يجب تعيين المنطقة الزمنية',
		sectorNameMinLength: 'يجب ألا يقل اسم القطاع عن حرفين',
		writeSectorName: 'اكتب اسم القطاع',
		writeDeviceSerialNumber: 'اكتب الرقم التسلسلي للجهاز',
		writeTvSerialNumber: 'اكتب الرقم التسلسلي للتلفزيون',
		healthSystemMinLength: 'يجب أن يتكون اسم نظام الصحة من حرفين على الأقل',
		nextStep: 'الخطوة التالية',
		minLengthIs: 'الطول الأدنى هو',
		areYouSureDeleteItem: 'هل أنت متأكد أنك تريد حذف هذه {القيمة}؟',
		addPrescription: 'إضافة وصفة طبية',
		medicalInfo: 'المعلومات الطبية',
		addMedicalInfo: 'إضافة قيمة}',
		diagnose: 'تشخيص',
		note: 'ملاحظة',
		prescription: 'وصفة طبية',
		titleIsRequired: 'يجب كتابة العنوان',
		appointmentSlotIsRequired: 'الرجاء تحديد خانة الموعد',
		personIsRequired: 'الشخص مطلوب',
		meetingOptionIsRequired: 'مطلوب خيار الاجتماع',
		descriptionIsRequired: 'الوصف مطلوب',
		descriptionMustHaveLetter: 'لا يمكن أن يحتوي الوصف على فراغات أو أرقام أو رموز فقط. يجب أن تحتوي على حروف.',
		rebootDevice: 'جهاز إعادة التشغيل',
		rebootDeviceQuestion: 'لماذا تريد إعادة تشغيل الجهاز',
		rebootDeviceReason: 'صف سبب إعادة التشغيل ...',
		editDetails: 'عدل التفاصيل',
		otherDevices: 'أجهزة أخرى',
		areYouSureDeletePrescription: 'هل أنت متأكد أنك تريد حذف هذه الوصفة؟',
		inviteDoctor: 'قم بدعوة طبيب',
		inactiveConference: 'لقد غادر المريض',
		inactiveConferenceDesc: 'لقد غادر المريض بالفعل الغرفة الافتراضية',
		joinCall: 'الانضمام إلى المكالمة',
		expandLatestHealthData: 'عرض أحدث البيانات الصحية',
		collapseLatestHealthData: 'إخفاء أحدث البيانات الصحية',
		minutesInWaitingRoom: 'دقائق في غرفة الانتظار',
		rejectedRequest: 'رفض طلبك',
		selectHelloDevice: 'حدد جهاز الترحيب',
		otherMedicalInfo: 'معلومات طبية أخرى',
		mobile: 'الهاتف المحمول',
		laptop: 'حاسوب محمول',
		youHaveLengthPatients: 'لديك (الطول) (المرضى) في غرفة الانتظار',
		bodyFat: 'دهون الجسم',
		muscleMass: 'كتلة العضلات',
		bodyWater: 'ماء الجسم',
		symptoms: 'أعراض',
		transferToDoctor: 'انقل للطبيب',
		transferToDoctorWarning:
			'سيؤدي تحويل المكالمة إلى دكتور {patient} إلى إنهاء المكالمة مع {doctor}. هل انت متأكد انك تريد المتابعة؟',
		aiPrivacyMode: 'وضع الخصوصية لمنظمة العفو الدولية',
		noMoreThanTwoAI: 'لا يمكنك إضافة أكثر من نموذجين للذكاء الاصطناعي',
		soundOn: 'الصوت مفعل',
		soundOff: 'الصوت مقفل',
		nightVisionOn: 'تشغيل الرؤية الليلية',
		nightVisionOff: 'الرؤية الليلية معطلة',
		visitWith: 'زيارة مع',
		mainDetails: 'التفاصيل الرئيسية',
		lengthOfVisit: 'طول الزيارة',
		viewDetails: 'عرض التفاصيل',
		newAppointmentRequest: 'لديك طلب موعد جديد مع',
		onDate: 'على',
		has: 'يمتلك',
		yourAppointmentInvitation: 'دعوة موعدك',
		youHaveAppointmentWith: 'لديك موعد مع',
		inMinutesOn: 'في {value} دقيقة على',
		hasAcceptedAppointment: 'تم قبول طلب موعدك',
		hasRejectedAppointment: 'تم رفض طلب موعدك',
		hasCanceledAppointment: 'تم إلغاء طلب موعدك',
		atDate: 'في',
		youHaveAppointmentRequest: 'لديك طلب موعد مع',
		updated: 'محدث',
		rescheduled: 'تمت إعادة جدولته',
		expand: 'قم بالتوسيع',
		reportAsFalse: 'تقرير كاذب',
		callEmergency: 'اتصل بالطوارئ',
		callNurse: 'استدعاء ممرضة',
		markFalseAlert: 'وضع علامة كتنبيه زائف',
		markAsSeen: 'وضع علامة كما شوهد',
		resultsFor: 'نتائج (القيمة)',
		patientExists: 'هذا المريض موجود بالفعل!',
		dataAcquisition: 'الحصول على البيانات',
		allRequests: 'كل الطلبات',
		opened: 'افتتح',
		cancelAppointmentTitle: 'إلغاء التعيين؟',
		cancelAppointment: 'إلغاء التعيين',
		cancelAppointmentQuestion: 'هل أنت متأكد أنك تريد إلغاء هذا الموعد؟',
		alertHistory: 'محفوظات التنبيه',
		healthData: 'البيانات الصحية',
		roundingHealthData: 'البيانات الصحية',
		healthDataAlerts: 'تنبيهات البيانات الصحية',
		artificialIntelligence: 'الذكاء الاصطناعي',
		wasDetected: 'تم الكشف عنه في',
		timeline: 'الخط الزمني',
		youNeedAuthorizedNurses: 'يجب أن يكون لديك ممرض واحد على الأقل مخول لتغيير سير العمل إلى "من خلال الممرضات.',
		timeSlotExists: 'لديك بالفعل موعد في تلك الفترة الزمنية.',
		hideEmergencyButtons: 'إخفاء أزرار الطوارئ',
		showEmergencyButtons: 'إظهار أزرار الطوارئ',
		appointmentDetail: 'تفاصيل الموعد',
		invitee: 'المدعو',
		selectOneOption: 'حدد أحد الخيارات التالية',
		noCanceledAppointments: 'لا توجد لديك طلبات ملغاة!',
		editOrder: 'تحرير الأمر',
		reorderSessions: 'إعادة ترتيب الجلسات',
		rearrangeMonitoringSessions: 'قم بالسحب والإفلات لإعادة ترتيب جلسات المراقبة',
		code: 'شفرة',
		alreadyAddedBreakTime: 'لقد أضفت بالفعل وقت الاستراحة هذا',
		completeAppointmentTitle: 'موعد كامل؟',
		completeAppointmentQuestion: 'هل أنت متأكد أنك تريد إكمال هذا الموعد؟',
		markAsCompleted: 'وضع علامة كمكتمل',
		contactNurses: 'اتصل بالممرضات',
		contactNurse: 'اتصل بالممرضات',
		contactNursesDescription:
			'عند تمكين هذا ، سيتمكن المستخدم من الاتصال بالممرضات عن طريق اختيار 3 خيارات: إرسال رسالة جماعية ، أو الاتصال بممرضة مناوبة ، أو الاتصال بممرضة معينة.',
		falseAlert: 'تنبيه كاذب',
		forwardToNurses: 'ترسل إلى الممرضات',
		forwardToFrontlineNurses: 'إلى الأمام إلى ممرضات الخط الأمامي',
		forwardToNursesDescription:
			'سير عمل الاتصال للتنبيهات التي سيتم إعادة توجيهها بواسطة الممرضة الافتراضية أو eSitter إلى الممرضات بجانب السرير.',
		sendGroupMessage: 'إرسال رسالة جماعية',
		callNursesOnDuty: 'استدعاء الممرضات في الخدمة',
		specificNurse: 'ممرضة محددة',
		stopTalking: 'انقر للتوقف عن الكلام',
		checkBreakTime: 'الرجاء إضافة وقت الراحة داخل الساعات المتاحة',
		teams: 'فرق',
		sendMessage: 'أرسل رسالة',
		message: 'رسالة',
		chat: 'دردشة',
		sort: 'فرز',
		viewLatestAlerts: 'عرض أحدث التنبيهات',
		sharedAttachments: 'المرفقات المشتركة',
		links: 'الروابط',
		sharedLinks: 'روابط مشتركة',
		images: 'الصور',
		sharedImages: 'الصور المشتركة',
		videos: 'أشرطة فيديو',
		sharedVideos: 'مقاطع الفيديو المشتركة',
		noTeamMembers: 'لا أعضاء الفريق',
		noTeamsYet: 'لا فرق بعد',
		noCheckedInNurse: 'لا يوجد ممرضات مسجلة متاحة!',
		nursesUnavailable: 'لا يوجد ممرضون متاحون. حاول مرة أخرى في وقت لاحق.',
		receivedAlert: 'تلقى إنذارًا',
		sentAlert: 'أرسل تنبيهًا',
		addedComment: 'أضاف تعليق',
		markedThe: 'احتفل',
		alertAcknowledged: 'التنبيه كما أقر',
		wasAddedAs: 'تمت إضافته كواحد',
		hospitalCareTaker: 'القائم بالعناية في المستشفى',
		alertDetails: 'تفاصيل المنبه',
		measured: 'تقاس',
		forwardedToNurses: 'أرسل الإنذار إلى الممرضات',
		raiseAlert: 'إنها تثير القلق',
		raiseAnAlertManually: 'رفع تنبيه يدويًا',
		raiseAlertDescription:
			'من خلال هذا الخيار ، ستكون الممرضة قادرة على رفع تنبيه يدويًا لهذا المريض. سيتم إرسال هذا التنبيه إلى ممرضات جانب السرير أو ممرضات الخط الأمامي.',
		typeOfAlert: 'نوع التنبيه',
		isAtRisk: 'معرضة للخطر بسبب',
		bloodType: 'فصيلة الدم',
		latestVitalSigns: 'البيانات الحيوية الأخيرة',
		latestDiagnosis: 'التشخيص الأخير',
		chronicDiseases: 'الأمراض المزمنة',
		allergies: 'الحساسية',
		goToAllScripts: 'انتقل إلى Allscripts EHR',
		alertAsFalse: 'التنبيه كاذب',
		manualAlerts: 'الإخطارات اليدوية',
		forwardToMSNurses: 'إرسال الممرضات إلى MS',
		raisedAnAlert: 'أثار ناقوس الخطر',
		badURL: 'URL غير صالح. يجب أن يكون تنسيق URL مثل https',
		symbolAndSpacesNotAllowed: 'الرموز والمسافات غير مسموح بها',
		symbolsNotAllowed: 'الرموز غير مسموح بها!',
		call911: 'اتصل ب ٩١١',
		call911Description: 'عند تمكين هذا ، سيتمكن المستخدمون من الاتصال برقم 911 بنقرة واحدة.',
		addComment: 'أضف تعليق',
		saveComment: 'احفظ التعليق',
		forwardToNoraApp: 'أرسل إلى تطبيق Nora',
		mainMedicalFamilyCenter: 'مركز الأسرة الطبي الرئيسي',
		medicalFamilyCenter: 'مركز الأسرة الطبية',
		digitalClinic: 'العيادة الرقمية',
		checkIn: 'تحقق في',
		checkOut: 'الدفع',
		checkInPage: 'تحقق في',
		noCheckInAllowed: 'تسجيل الدخول غير مسموح به',
		userIsCheckedIn: 'مستخدم مع البريد الإلكتروني',
		hangUp: 'شنق',
		calling: 'دعوة',
		emergencyCall: 'مكالمة طارئة',
		callEnded: 'انتهت المكالمة',
		typeNameForPool: 'أدخل اسم هذه المجموعة',
		selectRoles: 'اختر الأدوار',
		selectRoleAppy: 'حدد الدور المطلوب تطبيق الدليل النشط',
		checkInPatient: 'قم بتسجيل مريض',
		selectPatient: 'حدد المريض',
		patientHealthDetails: 'الأعراض والبيانات الصحية الإضافية',
		measureVitalSigns: 'قياس علامات المريض الحيوية',
		searchForPatients: 'ابحث عن المرضى',
		addNewPatient: 'أضف مريض جديد',
		addPatient: 'أضف المريض',
		results: 'النتائج',
		showConsent: 'موافقة من المريض',
		idNumber: 'رقم الهوية',
		selectThePatient: 'حدد المريض',
		typeSymptoms: 'اكتب الأعراض والعلامات هنا',
		selectedSymptoms: 'بعد تحديد الأعراض ستظهر هنا',
		howLongSymptoms: 'ما هي مدة ظهور الأعراض لديك؟',
		preExistingCondition: 'هل لديك أي شروط موجودة مسبقًا؟ إذا كانت الإجابة بنعم ، فالرجاء كتابتها أدناه',
		anyAllergies: 'هل لديك أي حساسية؟',
		dragAndDropOrUpload: 'انقر هنا أو قم بالسحب والإفلات لتحميل ملف',
		measurePatientVitalSigns: 'الرجاء قياس العلامات الحيوية للمريض',
		saveAndSelectPatient: 'حفظ واختيار المريض',
		addManually: 'إضافة يدويا',
		userExists: 'هذا المستخدم موجود بالفعل!',
		viewDevices: 'عرض المعدات',
		assignDevicesList: 'قائمة الأجهزة المحددة',
		monitoringSettings: 'ترتيب المراقبة',
		editMonitoringSettings: 'تغيير ترتيبات المراقبة',
		nightVision: 'الرؤية الليلية',
		nightVisionDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من الرؤية عندما تكون الغرفة مظلمة.',
		EHRDescription: 'الوصول إلى بيانات السجلات الصحية الإلكترونية الخاصة بالمريض.',
		microsoftTeams: 'فرق مايكروسوفت',
		microsoftTeamsDescription: 'سير عمل التواصل مع تطبيق Microsoft Team الخاص بالمستشفى',
		walkieTalkie: 'اسلكية تخاطب',
		walkieTalkieDescription: 'تم تسجيل البث الصوتي لجميع الممرضات والممرضات الافتراضية بجوار السرير في الطابق المحدد.',
		addDigitalClinician: 'إضافة طبيب رقمي',
		digitalClinicianOnList: 'طبيب رقمي',
		maxSizeExceeded: 'جرب تحميل ملف أقل من ٢ ميغا بايت',
		maxCompanySizeExceeded: 'حاول تحميل ملف أقل من ٦٠٠ كيلوبايت',
		appointmentDateRequired: 'تاريخ التعيين مطلوب',
		timeSlotRequired: 'الفترة الزمنية المطلوبة',
		editSafeRanges: 'تحرير النطاقات الآمنة',
		thresholdAlertDescription: 'أدخل القيم الدنيا والقصوى لكل بيانات صحية بناءً على حالة المريض',
		doctorPooling: 'مجموعة الأطباء',
		addDoctor: 'أضف طبيب',
		searchDoctor: 'ابحث عن طبيب',
		selectedDoctors: 'أطباء مختارون',
		sureToDeleteDoctor: 'هل أنت متأكد أنك تريد حذف دكتور (قيمة)؟',
		deleteDoctor: 'حذف الطبيب',
		checkedIn: 'تم تسجيل الوصول إليه',
		checkedOut: 'بدون تسجيل',
		placement: 'بلد',
		seeDoctorInDC: 'قابل الطبيب في العيادة الرقمية',
		patientIsInVisit: 'المريض يزور بالفعل!',
		doctorAvailableForCall: 'متاح للاتصال الآن',
		healthDataSafeRanges: 'نطاقات بيانات صحية آمنة',
		medicalReport: 'تقرير طبي',
		doctorsStampSignature: 'توقيع وختم الطبيب',
		doctorFullName: 'اسم الطبيب الكامل',
		printReports: 'تقارير الطباعة',
		printReport: 'اطبع التقرير',
		capsulesDosage: '(القيمة) كبسولة',
		oneCapsule: '(القيمة) كبسولة',
		specialty: 'التخصص',
		refillsLowercase: 'التجديد',
		cityMustHaveLetter: 'يجب أن تحتوي المدينة أيضًا على أحرف',
		ok: 'موافق',
		measuredIn: 'تقاس في',
		completeRegistration: 'نوصيك بملء معلوماتك الشخصية والمهنية في النموذج أدناه',
		dragAndDropOr: 'قم بالسحب والإفلات أو',
		click: 'انقر هنا',
		uploadProfilePicture: 'لتحميل صورة ملف التعريف الخاصة بك',
		addLicense: 'إضافة ترخيص',
		licenseNo: 'رقم الرخصة',
		selectDate: 'حدد تاريخ',
		selectCountry: 'حدد الدولة',
		selectSpecialty: 'حدد التخصص',
		saveAndContinue: 'حفظ ومتابعة',
		position: 'موضع',
		addPosition: 'إضافة الموقف',
		company: 'الشركة',
		educationHistory: 'تاريخ التعليم',
		school: 'مدرسة',
		degree: 'درجة',
		startYear: 'سنة البدء',
		endYear: 'نهاية السنة',
		employmentHistory: 'تاريخ التوظيف',
		addEducation: 'أضف التعليم',
		licenses: 'التراخيص',
		reset: 'إعادة تعيين',
		companyNameRequired: 'اسم الشركة مطلوب',
		doctorsStamp: 'ختم الطبيب',
		doctorsSignature: 'توقيع الطبيب',
		companyWebsiteRequired: 'مطلوب موقع الشركة',
		companyAddressRequired: 'عنوان الشركة مطلوب',
		somethingWrongWithConference: 'حدث خطأ ما أثناء إنشاء المؤتمر!',
		patientIsOnWaitingRoom: 'المريض في غرفة الانتظار الآن!',
		deviceOfflineNow: 'الجهاز غير متصل في الوقت الحالي. حاول مرة اخرى!',
		personalNumber: 'رقم شخصي',
		pleaseWriteIdNumber: 'الرجاء كتابة رقم هويتك',
		idNumberLength: 'يجب أن يتكون رقم المعرف من ١٠ أحرف وألا يبدأ بصفر',
		patientHealthCardNumberLength: 'يجب أن يتكون رقم البطاقة الصحية للمريض من 10 إلى 20 حرفًا ويجب ألا يبدأ بصفر.',
		diabetes: 'السكري',
		oxygen: 'الأكسجين',
		distanceWalkingRunning: 'مسافة المشي + الجري',
		temperature: 'درجة الحرارة',
		pi: 'مؤشر الإرواء',
		numberOfTimesFallen: 'عدد المرات التي سقط فيها',
		pleaseUploadProfilePicture: 'الرجاء تحميل صورة الملف الشخصي',
		beforeContinue: 'قبل أن تكمل',
		currentlyWorkingHere: 'تعمل حاليا هنا',
		currentlyStudyingHere: 'تدرس حاليا هنا',
		high: 'عالٍ',
		low: 'منخفض',
		criticalHigh: 'مرتفع حرج',
		criticalLow: 'منخفض حرج',
		french: 'فرنسي',
		german: 'ألمانية',
		dutch: 'هولندي',
		turkish: 'التركية',
		spanish: 'الأسبانية',
		englishUnitedStates: 'انجليزية الولايات المتحدة)',
		serbian: 'الصربية',
		graduateSchool: 'تخرج من المدرسه',
		nursePractitioner: 'ممرضة متدربة',
		emergencyMedicinePhysician: 'طبيب طب الطوارئ',
		hospitalist: 'طبيب مستشفى',
		internist: 'طبيب باطني',
		pediatrician: 'طبيب الأطفال',
		psychiatrist: 'طبيب نفسي',
		childPsychologist: 'علم نفس الأطفال',
		counselor: 'مستشار',
		psychologist: 'الطبيب النفسي',
		psychotherapist: 'معالج نفسي',
		other: 'أخرى',
		newVersionAvailable: 'الإصدار الجديد من التطبيق جاهز',
		checkInOtherPatient:
			'إذا واصلت تسجيل وصول مريض آخر ، فستفقد جميع التغييرات الحالية التي أجريتها. هل أنت متأكد من أنك تريد تجاهل التغييرات والبدء من جديد في عملية تسجيل الوصول؟',
		discardAndContinue: 'تجاهل التغييرات واستمر',
		currentSignature: 'التوقيع الحالي',
		cancelCall: 'إلغاء المكالمة',
		deviceOfflineTryLater: 'هذا الجهاز غير متصل بالإنترنت. حاول مرة أخرى في وقت لاحق.',
		deviceOnCallTryLater: 'هذا الجهاز قيد المكالمة حاليًا. حاول مرة أخرى في وقت لاحق.',
		deviceOfflineTryLaterExtended: '(القيمة ١) غير متصل. حاول مرة أخرى في وقت لاحق.',
		deviceOnCallTryLaterExtended: '(القيمة ١) قيد المكالمة حاليًا. حاول مرة أخرى في وقت لاحق.',
		cannotConnect: 'لا يمكن الاتصال. حاول مرة اخرى.',
		callDeclined: 'تم رفض الاتصال',
		callEndedTryAgain: 'انتهى الاتصال. حاول مرة اخرى',
		failedReconnect: 'فشل إعادة الاتصال بالشبكة. حاول مرة اخرى.',
		idleCallEnded: 'لقد كنت خاملا لفترة طويلة. تم إنهاء المكالمة.',
		riskOfFall: 'خطر السقوط',
		patientNeedsHelp: 'يحتاج المريض إلى المساعدة',
		patientRequiresHelp: 'يطلب المريض المساعدة',
		otherActivity: 'نشاط آخر',
		searchPatientByName: 'يرجى البحث عن المريض بالاسم الأول واسم العائلة لمعرفة معلومات زياراته',
		switchToHelloSource: 'قم بالتبديل إلى HELLO Source',
		powerOnTV: 'تشغيل التلفزيون',
		powerOffTV: 'إيقاف تشغيل التلفزيون',
		time: 'زمن',
		macAddress: 'عنوان ماك',
		appVersion: 'نسخة التطبيق',
		androidVersion: 'نسخة أندرويد',
		osVersion: 'إصدار نظام التشغيل',
		ipAddress: 'عنوان بروتوكول الإنترنت',
		stethoscopeAudioRecordings: 'تسجيلات صوتية لسماعة الطبيب',
		selectPatientAndContinue: 'يرجى تحديد أحد المرضى من أجل المضي قدماً في الإجراءات الأخرى',
		unit: 'الوحدة',
		pleaseWriteHeight: 'من فضلك اكتب طولك',
		pleaseWriteWeight: 'من فضلك اكتب وزنك',
		pleaseWriteTotalCholesterol: 'من فضلك اكتب الكوليسترول الكلي الخاص بك',
		pleaseWriteHdlCholesterol: 'يرجى كتابة الكولسترول HDL الخاص بك',
		pleaseWriteWaistCircumference: 'يرجى كتابة محيط خصرك',
		centimeters: 'السنتيمترات',
		kilograms: 'كيلوغرامات',
		pleaseSelectCountry: 'رجاء قم بإختيار دوله',
		pleaseWriteCity: 'من فضلك اكتب مدينة',
		pleaseWriteZipCode: 'الرجاء كتابة رمز بريدي',
		pleaseWriteAddress: 'الرجاء كتابة عنوان',
		pleaseWriteLicense: 'الرجاء إدخال ترخيص',
		pleaseSelectDegree: 'الرجاء تحديد درجة',
		pleaseWriteStartDate: 'الرجاء إدخال تاريخ البدء',
		startDateCannotBeHigher: 'لا يمكن أن يكون تاريخ البدء أعلى من اليوم',
		startDateCannotBeThatLower: 'لا يمكن أن يكون تاريخ البدء أقل من ١٢٠ عامًا من الآن',
		endDateCannotBeSameAsStartDate: 'يجب أن يكون تاريخ الانتهاء أعلى من تاريخ البدء بسنة واحدة على الأقل',
		pleaseWriteEndDate: 'الرجاء كتابة تاريخ الانتهاء',
		pleaseWriteCompany: 'الرجاء كتابة اسم الشركة',
		pleaseWritePosition: 'الرجاء كتابة الموقف',
		clear: 'صافي',
		noDiagnosesFound: 'لم يتم العثور على تشخيصات',
		noPrescriptionsFound: 'لم يتم العثور على وصفات طبية',
		noNotesFound: 'لم يتم العثور على ملاحظات',
		noProcedureFound: 'لم يتم العثور على أي إجراء',
		setupAvailability: 'توافر الإعداد',
		noVisitsFound: 'لم يتم العثور على زيارات',
		connectionType: 'نوع الاتصال',
		physicalExercises: 'تمارين بدنية',
		physicalTherapy: 'علاج بدني',
		medicalInfoPhysicalTherapy: 'علاج بدني',
		physicalTherapist: 'معالج فيزيائي',
		startingDate: 'تاريخ البدء',
		targetedDays: 'الأيام المستهدفة',
		pTPrescriptionReport: 'تقرير الوصفة الطبية PT',
		dataEditedSuccessfully: 'تم تحرير البيانات بنجاح',
		exercise: 'ممارسة',
		exerciseDate: 'تاريخ التمرين',
		editExercise: 'تحرير التمرين',
		noPhysicalExercisesFound: 'لم يتم العثور على علاجات فيزيائية',
		treeView: 'عرض الشجرة',
		listView: 'عرض القائمة',
		currentlyLoggedInAs: 'انت حاليا مسجل الدخول باعتبارك',
		logOutHere: 'تسجيل الخروج هنا',
		pleaseLogOutFromThisAccount: 'الرجاء تسجيل الخروج من هذا الحساب والعودة إلى بريدك الإلكتروني لقبول هذه الدعوة.',
		thisDeviceIsOnACall: 'هذا الجهاز في مكالمة.',
		onePatient: 'صبور',
		multiplePatients: 'مرضى',
		noOptions: 'بلا خيارات',
		anotherProcedure: 'إجراء',
		addExistingPatient: 'أضف المريض الموجود',
		addOrUpdate: 'إضافة أو تحديث الخاص بك',
		locationAndAddress: 'الموقع والعنوان',
		signatureAndStamp: 'التوقيع والختم',
		employment: 'العمالة',
		education: 'تعليم',
		signatureDescription: 'عندما تحفظ تغييراتك ، فإن كل ما تكتبه في اللوحة سيصبح توقيعك.',
		stampDescription: 'يوصى بأن يكون الختم شفافًا وبتنسيق PNG.',
		visceralFatGrade: 'درجة الدهون الحشوية',
		daysNo: 'أيام',
		emailExists: 'يوجد بريد الكتروني',
		gridView: 'عرض الشبكة',
		singleView: 'منظر واحد',
		unknown: 'مجهول',
		measuredAt: 'تقاس عند',
		patientName: 'اسم المريض',
		patientMRN: 'المريض MRN',
		of: 'من',
		firstNameNonNumber: 'يجب أن يحتوي الاسم الأول على أحرف فقط.',
		lastNameNonNumber: 'يجب أن يحتوي الاسم الأخير على أحرف فقط.',
		parentNameNonNumber: 'يجب أن يحتوي اسم الوالد على أحرف فقط.',
		cityNameNonNumber: 'يجب أن يحتوي اسم المدينة على أحرف فقط.',
		dateOfBirthNotValid: 'تاريخ الميلاد غير صحيح',
		minHeightIs: 'ارتفاع الحد الأدنى',
		maxHeightIs: 'أقصى ارتفاع',
		minWeightIs: 'الوزن الأدنى',
		maxWeightIs: 'الوزن الأقصى هو',
		minTotalCholesterolIs: 'الحد الأدنى من الكوليسترول الكلي',
		maxTotalCholesterolIs: 'الحد الأقصى للكوليسترول الكلي',
		minHdlCholesterolIs: 'الحد الأدنى من الكولسترول HDL هو',
		maxHdlCholesterolIs: 'الحد الأقصى للكوليسترول HDL هو',
		minWaistCircumferenceIs: 'الحد الأدنى لمحيط الخصر هو',
		maxWaistCircumferenceIs: 'محيط الخصر الأقصى هو',
		dateFormat: 'صيغة التاريخ',
		timeFormat: 'تنسيق الوقت',
		companyRegion: 'منطقة الشركة',
		companyOwner: 'صاحب شركة',
		invite: 'قم بدعوة',
		defaultMeasurementUnits: 'وحدات القياس الافتراضية',
		userCanChangeMeasurement:
			'ستكون الوحدات المحددة افتراضيًا لكل مستخدم مسجل في شركتك ، ولكن سيتمكن كل مستخدم من تغييرها إلى ما يفضله (فقط لأنفسهم).',
		walkingRunningDistanceRequired: 'المشي + مسافة الجري المطلوبة',
		weightRequired: 'الوزن المطلوب',
		bodyTemperatureRequired: 'درجة الحرارة مطلوبة',
		heightRequired: 'الطول المطلوب',
		bloodGlucoseRequired: 'مطلوب سكر الدم',
		dateFormatRequired: 'تنسيق التاريخ مطلوب',
		timeFormatRequired: 'تنسيق الوقت مطلوب',
		cameraNameRequired: 'اسم الكاميرا مطلوب',
		enterCameraName: 'أدخل اسم الكاميرا هنا',
		uniqueEmail: 'يجب أن تكون عناوين البريد الإلكتروني فريدة',
		companies: 'الشركات',
		verificationEmailFollowInstructions: 'لقد تلقيت رسالة بريد إلكتروني للتحقق ، يرجى اتباع التعليمات.',
		success: 'النجاح',
		requiredCompanyName: 'اسم الشركة مطلوب',
		requiredWebsiteName: 'مطلوب عنوان URL لموقع الشركة',
		milesUnit: 'ميل (أميال)',
		kmUnit: 'كم (كيلومترات)',
		lbsUnit: 'رطل (جنيه)',
		kgUnit: 'كجم (كيلوغرام)',
		fahrenheitUnit: 'درجة فهرنهايت (فهرنهايت)',
		celsiusUnit: 'درجة مئوية (مئوية)',
		ftUnit: 'قدم (قدم)',
		cmUnit: 'سم (سنتيمتر)',
		mgUnit: 'ملليغرام / ديسيلتر (مليغرام لكل ديسيلتر)',
		mmolUnit: 'مليمول / لتر (مليمول لكل لتر)',
		hourFormat12: '١٢ ساعة',
		hourFormat24: '٢٤ ساعة',
		continueAs: 'تواصل باسم',
		rememberAsDefaultChoice: 'تذكر هذا على أنه خياري الافتراضي',
		myUserRoles: 'الأدوار الخاصة بي',
		switch: 'مفتاح الكهرباء',
		addPatientRole: 'أضف دور المريض',
		addDoctorRole: 'أضف دور الطبيب',
		primarySpecialty: 'التخصص الأساسي',
		selectStates: 'حدد الدولة (البلدان)',
		statesLicensed: 'الولايات المرخص لها في',
		addARole: 'أضف دورًا',
		resetTwoFa: 'إعادة تعيين المصادقة الثنائية "،',
		selectAuth: 'اختر المصادقة الثنائية "،',
		pleaseSelectRole: 'الرجاء تحديد الدور الذي ترغب في إضافته إلى هذا العضو',
		switchRole: 'تبديل الأدوار',
		providerOrPatient: 'قم بالتبديل إلى حساب مزود أو مريض',
		admin: 'مدير',
		selectRole: 'اختر دورًا',
		digitalClinician: 'طبيب رقمي',
		areYouSureDeleteRole: 'هل أنت متأكد أنك تريد حذف هذا الدور من هذا العضو؟',
		deleteRole: 'حذف الدور؟',
		memberRole: 'الدور',
		hrs: 'ساعة',
		physicalTherapyExercises: 'تمارين بدنية',
		addNewExercise: 'أضف تمرينًا جديدًا',
		selectTypeOfExercise: 'حدد نوع التمرين',
		exerciseSettings: 'ترتيب التمرين',
		addExerciseSettings: 'أضف ترتيب التمرين',
		noAddedExercise: 'ليس لديه تمارين مضافة!',
		selectCategory: 'حدد الفئة',
		regulateExerciseIntensity: 'اضبط شدة التمرين',
		selectDatesForExercise: 'اختر مواعيد للتمارين',
		containOnlyNumbers: 'يجب أن يحتوي على أرقام فقط',
		rangeExceeded: 'يجب أن يكون الرقم بين (قيمة ١) و (قيمة ٢)',
		downloadReport: 'تنزيل التقرير',
		participantProfile: 'الملف الشخصي للمشارك',
		bridge: 'كوبري',
		togglePatientsTv: 'تبديل إيقاف تشغيل مريض التلفزيون',
		toggleGridView: 'تبديل عرض التخطيط',
		toggleLayoutView: 'تبديل عرض التخطيط',
		invitePeople: 'ادعو الناس',
		moreOptions: 'المزيد من الخيارات',
		phoneNumberExists: 'رقم الهاتف موجود بالفعل.',
		pleaseEnterValidNumber: 'يرجى إدخال رقم هاتف صالح.',
		directLink: 'رابط مباشر',
		sendLinkToPeople: 'أرسل هذا الارتباط إلى الأشخاص الذين تريد دعوتهم',
		addEmail: 'أضف بريدًا إلكترونيًا',
		inviteViaEmail: 'دعوة عبر البريد الإلكتروني',
		pleaseEnterToAddEmail: 'اضغط على زر الإدخال لإضافة بريد إلكتروني.',
		inviteViaSms: 'دعوة عبر الرسائل القصيرة',
		inviteSms: 'دعوة عبر الرسائل القصيرة',
		pressEnterToAddPhoneNumber: 'اضغط على زر الإدخال لإضافة رقم الهاتف.',
		joinViaPhone: 'الانضمام عبر الهاتف',
		accessCode: 'أضف رمز الوصول',
		sendInvite: 'ارسل دعوة',
		addOneOrMorePplEmailSmsLink: 'أضف شخصًا واحدًا أو أكثر عبر البريد الإلكتروني أو الرسائل القصيرة أو الرابط',
		pleaseEnterValidEmail: 'الرجاء إدخال بريد إلكتروني صالح.',
		emailAlreadyExists: 'هذا البريد الإلكتروني موجود بالفعل.',
		conferenceLoading: 'المؤتمر جاري التحميل',
		cameraIsOff: 'الكاميرا متوقفة',
		joinNow: 'نضم الان',
		youAreLoggedInAs: 'لقد قمت بتسجيل الدخول باعتبارك',
		youAreAboutToParticipate: 'أنت ستشارك في موعد للتطبيب عن بعد.',
		pleaseIntroduceYourself: 'يرجى تقديم نفسك',
		yourName: 'اسمك',
		askToJoin: 'اطلب الانضمام',
		conferenceNotActive: 'المؤتمر غير نشط!',
		joiningConference: 'الانضمام إلى المؤتمر',
		firstNameRequired: 'الإسم الأول مطلوب',
		seeingOrHearingPatient:
			'أنت لا تستمع أو ترى المريض {value5} {value2} لأنه تم تفعيل زر الخصوصية الجسدية على {value}. يمكن تعطيل هذا الزر يدويًا فقط. يرجى الاتصال بالمسؤول الخاص بك. {value4}',
		alsoTvIsMuted: 'أيضًا ، تم كتم صوت التلفزيون أو إيقاف تشغيله. يُرجى التأكد من أن {value} يمكنه سماعك.',
		tvIsMuted: 'تم كتم صوت التلفزيون أو إيقاف تشغيله. يُرجى التأكد من أن {value} يمكنه سماعك.',
		thePatient: 'صبور',
		theBaby: 'طفل',
		connectingToHello: 'الاتصال بـ {value}',
		loadingCamera: 'تحميل الكاميرا',
		cameraAndMicBlocked: 'تم حظر الكاميرا والميكروفون',
		micBlocked: 'تم حظر الميكروفون الخاص بك',
		cameraBlocked: 'تم حظر الكاميرا الخاصة بك',
		clickMicOrCamera: 'انقر فوق (القيمة) الأيقونة المحظورة أعلى يمين الشاشة',
		camera: 'كاميرا',
		microphone: 'الميكروفون',
		maximizeFeed: 'شاشة كاملة',
		minimizeFeed: 'الخروج من وضع ملئ للشاشة',
		allowAccessRefreshPage: 'اسمح بالوصول وقم بتحديث الصفحة',
		youHaveLeftMeeting: 'كنت قد غادرت الاجتماع',
		meetingEndedFamilyMember:
			'انتهت الجلسة من قبل المستشفى. تم تفعيل أزرار الخصوصية على جهاز {value}. يمكنك رؤية الطفل عندما تتم إزالة أزرار الخصوصية.',
		babyOutsideVisitingHoursMessage: 'لا يمكنك رؤية طفلك خارج ساعات الزيارة.',
		unSupportedTV: 'تلفزيون غير مدعوم',
		commandIsNotSupportedPatientTV: 'هذا الأمر غير مدعوم على تلفزيون هذا المريض.',
		areUsureUwantToLeaveSession: 'هل أنت متأكد أنك تريد مغادرة الجلسة؟',
		copy: 'ينسخ',
		copied: 'نسخ',
		failedToCopyLink: 'فشل نسخ الرابط!',
		copyCorrelationId: 'نسخ معرف الارتباط',
		thereIsActiveConference: 'يوجد بالفعل مؤتمر نشط',
		callTransferredToAnotherClient: 'تم تحويل المكالمة إلى عميل آخر.',
		callAnsweredElsewhere: 'تم الرد على المكالمة على جهاز آخر.',
		rejoin: 'إعادة الانضمام',
		submitPhysicalTherapy: 'استحداث العلاج الطبيعي',
		universalSafetyInterventions: 'تدخلات السلامة العالمية',
		fallRisk: 'مخاطر السقوط',
		fallHarmRisk: 'الوقوع مع مخاطر الأذى',
		silentHeartAttack: 'النوبة القلبية الصامتة',
		addSuperAdmin: 'إضافة مشرف متميز',
		privacyOn: 'تشغيل الخصوصية',
		privacyOff: 'الخصوصية معطلة',
		settings: 'إعدادات',
		safeRanges: 'نطاقات آمنة',
		pressureInjury: 'إمخاطر القرحة',
		getOutOfBed: 'القيام من السرير',
		faceDown: 'الوجه للأسفل',
		rails: 'القضبان',
		left: 'اليسار',
		right: 'اليمين',
		top: 'أعلى',
		bottom: 'أسفل',
		skeletonMode: 'وضع الهيكل العظمي',
		physicalTherapies: 'العلاجات الفيزيائية',
		addExercise: 'أضف تمرين',
		seconds: 'ثواني',
		hour: 'ساعات',
		noRooms: 'لا توجد غرف في الوقت الحالي.',
		callType: 'نوع المكالمة',
		details: 'تفاصيل',
		exportCSV: 'تصدير سجلات CSV',
		exportToCSV: 'تصدير إلى CSV',
		cannotBeNDaysDifference: 'لا يمكن أن يكون فرق التاريخ أكثر من {value} من الأيام',
		reportSentSuccessfully: 'تم إرسال التقرير بنجاح',
		toAccessCheckEmail: 'للوصول إلى التقرير وتنزيله ، يرجى المتابعة على عنوان بريدك الإلكتروني',
		listOfRooms: 'قائمة الغرف',
		exportCSVInBackground: 'تصدير CSV في الخلفية',
		talkToPatient: 'تحدث إلى المريض',
		talkToNurse: 'تحدث إلى الممرضة',
		patientView: 'ظهور المريض',
		ongoing: 'جاري التنفيذ',
		past: 'ماضي',
		screenUsed: 'الشاشة المستخدمة',
		callDetails: 'تفاصيل المكالمة',
		endCallForRoom: 'إنهاء المكالمة للغرفة',
		retry: 'أعد المحاولة',
		callDurationSoFar: 'مدة المكالمة (حتى الآن)',
		noOngoingConferences: 'لا توجد مؤتمرات جارية',
		refresh: 'تحديث',
		areYouSureToTerminate: 'هل أنت متأكد من إنهاء',
		terminateRequestTimeOut: 'مهلة طلب الإنهاء',
		retryRequest: 'هل تريد إعادة المحاولة؟ سيؤدي ذلك إلى إنهاء المكالمة بين الطبيب والمريض.',
		sessionTerminatedSuccessfully: 'لقد تم إنهاء الجلسة بنجاح',
		terminateRoomSession: 'هل أنت متأكد أنك تريد إنهاء جلسة الغرفة هذه؟ سيؤدي ذلك إلى إنهاء المكالمة بين الطبيب والمريض.',
		roomSessionTerminated: 'تم الانتهاء من جلسة الغرفة بنجاح.',
		sessionAlreadyEnded: 'هذه الجلسة قد انتهت بالفعل.',
		terminationInitiated: 'بدأ إنهاء هذه الجلسة بواسطة مسؤول آخر.',
		noPermissionToTerminate: 'غير مصرح لك بإنهاء هذه الجلسة.',
		assignedDevices: 'المعدات المحددة',
		assignedDevice: 'الجهاز المخصص',
		availabilitySetup: 'بيانات',
		data: 'التوفر',
		participants: 'مشاركون',
		nursesCurrentlyDuty: 'العاملون الصحيون في الخدمة في الوقت الحالي',
		noCategory: 'لا توجد فئات',
		required: 'مطلوب',
		fillRequiredFields: 'املأ الحقول المطلوبة',
		ai: 'AI',
		noMoreThanOneAiType: 'لا يمكنك تمكين أكثر من إعدادات AI في نفس الوقت',
		maxBirthdayLimit: 'يجب أن يكون عمرك ١٢٠ عامًا على الأكث',
		year: 'سنة',
		video: 'فيديو',
		file: 'الملف',
		invalidLink: 'رابط غير صالح. يرجى المحاولة مرة أخرى!',
		measureCompleted: 'اكتمل القياس',
		couldNotGetPatientData: 'تعذر الحصول على بيانات المريض',
		tryAgainWithoutMoving: 'حاول مرة أخرى دون أن تتحرك',
		pressurizationSlow: 'الضغط بطيء! يرجى التحقق من الكفة!',
		wait5minutes: 'انتظر ٥ دقائق وحاول مرة أخرى',
		doToRetakeMeasure: 'يمكنك {value} لإعادة القياس!',
		toRemeasureTemperature: 'لإعادة إجراء القياس ، اضغط على زر قياس ميزان الحرارة.',
		doToReconnect: 'يمكنك {value} لإعادة الاتصال!',
		measureHasFinished: '{value} انتهت من القياس',
		deviceIsConnected: '{value} - الجهاز متصل',
		deviceIsConnecting: '{value} - الجهاز متصل',
		deviceDisconnected: '{value} - تم فصل الجهاز',
		pleaseWaitConnection: 'الرجاء الانتظار حتى يتم الاتصال',
		pleaseStandOnScale: 'يرجى الوقوف على الميزان لبدء القياس.',
		valueIsDisconnected: '{value} غير متصل',
		valueIsConnecting: 'يتم توصيل {value}',
		valueIsConnected: 'تم توصيل {value}',
		deviceCouldNotConnect: '{value} - تعذر الاتصال بالجهاز. تحقق من البلوتوث',
		deviceMeasuring: '{value} - الجهاز يقيس',
		deviceConnectFail: '{value} - فشل اتصال الجهاز',
		deviceMeasureStopped: '{value} - قياس توقف',
		deviceMeasureCompleted: '{value} - اكتمل القياس',
		deviceReconnecting: '{value} - الجهاز يعيد الاتصال',
		problemWithReader: 'مشكلة مع القارئ. قم بإجراء اختبار جديد باستخدام شريط اختبار جديد!',
		testStripRemoved: 'تمت إزالة شريط الاختبار أثناء القياس.',
		problemStrip:
			'مشكلة في شريط الاختبار (الشريط المبلل ، تمت إزالته بسرعة كبيرة ، وضع الكثير من الدم على الشريط). قم بإجراء اختبار جديد باستخدام شريط اختبار جديد!',
		lowRoomTemperature:
			'درجة حرارة الغرفة منخفضة للغاية. درجة الحرارة المثلى هي ١٠ درجة مئوية - ٤٠ درجة مئوية / ٥٠ درجة فهرنهايت - ١٠٤ درجة فهرنهايت ،',
		highRoomTemperature:
			'درجة حرارة الغرفة مرتفعة للغاية. درجة الحرارة المثلى هي ١٠ درجة مئوية - ٤٠ درجة مئوية / ٥٠ درجة فهرنهايت - ١٠٤ درجة فهرنهايت ،',
		communicationError: '{value} - خطأ في الاتصال. اضغط مع الاستمرار على زر M (الذاكرة) لمدة ٧ ثوانٍ لاستعادة الجهاز',
		newTestNewStrip: 'قم بإجراء اختبار جديد باستخدام شريط اختبار جديد!',
		getBlood: 'احصل على الدم!',
		measureStarted: 'بدأ القياس',
		stripIn: 'تجريد في',
		stripOut: 'تجريد خارج',
		totalCholesterol: 'الكولسترول الكلي',
		hdlCholesterol: 'كوليسترول HDL',
		waistCircumference: 'الخصر محيط',
		healthInformation: 'معلومات صحية',
		currentEverydaySmoker: 'الحالي كل يوم المدخن',
		currentSomedaySmoker: 'مدخن حالي في يوم ما',
		formerSmoker: 'كنت مدخنًا في الماضي',
		neverSmoker: 'لا تدخن أبدًا',
		sufferFromHypertension: 'هل تعاني من ضغط الدم المرتفع؟',
		medicalStatus: 'هل لديك حالة دواء؟',
		areYouSmoker: 'هل أنت مدخن؟',
		haveDiabeticStatus: 'هل لديك حالة مرض السكري؟',
		patientIsBeingRegistered: 'يتم تسجيل المريض',
		patientAlreadyExists: 'المريض موجود بالفعل',
		pleaseSelectOneOfTheOptionsBelow: 'من فضلك حدد واحد من الخيارات بالأسفل',
		diabetesMellitusI: 'داء السكري من النوع الأول',
		diabetesMellitusII: 'داء السكري من النوع الثاني',
		submit: 'إرسال',
		dateTimeFormats: 'تاريخ تنسيقات الوقت',
		seeingAndHearing: 'رؤية وسماع',
		seeing: 'رؤية',
		hearing: 'السمع',
		patientRequestedHelp: 'طلب المريض المساعدة',
		patientSitting: 'تم اكتشاف أن المريض غير نشط في وضع الجلوس.',
		patientStanding: 'تم اكتشاف أن المريض غير نشط في وضع الوقوف.',
		patientLayingSide: 'وجد المريض غير نشط في وضع الاستلقاء الجانبي.',
		patientLayingFront: 'تم الكشف عن أن المريض غير نشط في وضع الاستلقاء متجهًا إلى الأمام.',
		patientFaceDown: 'تم الكشف عن وجه المريض لأسفل.',
		patientInactive: 'تم اكتشاف المريض غير نشط.',
		closed: 'مغلق',
		or: 'أو',
		history: 'تاريخ:',
		availableDoctors: 'الأطباء المتوفرون',
		alreadySelected: 'ما لم يتم التحديد',
		alreadyInPool: 'إلا أنه في مجموعة',
		cantAddMoreDoctors: 'لا يمكنك إضافة أكثر من ٥ أطباء دفعة واحدة!',
		cam: 'الكاميرا',
		mic: 'ميكروفون',
		camAndMic: 'الكاميرا والميكروفون',
		hellocareMode: 'وضع Hellocare',
		aiWidgetMode: 'وضع أدوات الذكاء الاصطناعي',
		telemedicineMode: 'طريقة التطبيب عن بعد',
		hospitalMode: 'وضع المستشفى',
		signOut: 'تسجيل الخروج',
		primaryHealthcare: 'العناية الصحية الاولية',
		healthSystemType: 'نوع النظام الصحي',
		selectHealthSystemType: 'حدد نوع النظام الصحي',
		personGettingOutOfBed: 'المريض ينهض من الفراش',
		personInactive: 'تم الكشف عن مريض غير نشط',
		personLayingFront: 'تم الكشف عن المريض ملقى على ظهره',
		personLayingSide: 'تم الكشف عن المريض ملقى على جانبه',
		clickToLoginAnotherAcc: 'انقر هنا لتسجيل الدخول إلى حساب آخر.',
		notAssignedHs: 'لم يتم تعيينك حاليًا لأي نظام صحي',
		digitalClinicMode: 'وضع العيادة الرقمية',
		impulsiveConfused: 'مندفع / مرتبك',
		pullingO2: 'سحب O2',
		nonRedirectable: 'غير قابل لإعادة التوجيه',
		precautions: 'احتياطات',
		precautionsDescription:
			'عند تمكين هذا ، سيتمكن الممرضون من وضع احتياطات مثل مخاطر السقوط للمريض حتى يتمكنوا من تصور المرضى بسهولة وفرزهم بناءً على قائمة الاحتياطات.',
		terminateSession: 'جلسة نهاية؟',
		terminateRoomCall: 'هل تريد إنهاء جلسة الغرفة؟',
		selectRoom: 'حدد غرفة',
		selectLocationReassin: 'حدد موقعًا من النظام الصحي أعلاه لإدارة المستشفيات عليه.',
		selectLocation: 'اختر موقعا',
		selectHsToManage: 'اختر النظام الصحي الذي تريد إدارته.',
		reAssignDevice: 'إعادة تعيين الجهاز',
		regionSaved: 'المنطقة المحفوظة',
		somethingWrong: 'حدث خطأ ما.',
		selectLocationReassign: 'قم باختيار موقع من النظام الصحي أعلاه لإدارة المستشفيات فيه.',
		hi: 'أهلاً',
		selectOptionsBelow: 'حدد الخيارات أدناه',
		willBeReceivingCalls: 'سأتلقى مكالمات من المرضى',
		willNotBeReceivingCalls: 'لن أتلقى مكالمات من المرضى',
		selectHealthSystems: 'حدد النظم الصحية',
		selectReceivingCalls: 'حدد ما إذا كنت ستتلقى مكالمات من المرضى أم لا.',
		selectHealthSystemsReceiveCalls: 'اختر الأنظمة الصحية التي ستتلقى المكالمات منها.',
		selectLevelReceiveCalls: 'حدد المستوى الذي ستتلقى المكالمات منه.',
		neckExercises: 'تمارين الرقبة',
		legExercises: 'تمارين الساق',
		shoulderExercises: 'تمارين الكتف',
		hipExercises: 'تمارين الورك',
		extendedLegExercise: 'تمارين تمديد الساق',
		singleLegGluteKicksExercise: 'تمرين عضلات الساق الواحدة',
		standingMarchExercise: 'تمرين المسيرة الدائمة',
		hamstringStretchExercise: 'تمرين شد أوتار الركبة',
		standingQuadStretchExercise: 'تمرين الشد الرباعي الواقف',
		neckExercise: 'تمرين الرقبة',
		neckStretchExercise: 'تمارين شد الرقبة',
		liftingHeadSidewaysExercise: 'تمرين رفع الرأس الجانبي',
		shoulderExercise: 'تمرين الكتف',
		armRaisesExercise: 'تمرين رفع الذراع',
		rotatorCuffExercise: 'تمرين الكفة المدورة',
		shoulderExtensionExercise: 'تمرين تمديد الكتف',
		seatedShoulderRaiseExercise: 'جالس تمرين رفع الكتف',
		hipExercise: 'تمرين الورك',
		hipMarchingExercise: 'تمرين مسيرة الورك',
		sideLyingLegLiftExercise: 'تمرين رفع الساق عند الكذب الجانبي',
		fireHydrantExercise: 'تمرين صنبور النار',
		maximumRangeOfMotion: 'أقصى مدى للحركة',
		leftSideNumberOfRepetition: 'عدد الجانب الأيسر من التكرار',
		rightSideNumberOfRepetition: 'عدد التكرار في الجانب الأيمن',
		numberOfDaysToAchieveTheTarget: 'عدد الأيام لتحقيق الهدف',
		degreeIncrementInTargetROM: 'زيادة الدرجة في ذاكرة القراءة فقط المستهدفة',
		holdingTimeInSeconds: 'عقد الوقت بالثواني',
		numberOfRepetition: 'عدد التكرار',
		numberOfRepetitionForRightNeck: 'عدد مرات التكرار في الجانب الأيمن',
		addPhysicalTherapy: 'أضف علاجًا طبيعيًا',
		remotePairedWithDevice: 'يوجد جهاز تحكم عن بعد مقترن بهذا الجهاز. هل تريد إلغاء إقرانه؟',
		remoteNotPairedWithDevice:
			'لا يحتوي هذا الجهاز على أي أجهزة تحكم عن بعد مقترنة. إذا كنت ترغب في إقران جهاز تحكم عن بُعد ، فاضغط مع الاستمرار على الزر "موافق" و "رجوع" حتى ترى ضوءًا أحمر نابضًا على جهاز التحكم عن بُعد ، ثم انقر فوق "إقران على الويب"',
		remotePairedSuccessfully: 'تم الاقتران عن بعد بنجاح.',
		remoteUnpairedSuccessfully: 'تم إلغاء إقران جهاز التحكم عن بعد بنجاح.',
		pairingTimeout: 'انتهت مهلة الاقتران. يرجى المحاولة مرة أخرى.',
		pair: 'حلقة الوصل',
		unpair: 'غير زوج',
		dismiss: 'رفض',
		tryingToPairRemote: 'جارٍ محاولة إقران جهاز التحكم عن بُعد ...',
		tryingToUnpairRemote: 'محاولة إلغاء إقران جهاز التحكم عن بُعد ...',
		remoteControl: 'موصل',
		personStandingWalking: 'تم الكشف عن فقدان التوازن',
		personIsDetectedWalking: 'تم الكشف عن فقدان التوازن. يرجى التحقق من المريض!',
		analyticPositionDescription: 'تم اكتشاف المريض {value} لـ {value1}',
		lyingFront: 'الكذب الجبهة',
		lyingSide: 'الجانب الكذب',
		lyingDown: 'الاستلقاء',
		sitting: 'يجلس',
		walking: 'المشي',
		inviteUsers: 'ادعُ المستخدمين',
		cannotEnableDataAcquisition: 'لا يمكنك تمكين الحصول على البيانات نظرًا لأن الخصوصية قيد التشغيل حاليًا على هذا الجهاز.',
		cannotEnablePrivacyMode: 'لا يمكنك تمكين الخصوصية نظرًا لأن الحصول على البيانات قيد التشغيل حاليًا على هذا الجهاز.',
		careEventsForNurses: 'أحداث الرعاية للممرضات',
		roundingCareEventsForNurses: 'أحداث الرعاية للممرضات',
		careEventsForNursesDescription: 'يتم تسجيل أحداث رعاية المرضى مثل القبول والاستشارة والعلاج والتفريغ التي يقوم بها VCPs.',
		careEventsForSafetyCompanions: 'التدخلات والتنبيهات لرفقاء الأمان والممرضات',
		careEvents: 'أحداث الرعاية',
		careEvent: 'حدث الرعاية',
		recordings: 'التسجيلات',
		savedCareEvent: 'حدث رعاية مخزنة',
		eventDetails: 'تفاصيل الحدث',
		homeMedListCompleted: 'الصفحة الرئيسية ميد القائمة الكاملة',
		admissionDataCompleted: 'تم استكمال بيانات القبول',
		safetyCompanions: 'رفاق السلامة',
		companionModeName: 'وضع رفيق',
		frontlineNurses: 'ممرضات الخط الأمامي',
		ipoCompleted: 'تم الانتهاء من IPOC',
		dischargeInstructionsCreated: 'تم إنشاء إرشادات التحميل',
		dischargeTeachingCompleted: 'اكتمل تنزيل البرنامج التعليمي',
		performed2ndRNMedVerification: 'تم إجراء التحقق الثاني من RN Med',
		notifiedMD: 'MD أخطر',
		performedMentorship: 'اكتمل التوجيه',
		performedSepsisScreenNotification: 'اكتمال إعلام شاشة الإنتان',
		completed24HourChartCheck: 'اكتمل فحص الرسم البياني على مدار ٢٤ ساعة',
		performedClinicalRounding: 'دورة سريرية مكتملة',
		providedUpdateToFamily: 'تم توفير تحديث للعائلة:',
		assistedWithCodeBlue: 'ساعد مع رمز الأزرق',
		otherCareEventForNurse: 'حدث آخر لرعاية الممرضة',
		redirectedPatientToPreventFall: 'أعد توجيه المريض لمنع السقوط',
		notifiedBedsideStaffToAssistPatientToPreventFall: 'إبلاغ موظفي السرير لمساعدة المريض على منع السقوط',
		notifiedBedsideStaffToAssistPatient: 'يتم إخطار العاملين بجانب السرير لمساعدة المريض',
		calledRapidResponse: 'دعا الاستجابة السريعة',
		preventedHarmToStaffMember: 'تم منع إصابة الموظف',
		typeOfCareEvent: 'إضافة حدث رعاية',
		stopRecording: 'إيقاف التسجيل',
		record: 'سجل',
		startRecording: 'ابدأ التسجيل',
		videoRecordingSaving: 'جاري حفظ تسجيل الفيديو',
		chooseValidDate: 'يرجى تحديد تاريخ صالح',
		patientHistory: 'تاريخ المريض',
		additionalHealthDataSymptoms: 'بيانات وأعراض إضافية',
		patientRegistration: 'تسجيل المريض',
		parentName: 'اسم الوالدين',
		patientHealthCardNumber: 'رقم البطاقة الصحية للمريض',
		maritalStatus: 'الحالة الزوجية',
		single: 'غير متزوج',
		married: 'متزوج',
		divorced: 'مطلقة',
		widowWidower: 'أرملة',
		registerNewPatient: 'تسجيل مريض جديد',
		getPatientData: 'احصل على بيانات المريض',
		menuSettings: 'إعدادات القائمة',
		editMenuSettings: 'تحرير إعدادات القائمة',
		alreadyWaitingForRequest: 'أنت تنتظر طلبًا بالفعل',
		whatReasonOfVisit: 'ما سبب زيارتك اليوم؟',
		reasonOfVisit: 'سبب الزيارة',
		cannotMeasureVS:
			'لا يمكن قياس العلامات الحيوية من الويب إذا لم تكن على اتصال مع الطبيب. اذهب إلى أو قم بتنزيل تطبيق hellocare على الهاتف المحمول لقياس المؤشرات الحيوية.',
		takeBreak: 'خذ استراحة',
		resumeWorking: 'استئناف العمل',
		symptomsDuration: 'مدة الأعراض',
		frontDeskCheckIn: 'مكتب الاستقبال تسجيل الوصول',
		startVisit: 'ابدأ الزيارة',
		minutesWaitingRoom: 'دقائق في غرفة الانتظار',
		markVisitAsCompleted: 'وضع علامة على الزيارة على أنها مكتملة',
		inPerson: 'اجتماع شخصي',
		virtualCall: 'مكالمة افتراضية',
		instructionsForConsultation: 'تعليمات للتشاور',
		instructionsForDiagnosticResearch: 'تعليمات البحث التشخيصي',
		healthInstitutionWherePatientInstructed: 'المؤسسة الصحية حيث يتم إرشاد المريض',
		consultant: 'مستشار',
		purposeOfInstruction: 'الغرض من التعليمات',
		medicalHistory: 'تاريخ طبى',
		examinations: 'الامتحانات',
		icd9Code: 'ICD-9 كود',
		currentTreatment: 'العلاج الحالي',
		codeMedicines: 'الكود الطبي',
		workingDiagnosis: 'تشخيص العمل',
		icd10Code: 'ICD-10 كود',
		organizationalUnit: 'وحدة تنظيمية',
		organizationalUnitCode: 'رمز الوحدة التنظيمية',
		addressOfInstitution: 'عنوان المؤسسة',
		consultantDoctor: 'طبيب استشاري',
		procedureCode: 'قانون الإجراءات',
		icpc: 'ICPC',
		addForms: 'أضف النماذج',
		visitDetails: 'تفاصيل الزيارة',
		forms: 'نماذج',
		numberOfVisits: 'عدد الزيارات',
		lastVisit: 'الزيارة الأخيرة',
		showVisitHistory: 'إظهار سجل الزيارة',
		remove: 'نزع',
		nextVisit: 'الزيارة القادمة',
		waitingList: 'قائمة الانتظار',
		nextTest: 'الاختبار التالي',
		startTesting: 'ابدأ الاختبار',
		instructions: 'تعليمات',
		test: 'اختبار',
		sample: 'عينة',
		enterResults: 'أدخل النتائج',
		submitResults: 'إرسال النتائج',
		checkInPatients: 'تحقق في المرضى',
		doYouHaveAllergies: 'هل لديك أي نوع من الحساسية؟',
		waitingResults: 'نتائج انتظار',
		healthInstitution: 'مؤسسة صحية',
		codeHealthInstitution: 'كود المؤسسة الصحية',
		addedForms: 'النماذج المضافة',
		addAnotherTest: 'أضف اختبارًا آخر',
		doesPatientHaveAllergies: 'هل يعاني المريض من أي حساسية؟',
		noResponseFromPatient: 'لا يوجد رد من المريض',
		whatTypeOfAllergies: 'ما نوع الحساسية التي يعاني منها المريض؟',
		food: 'غذاء',
		environmental: 'بيئي',
		biological: 'بيولوجي',
		foodAllergies: 'الحساسية الغذائية',
		medicationAllergies: 'أدوية الحساسية',
		addAllergies: 'أضف الحساسية',
		registerPatient: 'تسجيل المريض',
		helloIsDisconnected: '{value} مفصول!',
		connectionCannotEstablish: 'تعذر الاتصال بـ {value}. يرجى التحقق من اتصال الإنترنت على الجهاز والمحاولة مرة أخرى.',
		cannotChangeWorkflow: 'لا يمكنك تغيير التدفق',
		noCompletedAppointments: 'ليس لديك طلبات مكتملة!',
		cholesterol: 'الكوليسترول',
		totalCholesterolTooltip:
			'إجمالي الكوليسترول في الدم هو مقياس لكوليسترول البروتين الدهني منخفض الكثافة وكوليسترول البروتين الدهني عالي الكثافة ومكونات دهنية أخرى. سيستخدم طبيبك إجمالي عدد الكوليسترول عند تحديد مخاطر الإصابة بأمراض القلب وأفضل طريقة للتعامل معها.',
		hdlCholesterolToolip:
			'يمتص HDL (البروتين الدهني عالي الكثافة) ، أو الكوليسترول "الجيد" ، الكوليسترول ويعيده إلى الكبد. ثم يطرده الكبد من الجسم. يمكن أن تقلل المستويات المرتفعة من كوليسترول البروتين الدهني عالي الكثافة من خطر الإصابة بأمراض القلب والسكتة الدماغية.',
		waistCircumferenceTooltip:
			'طريقة أخرى لتقييم المخاطر المحتملة للمرض هي قياس محيط الخصر. قد تكون دهون البطن الزائدة خطيرة لأنها تعرضك لخطر أكبر للإصابة بحالات مرتبطة بالسمنة مثل مرض السكري من النوع ٢ وارتفاع ضغط الدم ومرض الشريان التاجي. لذلك قد يخبرك محيط الخصر لديك أن لديك مخاطر أكبر للإصابة بحالات مرتبطة بالسمنة',
		meetingWithDoctor: 'لقاء مع د.',
		meetingWithPatient: 'لقاء المريض',
		enterNoteHere: 'أدخل الملاحظة هنا',
		accountIsDeactivated: 'مرحبا (القيمة)',
		helloUser: 'مرحبا {value}',
		reachAdministrator: 'عدد العينات',
		numberOfSamples: 'عدد الاختبارات',
		numberOfTests: 'حالات الطوارئ',
		emergencyCases: 'ابدأ حالة طارئة',
		startEmergencyCase: 'في انتظار النتائج',
		waitingForResults: 'البحث عن اسم المريض أو رقم بطاقة الهوية',
		searchPatientNameOrNumber: 'إضافة النتائج',
		addResults: 'إضافة النتائج',
		network: 'شبكة الاتصال',
		profileName: 'اسم الشخصية',
		cidr: 'CIDR',
		wiFiBand: 'نطاق واي فاي',
		minBandwidth: 'الحد الأدنى لعرض النطاق الترددي بالكيلو بايت في الثانية',
		maxBandwidth: 'أقصى عرض النطاق الترددي بالكيلو بايت في الثانية',
		addConfiguration: 'أضف التكوين',
		bandwidth: 'عرض النطاق',
		wifiFrequency: 'تردد WiFi',
		minShouldBeLessThanMax: 'يجب أن يكون الحد الأدنى أقل من الحد الأقصى.',
		minBandwidthRequired: 'الحد الأدنى من سرعة الإرسال إلزامي.',
		maxBandwidthRequired: 'السرعة القصوى للإرسال إلزامية.',
		minBandwidthLessThan: 'لا يمكن أن يكون الحد الأدنى لسرعة الإرسال أقل من ٥٠٠.',
		maxBandwithMoreThan: 'لا يمكن أن تكون سرعة الإرسال القصوى أكثر من ٥٠٠٠.',
		wiFiBandRequired: 'نطاق WiFi إلزامي.',
		chooseUnit: 'حدد الوحدة',
		applyConfig: 'طبِّق التهيئة.',
		selectHospital: 'اختر المستشفى',
		selectHospitalApply: 'حدد المستشفى لتطبيق الدليل النشط',
		selectFloor: 'حدد الأرضية',
		setMinBandwidth: 'اضبط الحد الأدنى لسرعة الإرسال',
		setMaxBandwidth: 'اضبط سرعة الإرسال القصوى',
		chooseWiFiBand: 'حدد نطاق WiFi',
		roleIsRequired: 'الدور إلزامي.',
		invalidIpAddress: 'عنوان IP أو نطاق CIDR غير صالح.',
		webSiteAccess: 'الوصول إلى الموقع',
		setIpAddress: 'قم بتعيين عنوان IP.',
		maximumIpAddresses: 'لا يمكنك إضافة أكثر من {قيمة) من عناوين IP في وقت واحد.',
		provideCIDRRange:
			'قدِّم نطاق عنوان باستخدام تدوين CIDR (مثل 192.168.99.0/24) ، أو عنوان IP (مثل 192.168.99.0). يمكنك أيضًا تقديم قائمة بعناوين IP أو نطاقات العناوين باستخدام الإدخال.',
		setIpAddressOrRange: 'تعيين عناوين IP أو النطاق',
		deleteNetworkConfigConfirmation: 'هل أنت متأكد أنك تريد حذف تكوين الشبكة هذا؟',
		deleteAdConfirmation: 'هل أنت متأكد أنك تريد حذف هذا الدليل النشط؟',
		deleteGroupRoleConfirmation: 'هل أنت متأكد من أنك تريد حذف دور مجموعة الدليل النشط هذا؟',
		representsRangeOfIps: 'يمثل مجموعة من عناوين IP',
		historyForConfig: 'محفوظات التكوين',
		notAuthorizedFromThisIP: 'غير مصرح لك بالوصول إلى هذه الصفحة من عنوان IP',
		notAuthorizedToAccessThisPage: 'أنت غير مفوض لدخول هذه الصفحة.',
		ifYouThinkThereAreMistakes: 'إذا كنت تعتقد أن هناك خطأ ما ، يرجى الاتصال بالمسؤول.',
		voiceCommandCalling: 'استدعاء الأوامر الصوتية',
		optonToActivateVCC:
			'يمكنك استخدام هذا الخيار لتنشيط الاتصال بالأوامر الصوتية في مستويات مختلفة من المستشفى. من خلال هذا الخيار ، سيتمكن المريض من الاتصال بالممرضات بقوله "اتصل بالممرضة" أو "اتصل بالممرضة',
		selectDeviceForFeature: 'حدد الأجهزة التي ترغب في تفعيل هذه الميزة.',
		activeDirectory: 'الدليل النشط',
		addAd: 'أضف ميلادي',
		addGroupRoles: 'أضف أدوار المجموعة',
		groupRoles: 'أدوار المجموعة',
		groupRolesDescription: 'مجموعة دور الدليل النشط',
		groupRolesRequired: 'أدوار المجموعة مطلوبة',
		azureAdUrl: 'قم بتعيين Azure AD Url',
		azureAdUrlDescription: 'https',
		setValidGroupName: 'قم بتعيين اسم مجموعة صالح',
		setValidGroupNameRequired: 'مطلوب اسم مجموعة صالح',
		setValidGroupNameDescription: 'اسم مجموعة صالح للدليل النشط',
		clientId: 'معرف العميل',
		clientIdRequired: 'معرف العميل مطلوب',
		clientIdDescription: `ضبط {القيمة} معرّف العميل`,
		clientSecret: 'سر العميل',
		clientSecretRequired: 'مطلوب سر العميل',
		clientSecretDescription: 'ضبط {القيمة} سر العميل',
		radiology: 'علم الأشعة',
		numberOfRepetitionForLeftNeck: 'عدد مرات التكرار للرقبة',
		inviteToLaboratory: 'ادعُ إلى المختبر',
		startTests: 'ابدأ الاختبارات',
		patientNoShowUp: 'لم يظهر المريض',
		markSamplesAsTaken: 'وضع علامة على العينات المأخوذة',
		markProcessAsCompleted: 'وضع علامة على العملية على أنها مكتملة',
		editPatient: 'تعديل المريض',
		searchPatientNameId: 'يمكنك البدء في البحث عن المرضى من خلال الاسم أو رقم الهوية',
		medicalForms: 'النماذج الطبية',
		formsDisplayedHere: 'سيتم عرض النماذج التي تضيفها هنا.',
		totalNumberOfSessions: 'إجمالي عدد الجلسات',
		completedVisits: 'الزيارات المنجزة',
		sessionDuration: 'مدة الجلسة',
		completedSessions: 'الجلسات المكتملة',
		notShownUp: 'لا يظهر',
		inProgress: 'في تَقَدم',
		ongoingVisits: 'الزيارات الجارية',
		registryNumber: 'رقم التسجيل',
		visitStart: 'بدء الزيارة',
		visitEnd: 'نهاية الزيارة',
		inviteToRoom: 'ادعُ إلى الغرفة',
		currentVisit: 'الزيارة الحالية',
		consultationReport: 'تقرير التشاور',
		instructionsForInjection: 'تعليمات إعطاء العلاج بالحقن (الحقن)',
		instructionsForInfusion: 'تعليمات إعطاء العلاج بالحقن (التسريب)',
		laboratory: 'مختبر',
		searchToAdd: 'ابحث لإضافة (قيمة) لـ (القيمة 2)',
		tests: 'اختبارات',
		xRayScans: 'عمليات المسح بالأشعة السينية',
		medicationNameHere: 'اسم الدواء هنا',
		medicationType: 'نوع الدواء',
		addAnotherMedication: 'أضف دواء آخر',
		selectData: 'حدد البيانات',
		addOtherData: 'أضف بيانات أخرى',
		returnReportFromLab: 'تقرير العودة من المختبر',
		returnReportFromRadiology: 'تقرير العودة من قسم الأشعة',
		addOtherNote: 'أضف ملاحظة أخرى',
		addMedicalForms: 'اضافة استمارات طبية',
		searchPatientNameIdVisitNumber: 'البحث عن طريق اسم المريض أو المعرف أو رقم التسجيل الخاص بالزيارة',
		nextDay: 'يوم',
		frontDesk: 'مكتب أمامي',
		injectionRoom: 'غرفة الحقن',
		infusionRoom: 'غرفة التسريب',
		capacity: 'قدره',
		writeCapacity: 'قدرة الكتابة',
		writePriority: 'كتابة الأولوية',
		startBreak: 'ابدأ استراحة',
		endBreak: 'استراحة النهاية',
		startSchedule: 'بدء الجدول',
		endSchedule: 'جدول الانتهاء',
		personalInformationTitle: 'معلومات شخصية',
		locationAndAddressTitle: 'الموقع والعنوان',
		licensesTitle: 'التراخيص',
		signatureAndStampTitle: 'التوقيع والطوابع',
		employmentTitle: 'العمالة',
		educationTitle: 'تعليم',
		visitHistoryFor: 'سجل الزيارة لـ',
		patientNoVisits: 'ليس لدى المريض أي زيارات.',
		openCases: 'فتح الحالات',
		closedCases: 'حالات مغلقة',
		instruction: 'تعليمات',
		therapyDescription: 'وصف العلاج',
		checkUp: 'الفحوصات',
		reCheckUp: 'إعادة التحقق',
		referral: 'الإحالة',
		referToOneOfTheDoctors: 'قم بإحالة المريض إلى أحد الأطباء',
		patientQueue: 'طابور المرضى',
		referToThisRoom: 'إحالة المريض إلى هذه الغرفة',
		waitingTime: 'وقت الانتظار',
		referralDetails: 'تفاصيل الإحالة',
		queueNumber: 'رقم قائمة الانتظار',
		patientAddedDoctorWaitingList: 'تمت إضافة المريض إلى قائمة انتظار الطبيب',
		itinerary: 'مسار الرحلة',
		additionalNote: 'مذكرة إضافية',
		visitIn: 'زيارة في',
		doctorCheckUp: 'فحص الطبيب',
		laboratoryTesting: 'الفحوصات المخبرية',
		invited: 'مدعو',
		notShowedUp: 'لم يظهر',
		cancelled: 'ألغيت',
		frontDeskPatientCheckinPending: 'المكتب الأمامي - تسجيل وصول المريض - معلق',
		frontDeskPatientCheckinInvited: 'المكتب الأمامي - تسجيل وصول المريض - تمت دعوته',
		frontDeskPatientCheckinInprogress: 'المكتب الأمامي - تسجيل وصول المريض - قيد التقدم',
		frontDeskPatientNotShowedUp: 'المكتب الأمامي - المريض لم يظهر',
		frontDeskPatientCheckinCompleted: 'المكتب الأمامي - اكتمل تسجيل وصول المريض',
		frontDeskPatientCheckinCancelled: 'المكتب الأمامي - تم إلغاء تسجيل وصول المريض',
		waitingForDoctor: 'في انتظار الطبيب',
		doctorInvitedPatient: 'المريض بدعوة من الطبيب',
		doctorInvitedPatientNotShowedUp: 'تمت دعوة المريض من قبل الطبيب - لم يظهر',
		inDoctorVisit: 'في زيارة مع الطبيب',
		doctorVisitCompleted: 'زيارة الطبيب - اكتملت',
		doctorVisitCancelled: 'زيارة الطبيب - ملغاة',
		patientWaitingForLaboratoryTest: 'اختبار معمل - المريض في انتظار',
		laborantInvitedPatient: 'تمت دعوة المريض إلى المختبر',
		laborantInvitedPatientNotShowedUp: 'تمت دعوة المريض إلى المختبر - لم يظهر',
		laboratoryTestInProgress: 'الاختبار المعملي - قيد التقدم',
		laboratoryVisitCompleted: 'زيارة المختبر - اكتملت',
		laboratoryVisitCancelled: 'زيارة المختبر - ملغاة',
		otherInformation: 'معلومات أخرى',
		preExistingDescription: 'الظروف الموجودة مسبقًا',
		typeOfAllergies: 'ما نوع الحساسية لديك؟',
		searchOrAddAllergies: 'ابحث أو أضف حساسيات جديدة',
		typeAllergyAndPressEnter: 'اكتب حساسيتك واضغط دخول',
		pleaseSelectOneOfTheOptionsAbove: 'الرجاء تحديد أحد الخيارات أعلاه',
		environmentalAllergies: 'الحساسية البيئية',
		biologicalAllergies: 'الحساسية البيولوجية',
		writePreExistingCondition: 'اكتب الشروط الموجودة مسبقًا',
		pleaseWritePreExistingCondition: 'يرجى كتابة الشروط الموجودة مسبقًا',
		patientToBeTestedFor: 'المريض المراد فحصه',
		creatinine: 'الكرياتينين',
		lucose: 'لوكوز',
		hemogram: 'الهيموجرام',
		sedimentation: 'الترسيب',
		triglycerides: 'الدهون الثلاثية',
		urea: 'اليوريا',
		urine: 'البول',
		setToPending: 'اضبط على معلق',
		numberOfAnalysis: 'عدد التحليلات',
		glucose: 'الجلوكوز',
		assignNewDoctor: 'تعيين طبيب جديد',
		reassignToQueue: 'إعادة التعيين إلى قائمة انتظار {القيمة}',
		pleaseWriteCorrectValues: 'الرجاء كتابة القيم الصحيحة',
		caseId: 'ID القضية',
		checkInNumber: 'رقم تسجيل الوصول',
		addNecessaryVitalSigns: 'أضف أي علامات حيوية ضرورية بالنقر فوق الزر "إضافة بيانات أخرى".',
		sediment: 'الرواسب',
		erythrocyteSedimentationRate: 'معدل ترسيب كرات الدم الحمراء',
		erythrocyteCount: 'عدد كرات الدم الحمراء',
		hemoglobin: 'الهيموغلوبين',
		femtolitre: 'فيمتوليتر',
		picograms: 'بيكوجرامس',
		hematocrit: 'الهيماتوكريت',
		ldlCholesterol: 'كوليسترول LDL',
		vldCholesterol: 'VLD كوليسترول',
		view: 'رأي',
		color: 'اللون',
		reaction: 'رد فعل',
		specificWeight: 'الوزن النوعي',
		proteins: 'البروتينات',
		acetone: 'الأسيتون',
		bilirubin: 'البيلروبين',
		urobilinogen: 'اليوروبيلينوجين',
		nitrites: 'النتريت',
		babyCrying: 'طفل يبكي',
		babyCryingDetected: 'تم الكشف عن بكاء طفل',
		availableLaboratorians: 'العمال المتاحون',
		referToOneOfTheLaboratorians: 'الرجوع إلى أحد فنيي المختبر',
		referToThisLaboratorian: 'الرجوع إلى هذا المختبر',
		leukocyteCount: 'عدد الكريات البيض',
		patientAddedNurseWaitingList: 'تمت إضافة المريض إلى قائمة الانتظار (القيمة}',
		auditLogs: 'سجلات التدقيق',
		client: 'عميل',
		logType: 'نوع السجل',
		inputter: 'المدخلات',
		inputterId: 'معرف الإدخال',
		inputterType: 'نوع الإدخال',
		subjectType: 'نوع الموضوع',
		subjectName: 'اسم الموضوع',
		subjectId: 'معرف الموضوع',
		tenantId: 'معرف المستأجر',
		objectType: 'نوع الكائن',
		objectId: 'معرف الكائن',
		sourceOfInput: 'مصدر المدخلات',
		actioneeName: 'اسم العامل',
		actioneeObjectId: 'معرف كائن الإجراء',
		actioneeObjectType: 'نوع كائن الإجراء',
		noAuditLogs: 'لا توجد سجلات تدقيق',
		filterLogs: 'سجلات التصفية',
		user: 'المستعمل',
		resetFilter: 'تصفية إعادة تعيين',
		waistUnit: 'وحدة الخصر',
		totalCholesterolUnit: 'وحدة الكوليسترول الكلي',
		hdlCholesterolUnit: 'وحدة كوليسترول البروتين الدهني عالي الكثافة',
		contactInformation: 'معلومات التواصل',
		updateYour: 'حدث معلوماتك',
		update: 'تحديث',
		profilePictureTitle: 'الصوره الشخصيه',
		addressTitle: 'العنوان',
		contactInformationTitle: 'معلومات التواصل',
		healthInformationTitle: 'معلومات صحية',
		manageProfileInformation: 'إدارة معلومات ملفك الشخصي',
		keepAccountSecure: 'حافظ على حسابك آمنًا',
		changePharmacyLocation: 'تغيير موقع الصيدلية الخاصة بك',
		manageApps: 'إدارة تطبيقاتك',
		changeAppLanguages: 'تغيير لغة التطبيق',
		changeMeasurementUnits: 'تغيير وحدات القياس',
		helpDescription: 'حول ، دعم الدردشة الحية ، دعوة الناس',
		appLanguage: 'لغة التطبيق',
		help: 'مساعدة',
		uploadNewPicture: 'تحميل صورة جديدة',
		postalCode: 'رمز بريدي',
		otherData: 'بيانات أخرى',
		selectOption: 'حدد خيار',
		selectRails: 'لماذا ريلز يرسل تنبيهًا',
		showFullProfile: 'عرض الملف الشخصي الكامل',
		fillMandatoryFields: 'لا يمكن حفظ التغييرات إلا إذا قمت بملء الحقول الإلزامية',
		endDateLaterThanStartDate: 'يجب أن يكون تاريخ الانتهاء بعد تاريخ البدء',
		endDateLaterThanToday: 'يجب ألا يكون تاريخ الانتهاء بعد تاريخ اليوم',
		rtgPulmonary: 'مسح رئوي',
		rtgSkeleton: 'مسح الهيكل العظمي',
		rtgChest: 'مسح الصدر',
		rtgUpperExtremities: 'مسح الأطراف العلوية',
		rtgLowerExtremities: 'مسح الأطراف السفلية',
		rtgSpine: 'مسح العمود الفقري',
		ultrasonography: 'صدى - الموجات فوق الصوتية',
		injection: 'حقنة',
		infusion: 'تسريب',
		recording: 'تسجيل',
		recordTheSession: 'سجل الجلسة',
		recordingDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من تسجيل جلسة المراقبة التي سيتم حفظها في ملف تعريف المريض.',
		pleaseChooseAnotherDate: 'الرجاء اختيار تاريخ آخر.',
		availableNursesIn: 'الممرضات المتاحين في (القيمة}',
		referToOneOfNurses: 'الرجوع إلى إحدى الممرضات',
		infusionTherapy: 'العلاج بالتسريب',
		injectionTherapy: 'العلاج بالحقن',
		deleteRegion: 'حذف منطقة؟',
		atLeastOneRegion: 'تحتاج الأنظمة الصحية إلى منطقة واحدة على الأقل!',
		deleteHS: 'حذف النظام الصحي؟',
		regionCannotBeEmpty: 'لا يمكن أن تكون المنطقة فارغة',
		regionExists: 'توجد بالفعل منطقة بهذا الاسم',
		healthSystemExists: 'النظام الصحي بهذا الاسم موجود بالفعل',
		positive: 'إيجابي',
		negative: 'سلبي',
		inTraces: 'في آثار',
		clearUrine: 'صافي',
		turbid: 'عكر',
		reddish: 'محمر',
		yellow: 'الأصفر',
		acidic: 'حمضي',
		noOngoingVisit: 'حاليا لا توجد أي زيارة جارية.',
		noShowUpVisit: 'لا توجد حاليًا أي حالة لم يحضر فيها المرضى.',
		noCompletedVisits: 'حاليا لا توجد أي زيارة مكتملة.',
		radiologyScans: 'فحوصات الأشعة',
		comment: 'التعليق',
		enterComment: 'أدخل التعليق',
		radiologyRoom: 'غرفة الأشعة',
		newPassword: 'كلمة السر الجديدة',
		readTermsAndConditions: 'قراءة البنود والشروط',
		readPrivacyPolicy: 'قراءة سياسة الخصوصية',
		chemicals: 'مواد كيميائية',
		distance: 'مسافه',
		weightAndLeanBodyMass: 'المتعلقة بالوزن وكتلة الجسم النحيل',
		relatedBodyTemperature: 'متعلق بدرجة حرارة الجسم',
		heightAndWaistCircumference: 'المتعلقة بالطول ومحيط الخصر',
		walkingAndRunning: 'المتعلقة بالمشي + مسافة الجري',
		bloodGlucoseAndCholesterol: 'تتعلق بجلوكوز الدم ، وإجمالي الكوليسترول ، وكوليسترول البروتين الدهني عالي الكثافة',
		selectUnit: 'حدد الوحدة',
		poolingFlow: 'تدفق التجمع',
		editGeneralSettings: 'تحرير الإعدادات العامة',
		durationDays: 'أيام',
		timesADay: 'مرات في اليوم',
		parenteralTherapyReport: 'تقرير العلاج بالحقن',
		returnReportFromDiagnostics: 'تقرير العودة من التشخيص',
		searchToAddDiagnosis: 'ابحث لإضافة التشخيص',
		addDiagnoses: 'أضف التشخيصات',
		previousDiagnoses: 'التشخيصات السابقة',
		presets: 'الإعدادات المسبقة',
		createPreset: 'إنشاء إعداد مسبق',
		createNewPreset: 'إنشاء إعداد مسبق جديد',
		typePresetDescription: 'اسم الإعداد المسبق',
		addNewPreset: 'إضافة إعداد مسبق جديد',
		deletePreset: 'حذف هذا الإعداد المسبق',
		areYouSureToDeletePreset: 'هل أنت متأكد أنك تريد حذف الإعداد المسبق {value}؟',
		goToPreset: 'الانتقال إلى هذا الإعداد المسبق',
		noPresets: 'لا تتوفر إعدادات مسبقة، جرب إضافة واحدة.',
		presetChangeDescription: 'يمكنك تغييره في أي وقت',
		presetName: 'اسم الإعداد المسبق',
		activePreset: 'الإعداد المسبق النشط:',
		noActivePreset: 'لا يوجد إعداد مسبق نشط',
		resetCamera: 'إعادة ضبط الكاميرا',
		updateToCurrentPosition: 'تحديث إلى الوضع الحالي',
		pleaseWriteValidDate: 'الرجاء إضافة المزيد من الخبرات الحديثة. للأسف ، لا ندعم حاليًا التجارب التي مرت ٦٠ عامًا.',
		returnReportFromParentalTherapy: 'تقرير عودة من العلاج الأبوي',
		number: 'رقم',
		refer: 'إحالة',
		sectorExistsMessage: '(القيمة} بهذا الاسم موجودة بالفعل',
		youAreAlreadyOnCall: 'أنت بالفعل في مكالمة.',
		deviceNotAvailableForCall: 'الجهاز غير متوفر للاتصال الآن.',
		couldNotGetDeviceState: 'تعذر الحصول على حالة الاتصال بالجهاز.',
		visitingHours: 'ساعات الزيارة',
		addVisitingHours: 'أضف ساعات الزيارة',
		selectAll: 'اختر الكل',
		deSelectAll: 'قم بإلغاء تحديد الكل',
		conferenceId: 'معرف المؤتمر',
		initiator: 'المبدأ',
		roomCount: 'عدد الغرف',
		origin: 'الأصل',
		usedAudio: 'الصوت المستخدم',
		usedVideo: 'الفيديو المستخدم',
		usedScreen: 'الشاشة المستخدمة',
		selectContentToDownload: 'حدد المحتوى الذي تريد تنزيله',
		applyGlobalSettings: 'قم بتطبيق الإعدادات العامة',
		areYouSureConfigurations: 'هل أنت متأكد أنك تريد حفظ التكوينات الجديدة؟',
		enforceConfiguration: 'فرض التكوين',
		overrideLocalConfiguration: 'من خلال تمكين هذا الخيار ، فإنك تتجاوز مجموعة التكوين المحلية في التدرج الهرمي أدناه.',
		confirmationModal: 'نموذج التأكيد',
		activeQueueError: 'لديك مرضى في طابور الانتظار الخاص بك. الرجاء إكمال قائمة الانتظار.',
		chooseRole: 'اختر دورًا',
		selectRoleOrAll: 'حدد دورًا أو اختر الكل لتطبيق فترة عدم النشاط',
		addToQueue: 'إضافة إلى قائمة الانتظار في (القيمة)',
		reject: 'يرفض',
		rejectSession: 'رفض الجلسة',
		confirmRejectSession: 'هل أنت متأكد أنك تريد رفض هذه الجلسة؟',
		rejectSessionReason: 'سبب الرفض (اختياري)',
		patientsInQueue: 'المرضى في الطابور',
		availableNurses: 'الممرضات المتوفرة',
		virtualSitterOnList: 'جليسة افتراضية',
		addVirtualSitter: 'إضافة جليسة افتراضية',
		virtualsitter: 'جليسة افتراضية',
		nextVisitIn: 'الزيارة التالية في (القيمة)',
		pleaseGoToFrontDesk: 'يرجى زيارة مكتب الاستقبال لتخصيص قائمة انتظار',
		hdmiPort: 'منفذ HDMI',
		defaultSource: 'المصدر الافتراضي',
		ringtoneVolume: 'حجم نغمة الرنين',
		'port/siteId': 'معرّف المنفذ / الموقع',
		port: 'المنفذ',
		siteId: 'رقم الموقع',
		apiKey: 'مفتاح API',
		pleaseSelectHealthSystem: 'الرجاء تحديد نظام صحي.',
		cannotSelectNrMoreThanValue: 'لا يمكن اختيار أكثر من {value} {item},',
		pleaseSelectHospital: 'الرجاء تحديد مستشفى.',
		pleaseSelectDepartment: 'الرجاء تحديد القسم.',
		pleaseSelectSector: 'من فضلك إختر قيمة}.',
		pleaseSelectHDMIPort: 'الرجاء تحديد منفذ HDMI.',
		pleaseSelectTV: 'الرجاء تحديد جهاز تلفزيون.',
		ringtoneVolumeValidation: 'يجب أن يكون حجم نغمة الرنين بين ٠ و ١٠٠.',
		pleaseSelectEndCallSource: 'يرجى تحديد مصدر الاتصال بالتلفزيون',
		pleaseSelectProfileName: 'يرجى تعيين اسم ملف التعريف.',
		pleaseSelectCompanionDevice: 'يرجى تحديد جهاز مصاحب إذا كان متاحًا',
		pleaseSetURL: 'يرجى تعيين URL.',
		pleaseSetSecret: 'من فضلك ضع سرا.',
		pleaseSetApiKey: 'يرجى تعيين مفتاح API.',
		pleaseSetSiteId: 'يرجى تعيين معرف الموقع.',
		pleaseSetClientId: 'الرجاء تعيين معرف العميل',
		pleaseSetUserName: 'الرجاء تحديد اسم مستخدم',
		integrationType: 'الرجاء اختيار نوع التكامل.',
		portValidation: 'يجب أن يكون رقم المنفذ أكبر من ٠',
		addHSConfiguration: 'إضافة تكوين النظام الصحي',
		addTVConfiguration: 'أضف تكوين التلفزيون',
		selectHSForConfiguration: 'حدد النظام الصحي حتى تتمكن من اختيار المستشفى لتطبيق التكوينات.',
		chooseHospitalForChanges: 'اختر المستشفى الذي تريد تطبيق التغييرات عليه',
		chooseSectorForChanges: 'اختر {القيمة} التي تريد تطبيق التغييرات عليها',
		selectDepartment: 'حدد القسم',
		chooseTVSetup: 'اختر إعداد التلفزيون',
		chooseTVSetupType: 'اختر نوع إعداد التلفزيون.',
		selectHelloCareHDMI: 'حدد منفذ HDMI hellocare',
		selectHDMIPort: 'حدد منفذ HDMI حيث سيتم توصيل هيلوكير به.',
		tvManagement: 'إدارة التلفزيون',
		selectTVOption: 'حدد الخيار حيث سيعود التلفزيون مرة أخرى بعد انتهاء المكالمة.',
		selectTeleHealthProfile: 'حدد ملف تعريف الصحة عن بُعد',
		selectPreConfiguredProfile: 'حدد ملف تعريف الرعاية الصحية عن بُعد الذي تم تكوينه مسبقًا. اتركه فارغا إذا لم يكن هناك شيء.',
		helloCareRingtone: 'نغمة hellocare',
		applyToAllDevices: 'سيتم تطبيق هذا الإعداد على جميع الأجهزة الموجودة في هذا المستشفى.',
		setProfileName: 'تعيين اسم ملف التعريف',
		setURL: 'تعيين URL',
		setPort: 'تعيين المنفذ',
		setSiteId: 'حدد معرف الموقع',
		setAPIKey: 'تعيين مفتاح API',
		secret: 'سر',
		setSecret: 'تعيين السر',
		setScope: 'تعيين النطاق',
		pleaseSetScope: 'يرجى تحديد النطاق.',
		pleaseSetTenantId: 'الرجاء تعيين معرف المستأجر.',
		facilityAccessKey: 'مفتاح الوصول إلى المنشأة',
		pleaseSetFacilityAccessKey: 'يرجى ضبط مفتاح الوصول إلى المنشأة.',
		setTheme: 'تعيين الموضوع',
		pleaseSetTheme: 'الرجاء تعيين الموضوع.',
		tokenUrl: 'عنوان URL للرمز المميز',
		pleaseSetTokenUrl: 'الرجاء تعيين عنوان URL للرمز المميز',
		deleteConfiguration: 'حذف التكوين؟',
		areYouSureToDeleteConfig: 'هل أنت متأكد أنك تريد حذف هذا التكوين؟',
		liveExaminations: 'الامتحانات الحية',
		selectOneOfExaminations: 'يرجى تحديد أحد الاختبارات لبدء القياس',
		ear: 'أذن',
		lungs: 'رئتين',
		throat: 'حُلقُوم',
		skin: 'بشرة',
		ecg: 'تخطيط كهربية القلب',
		spirometer: 'مقياس التنفس',
		abdomen: 'بطن',
		lungsAir: 'مقياس التنفس',
		connectDevice: '(قيمة} (قيمة ١}',
		confirmIotProperlyPlaced: 'يرجى التأكيد مع المريض أن الجهاز الطبي في وضع صحيح.',
		toCheckConnectToDevice: 'إلى (قيمة) (قيمة ٢} ، يحتاج المريض إلى (قيمة ٣}.',
		toStartMeasureTemperature: 'لبدء قياس درجة الحرارة ، يجب على المريض الضغط على زر القياس بالجهاز.',
		check: 'التحقق من',
		toMeasure: 'يقيس',
		otoscope: 'منظار الأذن',
		dermatoscope: 'منظار الجلد',
		ultrasound: 'الموجات فوق الصوتية',
		deviceIsConnectedStartMeasurement: '(القيمة} متصلة ، يمكنك بدء القياس!',
		startMeasuring: 'ابدأ القياس',
		previousMeasurements: 'القياسات السابقة',
		showMore: 'أظهر المزيد',
		checkMePro: 'Checkme Pro',
		initiateDevice: 'بدء (قيمة}',
		assignVisitor: 'تعيين الزائر',
		visitors: 'الزائرين',
		disableVisits: 'تعطيل الزيارات',
		disableVisitsDescription:
			'عند تمكين هذا ، سيحدد المستخدمون الحد الأقصى للوقت الذي سيتم فيه تعطيل المكالمات للمريض للعائلة والأصدقاء.',
		enableVisits: 'تمكين الزيارات',
		selectTime: 'حدد الوقت',
		areYouSureDisableVisits:
			'من خلال تمكين هذا الخيار ، تقوم بتعيين الحد الأقصى للوقت الذي سيتم فيه تعطيل المكالمات للمريض للعائلة والأصدقاء. بعد مرور الوقت المحدد ، سيتم السماح بالمكالمات بناءً على دور المستخدم وجدول زيارات المستشفى.',
		idNumberExists: 'رقم المعرف هذا موجود بالفعل. يرجى المحاولة مرة أخرى!',
		infectiousDisease: 'الأمراض المعدية',
		cardiologist: 'طبيب قلب',
		pulmonologist: 'طبيب رئوي',
		nephrologist: 'طبيب كلي',
		callDuringVisitingHours: 'يمكنك الاتصال بالمريض فقط خلال ساعات الزيارة.',
		retakeMeasurement: 'إعادة أخذ القياس',
		deviceIsMeasuring: '{value} تقيس ',
		waitForCompletionToEnsure: 'انتظر حتى الانتهاء لضمان الحصول على نتائج دقيقة!',
		removeDeviceToCompleteMeasure: 'أخرج الجهاز من الإصبع لإكمال القياس.',
		completeProfileTerms: 'حدد هنا للإشارة إلى أنك قد قرأت ووافقت على',
		terms: 'مصطلحات',
		disabledFor: 'معطل ل',
		familyFriends: 'العائلة والأصدقاء',
		otherPeople: 'أشخاص أخرون',
		hierarchy: 'التسلسل الهرمي',
		roomOnList: 'غرفة',
		selectHSHierarchy: 'حدد التسلسل الهرمي للنظام الصحي',
		applyVisitingHoursAllDays: 'تطبيق ساعات الزيارة على جميع أيام الأسبوع',
		addedConfigsRecurring: 'التكوينات المضافة متكررة بناءً على يوم محدد من الأسبوع.',
		deleteMember: 'حذف عضو؟',
		stopMeasuring: 'توقف عن القياس',
		showHistory: 'إظهار التاريخ',
		error: 'خطأ',
		takingCareOfbaby: 'رعاية هذا الطفل',
		takingCareOfbabyConfirm: 'من خلال تمكين هذا الخيار ، سيتم تعيينك في مجموعة الدردشة مع أفراد عائلة هذا الطفل. يتأكد؟',
		takingCareOfbabyConfirmUnAssigned:
			'من خلال تمكين هذا الخيار ، لن يتم تعيينك في مجموعة الدردشة مع أفراد عائلة هذا الطفل. يتأكد؟',
		nicuChat: 'محادثات NICU',
		nicuCheckIn: 'تسجيل NICU',
		nicuCheckOut: 'اترك NICU',
		kiosk: 'كشك',
		triage: 'نخب',
		includeRoles: 'قم بتضمين الأدوار',
		isSingleSignOutEnabled: 'تمكين تسجيل الخروج الأحادي',
		deviceIsBusy: 'الجهاز مشغول',
		babyRoomDeviceBusy: 'لا يمكنك رؤية الطفل لأن {value} قيد الطلب',
		ntpConfigurations: 'تكوينات NTP',
		ntpUrl: 'نقطة نهاية NTP',
		ntpPort: 'منفذ NTP',
		setNtpUrl: 'قم بتعيين نقطة نهاية NTP',
		setNtpPort: 'اضبط منفذ NTP',
		setNtpUrlPort: 'تعيين نقطة نهاية NTP والمنفذ',
		pleaseEnterNtpUrl: 'الرجاء إدخال نقطة نهاية NTP',
		pleaseEnterNtpPort: 'الرجاء إدخال منفذ NTP',
		atLeastOneNtpUrlIsRequired: 'يجب تحديد نقطة نهاية NTP واحدة على الأقل',
		enterValidUrl: 'أدخل عنوان url صالحًا',
		portIsRequired: 'يجب كتابة المنفذ',
		urlPortCannotBeSame: 'يجب أن يكون حقلا نقطة النهاية والمنفذ فريدين',
		turnOnGettingOutOfBed: 'حرر من خلال النهوض من السرير',
		turnOffGettingOutOfBed: 'توقف عن النهوض من السرير',
		turnOnInactiveTime: 'حرر وقت خمول المريض',
		turnOffInactiveTime: 'أوقف وقت خمول المريض',
		patientInQueue: 'لديك مرضى في قائمة الانتظار. الرجاء ملء قائمة الانتظار!',
		medicalVisitInitiated: 'لقد بدأت الزيارة الطبية بالفعل. من فضلك أكمل الزيارة!',
		disableAi: 'تعطيل AI',
		disableAiTemporarily: 'قم بتعطيل الذكاء الاصطناعي مؤقتًا',
		disableAiDescription:
			'عند تمكين هذا ، سيتمكن المستخدمون من تعطيل حالة استخدام الذكاء الاصطناعي التي تم تشغيلها لفترة زمنية مطلوبة وبعد انقضاء هذا الوقت ، سيواصل الذكاء الاصطناعي نشاطه.',
		enableAi: 'قم بتنشيط AI',
		sendRailsAlert: 'لماذا ريلز يرسل تنبيهًا',
		areYouSureDisableAi:
			'من خلال تمكين هذا الخيار ، فإنك تحدد الوقت الذي سيتم فيه تعطيل تنبيهات AI لك وللمريض. بعد مرور الوقت المحدد ، سيتم تشغيل وظيفة AI وستتلقى تنبيهات.',
		defaultCamera: 'الكاميرا الافتراضية ،',
		selectDefaultCamera: 'اختر الكاميرا الافتراضية ،',
		selectedDefaultCameraDescription: 'ستظهر الكاميرا المحددة على أنها الكاميرا الافتراضية في كل مكالمة. ،',
		defaultCameraDescription: 'قبل تحديد كاميرا {value} ككاميرا افتراضية، يرجى التأكد من أنها متصلة بالجهاز.',
		scheduleDataAcquisition: 'جدولة الحصول على البيانات',
		scheduleTimeForDataAcquisition: 'جدولة الوقت للحصول على البيانات',
		dataAcquisitionSchedule: 'جدول الحصول على البيانات',
		snoozeFalseAlert: 'سيتم تأجيل هذا التنبيه لمدة ٥ دقائق.',
		snoozeAcknowledgeAlert: 'سيتم إيقاف تشغيل هذا التنبيه لمدة ١٥ دقيقة بينما يتلقى المريض المساعدة.',
		configureFeatureFlags: 'تكوين علامات الميزات',
		generalAndMenuSettings: 'الإعدادات العامة والقائمة',
		featureFlags: 'أعلام الميزة',
		featureFlagsDescription: 'توفر إشارات الميزات إعداد تحكم مركزيًا لتمكين أو تعطيل ميزات محددة عبر مستويات الشركة بأكملها.',
		selectSector: 'اختار القيمة}',
		sector: 'قطاع',
		pleaseSelectFloor: 'الرجاء تحديد طابق.',
		adminPreviousConfigs: 'بتحديد هذا الخيار ، فإنك تتجاوز التكوينات السابقة للقطاعات أدناه',
		maxPortNumber: 'الحد الأقصى لرقم المنفذ هو',
		portCannotBeNegative: 'لا يمكن أن يكون المنفذ رقمًا سالبًا',
		wearablesDescription: 'عند تمكين هذا، ستكون الأجهزة القابلة للارتداء متاحة للاستخدام أثناء الفحص المباشر',
		bodyComposition: 'تركيب الجسم',
		snoreDetection: 'كشف الشخير',
		caloriesBurned: 'سعرات حرارية محروقة',
		makeSureDeviceIsOn: 'تأكد من تشغيل جهاز المريض.',
		samsungWatch: 'ساعة سامسونج',
		vitalKit: 'كيت الحيوية',
		showPreviousMeasurements: 'إظهار القياسات السابقة',
		patientHasMeasured: '(القيمة ١) تم قياسها لـ (القيمة ٢}',
		ecgDoesNotShowSigns: 'لا يظهر مخطط كهربية القلب هذا علامات الرجفان الأذيني',
		cannotCheckForSignsOfHeartAttack: 'لا يمكننا التحقق من علامات النوبة القلبية. إذا كنت تعتقد أنك تعاني من حالة طبية طارئة ،',
		callEmergencyServices: 'اتصل بخدمات الطوارئ',
		theear: 'أذن',
		thelungs: 'رئتين',
		theheart: 'قلب',
		thethroat: 'حُلقُوم',
		theheartRate: 'معدل ضربات القلب',
		theoxygenSaturation: 'تشبع الأكسجين',
		thetemperature: 'درجة الحرارة',
		theskin: 'بشرة',
		thebloodPressure: 'ضغط الدم',
		theECG: 'تخطيط كهربية القلب',
		thebloodGlucose: 'جلوكوز الدم',
		theweight: 'وزن',
		theabdomen: 'بطن',
		theotoscope: 'منظار الأذن',
		thestethoscope: 'سماعة الطبيب',
		thepulseOximeter: 'مقياس النبض',
		thethermometer: 'ميزان الحرارة',
		thedermatoscope: 'منظار الجلد',
		theglucometer: 'غلوكمتر',
		thescale: 'مقياس',
		thespirometer: 'مقياس التنفس',
		theultrasound: 'الموجات فوق الصوتية',
		selectAvailableDevices: 'حدد أحد الأجهزة المتوفرة لبدء الفحص.',
		medicalDevices: 'أجهزة طبية',
		medicalDevicesDescription: 'عند تمكين هذا ، ستكون جميع أجهزة إنترنت الأشياء متاحة للمستخدم لاستخدامها أثناء الفحص المباشر',
		aiUsingCamera: 'AI باستخدام الكاميرا',
		guidePatientOtoscope:
			'قم بتوجيه المريض لإدخال منظار الأذن البيضاوي في الكاميرا الأسطوانية بالجهاز ووضعها بشكل صحيح في الأذن.',
		guidePatientHeartLungs: 'قم بتوجيه المريض لوضع الجهاز بشكل صحيح على الجسم باستخدام الرسم التوضيحي الموجه على الشاشة.',
		guidePatientThroat: 'وجه المريض لإدخال خافض اللسان بالجهاز ووضعه بشكل صحيح على الفم.',
		guidePatientHeartRate: 'وجه المريض لوضع الإبهام الأيمن على القطب الأيمن السفلي.',
		guidePatientForMeasurement: 'أرشد المريض لكيفية عمل القياس.',
		guidePatientDiabetes: 'تأكد من تشغيل أجهزة المريض وتوصيلها عبر البلوتوث.',
		guidePatientECG:
			'قم بتوجيه المريض لف الجهاز أفقيًا على اليمين. اضغط على الإبهام الأيمن على القطب الأيمن السفلي. اضغط على الإبهام الأيسر على القطب الأيسر السفلي. اضغط على السبابة اليسرى أعلى القطب الأيسر.',
		startDateLaterThanToday: 'يجب ألا يكون تاريخ البدء بعد تاريخ اليوم',
		pleaseWriteAllergy: 'الرجاء كتابة ما لا يقل عن الحساسية وانقر فوق إدخال!',
		examKit: 'طقم الامتحان الكل في واحد',
		examKitDescription: 'عند تمكين هذا ، سيكون جهاز طقم الامتحان الكل في واحد متاحًا للاستخدام أثناء الفحص المباشر',
		aiMeasurements: 'قياسات الذكاء الاصطناعي',
		AIMeasurementsDescription: 'عند تمكين هذا ، سيكون قياس العلامات الحيوية متاحًا من خلال الذكاء الاصطناعي أثناء الفحص المباشر',
		biobeatPatch: 'بيوبيت باتش',
		biobeatPatchDescription: 'عند تمكين هذا ، سيكون جهاز بيوبيت باتش متاحًا للاستخدام أثناء الفحص المباشر',
		lifesignalsPatch: 'رقعة Lifesignals',
		lifeSignalsPatchDescription: 'عند تمكين هذا ، سيكون جهاز Lifesignal رقعة متاحًا للاستخدام أثناء الفحص المباشر',
		healthDataDescription: 'عند تمكين هذا ، سيتم عرض جميع البيانات الصحية الخاصة بالمريض في المكالمة.',
		summaryDescription: 'عند تمكين هذا ، سيتم عرض ملخص المعلومات الطبية في مكان واحد',
		visitsDescription: 'عند تمكين هذا ، سيتم عرض سجل الزيارات للمريض في المكالمة.',
		alertHistoryDescription: 'عند تمكين ذلك ، سيتم عرض خيار تحديث وعرض محفوظات مخاطر العلامات الحيوية.',
		medicalInfoPatientDiagnosis: 'تشخيص المريض',
		patientDiagnosesDescription: 'عند تمكين هذا ، سيكون لدى الأطباء خيار إضافة تشخيص للمريض أثناء المكالمة.',
		proceduresDescription: 'عند تمكين هذا ، سيكون لدى الأطباء خيار إضافة إجراء للمريض أثناء المكالمة.',
		roundingNotesDescription: 'عند تمكين هذا ، سيكون لدى الأطباء خيار إضافة ملاحظات للمريض أثناء المكالمة.',
		prescriptionsDescription: 'عند تمكين هذا ، سيكون لدى الأطباء خيار إضافة وصفة طبية للمريض أثناء المكالمة.',
		physicalTherapyDescription: 'عند تمكين هذا ، سيكون لدى الأطباء خيار إضافة تمرين جديد للمريض أثناء المكالمة.',
		wrapUpPage: 'ملخص الجلسة',
		sessionWrapUpDescription: 'عند تمكين هذا الخيار، ستظهر للأطباء صفحة يمكنهم من خلالها مراجعة المعلومات الطبية وإدخالها.',
		previous: 'سابق',
		ehr: 'EHR',
		companyImage: 'صورة الشركة',
		togglePatientsTvON: `يعمل بالطاقة`,
		talkToPatients: 'تحدث إلى المرضى',
		tvChannels: 'القنوات التلفزيونية عبر القنوات',
		previousState: 'الولاية السابقة',
		visitor: 'زائر',
		digitalclinician: 'طبيب رقمي',
		nicuMode: 'وضع NICU',
		virtualExaminationMode: 'وضع الفحص الافتراضي',
		simplifiedMode: 'الوضع المبسط',
		eVideonMode: 'وضع eVideon',
		hellocareEngagement: 'المشاركة مع هيلوكير',
		rpm: 'مراقبة المريض',
		bedDefinition: 'تعريف السرير EHR',
		bed: 'سرير',
		locked: 'مقفل',
		unlocked: 'مفتوحة',
		callSettingStatus: 'مسموح بها {value2} {value}',
		is: 'هو',
		isNot: 'ليس',
		audioRoutingOptions: 'خيارات توجيه الصوت',
		selectAudioRoutingOptions: 'حدد الخيار الذي ترغب في التوجيه إليه لكل نوع من',
		customConfigurations: 'التكوينات المخصصة',
		basedOnDeviceType: 'بناءً على نوع الجهاز',
		selectOneOfRoutingOptions: 'حدد أحد خيارات التوجيه',
		audioRoutingDeviceType: 'حدد تكوينات معينة بناءً على نوع الجهاز',
		notAuthorizedHealthSystem: '.غير مصرح لك باستخدام هذا النظام الصحي',
		switchHsFeature: '.يرجى تبديل النظام الصحي لاستخدام هذه الميزة',
		tablet: 'تابلت',
		alwaysFromHello: '"دائمًا من HELLO',
		routesToTvPatientNurse: 'المسارات إلى التلفزيون عندما يرى المريض الممرضة',
		routesToTvSourceAvailable: 'التوجيهات إلى التلفزيون عند توفر المصدر / الحالة',
		alwaysPillowSpeaker: 'دائمًا من المتحدث',
		alwaysFromPhone: 'دائمًا من الهاتف',
		alwaysFromTablet: 'دائمًا من الجهاز اللوحي',
		routeExternalDetected: 'التوجيه إلى مكبر الصوت الخارجي عند اكتشافه',
		viewPatient: 'مشاهدة المريض',
		callStartAudioOnly: 'تبدأ المكالمة بصوت فقط',
		callStartAudioVideo: 'تبدأ المكالمة بالصوت والفيديو',
		audioOnly: 'ابدأ بالصوت فقط',
		audioVideo: 'ابدأ بالصوت والفيديو',
		callSettings: 'إعدادات الاتصال',
		wallpaper: 'ورق الجدران والخلفية',
		selectCallOptions: 'اختر من بين الخيارات المحددة لكل نوع من المكالمات',
		others: 'آخرون',
		callTypes: 'أنواع المكالمات',
		notAuthorizedMonitoring: 'أنت غير مصرح لك بالمراقبة',
		areYouSureToRemoveMember: 'هل أنت متأكد أنك تريد إزالة هذا العضو من الغرفة؟',
		returnReport: 'تقرير الإرجاع',
		medicalAssessment: 'التقييم الطبي',
		therapy: 'مُعَالَجَة',
		diagnosticResearch: 'البحث التشخيصي',
		menuOptions: 'خيارات القائمة',
		mainConcerns: 'الشواغل الرئيسية',
		anamnesis: 'سوابق المريض',
		clickToSave: 'انقر للحفظ',
		addMoreAllergies: 'أضف المزيد من الحساسية',
		patientAcknowledgedAllergies: 'اعترف المريض بأنه يعاني من الحساسية التالية',
		doesHaveAllergies: 'هل هذا المريض يعاني من الحساسية؟',
		writeAllergyAndEnter: 'اكتب الحساسية واضغط على يدخل للإضافة',
		noAddedAllergies: 'لا حساسية مضافة',
		searchForDiagnoses: 'ابحث عن الرمز أو الاسم واضغط على يدخل لإضافة التشخيص',
		addedDiagnoses: 'التشخيصات المضافة',
		recommendations: 'التوصيات',
		prescribeDrugs: 'وصف الأدوية',
		useToPrescribe: 'استخدم لوصف {value}',
		drugs: 'بارنا',
		prescribeInfusions: 'وصف الحقن',
		prescribeInjections: 'وصف الحقن',
		addedTests: 'الاختبارات المضافة',
		useToReferPatientTo: 'تستخدم لإحالة المريض إلى {value}.',
		searchforTheCodeAndEnter: 'ابحث عن الرمز واضغط على Enter لإضافة إجراء.',
		commentForTechnicians: 'تعليقات للفنيين.',
		visitSummaries: 'ملخصات الزيارة',
		isCompanyLevelConfiguration: 'تكوين مستوى الشركة',
		autoAdd: 'إضافة تلقائية',
		autoUpdate: 'التحديث التلقائي',
		autoDelete: 'حذف تلقائي',
		adUserDoesNotExist:
			'هذا المستخدم موجود بالفعل أو أن حساب المستخدم غير موجود في قائمة الموفرين الخارجيين للهوية للمستخدمين ولا يمكنه الوصول إلى التطبيق. يرجى التحقق مما إذا كان الحساب صحيحًا.',
		additionalDataMissing: 'لا يمكن الحصول على بيانات مقياس إضافية بسبب عدم وجود معلومات الطول والوزن والجنس في ملف تعريف المريض',
		missingWeight: 'لا يمكن الحصول على بيانات مقياس إضافية بسبب عدم وجود وزن',
		missingHeight: 'لا يمكن تحقيق بيانات المقياس الإضافية بسبب عدم وجود ارتفاع',
		missingGender: 'لا يمكن الحصول على بيانات مقياس إضافية بسبب غياب الجنس',
		unrealisticWeight: 'لا يتناسب الوزن مع ارتفاع المريض',
		shoesOn: 'لا يمكن للميزان اكتشاف مقاومة الجسم. تأكد من وضع قدميك العاريتين بشكل صحيح على الأقطاب الأربعة',
		emailNotValid: 'البريد الإلكتروني غير صالح',
		emailDuplicated: 'تم نسخ البريد الإلكتروني',
		taskCompleted: 'تمت المهمة',
		noEmailsExelFile: 'لا توجد رسائل بريد إلكتروني في ملف اكسل الخاص بك',
		errorType: 'نوع الخطأ',
		bulkUpload: 'تحميل مجمع',
		emailList: 'قائمة الايميلات',
		medium: 'واسطة',
		sensitivity: 'حساسية',
		highSensitivity: 'تم تصميم هذا الوضع لإطلاق تنبيه حتى عند أدنى حركات لشخص بعيدًا عن سريره.',
		mediumSensitivity: 'تم تعيين هذا الوضع لإرسال تنبيه عندما يتحرك الشخص بشكل معتدل بعيدًا عن سريره.',
		lowSensitivity: 'تمت برمجة هذا الوضع لإطلاق تنبيه فقط عندما يغادر الشخص سريره تمامًا.',
		standOnScale: 'الوقوف على نطاق واسع',
		deviceDisconnect: 'انقطع الاتصال',
		thelungsAir: 'FEV1/PEF',
		lowBodyTemperatureDescription: 'انخفاض درجة حرارة الجسم',
		highBodyTemperatureDescription: 'ارتفاع درجة حرارة الجسم',
		lowHeartRateDescription: 'المريض لديه معدل ضربات قلب منخفض جدا!',
		highHeartRateDescription: 'المريض لديه معدل ضربات قلب مرتفع جدا!',
		criticalHighBodyTemperature: 'ارتفاع درجة حرارة الجسم الحرجة',
		lowBMIUnderweight: 'انخفاض مؤشر كتلة الجسم ونقص الوزن',
		highBMI: 'ارتفاع مؤشر كتلة الجسم',
		criticalHighBMI: 'ارتفاع مؤشر كتلة الجسم الحرج',
		systolicLowBloodPressure: 'انخفاض ضغط الدم الانقباضي',
		diastolicLowBloodPressure: 'انخفاض ضغط الدم الانبساطي',
		systolicCriticalLowBloodPressure: 'انخفاض ضغط الدم الانقباضي الحرج',
		diastolicCriticalLowBloodPressure: 'ضغط الدم الانبساطي الحرج',
		systolicHighBloodPressure: 'ارتفاع ضغط الدم الانقباضي',
		diastolicHighBloodPressure: 'ارتفاع ضغط الدم الانبساطي',
		systolicCriticalHighBloodPressure: 'ارتفاع ضغط الدم الانقباضي الحرج',
		diastolicCriticalHighBloodPressure: 'ضغط الدم الانبساطي الحرج',
		lowOxygenSaturation: 'انخفاض تشبع الأكسجين',
		criticalLowOxygenSaturation: 'انخفاض تشبع الأكسجين',
		thehighHeartRate: 'ارتفاع معدل ضربات القلب',
		thelowHeartRate: 'انخفاض معدل ضربات القلب',
		thelowBodyTemperature: 'انخفاض درجة حرارة الجسم',
		thehighBodyTemperature: 'ارتفاع درجة حرارة الجسم',
		thecriticalHighBodyTemperature: 'ارتفاع درجة حرارة الجسم الحرجة',
		thelowBMIUnderweight: 'انخفاض مؤشر كتلة الجسم ونقص الوزن',
		thehighBMI: 'ارتفاع مؤشر كتلة الجسم',
		thecriticalHighBMI: 'ارتفاع مؤشر كتلة الجسم الحرج',
		thesystolicLowBloodPressure: 'انخفاض ضغط الدم الانقباضي',
		thediastolicLowBloodPressure: 'انخفاض ضغط الدم الانبساطي',
		thesystolicCriticalLowBloodPressure: 'انخفاض ضغط الدم الانقباضي الحرج',
		thediastolicCriticalLowBloodPressure: 'ضغط الدم الانبساطي الحرج',
		thesystolicHighBloodPressure: 'ارتفاع ضغط الدم الانقباضي',
		thediastolicHighBloodPressure: 'ارتفاع ضغط الدم الانبساطي',
		thesystolicCriticalHighBloodPressure: 'ارتفاع ضغط الدم الانقباضي الحرج',
		thediastolicCriticalHighBloodPressure: 'ضغط الدم الانبساطي الحرج',
		thelowOxygenSaturation: 'انخفاض تشبع الأكسجين',
		thecriticalLowOxygenSaturation: 'انخفاض تشبع الأكسجين',
		thehighBloodGlucose: 'ارتفاع نسبة السكر في الدم',
		stethoscopeMeasurements: 'قياسات {value} الطبيب',
		heartStetho: 'قلب',
		lungsStetho: 'رئتين',
		clickAnyPoint: 'انقر فوق أي من التسجيلات الموجودة في الجزء السفلي لبدء تشغيل الملف وعرض الرسم البياني للقياس',
		availableRecordings: 'التسجيلات المتاحة',
		measurement: 'قياس',
		pause: 'وقفة',
		more: 'أكثر',
		switchToFrontBack: 'التبديل إلى عرض {value}',
		frontView: 'أمام',
		backView: 'عُد',
		totalMeasurements: '{value} قياسات',
		allRoleCustomizationsDisabled: 'يمكن تعطيل إشارات ميزة تخصيص الدور لدورك.',
		point: 'هدف',
		selectOneOfThePoints: 'حدد إحدى النقاط لبدء القياس!',
		selectAnotherPoint: 'حدد نقطة أخرى',
		front: 'أمام',
		bulkView: 'عرض مجمّع',
		defaultView: 'العرض الافتراضي',
		maxEmail: 'الحد الأقصى من رسائل البريد الإلكتروني التي يمكنك تحميلها هو 100',
		missingPatientInfo: 'معلومات المريض مفقودة',
		conversationFiles: 'ملفات المحادثة',
		conversationPhotos: 'صور المحادثة',
		noPermission: 'ليس لديك تصريح!',
		sendRequestFailed: 'فشل إرسال الطلب. حاول مرة اخرى!',
		conferenceFailed: 'فشل المؤتمر. حاول مرة اخرى!',
		requestHasBeenSent: 'تم إرسال الطلب!',
		manageBedside: 'إدارة السرير',
		serialNumber: 'رقم سري',
		roomSignage: 'لافتات الغرفة',
		respiration: 'التنفس',
		facialSkinAge: 'عمر بشرة الوجه',
		stressIndex: 'مؤشر الإجهاد',
		cardiacWorkload: 'عبء عمل القلب',
		heartAttackRisk: 'مخاطر النوبات القلبية',
		strokeRisk: 'مخاطر السكتة الدماغية',
		extremelyLowOxygen: '!يعاني المريض من انخفاض شديد في تشبع الأكسجين',
		lowOxygen: 'المريض يعاني من انخفاض تشبع الأكسجين!',
		appleWatch: 'ساعة آبل',
		selectTimer: 'حدد عداد الوقت',
		snoozeAlert: 'سيتم إيقاف هذا التنبيه لـ {value} أثناء حصول المريض على المساعدة.',
		translationServices: 'خدمات الترجمة',
		generateQRCode: 'إنشاء رمز الاستجابة السريعة',
		clickGenerateQRCode: 'الرجاء النقر فوق إنشاء رمز الاستجابة السريعة لتخويل مجموعة',
		scanGeneratedQRCode:
			'تم إنشاء رمز الاستجابة السريعة. يرجى أن تطلب من الممرضة الموجودة بجانب السرير مسح رمز الاستجابة السريعة ضوئيًا باستخدام All-in-one Exam Kit.',
		removedAek: 'تمت إزالة All-in-one Exam Kit من الجلسة.',
		spirometerMeasurements: 'قياسات مقياس التنفس',
		spirometerTooltip: `مقياس التنفس هو جهاز تشخيصي يقيس كمية الهواء التي يمكنك استنشاقها وإخراجها. كما أنه يتتبع الوقت الذي تستغرقه في الزفير تمامًا بعد أن تأخذ نفسًا عميقًا. يتطلب اختبار قياس التنفس أن تتنفس في أنبوب متصل بجهاز يسمى مقياس التنفس.`,
		guidePatientSpirometer: 'أرشد المريض لوضع الجهاز بشكل صحيح',
		selectedTranslator: 'مترجم مختار',
		areYouSureRemoveTranslation: 'هل أنت متأكد أنك تريد إزالة خدمة الترجمة؟',
		providerName: 'اسم المزود',
		cardiacOutput: 'القلب الناتج',
		cardiacIndex: 'مؤشر القلب',
		reloadLatestFeatures: 'يرجى {value} الصفحة للوصول إلى أحدث الميزات والتحسينات.',
		minute: 'دقيقة',
		otoscopeMeasurements: 'منظار الأذن {value} القياسات',
		dermatoscopeMeasurements: 'قياسات الجلد بمنظار الجلد',
		darkMode: 'الوضع المظلم',
		handWashing: 'غسل اليدين',
		handsDisinfected: 'تطهير اليدين',
		handsDisinfectedSuccessfully: 'تم تطهير الأيدي بنجاح',
		lastUpdate: 'اخر تحديث',
		interpreter: 'إيبانتربرتر',
		resourceAssigned: 'تم تعيين المورد',
		e2ee: 'مكالمات E2EE',
		dialIn: 'يتصل ب',
		dialOut: 'الإتصال',
		cannotBeEmpty: 'لايمكن ان يكون فارغا',
		viewPatientTooltip: 'هذا الخيار سيتيح لك رؤية وسماع المريض. لن يتمكن المريض من رؤيتك أو سماعك ما لم تمكّن ذلك أثناء التغذية.',
		ttpTooltip:
			'هذا الخيار سيقوم ببدء مكالمة صوتية مع المريض، سيتمكنون من سماعك وسوف تتمكن من سماعهم. خلال المكالمة، يمكنك تشغيل كاميرا الطرف الخاص بك أو الطرف الآخر، أو حتى مشاركة شاشتك.',
		userNoAccess: 'ليس لديك إذن لإكمال هذا الإجراء.',
		ptzCam: 'ضوابط PTZ',
		pTZControlsDescription:
			'عند تمكين هذا ، سيتمكن المستخدمون من التحكم في PTZ لجهاز Hello وكاميرا 20X USB وإنشاء إشارات مرجعية لمواضع محددة للكاميرا.',
		faceDownDescription:
			'عند تفعيل هذه الميزة ، سيتمكن المستخدمون من الحصول على تنبيهات من خلال الذكاء الاصطناعي عندما يتحول المريض للنوم على وجهه.',
		aIPrivacyModeDescription: 'عند تفعيل هذه الميزة ، سيتمكن المستخدمون من إخفاء المريض ورسم شكل بسيط فوقه لحماية خصوصيته.',
		handWashingDescription:
			'عند تفعيل هذه الميزة ، سيكون بإمكان مديري المستشفيات مراقبة مزودي الرعاية الصحية من خلال الذكاء الاصطناعي إذا كانوا يلتزمون بنظافة المستشفى.',
		fallDetectionDescription:
			'عند تفعيل هذه الميزة ، سيتمكن المستخدمون من الحصول على تنبيهات من خلال الذكاء الاصطناعي عند سقوط المريض على الأرض.',
		patientMobility: 'تنقل المريض',
		patientWalkingDescription:
			'عند تفعيل هذه الميزة ، سيتمكن المستخدمون من الحصول على تنبيهات من خلال الذكاء الاصطناعي عندما يكون المريض عرضة للسقوط أثناء المشي.',
		railsDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من تنبيههم عبر الذكاء الاصطناعي عندما يضع المريض سكة حديدية أسفل سريره.',
		inactiveTimeDescription:
			'عندما يتم تمكين هذا الخيار ، سيتمكن المستخدمون من تلقي تنبيهات عبر الذكاء الاصطناعي عندما يبقى المريض غير نشط لفترة محددة من الوقت.',
		gettingOutOfBedDescription:
			'عند تمكين هذا ، سيتمكن المستخدمون من تنبيههم عبر الذكاء الاصطناعي عندما يحاول المريض النهوض من سريره.',
		aIVitalSignsDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من مراقبة العلامات الحيوية للمرضى باستخدام الذكاء الاصطناعي.',
		biobeatVitalSigns: 'العلامات الحيوية Biobeat',
		biobeatVitalSignsDescription:
			'من خلال هذا الخيار ، سيتمكن المستخدمون من تمكين مراقبة العلامات الحيوية في الوقت الحقيقي من Biobeat.',
		smartWatchVitalSigns: 'العلامات الحيوية للساعة الذكية',
		smartWatchVitalSignsDescription:
			'من خلال هذا الخيار ، سيتمكن المستخدمون من تمكين مراقبة العلامات الحيوية الحية للساعة الذكية.',
		dialInDescription: 'عند تمكين هذا الخيار ، سيتم عرض خيار دعوة الأشخاص إلى المكالمة عبر رقم الهاتف.',
		dialOutDescription: 'عند تمكين هذا الخيار ، سيتم عرض خيار الاتصال بالأشخاص عبر رقم الهاتف.',
		inviteViaEmailDescription: 'عند تمكين هذا الخيار ، سيتم عرض خيار دعوة الأشخاص إلى المكالمة عبر البريد الإلكتروني.',
		translationServiceDescription: 'عند تمكين هذا ، سيتم عرض خدمات الترجمة بلغات مختلفة.',
		inviteViaSmsDescription: 'عند تمكين هذا الخيار ، سيتم عرض خيار دعوة الأشخاص إلى المكالمة عبر الرسائل القصيرة.',
		meetingUrl: 'عنوان URL للاجتماع',
		meetingUrlDescription: 'عند تمكين هذا الخيار ، سيتم عرض خيار دعوة الأشخاص إلى المكالمة عبر الرابط.',
		roundingNightVisionDescription: 'عند تمكين هذا الخيار ، سيظهر خيار الرؤية في وضع الرؤية الليلية أثناء المكالمة.',
		gridViewDescription: 'عند تمكين هذا الخيار ، سيظهر خيار إظهار جميع المشاركين في المكالمة كعرض شبكة.',
		fitToScreenDescription: 'عند تمكين هذا الخيار ، سيتم عرض خيار ملء الشاشة أو ملاءمة الشاشة أثناء المكالمة.',
		screenSharing: 'مشاركة الشاشة',
		screenSharingDescription: 'عند تمكين هذا الخيار ، سيتم عرض خيار مشاركة الشاشة أثناء المكالمة.',
		invitingPeople: 'دعوة الناس',
		generalFeatures: 'الملامح العامة',
		communicatingWithThePatient: 'التواصل مع المريض',
		communicatingWithNurses: 'التواصل مع الممرضات',
		automaticAlerts: 'التنبيهات الآلية',
		reload: 'إعادة تحميل',
		rpmProgram: 'برنامج دورة في الدقيقة',
		rpmPrograms: 'برامج دورة في الدقيقة',
		addRpmProgram: 'إضافة برنامج دورة في الدقيقة',
		pleaseWriteProgramName: 'يرجى كتابة برنامج دورة في الدقيقة',
		careTeam: 'فريق الرعاية',
		pleaseSelectCareTeam: 'يرجى تحديد أعضاء فريق الرعاية',
		primaryCarePhysician: 'طبيب رعاية أولية',
		specialist: 'متخصص',
		pharmacist: 'صيدلاني',
		careGiver: 'الراعي',
		selectMember: 'حدد عضوا',
		survey: 'مسح/استطلاع',
		viewSurvey: 'عرض الاستطلاع',
		viewCareTeam: 'عرض فريق الرعاية',
		score: 'النتيجة',
		heartRateSpo2: 'معدل ضربات القلب',
		mod: 'واسطة',
		searchByName: 'ابحث عن المرضى بالاسم أو',
		pleaseSelectRpmProgram: 'الرجاء تحديد برنامج دورة في الدقيقة',
		selectProgram: 'اختر البرنامج',
		pleaseSelectMember: 'رجاءا اختر قيمة',
		pleaseSelectDoctor: 'الرجاء اختيار طبيب',
		program: 'برنامج',
		createProgram: 'إنشاء البرنامج',
		deleteProgram: 'حذف البرنامج?',
		areYouSureDeleteProgram: 'هل أنت متأكد أنك تريد حذف هذا البرنامج?',
		heartLungsAuscultation: 'تسمع القلب والرئتين',
		creatingProgram: 'برنامج الإنشاء',
		enterDifferentName: 'أدخل اسمًا مختلفًا عن البرامج المحددة مسبقًا.',
		programName: 'إسم البرنامج',
		measurementsSelection: 'اختيار القياسات',
		pickMeasurements: 'اختر القياسات والأجهزة التي تريد تمكينها لهذا البرنامج.',
		selectedMeasurements: 'قياسات مختارة',
		measurements: 'قياسات',
		pleaseSelectMeasurement: 'الرجاء تحديد قياس واحد على الأقل.',
		pleaseSelectDevice: 'الرجاء تحديد جهاز واحد على الأقل لكل قياس محدد.',
		editingProgram: 'برنامج التحرير',
		editProgram: 'تحرير البرنامج',
		programExists: 'هذا البرنامج موجود بالفعل!',
		maximumDevices: 'يجب أن يحتوي القياس على ما يصل إلى جهازين!',
		assignPatch: 'تعيين الرقعة',
		wearables: 'الأجهزة القابلة للارتداء',
		provider: 'مزود',
		patchId: 'معرف الرقعة',
		wearableId: 'معرف الجهاز',
		wearableType: 'نوع',
		patchWearableType: 'لصقة',
		watchWearableType: 'ساعة',
		inactive: 'غير نشط',
		viewFullData: 'عرض البيانات الكاملة',
		showEcg: 'عرض القلب الكهربائي',
		monitor: 'راقب',
		aspDescription:
			'تجعل Alexa Smart Properties للرعاية الصحية من السهل والفعال من حيث التكلفة للمستشفيات ومقدمي الخدمات رعاية مرضاهم. تعمل Alexa Smart Properties على تبسيط نشر وإدارة الأجهزة التي تدعم Alexa على نطاق واسع وتسمح للخصائص بتمكين مهارات Alexa المؤهلة لـ HIPAA ، وربط مقدمي الرعاية بالمرضى من خلال التجارب الصوتية.',
		alexaSmartProperties: 'خصائص اليكسا الذكية',
		alexaSmartPropertiesActivated: 'تنشيط خصائص اليكسا الذكية',
		aspFailed: 'تعذر تنشيط ASP ، يرجى المحاولة مرة أخرى لاحقًا',
		nutritionist: 'اخصائي تغذيه',
		providerError: 'الرجاء اختيار مزود.',
		wearableTypeError: 'الرجاء تحديد نوع الجهاز.',
		wearableIdEmpty: 'الرجاء إدخال معرف الجهاز.',
		invalidWearableId: 'الرجاء إدخال معرف صالح.',
		patchIdEmpty: 'لا يمكن أن يكون معرف الباتش فارغًا.',
		patchIdAlreadyExists: 'معرف التصحيح موجود بالفعل',
		baseURL: 'عنوان Url الأساسي',
		addBaseUrl: 'إضافة عنوان Url الأساسي',
		addApiKey: 'أضف مفتاح Api',
		eCareManager: 'مدير الرعاية الإلكترونية',
		secretKey: 'المفتاح السري',
		nodeId: 'معرّف العقدة',
		nodeLevel: 'مستوى العقدة',
		carelogs: 'الرعاية',
		carelogDetails: 'تفاصيل سجل الرعاية',
		visitInfo: 'زيارة المعلومات',
		patientInfo: 'معلومات المريض',
		endVisit: 'نهاية الزيارة',
		careMemberType: 'نوع عضو الرعاية',
		liveMeasurements: 'القياسات الحية',
		medicalRecordNumber: 'رقم السجل الطبي',
		patientsCareLogs: 'سجلات الرعاية {value}',
		possessiveSessions: 'جلسات {value}',
		at: 'في {value}',
		removePosition: 'إزالة الموقف',
		removeLicense: 'إزالة الترخيص',
		removeEducation: 'إزالة التعليم',
		ewsScore: 'النتيجة EWS',
		lowRisk: 'مخاطرة منخفضة',
		ewsSettings: 'إعدادات EWS',
		removeOption: 'خيار الإزالة',
		removeQuestion: 'إزالة السؤال',
		display: 'عرض',
		theme: 'السمة',
		defaultTheme: 'السمة الافتراضية',
		light: 'فاتح',
		dark: 'داكن',
		question: 'سؤال',
		option: 'سؤال',
		addNewSurveyDescription: 'لم تتم إضافة أي استطلاعات حتى الآن.',
		addNewSurvey: 'إضافة استبيان جديد',
		addNewQuestion: 'أضف سؤال جديد',
		addMoreOptions: 'أضف المزيد من الخيارات',
		atLeastTwoOptions: 'الرجاء تحديد خيارين على الأقل لكل سؤال',
		valuesCannotBeEmpty: 'لا يمكن أن تكون القيم فارغة',
		options: 'خيارات',
		patchNotExist: 'هذا الرقعة غير موجودة',
		telemetry: 'تلمتريا',
		deleteFileForMyself: 'احذف الملف بنفسي',
		deleteFileWarning: 'هل أنت متأكد أنك تريد حذف هذا الملف؟',
		conferenceEndedParticipantInviteDenied: 'ليس لديك أذونات كافية للاتصال بهذا المشارك. الرجاء الاتصال بالمسؤول الخاص بك.',
		participantInviteDenied: 'ليس لديك أذونات كافية لاستدعاء {value}. الرجاء الاتصال بالمسؤول الخاص بك.',
		switchingToOtherTab: 'ستنتهي جلسة المراقبة هذه وسيتم إغلاق النوافذ عند مغادرتك هذه الصفحة. هل أنت متأكد أنك تريد المغادرة؟',
		key: 'مفتاح',
		selectConfiguration: 'اختر التكوين',
		selectConfigurationDesc: 'اختر التكوين الذي ترغب في تطبيقه',
		nodeName: 'اسم العقدة',
		scope: 'نطاق',
		issuer: 'المُرسِل',
		returnUrl: 'عنوان العودة',
		oauthScopes: 'نطاقات OAuth',
		dictate: 'تملي',
		write: 'اكتب',
		statAlarm: 'إنذار سريع',
		statAlarmDescription:
			'عند تمكين هذا ، سيرى المستخدمون رمزًا إضافيًا داخل كل تغذية مراقبة ، وسيؤدي التبديل إلى تشغيل إنذار من جانب المريض.',
		editRoomNameRounding: 'تحرير اسم الغرفة',
		editRoomNameMonitoring: 'تحرير اسم الغرفة',
		editRoomNameRoundingDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من تحرير أسماء الغرف للتقريب',
		editRoomNameMonitoringDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من تحرير أسماء الغرف للمراقبة',
		enableStatAlarm: 'تمكين تنبيه ثابت',
		disableStatAlarm: 'تعطيل تنبيه ثابت',
		alexaEnabled: 'Alexa',
		enableAlexa: 'تمكين Alexa لهذه الشركة',
		enableAlexaConfiguration: 'من خلال تمكين هذه الميزة، ستكون المستشفيات قادرة على استخدام Alexa على أجهزتها {helloName}.',
		pleaseSetPassword: 'الرجاء تعيين كلمة مرور.',
		errorStatAlarm: 'حدث خطأ أثناء تبديل إنذار الثابت لـ {value}. يرجى المحاولة مرة أخرى!',
		shippingAddress: 'عنوان الشحن',
		requestedBy: 'بتوصية من',
		shipped: 'شحنها',
		logistics: 'الخدمات اللوجستية',
		patientProfile: 'الملف الشخصي للمريض',
		dashboard: 'لوحة القيادة',
		totalNumberOfCalls: 'إجمالي عدد المكالمات',
		sessionByRole: '{value} الجلسات حسب الدور',
		totalNumberSessions: 'إجمالي عدد الجلسات',
		averageSessionDuration: 'متوسط مدة الجلسة',
		totalDuration: 'المدة الإجمالية',
		sessionsByHour: 'الجلسات بالساعة',
		sessions: 'الجلسات',
		familyVisits: 'زيارات العائلة',
		avgSessions: 'جلسات متوسطة',
		highestVolumeOfSessions: 'أعلى حجم للجلسات',
		totalSessions: 'إجمالي الجلسات',
		sessionByType: 'الجلسات حسب النوع',
		externalIdentityProviders: 'موفري هوية خارجية',
		externalIdentityProviderMembers: 'أعضاء مزود الهوية الخارجي',
		selectNurse: 'حدد ممرضة',
		noMembersAdded:
			'لم يتم إضافة {value} حتى الآن. لبدء إضافة {value} ، ما عليك سوى النقر فوق الزر أعلاه. يمكنك إضافة ما يصل إلى {value2} {value}.',
		doctors: 'الأطباء',
		atLeastOneMember: 'الرجاء تحديد عضو واحد على الأقل!',
		addAdditionalMember:
			'يمكنك إضافة {value} إضافية بالإضافة إلى العناصر المضمنة بالفعل في البرنامج. لبدء إضافة {value} ، ما عليك سوى النقر فوق الزر أعلاه. يمكنك إضافة ما يصل إلى {value2} {value}.',
		editRpmProgram: 'تحرير برنامج',
		levelOfConsciousness: 'مستوى الوعي',
		supplementOxygen: 'مكمل الأكسجين',
		phoneNotReachable: 'رقم الهاتف غير قابل للوصول',
		sipNotAvailable: 'الاتصال الهاتفي غير متوفر في الوقت الحالي.',
		integrations: 'التكاملات الإلكترونية',
		eCareApiKey: 'مفتاح واجهة برمجة تطبيقات e-care',
		eCareBaseUrl: 'رابط e-care الأساسي',
		deviceWiFiTryingToConnect: 'محاولة توصيل {value}',
		deviceWiFiScanFailed: 'فشل مسح {value}. من فضلك انتظر قليلا وحاول مرة أخرى.',
		diviceWiFiScanNotFound: '{value} غير موجود ، يرجى المحاولة مرة أخرى.',
		deviceWiFiConnectionFailed: '{value} فشل الاتصال. حاول مرة اخرى.',
		deviceWiFiDisconnected: 'غير متصل {value}. حاول مرة اخرى.',
		length: 'طول',
		viewAllProviders: 'عرض جميع مقدمي الخدمة',
		totalNumberOfProviders: 'العدد الإجمالي لمقدمي الخدمات',
		lastSessionDate: 'تاريخ الجلسة الأخيرة',
		noProvidersFound: 'لا يوجد مقدمي خدمات مع جلسات مكتملة في القائمة',
		sessionStart: 'بدء الجلسة',
		sessionEnd: 'نهاية الجلسة',
		sessionStatus: 'حالة الجلسة',
		sessionDetails: 'تفاصيل الجلسة',
		callStart: 'ابدأ المكالمة',
		callEnd: 'نهاية المكالمة',
		allowMicPermissions:
			'يحتاج hellocare إلى الوصول إلى الميكروفون الخاص بك حتى يتمكن المرضى من سماعك. الرجاء السماح للميكروفون بالوصول في إعدادات متصفح الويب.',
		turnOnDictation: 'تشغيل الكتابة الصوتية',
		turnOffDictation: 'إيقاف الكتابة الصوتية',
		somethingWentWrongSpecific: '{value}. حدث خطأ!',
		unitCannotBeEmpty: 'الوحدة لا يمكن أن تكون فارغة',
		roomCannotBeEmpty: 'الغرفة لا يمكن أن تكون فارغة',
		bedCannotBeEmpty: 'السرير لا يمكن أن يكون فارغاً',
		total: 'المجموع',
		reason: 'سبب',
		subjectIdentification: 'تحديد الموضوع',
		employeeDirection: 'اتجاه الموظف',
		handHygieneAudits: 'تدقيقات نظافة اليدين',
		handHygieneViewed: 'عرض نظافة اليد',
		exportExcel: 'تصدير Excel',
		exportExcelBackground: 'تصدير Ecel في الخلفية',
		complianceReport: 'تقرير الامتثال للنظافة',
		screenShareNotAllowed: 'يقوم شخص آخر بمشاركة شاشته.',
		screenSharingNotAllowed: 'لا يمكنك مشاركة شاشتك أثناء قيام شخص آخر بالمشاركة.',
		ptzCamSettingsTitle: '20x PTZ إعدادات الكاميرا',
		reverseCamera: 'كاميرا الرجوع إلى الخلف',
		allowPermissions: 'الرجاء السماح بتصاريح الكاميرا والميكروفون للاتصال بالمريض.',
		guest: 'زائر',
		selectSurvey: 'حدد نوع الاستطلاع',
		generic: 'عام',
		safetyRange: 'نطاق الأمان',
		createSurvey: 'إنشاء استطلاع',
		addQuestionsDescription: 'يمكنك البدء في إضافة الأسئلة بالضغط على الزر أعلاه.',
		typeOfSurvey: '{value} استطلاع',
		editSurvey: 'يحرر استطلاع',
		surveyType: 'نوع المسح',
		surveys: 'الدراسات الاستقصائية',
		scheduledDate: 'التاريخ المقرر',
		questions: 'أسئلة',
		rapidRequestType: 'سريع',
		admissionRequestType: 'قبول',
		regularRequestType: 'عادي',
		failedToGetParticipantInfo: 'فشل الحصول على معلومات المشترك. حاول مرة اخرى.',
		failedToGetYourInfo: 'فشل في الحصول على المعلومات الخاصة بك. حاول مرة اخرى.',
		failedToGetParticipantInfoName: 'فشل في الحصول على معلومات {value}. حاول مرة اخرى.',
		failedToGetInitiatorInfo: 'فشل في الحصول على معلومات البادئ. حاول مرة اخرى.',
		ascAlphaOrder: '(أ-ي)',
		descAlphaOrder: '(ي-أ)',
		ascNumOrder: '(0-9)',
		descNumOrder: '(9-0)',
		queueManagement: 'إدارة قائمة الانتظار',
		rapidRequestTypeCalls: 'مكالمات سريعة',
		admissionRequestTypeCalls: 'مكالمات القبول',
		regularRequestTypeCalls: 'مكالمات منتظمة',
		manageQueue: 'إدارة قائمة الانتظار',
		viewQueue: 'عرض قائمة الانتظار',
		doctorsQueue: `طابور`,
		transferPatientsToDoctor: 'تحويل المرضى إلى طبيب',
		noAvailableDoctor: 'لا يوجد طبيب متوفر في هذا المستشفى!',
		successfullyTransferPatient: 'تم التحويل بنجاح',
		requestSuccessfullyTransferPatient: 'تم إرسال تحويل المريض الخاص بك بنجاح!',
		incomingPatientRequest: 'طلب مريض وارد!',
		changeRpmProgram: 'تغيير دورة في الدقيقة',
		reasonToConnect: 'سبب الاتصال',
		acuity: 'حدة',
		clinicalConcern: 'اهتمام سريري',
		nurseInfo: 'معلومات الممرضة',
		clinicalContact: 'اتصال سريري',
		isNurseClinicalContact: 'هل هو اتصال سريري للممرضة؟',
		rapidResponse: 'استجابة سريعة',
		edAdmitDiscussion: 'مناقشة القبول في قسم الطوارئ',
		drugOrderOrClarification: 'طلب دواء / توضيح',
		abnormalVital: 'مؤشرات حيوية غير طبيعية',
		abnormalLab: 'مختبر غير طبيعي',
		criticalLab: 'مختبر حرج',
		patientDeclining: 'تراجع المريض',
		otherEmergent: 'حالة طارئة أخرى',
		reviewOutsideFacilityTransfer: 'مراجعة نقل خارج المنشأة',
		drnOrDniClarification: 'توضيح DRN أو DNI',
		otherNonEmergent: 'حالة غير طارئة أخرى',
		admitIcu: 'القبول في وحدة الع',
		immediate: 'فوري',
		callPhoneNumber: 'اتصل برقم الهاتف',
		lifeSignals: 'اشارات الحياة',
		lifeSignalDescription: 'عند تمكين هذا ، سيكون جهاز Lifesignal Patch متاحًا للاستخدام أثناء المراقبة',
		unableToGetInfo: 'تعذر الحصول على معلومات {value}.',
		unableToGetInfoGeneralMessage: 'تعذر الحصول على معلومات المشترك.',
		callNumber: 'يتصل',
		assignWearable: 'تعيين الجهاز',
		wearableHistoryTooltip: 'تاريخ',
		unassignWearable: 'هل أنت متأكد أنك تريد إلغاء تعيين هذا الجهاز لهذا المريض؟',
		unassignWearableTooltip: 'إلغاء تعيين الجهاز',
		roleCustomization: 'تخصيص الدور',
		monitoringPrecautionsDescription: 'عند تمكين هذا ، ستتمكن {value} من رؤية وسيلة إيضاح الاحتياطات أثناء المراقبة.',
		poolingFlowDescription: 'عند تمكين هذا ، ستتمكن {value} من تغيير تدفق التجميع.',
		conversationHistoryDescription: 'عند تمكين هذا ، ستتمكن {value} من رؤية سجل المحادثات.',
		notificationsDescription: 'عندما يتم تمكين هذا ، ستكون {value} قادرة على رؤية الإخطارات.',
		savedSessionsDescription: 'عند تمكين هذا ، سيتمكن {value} من حفظ عرض الجلسات.',
		conversationFilesDescription: 'عند تمكين هذا ، ستتمكن {value} من مشاركة الملفات أثناء الدردشة مع مستخدمين آخرين.',
		conversationPhotosDescription: 'عند تمكين هذا ، ستتمكن {value} من مشاركة الصور أثناء الدردشة مع المستخدمين الآخرين.',
		homePageDescription: 'عند تمكين هذا ، سترى {value} قائمة بالمرضى الذين ينتظرون الانضمام إلى المكالمة.',
		waitingRoomDescription: 'عند تمكين هذا ، سترى {value} قائمة بالمرضى الذين ينتظرون الانضمام إلى المكالمة.',
		patientsDescription: 'عند تمكين هذا ، سترى {value} قائمة بالمرضى الذين تم تعيينهم له / لها.',
		apppointmentsDescription: `عند تمكين هذا ، سيكون لدى {value} خيار لتعيين الإتاحة وإنشاء المواعيد للمرضى.`,
		roundingDescription: `عند تمكين هذا ، ستتمكن {value} من بدء التقريب مع المرضى.`,
		rpmDescription: 'عند تمكين هذا ، ستتمكن {value} من بدء برامج  للمرضى.',
		chatDescription: 'عند تمكين هذا ، ستتمكن {value} من بدء الدردشة مع مستخدمين آخرين.',
		teamsDescription: 'عند تمكين هذا ، ستتمكن {value} من بدء الدردشة مع مستخدمين آخرين.',
		monitoringDescription: 'عند تمكين هذا ، ستتمكن {value} من بدء جلسات المراقبة.',
		visitorsDescription: 'عند تمكين هذا ، ستتمكن {value} من رؤية قائمة بزوار الغرفة المحددة.',
		dashboardDescription: 'عند تمكين هذا، سيكون {value} قادرًا على رؤية البيانات في الوقت الحقيقي للنظام الصحي المعين.',
		auditLogsDescription: 'عند تمكين هذا، سيكون المستخدم قادرًا على رؤية السجلات للنظام الصحي المحدد.',
		queueManagementDescription: 'عند تمكين هذا، سيكون {value} قادرًا على إدارة طابور الأطباء.',
		organizationDescription: 'عند تمكين هذا، سيكون {value} قادرًا على إضافة مستشفيات وأقسام وطوابق وغرف جديدة.',
		callLogsDescription: 'عند تمكين هذا، سيكون {value} قادرًا على رؤية المكالمات السابقة والمستمرة.',
		userManagementDescription: 'عند تمكين هذا، سيكون {value} قادرًا على رؤية الأعضاء والأعضاء المدعوين.',
		configurationsDescription: 'عند تمكين هذا، سيكون {value} قادرًا على تكوين الجهاز والشبكة وأحداث الرعاية.',
		noDataToDownload: 'لا توجد بيانات للتحميل',
		noAssignedWearable: 'لم يتم تخصيص أي جهاز لهذا المريض. لتعيين جهاز، انقر فوق الزر "+" الموجود على اليمين.',
		enableMultipleFeedAudioDescription: 'عند تمكين هذا ، سيتمكن المستخدمون من تمكين الصوت عند مراقبة عدة موجزات.',
		patientInformation: 'معلومات المريض',
		voiceOver: 'تعليق صوتي',
		telehealthDescription:
			'يتيح حل التلفزيون المتكامل للأفراد في المستشفى الاستمتاع بالأفلام والمحتوى الترفيهي، مما يعزز إقامتهم دون الحاجة إلى التنقل الجسدي.',
		evideoDescription:
			'eVideon عبارة عن منصة شاملة لتجربة المرضى مصممة لتعزيز المشاركة والتواصل في مرافق الرعاية الصحية. ويقدم حلول الوسائط المتعددة التفاعلية التي توفر أدوات تعليمية وترفيهية واتصالات مخصصة للمرضى، مما يحسن رضاهم وتجربة المستشفى بشكل عام.',
		pcareDescription: `ومن خلال منصة مشاركة المرضى الخاصة بـ pCare، يستطيع المرضى الوصول إلى الأدوات التفاعلية للتعليم والترفيه والتواصل. فهو يمكّن المرضى من خلال توفير المعلومات والموارد الصحية الشخصية، والحد من القلق، وتعزيز المشاركة بشكل أكبر في رعايتهم.`,
		getwellDescription: `تعمل منصة Getwell لإشراك المرضى والتواصل معهم على تسهيل الاتصال السلس بين المرضى ومقدمي الرعاية الصحية. وهو يقدم مجموعة من الأدوات للتعليم والتواصل وتنسيق الرعاية، مما يمكّن المرضى من الوصول إلى المعلومات الصحية الشخصية، والتفاعل مع فريق الرعاية الخاص بهم، والمشاركة بنشاط في خطط العلاج الخاصة بهم.`,
		sonifiDescription:
			'عزز تجربة المريض من خلال حل SONIFI Health المتكامل، الذي يدمج الاتصالات والتعليم والترفيه بسلاسة. رفع مستوى تقديم الرعاية من خلال تمكين مشاركة المرضى بسهولة داخل غرف المستشفى.',
		patientExperience: 'تجربة المريض',
		rtls: 'RTLS',
		rtlsDescription: 'عندما يتم تمكين هذا، سيرى المرضى أن الممرضة موجودة في الغرفة.',
		roomModes: 'أوضاع الغرفة',
		helloCareDescription: `عند تمكين هذا، ستعرض الشاشة معلومات مثل التاريخ واسم الغرفة واسم المريض.`,
		nicuDescription: 'عند تمكين هذا الخيار، ستعرض الشاشة منظرًا لغرفة الطفل.',
		customRoomModes: 'أوضاع الغرفة المخصصة',
		intakeForm: 'شكل المدخول',
		intakeFormDescription: 'عند تمكين هذا، سيتمكن مقدمو الخدمة من جمع معلومات المريض.',
		doctorPoolingDescription: 'عند تمكين هذا الخيار، ستعرض الشاشة قائمة الأطباء المعينين لذلك المستشفى.',
		scpHealthForm: 'نموذج الصحة SCP',
		baycareForm: 'نموذج باي كير',
		aiWidgetDescription:
			'عند تمكين هذا، ستعرض الشاشة عناصر واجهة مستخدم مثل العلامات الحيوية، والمواعيد القادمة، والاتصال بمقدم الرعاية، والعلامات الحيوية بالذكاء الاصطناعي، والعلاج الطبيعي.',
		widgetDescription:
			'عند تمكين هذا، ستعرض الشاشة عناصر واجهة المستخدم مثل العلامات الحيوية والمواعيد القادمة والاتصال بمقدم الرعاية والتعليم والوصفات الطبية اليوم.',
		digitalClinicDescription: `عند تمكين هذا، ستعرض الشاشة معلومات مثل التاريخ واسم الغرفة واسم المريض وأحدث قياسات المريض.`,
		telemedicineDescription:
			'عند تمكين هذا الخيار، ستعرض الشاشة قائمة الأطباء المتاحين ونوع المكالمات التي يمكن للمستخدمين إجراؤها.',
		hospitalDescription:
			'عند تمكين هذا، ستعرض الشاشة عناصر واجهة المستخدم مثل قائمة العائلة والأصدقاء والتطبيقات والاتصال بمقدم الرعاية والتعليم والوصفات الطبية اليوم.',
		virtualExaminationDescription: 'عند تمكين هذا، ستعرض الشاشة قائمة الأطباء المتاحين الذين يمكن للمستخدم إجراء المكالمات معهم.',
		simplifiedDescription: `عند تمكين هذا، ستعرض الشاشة معلومات مثل التاريخ واسم الغرفة واسم المريض.`,
		eVideonDescription:
			'عند تمكين هذا الخيار، ستعرض الشاشة حلولاً تفاعلية لرعاية المرضى تعمل على إشراك وتثقيف وترفيه المرضى والزوار، مع تبسيط سير العمل للموظفين.',
		patientExperienceDescription:
			'عند تمكين هذا الخيار، ستعرض الشاشة حلولاً تفاعلية لرعاية المرضى تعمل على إشراك وتثقيف وترفيه المرضى والزوار، مع تبسيط سير العمل للموظفين.',
		mdmDescription: 'تعمل منصة تجربة المريض MDM على رفع مستوى مشاركة المريض طوال سلسلة الرعاية المستمرة.',
		azureAd: 'Azure AD',
		pingFederate: 'Ping Federate',
		okta: 'OKTA',
		hillRom: 'Hill-Rom',
		epic: 'Epic',
		cerner: 'Cerner',
		telehealth: 'Telehealth',
		evideon: 'eVideon',
		pcare: 'pCare',
		getwell: 'GetWell',
		sonifi: 'Sonifi',
		mdm: 'MDM',
		globo: 'Globo',
		amn: 'AMN',
		addUnitPhoneNumber: 'أضف رقم الهاتف للوحدة',
		unitPhoneNumber: 'رقم هاتف الوحدة',
		noIntegrationTypesAvailable: 'لا توجد أنواع التكامل المتاحة',
		pleaseSetupIntegrationType: 'الرجاء إعداد نوع التكامل أولاً. اتصل بمزود الخدمة الخاص بك للحصول على المساعدة.',
		errorVoiceOver: ' حدث خطأ أثناء تبديل تنبيه "ابق هادئًا" للقيمة {value}. يرجى المحاولة مرة أخرى!',
		enableStayStill: 'تفعيل تنبيه "ابق هادئًا"',
		disableStayStill: 'تعطيل تنبيه "ابق هادئًا"',
		stoppedAlarmPopup: 'توقف الإنذار بسبب رد من الممرضة {value}',
		activeAlarmTriggered: 'تنبيه نشط تم تفعيله بواسطة',
		statAlarmStarted: ' بدأت الإنذار الثابت',
		statAlarmStopped: ' توقف الإنذار الثابت',
		complete: 'مكتمل',
		hasStoppedStatAlarm: 'أوقف المنبه',
		voiceOverAlarmStopped: 'تم إلغاء تنبيه يدوي',
		voiceOverAlarmStarted: 'تم تفعيل تنبيه يدوي',
		addTranslatorInCall: 'أضف مترجمًا إلى المكالمة',
		whiteboard: 'لوح أبيض',
		whiteboardType: 'نوع السبورة',
		stoppedAlertPopup: 'تم إيقاف التنبيه اليدوي بسبب استجابة من الممرضة {value}',
		selectAlarm: 'حدد إنذار',
		availableAlarms: 'الإنذارات المتاحة',
		selectOtherAlarm: 'حدد إنذارًا آخر',
		alarm: 'إنذار',
		audioFromMonitoringFeeds: 'الصوت من مراقبة الخلاصات',
		scheduled: 'المقرر',
		repeat: 'يكرر',
		weekly: 'أسبوعي',
		monthly: 'شهريا',
		selectSchedule: 'حدد نوع الجدول الزمني',
		ivBagFluidLevel: 'مستوى السائل في كيس الوريد الداخلي',
		admissions: 'القبولات',
		discharges: 'الخروجات',
		admissionsAndDischarges: 'القبولات والخروجات',
		avgAdmissionsPerHour: 'المعدل اليومي للاستقبال في الساعة',
		avgDischargesPerHour: 'المعدل اليومي للخروج في الساعة',
		highestAdmissionsPerHour: 'أعلى عدد للاستقبال في الساعة',
		highestDischargesPerHour: 'أعلى عدد للخروج في الساعة',
		ivBagDescription:
			'عند تمكين هذا، سيكون بإمكان المستخدمين رؤية تحديثات في الوقت الحقيقي لمستوى السائل في كيس الوريد الداخلي وستتم إعلامهم عندما يكون المستوى منخفضًا.',
		monitoringHealthDataDescription: 'عند تمكين هذا، يجب عرض علامة تبويب بيانات الصحة تحت التنبيهات على الشاشة.',
		ivBagCheck: 'مستوى السائل في كيس الوريد الداخلي منخفض. يرجى التحقق من الوريد الداخلي.',
		warningAlertAt: 'تنبيه تنبيه عند',
		enableIvBagWarning: 'لتمكين هذا الخيار، يجب عليك أولاً تقديم قيمة تحذير.',
		patientEngagement: 'مشاركة المريض',
		nurseCallsSystems: 'أنظمة استدعاء الممرضات',
		configurationTypeRequired: 'نوع التكوين مطلوب',
		externalTypeDescription:
			'عند تمكين هذا، سيكون بمقدورنا كسلطة مصادقة عالمية أن تسمح للعملاء والموظفين والشركاء بالوصول بشكل آمن إلى جميع التطبيقات التي يحتاجونها من أي جهاز.',
		ehrDescription: 'عند تمكين هذا، سيتمكن المستخدمون من الوصول إلى سجل مريض موحد لاتخاذ قرارات سريرية أفضل وتحسين رعاية المرضى.',
		translationTypeDescription: 'عند تمكين هذا، سيتمكن المستخدمون من ترجمة المحتوى والتواصل بسلاسة إلى لغات متعددة.',
		doctorQueue: 'طابور الطبيب',
		lowIvBagFluidLevel: 'مستوى السائل منخفض في كيس الحقن الوريدي',
		bedIdentifier: 'معرف السرير',
		enterEmailAddress: 'أدخل عنوان البريد الالكتروني',
		familyMemberNotAssigned: 'لم يتم تعيينك لأي مريض حتى الآن',
		dependants: 'المعالين',
		cannotBeFamilyMember: 'لا يمكن للمريض أن يكون أحد أفراد الأسرة لنفسه',
		personGettingOutOfChair: 'تم اكتشاف الخروج من الكرسي',
		personGettingOutOfChairMessage: 'المريض يخرج من الكرسي',
		sessionsQueue: 'طابور الجلسات',
		sessionsQueueDescription: 'عند تمكين هذا، سيتمكن الطبيب من رؤية طابور الجلسات الذي يمكنه الانضمام إليه أو رفضه.',
		patientIsInactiveToastMessage: 'حان الوقت المناسب لتدوير المريض. يرجى إعلام الممرضة عند السرير',
		forwardToNursesBtn: 'إعادة توجيه إلى الممرضات',
		personInactiveInformation: `حان الوقت المناسب لتدوير المريض`,
		careEventsForNursesDesc:
			'تم تسجيل تدخلات المريض مثل القبول، الاستشارة، العلاج، الإخراج، إنذار الحالة ووضع الخصوصية التي يؤديها مقدمو الرعاية الافتراضية.',
		measuringIv: 'قياس مستوى السائل الوريدي',
		digitalBackground: 'خلفية رقمية',
		selectPreferences: 'حدد التفضيلات',
		backgroundImage: 'صورة الخلفية',
		backgroundImagesAllowed: 'توفير خيارات الصورة للمستخدمين لتحديد تأثيرات الخلفية أثناء مكالمات الفيديو.',
		presentImage: 'قدم الصور',
		addImage: 'إضافة صورة',
		blurryBackgroundDefault: 'قم بتعيين الخلفية الغامضة كافتراضية',
		blurryBackground: 'خلفية ضبابية',
		enabled: 'ممكّن',
		notEnabled: 'غير ممكّن',
		viewMedications: 'عرض الأدوية',
		todaysMedication: 'دواء اليوم',
		patientSkippedMedication: 'لقد تخطي المريض الدواء',
		notifyPatientForMedication: 'إخطار المريض بتناول أدويته',
		featureChanged: 'تغيير الميزة',
		variant: 'المتغير',
		changedForRole: 'تم التغيير للدور',
		skipped: 'تم تخطيه',
		taken: 'مأخوذ',
		callerId: 'عرض اسم المتصل',
		callerIdDescription: 'عند تمكين هذا، سيتم عرض اسم "الدور" في المكالمة.',
		sessionMaintenance: 'انتهت هذه الجلسة بواسطة الصيانة.',
		exceededCharactersLimit: 'لقد تجاوزت الحد الأقصى المسموح به من الأحرف. يرجى ضبط أو إزالة الملاحظات الزائدة.',
		helloCareEngagement: 'مشاركة هيلوكير',
		homeWidget: 'الصفحة الرئيسية',
		whiteboardURL: 'Whiteboard URL',
		engagementWhiteboardURLDescription: 'أدخل عنوان URL للوحة المعلومات للوصول إلى وظيفة السبورة البيضاء لـ hellocare',
		home: 'الصفحة الرئيسية',
		engagementHomeDescription:
			'مركز التواصل والمعلومات للمرضى: مركز مركزي يسهل التواصل مع المرضى ويقدم التحديثات الأساسية. يتضمن لوحة بيضاء تفاعلية للاستخدام المتعدد.',
		educationWidget: 'التعليم',
		engagementEducationDescription:
			'موارد تعليمية في مجال الرعاية الصحية: مستودع لمقاطع فيديو تعليمية ذات صلة بالرعاية الصحية، لتعزيز معرفة المرضى ومشاركتهم.',
		liveTvWidget: 'البث المباشر للتلفزيون',
		engagementLiveTVDescription:
			'قنوات الترفيه والمعلومات: مجموعة متنوعة من قنوات التلفزيون في مختلف الأنواع، مما يضمن للمرضى الوصول إلى خيارات الترفيه والمعلومات.',
		moviesWidget: 'الأفلام',
		engagementMoviesDescription: 'مكتبة الأفلام: فهرس للأفلام تغطي مختلف الأنواع، وتقدم ترفيهًا عند الطلب.',
		musicWidget: 'الموسيقى',
		engagementMusicDescription:
			'محطات راديو الموسيقى: الوصول إلى محطات راديو الموسيقى مصنَّفة حسب النوع، للإسترخاء والتمتع من قبل المرضى.',
		moodsWidget: 'المزاج',
		engagementMoodsDescription:
			'مقاطع فيديو للإسترخاء: مجموعة من مقاطع الفيديو التي تعزز المزاج، بما في ذلك مناظر مثل المدافئ والشواطئ، لتعزيز راحة ورفاهية المرضى.',
		appsWidget: 'التطبيقات',
		engagementAppsDescription:
			'تكامل مع تطبيقات الترفيه الشهيرة مثل نيتفليكس، أمازون برايم، ويوتيوب ميوزيك، لتوسيع خيارات الترفيه للمرضى.',
		familyAndFriendsWidget: 'العائلة والأصدقاء',
		engagementFamilyFriendsDescription:
			'الاتصال بالعائلة والأصدقاء: ميزة تتيح للمرضى البقاء على اتصال مع أحبائهم من خلال مكالمات الفيديو، لتعزيز الدعم العاطفي.',
		orderMealsWidget: 'طلب الوجبات',
		engagementOrderMealDescription:
			'سهولة طلب الوجبات: خيار ملائم للمرضى لطلب وجبات طعام استنادًا إلى تفضيلاتهم، لتحسين تجربتهم في تناول الطعام.',
		whiteBoardWidget: 'السبورة التفاعلية',
		engagementWhiteboardDescription:
			'مركز المعلومات التفاعلي: سبورة متعددة الوظائف تعمل كمركز معلومات تفاعلي، تسمح بالملاحظات وعرض المحتوى والتكامل مع الفيديو.',
		careTeamWidget: 'فريق الرعاية',
		engagementCareTeamDescription:
			'الوصول إلى فريق الرعاية: يمكن للمرضى الوصول بسهولة والتواصل مع أعضاء فريق الرعاية المخصص لهم، مما يبسط تنسيق الرعاية.',
		healthDataWidget: 'بيانات الصحة',
		engagementHealthDataDescription:
			'مراقبة الصحة والتحليلات: الوصول إلى بيانات الصحة من مختلف أجهزة المراقبة وأجهزة الإنترنت من الأشياء، لتوفير نظرة شاملة على صحة المريض.',
		sessionAborted: 'تم إحباط الجلسة. الرجاء المحاولة مرة أخرى.',
		sessionDisrupted: 'تم تعطيل الجلسة. الرجاء المحاولة مرة أخرى.',
		perfect: 'مثالي',
		good: 'جيد',
		bad: 'سيء',
		mediocre: 'متوسط',
		noCareEvents: 'لا تتوفر أحداث رعاية.',
		askAdministratorForCareEvents: 'يُرجى طلب من مشرفك إضافة أحداث الرعاية.',
		providerCamera: 'تعطيل الفيديو',
		providerMic: 'ميكروفون المزود',
		providerMicDesc: 'من خلال تمكين هذا، سيكون ميكروفون مقدم الخدمة قيد التشغيل عند بدء المكالمة.',
		patientCamera: 'كاميرا المريض',
		patientMicrophone: 'ميكروفون المريض',
		patientMicrophoneDesc: 'من خلال تمكين هذا، سيكون ميكروفون المريض قيد التشغيل عند بدء المكالمة.',
		disableProviderCameraDesc: 'من خلال تمكين هذا، ستكون كاميرا مقدم الخدمة قيد التشغيل عند بدء المكالمة.',
		patientMicDesc: 'من خلال تمكين هذا، سيكون ميكروفون المريض قيد التشغيل عند بدء المكالمة.',
		patientCameraDesc: 'من خلال تمكين هذا، ستكون كاميرا المريض قيد التشغيل عند بدء المكالمة.',
		enableClosedCaptions: 'تمكين التسميات التوضيحية المغلقة',
		disableClosedCaptions: 'تعطيل التسميات التوضيحية المغلقة',
		closedCaptions: 'تعاليق مغلقة',
		closedCaptionsDescription: 'عند تمكين هذا، ستتمكن {value} من رؤية التسميات التوضيحية المباشرة للمشارك الذي يتحدث.',
		participantRemovedByAdministrator: 'لقد تمت إزالتك من هذا المؤتمر بواسطة أحد المسؤولين.',
		wantToTurnOnFallPrevention: 'هل تريد تفعيل منع السقوط بالذكاء الاصطناعي في المراقبة؟',
		continueNoAi: 'لا، استمر بدون الذكاء الاصطناعي',
		aiMonitoringOn: 'تشغيل مراقبة الذكاء الاصطناعي',
		aiFallPrevention: 'الوقاية من السقوط بالذكاء الاصطناعي',
		helloBackground: 'خلفية التحية',
		setHelloBackground: 'حدد الصورة التي ستظهر أمام المريض قبل بدء الجلسة.',
		helloWallpaper: 'ورق الحائط Hello',
		setHelloWallpaper: 'حدد الصورة التي ستظهر خلف الأيقونات الموجودة على شاشة التطبيق',
		addCareEvent: 'إضافة حدث الرعاية',
		nameOrEmail: 'الاسم أو البريد الإلكتروني',
		careEventHistory: 'تاريخ حدث الرعاية',
		talkToPatientSettings: 'إعدادات الحديث مع المريض',
		talkToPatientSettingsDesc:
			'استخدم الخيارات أدناه لتحديد ما سيراه ويسمعه مقدم الخدمة والمريض عند إجراء مكالمة من أو إلى جهاز hello.',
		viewPatientSettings: 'عرض إعدادات المريض',
		viewPatientSettingsDesc: 'استخدم هذا الخيار لإظهار أو إخفاء خيار عرض المريض على جانب مقدم الخدمة.',
		videoMonitoring: 'مراقبة الفيديو',
		ambientMonitoring: 'المراقبة المحيطة',
		ambientAndVideoMonitoring: 'مراقبة البيئة المحيطة والفيديو',
		ambientMonitoringDescription: 'عند تمكين هذا، سيتمكن المزود من تلقي التنبيهات.',
		unoccupied: 'غير محتلة',
		receiveAlerts: 'للحصول على التنبيهات،',
		ambientMonitoringNotActive: 'المراقبة المحيطة غير نشطة',
		inOrderToReceiveAlerts: 'من أجل الحصول على التنبيهات.',
		alert: 'إنذار',
		manageAlert: 'إدارة التنبيه',
		closeAlert: 'إغلاق التنبيه',
		selectViewOrAddRooms: 'حدد إحدى طرق العرض المحفوظة، أو ابدأ بإضافة غرف إلى طريقة العرض الحالية.',
		maxAmbientMonitoringProviders: 'لقد وصل هذا الجهاز إلى الحد الأقصى المسموح به لموفري المراقبة المحيطة.',
		removeParticipant: 'إزالة المشارك',
		confirmParticipantRemoval: 'تأكيد إزالة المشارك',
		confirmationOfRemovalParticipant: 'هل أنت متأكد أنك تريد إزالة {value} من المكالمة؟',
		youAreRemovedFromConference: 'لقد تمت إزالتك من هذا الاجتماع.',
		helloSpeakerSelected: 'تم اختيار مكبر الصوت الداخلي لجهاز Hello',
		tvSpeakerSelected: 'تم اختيار مكبر الصوت التلفزيون',
		switchToTv: 'التبديل إلى مكبر الصوت التلفزيون',
		switchToHello: 'التبديل إلى مكبر الصوت لجهاز Hello',
		viewing: 'مشاهدة',
		fetchingPrecautionsFailed: 'فشلت عملية جلب الاحتياطات.',
		selectCareEvent: 'لتمكين الملاحظات، حدد حدث رعاية واحد',
		supportChat: 'الدعم الدردشة',
		supportChatDescription: 'عند تمكين هذا، سيكون لدى المستخدمين دردشة الدعم متاحة داخل التطبيق.',
		prefix: 'بادئة',
		displayFullName: 'عرض الاسم الكامل',
		displayCredentialsName: 'عرض الاسم الأول، والحرف الأول من اللقب، والبيانات الاعتمادية',
		deleteConfirmation: 'تأكيد الحذف',
		failedToGetCaptions: 'فشل في الحصول على ترجمات! يرجى المحاولة مرة أخرى في لحظة.',
		careEventsDashboard: 'لوحة معلومات أحداث الرعاية',
		created: 'مخلوق',
		departmentFloorRoom: 'القسم > الطابق > الغرفة',
		careEventType: 'نوع حدث الرعاية',
		admission: 'قبول',
		discharge: 'تسريح',
		allEvents: 'كل الأحداث',
		myCareEvents: 'أحداث رعايتي',
		selectCareTeam: 'حدد فريق الرعاية',
		assignCareTeam: 'تعيين فريق الرعاية',
		newCareEvent: 'حدث الرعاية الجديد',
		searchRoomName: 'ابحث عن اسم الغرفة',
		careEventsDescription: 'عند تمكين هذا، ستتمكن {value} من رؤية أحداث الرعاية.',
		statusAlreadyExists: 'هذه الحالة موجودة بالفعل!',
		patientCannotBeEmpty: 'لا يمكن للمريض أن يكون فارغا',
		nurseCannotBeEmpty: 'لا يمكن للممرضة أن تكون فارغة',
		addPatientWithEvent: 'قم بتعيين مريض حالي أو جديد إلى غرفة لربط حدث الرعاية بسجله',
		selectExistingPatientWithEvent: 'حدد مريضًا لإضافة حدث رعاية، وقم بتعيين أحد أعضاء فريق الرعاية لإدارته كمهمة.',
		searchPatientByMrn: 'بحث المريض بالاسم أو MRN',
		aIDetectionDrawings: 'رسومات الكشف الذكاء الاصطناعي',
		eventHistory: 'تاريخ الحدث',
		eventCompleted: 'اكتمل {value}',
		eventCreated: 'تم إنشاء حدث {value}',
		statusUpdated: 'تم تحديث الحالة',
		youUpdatedStatus: 'لقد قمت بتحديث الحالة في حدث ما',
		describeChange: 'صف سبب قيامك بهذا التغيير',
		yourInsightsHelp: 'تساعدنا أفكارك في الاحتفاظ بسجلات دقيقة وضمان أفضل رعاية لجميع المشاركين',
		youReassignedCareMember: 'لقد قمت بإعادة تعيين فريق رعاية في حدث ما',
		reassigned: 'أعيد تعيينه',
		assignedCareTeam: 'فريق الرعاية المخصص',
		patientIsAtRoom: 'المريض في الغرفة',
		isIntervening: 'يتدخل',
		sameExerciseSameDay: 'تم بالفعل إنشاء نفس التمرين، في نفس اليوم!',
		youUpdatedPriority: 'لقد قمت بتحديث الأولوية على حدث ما',
		priorityUpdated: 'تم تحديث الأولوية',
		selectStatus: 'اختيار الحالة',
		clearAll: 'مسح الكل',
		filters: 'مرشحات',
		dateRange: 'نطاق التاريخ',
		selectWithinDateRange: 'لا يمكن أن يكون النطاق الزمني أكثر من {value} من الأيام',
		selectValidDateRange: 'الرجاء تحديد نطاق زمني صالح!',
		selectValidEndTime: 'يرجى اختيار وقت انتهاء صالح!',
		patientPlacement: 'توجيه المريض',
		skipSslVerification: 'تخطي التحقق من SSL',
		mrnNoMatch: 'رقم السجل الطبي (MRN) لا يتطابق مع سجلاتنا.',
		healthAlerts: 'التنبيهات الصحية',
		aiAlerts: 'تنبيهات الذكاء الاصطناعي',
		activeAlerts: 'التنبيهات النشطة',
		showAll: 'عرض الكل',
		showLess: 'عرض أقل',
		allHospitals: 'جميع ال مستشفيات',
		allDepartments: 'جميع الإدارات',
		allFloors: 'جميع الطوابق',
		allRooms: 'كل الغرف',
		aiSettings: 'إعدادات الذكاء الاصطناعي',
		dataCollectionAlerts: 'السماح بالحصول على البيانات للتنبيهات الكاذبة',
		dataCollectionAlertsDescription: 'يتيح هذا الخيار لنظامنا الحصول على صور في حالة الإبلاغ عن تنبيه كاذب.',
		savingImage: 'حفظ الصورة',
		imageSaved: 'تم حفظ الصورة!',
		savingImageFailed: 'فشل حفظ الصورة!',
		helloDevice: 'Hello مرحبا',
		digitalSign: 'علامة رقمية',
		aiConfigurations: 'تكوينات الذكاء الاصطناعي',
		railsDetection: 'كشف القضبان',
		statAlarmActivation: 'تفعيل التنبيه الأساسي على تنبيهات الذكاء الاصطناعي',
		toastMessage: 'رسالة نخب',
		alertOnMonitoringFeed: 'تنبيه بشأن مراقبة الخلاصة',
		patientAudio: 'صوت المريض',
		allowPatientAudio: 'تتطلب المتصفحات تفاعل المستخدم قبل تشغيل الصوت. فقط انقر فوق "موافق" للمتابعة.',
		allow: 'يسمح',
		transferOwnership: 'نقل الملكية',
		transferOwnershipDescription: 'سيؤدي ترك المكالمة إلى نقل الملكية إلى مشارك آخر ولن يؤدي إلى إنهاء المكالمة.',
		leaveCall: 'اترك المكالمة',
		directCallEcm: 'مكالمة مباشرة من eCM',
		activeCareEvent: 'هناك حدث رعاية نشط للمريض الحالي في الغرفة المختارة!',
		setTimeZone: 'تعيين المنطقة الزمنية',
		selectedTimeZone: 'المنطقة الزمنية المحددة',
		searchPlaceTimeZone: 'البحث عن مكان أو منطقة زمنية',
		updateCareEvents: 'هناك تحديثات جديدة متاحة لأحداث الرعاية. انقر فوق الزر الموجود على اليمين للتحديث',
		updateList: 'قائمة التحديث',
		host: 'المضيف',
		path: 'المسار',
		isSubscribed: 'مشترك',
		multipleBedRoom: 'غرفة متعددة الأسرة',
		multipleBedRoomDesc: 'سيقوم هذا بتقييد رؤية الجهة الزائرة. يجب إضافة ذلك أعلى نوع الغرفة.',
		careTeamReport: 'تقرير فريق الرعاية',
		patientReport: 'تقرير المريض',
		head: 'رأس',
		rail: 'سكة حديدية',
		foot: 'قدم',
		admittedDate: 'تاريخ القبول',
		dischargedDate: 'تاريخ التفريغ',
		viewSessions: 'عرض الجلسات',
		endTime: 'وقت الانتهاء',
		intervention: 'تدخل',
		actionTaken: 'الإجراء المتخذ',
		interventedBy: 'تدخل بواسطة',
		hidePatientProfile: 'إخفاء ملف المريض',
		showPatientProfile: 'عرض ملف المريض',
		nameNumber: 'الاسم/الرقم',
		primaryPatientCondition: 'حالة المريض الأساسية',
		primaryAdverseEvent: 'حدوث سلبي أساسي',
		additionalTitleBarNotes: 'ملاحظات إضافية في شريط العنوان',
		silentMode: 'وضع الصمت',
		cameraSettings: 'إعدادات الكاميرا',
		cameraUse: 'استخدام الكاميرا',
		selectOneOfCameraSettings: 'حدد أحد إعدادات الكاميرا',
		singleCameraUse: 'استخدام كاميرا واحدة',
		multiCameraUse: 'استخدام الكاميرا المتعددة',
		genericDescription:
			'تم تصميم المسح العام للمرضى لإكماله في الوقت الذي يناسبهم أو عندما يشعرون أنه ضروري. سيكون هذا الاستطلاع متاحًا للمرضى على صفحتهم الرئيسية.',
		safetyRangeDescription:
			'يتم إنشاء استبيان نطاقات الأمان ليملأه المرضى عندما تتجاوز قياساتهم حدود الأمان المحددة لعلامة حيوية معينة.',
		scheduledDescription: 'يتم تنظيم المسوحات المجدولة لتكون بمثابة فحوصات شهرية أو أسبوعية أو يومية للمريض.',
		removeRoomMonitoring: 'هل ترغب في إزالة الغرفة {value}؟',
		sureRemoveRoomMonitoring: 'يرجى تأكيد رغبتك في إزالة هذه الغرفة من جلسة المراقبة الحالية.',
		removeSessionMonitoring: 'هل ترغب في إغلاق الجلسة الحالية؟',
		sureRemoveSessionMonitoring: 'يرجى تأكيد رغبتك في إغلاق الجلسة الحالية.',
		interventions: 'التدخلات',
		addInterventions: 'إضافة التدخلات',
		interventionsHistory: 'تاريخ التدخلات',
		newInterventions: 'تدخلات جديدة',
		noInterventions: 'لا توجد تدخلات متاحة',
		askAdministratorForInterventions: 'يرجى طلب من المسؤول إنشاء قائمة من التدخلات للاختيار من بينها.',
		selectInterventions: 'اختر خيارًا لإضافة ملاحظات إضافية.',
		aditionalNotes: 'ملاحظات إضافية',
		addAdditionalNotes: 'إضافة ملاحظات إضافية',
		patientNotes: 'ملاحظات المريض',
		addNotes: 'إضافة ملاحظات عن المريض',
		enterPatientName: 'أدخل اسم المريض',
		patientAge: 'عمر المريض',
		enterPatientAge: 'أدخل عمر المريض',
		nurseNameNumber: 'RN - الاسم/الرقم',
		enterNurseNameNnumber: 'أدخل RN - الاسم/الرقم',
		nurseAssistantNameNumber: 'PCT - الاسم/الرقم',
		enterNurseAssistantNameNumber: 'أدخل PCT - الاسم/الرقم',
		tlNameNumber: 'TL - الاسم/الرقم',
		enterTlNameNumber: 'أدخل TL - الاسم/الرقم',
		enterPrimaryAdverseEvent: 'أدخل الحدث السلبي الأساسي',
		enterPrimaryPatientCondition: 'أدخل حالة المريض الأساسية',
		primaryPatientRiskFactor: 'عامل خطر المريض الأساسي',
		enterPrimaryPatientRiskFactor: 'أدخل عامل خطر المريض الأساسي',
		titleBarNotes: 'ملاحظات شريط العنوان الإضافية',
		enterTitleBarNotes: 'أدخل ملاحظات إضافية لشريط العنوان',
		additionalPatientNotes: 'ملاحظات إضافية للمريض',
		enterAdditionalPatientNotes: 'أدخل ملاحظات إضافية للمريض',
		enterPatientInformation: 'أدخل معلومات المريض',
		voiceAnnouncements: 'إعلانات صوتية',
		patientPrivacy: 'خصوصية المريض',
		patientOutOfRoom: 'المريض خارج الغرفة',
		pleaseDoNotGetUp: 'تنبيه: من فضلك لا تقم بالنهوض!',
		pleaseDoNotTouchThat: 'من فضلك لا تلمس ذلك!',
		pleaseStopWhatYouAreDoing: 'تنبيه: من فضلك توقف عن ما تقوم به!',
		stopPlease: 'توقف، من فضلك!',
		someoneIsOnTheWay: 'تحدثت مع ممرضتك، هناك شخص في الطريق إلى غرفتك.',
		useYourCallLight: 'يرجى استخدام ضوء النداء الخاص بك.',
		doYouNeedYourNurse: 'هل تحتاج إلى ممرضتك؟',
		staffInjury: 'إصابة الموظف',
		isolationExposure: 'تعرض للعزل',
		medicalDeviceInterference: 'تداخل الجهاز الطبي',
		elopement: 'الهروب ,',
		intentionalSelfHarm: 'الإيذاء الذاتي المقصود',
		suicide: 'الانتحار',
		delirium: 'الهذيان',
		dementia: 'الخرف',
		substanceWithdrawal: 'انسحاب المادة',
		brianInjury: 'إصابة الدماغ',
		psychiatricDisorder: 'اضطراب نفسي',
		stroke: 'سكتة دماغية',
		infectiousDiseaseIsolation: 'عزل الأمراض المعدية',
		poorShortTermMemory: 'ذاكرة قصيرة الأجل ضعيفة',
		poorComprehension: 'فهم ضعيف',
		impulsivity: 'عجلة',
		agitation: 'اضطراب',
		anxiety: 'قلق',
		distortedPerceptionOfReality: 'إدراك مشوه للواقع',
		angerFrustration: 'غضب/إحباط',
		none: 'لا شيء',
		patientIsOutOfTheRoom: 'المريض خارج الغرفة',
		surgery: 'جراحة',
		patientisGoingDownForACTScan: 'المريض ينخفض لفحص ACT',
		patientIsGoingDownForAnMRI: 'المريض ينخفض لفحص MRI',
		ambulating: 'السير',
		paracentesis: 'إجراء تحفظي لنزح السوائل',
		sunshinePrivileges: 'امتيازات ضوء الشمس',
		transferring: 'نقل',
		erCp: 'ER CP',
		dialysis: 'غسيل الكلى',
		mandarinChinese: 'لغة الماندرين الصينية',
		patientReturnedToRoom: 'عاد المريض إلى الغرفة',
		roomWithoutPatientData: 'هذه الغرفة لا تحتوي على أي بيانات للمريض.',
		fitToScreenVideoFeeds: 'تناسب تغذيات الفيديو مع الشاشة',
		drafts: 'المسودات',
		forward: 'Forward',
		interventionsAndAlerts: 'Interventions and Alerts',
		monitorPatient: 'رصد المريض',
		removeFeed: 'إزالة تغذية المراقبة',
		turnVideo: 'عرض الفيديو',
		turnVideoOn: 'تشغيل الفيديو',
		sessionType: 'نوع الجلسة',
		voiceAnnouncementsDescription:
			'تفعيل إعادة التوجيه الشفوي سيضيف نفس الخاصية ضمن الرصد حيث يمكن للمزودين استخدامها للتواصل مع المريض ببساطة عن طريق تشغيلها كصوت مسجل مسبقًا.',
		patientOutOfRoomDescription:
			'تفعيل خاصية المريض خارج الغرفة سيضيف نفس الخاصية ضمن الرصد حيث يمكن للمزودين توثيق الحالات التي يكون فيها المريض خارج الغرفة لأسباب معينة (تُعلن كقائمة منسدلة).',
		snoozeTimerForAlert: 'مؤقت التأجيل لتنبيه {value}',
		primaryPatientRisk: 'المخاطر الأساسية للمريض',
		sessionByTime: 'الجلسة حسب الوقت',
		totalHours: 'إجمالي الساعات',
		transferPatient: 'نقل المريض',
		openLifeSignals: 'مفتوح LifeSignals',
		isOldUserExperience: 'هو تجربة مستخدم قديمة',
		isEnabledForHs: 'مفعل لأنظمة الصحة',
		isOldUserExperienceDescription: 'عند تمكين هذا، ستكون تجربة مستخدم الشركة أو نظام الصحة القديمة.',
		userExperience: 'تجربة المستخدم',
		mixedMonitoringView: '"عرض المراقبة المختلطة (فيديو ومحيط)"',
		mixedMonitoringViewDescription:
			"عند تفعيل هذا، سيرى المزودون 'المراقبة البيئية' كقدرة/تبويب إضافي ضمن عرض الشجرة وبهذه الطريقة يمكن تمكين عرض المراقبة المختلطة (تغذية الفيديو + المراقبة البيئية).",
		invitationNote: 'مذكرة الدعوة',
		invitationNotePlaceholder: 'أضف ملاحظة دعوة ليشاهدها الضيف.',
		invitationNoteDescription: 'سيرى جميع المشاركين هذه الملاحظة. الحد الأقصى 160 حرفًا.',
		pinLock: 'قفل رقم التعريف الشخصي',
		disablePIN: 'من خلال تعطيل قفل الدبوس، لن تكون هناك حاجة إليه عند الوصول إلى الإعدادات.',
		pinMinValue: 'يجب أن يكون رقم التعريف الشخصي 1000 على الأقل',
		changePin: 'تغيير رقم التعريف الشخصي',
		disablePinBtn: 'تعطيل رقم التعريف الشخصي',
		disablePinLock: 'تعطيل قفل الدبوس',
		newPinLock: 'قفل رقم التعريف الشخصي الجديد',
		searchByPatientNameMrn: 'البحث حسب اسم المريض أو MRN',
		talkToPatientDescription: 'عندما يتم تمكين هذا، سيكون المستخدم قادرا على التحدث إلى المريض',
		viewPatientDescription: 'عند تمكين هذا، سيكون المستخدم قادرا على عرض المريض',
		checkFeatureAvailability: 'يرجى التواصل مع المسؤول لديك لتوفير هذه الميزة',
		badgeId: 'هوية الشارة',
		hillRomId: 'هيل روم الهوية',
		userPicture: 'صورة المستخدم',
		badgeAuthorization: 'تفويض الشارة',
		hl7v2: 'HL7 v2',
		hl7v2Description: 'عند تفعيل هذا، سيتم عرض تعريف السرير مع الأقسام اللازمة.',
		enablingPrivacyMode:
			'تفعيل هذه الوظيفة سيجعل عرض المريض ضبابيًا للوقت المحدد. الرجاء إدخال الوقت الذي ترغب في أن يصبح شاشتك ضبابية فيه أو اختر من الخيارات المحددة مسبقًا.',
		enterSelectTimer: 'أدخل أو اختر المؤقت بالدقائق.',
		writeWholeNumber: 'الرجاء إدخال رقم صحيح بين 1 و 60.',
		isActive: 'نشط',
		badgeStatus: 'حالة الشارة',
		badgeDeleteMsg: 'هل أنت متأكد أنك تريد تعطيل هذا الموظف؟',
		aspectRatio: 'ابعاد متزنة',
		badgingAccess: 'وصول بواسطة الشارة',
		centrakBadge: 'شارة Centrak',
		proxBadge: 'شارة Prox',
		overviewTab: '.عند تمكين هذا الخيار، ستكون علامة التبويب "نظرة عامة" متاحة',
		converationTab: '.عند تمكين هذا الخيار، ستكون علامة تبويب المحادثة متاحة',
		filesTab: '.عند تمكين هذا الخيار، ستكون علامة التبويب "الملفات" متاحة',
		healthTab: '.عند تمكين هذا الخيار، ستكون علامة التبويب الصحية متاحة',
		physicalTherapyTab: '.عند تمكين هذا الخيار، ستكون علامة تبويب العلاج الطبيعي متاحة',
		visitTab: '.عند تمكين هذا الخيار، ستكون علامة التبويب "الزيارات" متاحة',
		alertHistoryTab: '.عند تمكين هذا الخيار، ستكون علامة التبويب "سجل التنبيهات" متاحة',
		transfer: 'تحويل',
		activate: 'تفعيل',
		moreParticipants: 'المزيد من المشاركين',
		clickToViewParticipants: 'انقر لعرض المشاركين',
		currentlyInThisCall: 'حالياً في هذا المكالمة ',
		regeneratePin: 'إعادة توليد الرقم السري',
		hide: 'إخفاء',
		pca: 'PCA',
		roomInfo: 'معلومات الغرفة',
		pain: 'ألم',
		painLevel: 'مستوى الألم',
		manageablePainLevel: 'مستوى الألم القابل للإدارة',
		roomServiceNumber: 'رقم خدمة الغرف',
		isolations: 'عزل',
		patientInfoDescription: 'عند تفعيل هذا، سيرى الممرضون "معلومات المريض" كصندوق ضمن عرض الغرفة.',
		monitoringWhiteboardDescription:
			'عند تمكين هذا، سيرى مقدمو الخدمة "السبورة البيضاء" كمربع داخل عرض الغرفة وسيكونون قادرين على تحديث السبورة البيضاء الخاصة بالمريض من المراقبة أو المراقبة المحيطة.',
		listOfParticipants: 'قائمة المشاركين',
		participant: 'مشارك',
		userAutoGeneratedPin: 'الرقم السري المولد تلقائيًا',
		autoGeneratedPinDesc:
			'عند تفعيل هذا، سيتوفر رقم سري تم توليده تلقائيًا من النظام تحت إدارة المستخدم للتفاعل مع السبورة البيضاء.',
		podcastsWidget: 'البودكاستات',
		podcastsWidgetDescription: 'مكتبة البودكاستات: كتالوج من البودكاستات التي تغطي مواضيع متنوعة.',
		gamesWidget: 'الألعاب',
		gamesWidgetDescription: 'الألعاب: قائمة بتطبيقات ألعاب مختلفة.',
		hdmiInWidget: 'HDMI-IN',
		hdmiInWidgetDescription: 'HDMI-IN: تشغيل المحتوى من صندوق الاستقبال أو أجهزة البث الأخرى.',
		roomControlsWidget: 'تحكمات الغرفة',
		roomControlsWidgetDescription: 'تحكم الغرفة: تمكين المريض من استخدام تحكمات الغرفة.',
		settingsWidget: 'الإعدادات',
		settingsWidgetDescription: 'الإعدادات: خيارات لتخصيص تجربتك على السبورة البيضاء.',
		whiteboardPiPWidget: 'السبورة PiP',
		whiteboardPiPWidgetDescription: 'السبورة PiP: تشغيل مجموعة متنوعة من قنوات التلفزيون عبر الأنواع في تنسيق صورة داخل صورة.',
		transferOwnershipMessage: 'أنت الآن مالك هذه المكالمة.',
		lying: 'الاستلقاء',
		goingHome: 'العودة إلى البيت',
		rideConfirmed: 'هل تم تأكيد الرحلة',
		healthSummary: 'ملخص الصحة',
		diet: 'حمية',
		bradenScore: 'Braden Score',
		sensoryPerceptions: 'الإدراكات الحسية',
		skinMoisture: 'رطوبة الجلد',
		mobility: 'الحركة',
		nutrition: 'التغذية',
		frictionShear: 'الاحتكاك والقص',
		within24Hours: 'خلال 24 ساعة',
		is2or3days: '2-3 أيام',
		more3days: 'أكثر من 3 أيام',
		rideIsConfirmed: 'تم تأكيد الرحلة',
		rideIsNotConfirmed: 'لم يتم تأكيد الرحلة',
		familyContact: 'اتصال العائلة',
		pcaPhoneNumber: 'رقم هاتف PCA',
		nursePhoneNumber: 'رقم هاتف الممرضة',
		completelyLimitedLabel: 'محدود بالكامل',
		completelyLimited:
			'محدود بالكامل: لا يستجيب (لا يئن، ينتفض، أو يمسك) للضغط. قدرة محدودة على الشعور بالألم في معظم أجزاء الجسم',
		veryLimitedLabel: 'محدود جدًا',
		veryLimited: 'محدود جدًا: يستجيب فقط للمؤثرات المؤلمة. لا يمكنه التواصل بشكل صحيح مع الانزعاج',
		slightlyLimitedLabel: 'محدود قليلًا',
		slightlyLimited: 'محدود قليلًا: يستجيب للأوامر اللفظية ولكن يشعر بالانزعاج في طرف أو اثنين',
		noImpairmentLabel: 'بدون عجز',
		noImpairment: 'بدون عجز: يستجيب للأوامر اللفظية، لا يوجد نقص في الحس',
		constantlyMoistDesc: '1 - رطب باستمرار: الجلد رطب تقريباً بشكل مستمر',
		constantlyMoist: 'رطب باستمرار',
		veryMoistDesc: '2 - رطب جداً: الجلد غالباً ما يكون رطب',
		veryMoist: 'رطب جداً',
		occasionallyMoistDesc: '3 - رطب أحياناً: الجلد رطب بين الحين والآخر ويتطلب تغيير الملابس مرة واحدة في اليوم',
		occasionallyMoist: 'رطب أحياناً',
		rarelyMoistDesc: '4 - نادراً ما يكون رطب: الجلد جاف',
		rarelyMoist: 'نادراً ما يكون رطب',
		bedfastDesc: '1 - مقيد بالفراش: محصور في السرير',
		bedfast: 'مقيد بالفراش',
		chairfastDesc: '2 - مقيد بالكرسي: القدرة على المشي محدودة جداً لكن لا يمكنه تحمل وزنه',
		chairfast: 'مقيد بالكرسي',
		walksOccasionallyDesc: '3 - يمشي أحياناً: يمكنه المشي لمسافات قصيرة جداً',
		walksOccasionally: 'يمشي أحياناً',
		walksFrequentlyDesc: '4 - يمشي بشكل متكرر: يخرج من الغرفة مرتين على الأقل في اليوم ويمكنه المشي بدون مساعدة',
		walksFrequently: 'يمشي بشكل متكرر',
		completelyImmobileDesc: '1 - غير متحرك تماماً: لا يمكن أن يقوم حتى بحركات خفيفة',
		completelyImmobile: 'غير متحرك تماماً',
		veryLimitedTitleDesc: '2 - محدود جداً: يمكنه تغيير وضعية جسده أحياناً لكن لا يمكنه إجراء تغييرات كبيرة بشكل مستقل',
		veryLimitedTitle: 'محدود جداً',
		slightlyLimitedTitleDesc: '3 - محدود قليلاً: يقوم بتغييرات جسدية بشكل مستقل لكن ليس لديه القوة للقيام بذلك بشكل متكرر',
		slightlyLimitedTitle: 'محدود قليلاً',
		noLimitationDesc: '4 - بدون قيود: المريض يمكنه تغيير وضعيته بدون مساعدة',
		noLimitation: 'بدون قيود',
		veryPoorDesc: '1 - ضعيف جداً: المريض لا يأكل وجبة كاملة أبداً. يتناول السوائل بشكل سيء ولا يمكنه أكل أكثر من ⅓ الطعام',
		probablyInadequateDesc: '2 - ربما غير كافي: المريض يأكل فقط حوالي ½ من الطعام المقدم',
		probablyInadequate: 'ربما غير كافي',
		adequateDesc: '3 - كافي: لا يمكن تناول وجبة كاملة',
		adequate: 'كافي',
		excellentDesc: '4 - ممتاز: المريض عادةً ما يأكل ما مجموعه 4 وجبات أو أكثر',
		problemDesc: '1 - مشكلة: رفع دون الانزلاق على الشراشف مستحيل',
		problem: 'مشكلة',
		potentialProblemDesc: '2 - مشكلة محتملة: المريض يتطلب مساعدة دنيا لتقليل الاحتكاك والقص',
		potentialProblem: 'مشكلة محتملة',
		noApparentProblemDesc:
			'3 - لا توجد مشكلة ظاهرة: القدرة على التحرك في السرير والكرسي بشكل مستقل. القدرة على الحفاظ على وضعية جيدة في السرير والكرسي.',
		noApparentProblem: 'لا توجد مشكلة ظاهرة',
		rateCallQuality: 'تقييم جودة المكالمة',
		rateCallQualityDesc: 'يسمح هذا للمشاركين بتقديم تعليقات في نهاية المكالمة.',
		veryPoor: 'ضعيف جداً',
		poor: 'ضعيف',
		veryGood: 'جيد جداً',
		excellent: 'ممتاز',
		howWasQuality: 'كيف كانت جودة المكالمة؟',
		rateCall: 'تقييم المكالمة',
		roundingCareEvents: 'جولات الأحداث الطبية',
		roundingCareEventsDesc: 'من خلال تمكين هذا الخيار ، يمكنك تعيين سبب المكالمة قبل المكالمة التالية أو خلال المكالمة.',
		excludeCpmPatients: 'استبعاد مرضى CPM',
		removeFromRpm: 'إزالة من دورة في الدقيقة',
		removePatient: 'إزالة المريض',
		areYouSureRemovePatient: 'هل أنت متأكد أنك تريد إزالة المريض من برنامج RPM؟',
		makeHost: 'اصنع مضيفًا',
		noPinGenerate: 'ليس لديك رقم تعريف شخصي موجود. يرجى توليد واحد.',
		missedCallsNotifications: 'إشعارات المكالمات الفائتة',
		missedCallFrom: 'مكالمة فائتة من',
		questionProvider: 'سؤال لمزودك',
		safetyPatientNeeds: 'تنبيهات السلامة/احتياجات المرضى',
		newMedicationAndSideEffects: 'الأدوية الجديدة والآثار الجانبية',
		whatMattersToMe: 'ما يهمني',
		planForToday: 'خطة لليوم',
		painMedAvailable: 'دواء متوفر للألم',
		lastDoseGiven: 'آخر جرعة معطاة',
		nextDose: 'الجرعة التالية',
		generatePin: 'إعادة توليد الرقم السري',
		aiDetectionDrawingsDescription:
			' إذا تم تمكين هذا، سيرى المراقب رسومًا حول الأشخاص والأشياء التي يتم اكتشافها بواسطة نموذج الذكاء الاصطناعي.',
		soundOnAiAlertDescription:
			'ذا تم تمكين ذلك، سيرى المراقب صوت تنبيه عندما يتم تشغيل إنذار AI. سيتم تشغيل الصوت على الجهاز الذي يستخدمونه لمراقبة المرضى.',
		caseManager: 'مدير الحالة',
		caseManagerPhone: 'هاتف مدير الحالة',
		showStats: 'إظهار الإحصائيات',
		mrn: 'MRN',
		sharedView: 'عرض مشترك',
		sharedBy: 'مشترك من قبل {value1} {value2}',

		logisticsDescription: 'عند تمكين هذا، سيتمكن {value} من رؤية واستخدام علامة التبويب اللوجستية.',
		thisMonth: 'هذا الشهر',
		thisWeek: 'هذا الاسبوع',
		sharedInChat: 'تمت المشاركة في الدردشة',
		handoverTo: 'التسليم إلى',
		selectIncomingStaff: 'اختر الموظفين الوافدين',
		handoverSession: 'تسليم الجلسة',
		mobileApp: 'تطبيق متنقل',
		noSnooze: 'لا تأجيل',
		triggerAlertsMoreThanOnePerson: ' أطلق إنذارات إذا كان هناك أكثر من شخص واحد في الغرفة',
		thresholdValues: 'عتبة',
		thresholdsForRailDown: 'عتبات لخفض السكك الحديدية',
		thresholdsForRailUp: 'عتبات لرفع السكك الحديدية',
		aiModelCheckTimer: 'مؤقت فحص نموذج الذكاء الاصطناعي',
		triggerAlertsRailsCovered: 'قم بتنشيط التنبيهات إذا كانت السكك مغطاة',
		setAsDefault: 'تعيين كافتراضي',
		enterNumber: 'أدخل الرقم',
		writeAndClickEnter: 'اكتب وانقر على',
		selectOneCheckbox: 'يرجى تحديد خيار واحد على الأقل، مع واحد فقط كافتراضي',
		provideThreshold: 'يرجى تقديم قيمة لكل من قيم العتبة',
		pleaseSelectOption: 'يرجى اختيار خيار واحد',
		fillRequiredFieldsAi: 'يُرجى ملء الحقول المطلوبة على علامة تبويب إعدادات الذكاء الاصطناعي.',
		preferredLanguage: 'اللغة المفضلة',
		updateRpmList: 'هناك تحديثات جديدة متاحة لقائمة مرضى RPM',
		roundingWhiteboardDescription: 'عند تمكين هذا الخيار، سيتم عرض خيار عرض وتحديث لوحة المعلومات الخاصة بالمريض أثناء المكالمة.',
		bodyPositionToSendAlert: 'موقف الجسم لإرسال التنبيهات {value}',
		side: 'جانب',
		down: 'أسفل',
		inactiveTime: 'مؤقت إشعار وقت الخمو',
	},
};

export default keys;
