import React from 'react';

const Grid = props => (
	<div
		className={props.className}
		style={{
			display: 'grid',
			gridTemplateRows: props.rows ? props.rows : 'min-content',
			width: props.width,
			gridTemplateColumns: props.columns,
			gridGap: props.gridGap,
			justifyItems: props.horizAlign,
			alignItems: props.vertAlign,
			height: props.stretch,
			backgroundColor: props.backgroundColor,
		}}
	>
		{props.children}
	</div>
);

export default Grid;
