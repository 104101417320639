import { DeviceActionTypes } from 'constants/action-types.js';

const initialState = {
	busyDevices: [],
	onlineDevices: [],
	privacyDevices: [],
	pairedRemoteDevices: [],
};

export const devices = (state = initialState, action) => {
	switch (action.type) {
		// busy
		case DeviceActionTypes.SET_DEVICE_BUSY:
			return {
				...state,
				busyDevices: [...state.busyDevices, action.payload],
			};
		case DeviceActionTypes.REMOVE_DEVICE_BUSY:
			return {
				...state,
				busyDevices: [...state.busyDevices.filter(id => id !== action.payload)],
			};
		case DeviceActionTypes.SET_BULK_DEVICES_BUSY:
			return {
				...state,
				busyDevices: action.payload,
			};
		case DeviceActionTypes.REMOVE_BULK_DEVICES_BUSY:
			return {
				...state,
				busyDevices: [],
			};
		// online;
		case DeviceActionTypes.SET_ONLINE_DEVICE:
			return {
				...state,
				onlineDevices: [...state.onlineDevices, action.payload],
			};
		case DeviceActionTypes.REMOVE_ONLINE_DEVICE:
			return {
				...state,
				onlineDevices: [...state.onlineDevices.filter(id => id !== action.payload)],
			};
		case DeviceActionTypes.SET_BULK_DEVICES_ONLINE:
			return {
				...state,
				onlineDevices: action.payload,
			};
		case DeviceActionTypes.REMOVE_BULK_DEVICES_ONLINE:
			return {
				...state,
				onlineDevices: [],
			};
		// privacy
		case DeviceActionTypes.SET_PRIVACY_DEVICE:
			return {
				...state,
				privacyDevices: [...state.privacyDevices, action.payload],
			};
		case DeviceActionTypes.REMOVE_PRIVACY_DEVICE:
			return {
				...state,
				privacyDevices: [...state.privacyDevices.filter(id => id !== action.payload)],
			};
		case DeviceActionTypes.SET_BULK_DEVICES_PRIVACY:
			return {
				...state,
				privacyDevices: action.payload,
			};
		case DeviceActionTypes.REMOVE_BULK_DEVICES_PRIVACY:
			return {
				...state,
				privacyDevices: [],
			};
		// pair remote
		case DeviceActionTypes.SET_PAIR_REMOTE_DEVICE:
			return {
				...state,
				pairedRemoteDevices: [...state.pairedRemoteDevices, action.payload],
			};
		case DeviceActionTypes.REMOVE_PAIR_REMOTE_DEVICE:
			return {
				...state,
				pairedRemoteDevices: [...state.privacyDevices.filter(id => id !== action.payload)],
			};
		case DeviceActionTypes.SET_BULK_PAIR_REMOTE_DEVICE:
			return {
				...state,
				pairedRemoteDevices: action.payload,
			};
		case DeviceActionTypes.REMOVE_BULK_PAIR_REMOTE_DEVICE:
			return {
				...state,
				pairedRemoteDevices: [],
			};
		default:
			return state;
	}
};
