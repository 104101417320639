import { gatewayApi } from 'constants/global-variables.js';
import { getStorage } from 'infrastructure/helpers/commonHelpers.js';

const getUserIPAccess = async () => {
	try {
		const companyId = getStorage().getItem('companyId');
		const gwData = await gatewayApi.get(`v1/healthcare/users/organizations/${companyId}/ip-access`);
		return { HasAccess: gwData.data.hasAccess };
	} catch (error) {
		return { error };
	}
};

export default getUserIPAccess;
