import styled from 'styled-components';

const Loader = styled.div`
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: conic-gradient(#0000 10%, ${props => (props.$color ? props.$color : 'blue')});
	-webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 4px), #000 0);
	animation: s3 0.75s infinite linear;

	@keyframes s3 {
		to {
			transform: rotate(1turn);
		}
	}
`;

export default Loader;
