import { DeviceActionTypes } from 'constants/action-types.js';

export const actionCreators = {
	// busy
	setBusyDevice: deviceId => async dispatch => {
		dispatch({ type: DeviceActionTypes.SET_DEVICE_BUSY, payload: deviceId });
	},
	removeBusyDevice: deviceId => async dispatch => {
		dispatch({ type: DeviceActionTypes.REMOVE_DEVICE_BUSY, payload: deviceId });
	},
	setBulkDevicesBusy: devices => async dispatch => {
		dispatch({
			type: DeviceActionTypes.SET_BULK_DEVICES_BUSY,
			payload: devices,
		});
	},
	removeBulkDevicesBusy: () => async dispatch => {
		dispatch({ type: DeviceActionTypes.REMOVE_BULK_DEVICES_BUSY });
	},
	// online
	setOnlineDevice: deviceId => async dispatch => {
		dispatch({ type: DeviceActionTypes.SET_ONLINE_DEVICE, payload: deviceId });
	},
	removeOnlineDevice: deviceId => async dispatch => {
		dispatch({
			type: DeviceActionTypes.REMOVE_ONLINE_DEVICE,
			payload: deviceId,
		});
	},
	setBulkDevicesOnline: devices => async dispatch => {
		dispatch({
			type: DeviceActionTypes.SET_BULK_DEVICES_ONLINE,
			payload: devices,
		});
	},
	removeBulkDevicesOnline: () => async dispatch => {
		dispatch({ type: DeviceActionTypes.REMOVE_BULK_DEVICES_ONLINE });
	},
	// privacy
	setPrivacyDevice: deviceId => async dispatch => {
		dispatch({ type: DeviceActionTypes.SET_PRIVACY_DEVICE, payload: deviceId });
	},
	removePrivacyDevice: deviceId => async dispatch => {
		dispatch({
			type: DeviceActionTypes.REMOVE_PRIVACY_DEVICE,
			payload: deviceId,
		});
	},
	setBulkDevicesPrivacy: devices => async dispatch => {
		dispatch({
			type: DeviceActionTypes.SET_BULK_DEVICES_PRIVACY,
			payload: devices,
		});
	},
	removeBulkDevicesPrivacy: () => async dispatch => {
		dispatch({ type: DeviceActionTypes.REMOVE_BULK_DEVICES_PRIVACY });
	},
	// pariedRemote
	setPairedRemoteDevice: deviceId => async dispatch => {
		dispatch({
			type: DeviceActionTypes.SET_PAIR_REMOTE_DEVICE,
			payload: deviceId,
		});
	},
	removePairedRemoteDevice: deviceId => async dispatch => {
		dispatch({
			type: DeviceActionTypes.REMOVE_PAIR_REMOTE_DEVICE,
			payload: deviceId,
		});
	},
	setBulkPairedRemoteDevice: devices => async dispatch => {
		dispatch({
			type: DeviceActionTypes.SET_BULK_PAIR_REMOTE_DEVICE,
			payload: devices,
		});
	},
	removeBulkPairedRemoteDevice: () => async dispatch => {
		dispatch({ type: DeviceActionTypes.REMOVE_BULK_PAIR_REMOTE_DEVICE });
	},
};
