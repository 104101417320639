import React, { useState, useRef, useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import { enums, participant } from '@solaborate/calls';
import { ToggleGroup, Modal, Button, CameraBookmarks, Icon } from 'calls/components/index.js';
import { useConferenceConfigurations, useControllerTracks, useHelloDeviceState } from 'calls/hooks/index.js';
import { CameraMaxZoomLevels, CameraTypes } from 'calls/enums/index.js';
import { LOCALES } from 'i18n-translations/locales.js';
import translate from 'i18n-translations/translate.jsx';
import { getPreferredLanguageLocale } from 'infrastructure/helpers/commonHelpers.js';
import { SocketContext } from 'infrastructure/socket-client/SocketContext.js';
import SocketEvents from 'constants/socket-events.js';
import { ButtonType, CameraZoomLevels } from 'constants/enums.js';
import { Dropdown } from 'components/index.js';
import { StyledPTZ, StyledCameraControls } from 'css/StyledComponents/index.js';

const { CameraMoveDirections, CameraEventActions } = enums;

/**
 * @param {object} props
 * @param {import('calls/RemoteHelloParticipant.js').default} props.helloDevice
 * @param {string} props.activeTrackType
 */
const CameraControls = ({ helloDevice, activeTrackType }) => {
	const socket = useContext(SocketContext);
	const [isRebootHuddleCamModalOpen, setIsRebootHuddleCamModalOpen] = useState(false);
	const [isSwitchDeviceDisabled, setIsSwitchDeviceDisabled] = useState(false);
	const intl = useIntl();

	const prevActionRef = useRef(null);
	const disabledSwitchTimeout = useRef(null);
	const conferenceConfigs = useConferenceConfigurations();
	const locale = getPreferredLanguageLocale();

	const deviceState = useHelloDeviceState(helloDevice);
	const selectedCamera = deviceState?.mediaDevices?.find(
		mediaDevice => mediaDevice.type === enums.MediaTypes.CAMERA && mediaDevice.isActive
	);

	const [sliderRange, setSliderRange] = useState(0);
	const [bookmarks, setBookmarks] = useState(deviceState.bookmarkList);
	const [activeBookmark, setActiveBookmark] = useState(null);

	const tracks = useControllerTracks(helloDevice?.remoteTrackController);
	const videoTrack = tracks[activeTrackType];

	useEffect(() => {
		const onEvent = event => {
			if (event instanceof participant.ActiveDeviceChanged) {
				setIsSwitchDeviceDisabled(false);
				setActiveBookmark(null);
			}
		};

		helloDevice.on(onEvent);

		return () => {
			helloDevice.off(onEvent);

			if (disabledSwitchTimeout.current) {
				clearTimeout(disabledSwitchTimeout.current);
			}
		};
	}, [helloDevice]);

	useEffect(() => {
		const onMediaControlsResponse = data => {
			if (data.command === enums.MediaControlsCommands.BOOKMARK_LIST) {
				setBookmarks(data.data);
				if (activeBookmark) {
					const isActivePresetInList = data.data.some(item => item.id === activeBookmark?.id);
					if (!isActivePresetInList) {
						setActiveBookmark(null);
					}
				}
			}

			if (data.command === enums.MediaControlsCommands.MOVE_TO_BOOKMARK) {
				const active = bookmarks.find(x => x.id === data.data);
				setActiveBookmark(active);
			}

			if (data.command === enums.MediaControlsCommands.ACTIVE_DEVICE) {
				setSliderRange(0);
			}
		};
		socket.on(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);

		return () => {
			socket.off(SocketEvents.Conference.ON_MEDIA_CONTROLS_RESPONSE, onMediaControlsResponse);
		};
	}, [socket, bookmarks]);

	useEffect(() => {
		setSliderRange(deviceState.cameraZoomLevel);
	}, [helloDevice, deviceState.cameraZoomLevel]);

	const sendDirection = (direction, action) => {
		if (
			(prevActionRef.current === CameraEventActions.STOP && action === CameraEventActions.STOP) ||
			(selectedCamera?.name === CameraTypes.HELLO && deviceState.disabledDirections[direction])
		) {
			return;
		}

		helloDevice.moveCamera(direction, action);
		prevActionRef.current = action;
	};

	const filteredDevices = deviceState.mediaDevices
		? deviceState.mediaDevices.filter(device => [CameraTypes.HELLO, CameraTypes.HUDDLE].includes(device.name))
		: [];

	const getCameraName = (name, type) => {
		if (CameraTypes.HUDDLE === type) {
			return name || '20x';
		}
		return name || type;
	};

	if (!videoTrack) {
		return <></>;
	}

	const saveHomePosition = () => {
		helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.UPDATE_HOME_POSITION, enums.MediaTypes.CAMERA, 'default');
	};

	const moveToHomePosition = async event => {
		event.stopPropagation();
		const response = await helloDevice.sendMediaControlsEvent(
			enums.MediaControlsCommands.RECENTER,
			enums.MediaTypes.CAMERA,
			'recenter'
		);
		if (!response.ok) {
			// error
		}
	};

	const directionIcon = {
		[CameraMoveDirections.UP]: 'keyboard_arrow_up',
		[CameraMoveDirections.RIGHT]: 'keyboard_arrow_right',
		[CameraMoveDirections.LEFT]: 'keyboard_arrow_left',
		[CameraMoveDirections.DOWN]: 'keyboard_arrow_down',
	};

	return (
		<>
			<StyledPTZ $top={0} $isRightToLeft={locale === LOCALES.ARABIC} $isDarkMode={conferenceConfigs.isDarkMode}>
				{filteredDevices.length > 1 && (
					<header>
						<ToggleGroup>
							{filteredDevices.map(({ id, name, isActive, capabilities }) => (
								<ToggleGroup.Item
									key={id}
									onChange={() => {
										if (isSwitchDeviceDisabled) {
											return;
										}

										setIsSwitchDeviceDisabled(true);

										// If no response is received from Hello enable switch after 3 seconds
										if (disabledSwitchTimeout.current) {
											clearTimeout(disabledSwitchTimeout.current);
										}

										disabledSwitchTimeout.current = setTimeout(() => {
											setIsSwitchDeviceDisabled(false);
										}, 3000);
										helloDevice.sendMediaControlsEvent(enums.MediaControlsCommands.ACTIVE_DEVICE, enums.MediaTypes.CAMERA, id);
									}}
									disabled={isSwitchDeviceDisabled}
									name='camera'
									checked={isActive}>
									{getCameraName(capabilities.customName, name)}
								</ToggleGroup.Item>
							))}
						</ToggleGroup>
					</header>
				)}
				{selectedCamera?.name !== CameraTypes.OTOSCOPE && (
					<>
						<StyledCameraControls $isDarkMode={conferenceConfigs.isDarkMode}>
							<div>
								{[CameraMoveDirections.UP, CameraMoveDirections.RIGHT, CameraMoveDirections.LEFT, CameraMoveDirections.DOWN].map(
									direction => (
										<button
											key={direction}
											type='button'
											onMouseDown={() => {
												sendDirection(direction, CameraEventActions.START);
											}}
											onMouseUp={() => {
												sendDirection(direction, CameraEventActions.STOP);
											}}
											onMouseOut={() => {
												sendDirection(direction, CameraEventActions.STOP);
											}}
											disabled={
												(selectedCamera?.name === CameraTypes.HELLO && deviceState.disabledDirections[direction]) ||
												!selectedCamera?.capabilities?.tilt
											}
											onBlur={() => {}}>
											<Icon name={directionIcon[direction]} />
										</button>
									)
								)}
								<div>
									{selectedCamera?.name === CameraTypes.HUDDLE && deviceState.isHuddleConnected && (
										<button type='button' onClick={moveToHomePosition}>
											<Icon name='home' />
										</button>
									)}
								</div>
							</div>
							<div>
								<input
									type='range'
									min={0}
									max={
										selectedCamera?.name === CameraTypes.HUDDLE ? CameraMaxZoomLevels.HUDDLE_MAX : CameraMaxZoomLevels.HELLO_MAX
									}
									step={20}
									value={sliderRange}
									onChange={evt => {
										evt.stopPropagation();
										setSliderRange(+evt.target.value);
									}}
									onMouseUp={evt => {
										helloDevice.zoomCamera(+evt.target.value);
									}}
									onTouchEnd={evt => {
										helloDevice.zoomCamera(+evt.target.value);
									}}
									disabled={selectedCamera?.capabilities?.zoom <= CameraZoomLevels.NO_ZOOM}
								/>
								{selectedCamera?.name === CameraTypes.HUDDLE && (
									<Dropdown position='right' icon='more_vert'>
										<div>
											<div>
												<button type='button' onClick={saveHomePosition} disabled={!deviceState.isHuddleConnected}>
													<Icon name='my_location' />
													{intl.formatMessage({ id: 'updateToCurrentPosition' })}
												</button>
											</div>
											<div>
												<button
													type='button'
													onClick={evt => {
														evt.stopPropagation();
														setIsRebootHuddleCamModalOpen(true);
													}}
													disabled={!deviceState.isHuddleConnected}>
													<Icon name='refresh' />
													{intl.formatMessage({ id: 'resetCamera' })}
												</button>
											</div>
										</div>
									</Dropdown>
								)}
							</div>
						</StyledCameraControls>
						{selectedCamera?.name === CameraTypes.HUDDLE && (
							<CameraBookmarks helloDevice={helloDevice} activeBookmark={activeBookmark} bookmarks={bookmarks} />
						)}
					</>
				)}
			</StyledPTZ>
			{isRebootHuddleCamModalOpen && (
				<Modal onDismiss={() => setIsRebootHuddleCamModalOpen(false)} title={intl.formatMessage({ id: 'rebootCamera' })}>
					<Modal.Content>
						<p>
							{translate('areYouSureRebootHuddleCam', {
								value: filteredDevices.find(device => device?.name === CameraTypes.HUDDLE)?.capabilities?.customName || '20x',
							})}
						</p>
					</Modal.Content>
					<Modal.Actions>
						<Button
							variant={ButtonType.SUBMIT}
							onClick={() => {
								if (deviceState.isHuddleConnected) {
									helloDevice.sendDeviceCommand(enums.DeviceCommands.REBOOT_HUDDLE_CAM);
									setIsRebootHuddleCamModalOpen(false);
								}
							}}>
							{translate('reboot')}
						</Button>
					</Modal.Actions>
				</Modal>
			)}
		</>
	);
};

export default CameraControls;
