import { gatewayApi, APP_CONFIG, legacyApi, localApi } from 'constants/global-variables.js';
import { MembersType } from 'constants/enums.js';
import { getUserId, getCompanyId } from 'infrastructure/auth.js';
import { buildParamsFromArray } from 'infrastructure/helpers/commonHelpers.js';

const companyId = getCompanyId();

export const getMembers = async ({
	pageSize = 10,
	pageIndex = 0,
	filterType = MembersType.ALL_MEMBERS,
	searchValue,
	roles = [],
	healthSystems = [],
	fromDate = '',
	toDate = '',
}) => {
	try {
		const params = {
			filterType,
			pageIndex,
			pageSize,
			fromDate,
			toDate,
			searchValue,
		};

		const { data } = await gatewayApi.get(
			`/v1/companies/organizations/${companyId}/members?${buildParamsFromArray(
				'roles',
				roles
			)}${buildParamsFromArray('healthSystems', healthSystems)}`,
			{
				params,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemMembers = async ({
	healthSystemId,
	pageSize = 10,
	pageIndex = 0,
	searchValue,
	roles = [],
	filterType,
	fromDate = '',
	toDate = '',
}) => {
	try {
		const params = {
			pageSize,
			pageIndex,
			searchValue,
			filterType,
			fromDate,
			toDate,
		};
		const { data } = await gatewayApi.get(
			`/v1/organizations/${companyId}/healthsystems/${healthSystemId}/members?${buildParamsFromArray('roles', roles)}`,
			{
				params,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getInvitedMembers = async ({
	pageSize = 10,
	pageIndex = 0,
	searchValue,
	inviteTypes = [],
	healthSystems = [],
	fromDate = '',
	toDate = '',
}) => {
	try {
		const params = {
			pageSize,
			pageIndex,
			searchValue,
			fromDate,
			toDate,
		};
		const { data } = await gatewayApi.get(
			`/v1/companies/${companyId}/organization-invites?${buildParamsFromArray(
				'healthSystems',
				healthSystems
			)}${buildParamsFromArray('inviteTypes', inviteTypes)}`,
			{
				params,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemInvitedUsers = async ({
	healthSystemId,
	pageSize = 10,
	pageIndex = 0,
	searchValue,
	inviteTypes,
	fromDate = '',
	toDate = '',
}) => {
	try {
		const params = {
			pageSize,
			pageIndex,
			searchValue,
			fromDate,
			toDate,
		};
		const { data } = await gatewayApi.get(
			`/v1/companies/${companyId}/healthsystems/${healthSystemId}/organization-invites?${buildParamsFromArray(
				'inviteTypes',
				inviteTypes
			)}`,
			{
				params,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const registerUser = ({ values, inviteId, compId }) => {
	return new Promise((res, rej) => {
		localApi
			.post(`/api/users/invite-signup`, {
				inviteId,
				companyId: compId,
				firstName: values.firstName,
				lastName: values.lastName,
				email: values.email,
				password: values.password,
			})
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
};

export const createInvite = async params => {
	try {
		const { data } = await gatewayApi.post(`/v1/companies/${companyId}/organization-invites`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const assignMember = async (healthSystemId, teamId, companyUserId) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${companyId}/health-systems/${healthSystemId}/levels/${teamId}/assign`,
			{
				CompanyUserId: companyUserId,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const assignMembers = async (healthSystemId, teamId, companyUsersId) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${companyId}/health-systems/${healthSystemId}/levels/${teamId}/assigns`,
			{
				CompaniesUsersId: companyUsersId,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const unAssignMember = async (healthSystemId, teamId, companyUserId) => {
	try {
		const { data } = await gatewayApi.put(
			`/v1/organizations/${companyId}/health-systems/${healthSystemId}/levels/${teamId}/unassign`,
			{
				CompanyUserId: companyUserId,
			}
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMember = async memberId => {
	try {
		const { data } = await gatewayApi.delete(`/v1/companies/${companyId}/members/${memberId}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteMemberV1M2 = async (memberId, roomId) => {
	try {
		const { data } = await gatewayApi.delete(`/v1.2/companies/${companyId}/members/${memberId}`, {
			params: { roomId },
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getInvite = async (inviteCompanyId, inviteId, email) => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${inviteCompanyId}/invites/${inviteId}`, {
			params: {
				email: email,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateInviteStatus = async (inviteId, statusId) => {
	try {
		const { data } = await gatewayApi.put(`/v1.1/companies/${companyId}/invites/${inviteId}/status`, {
			statusId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMyContacts = () => {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`${APP_CONFIG.URL.syncApiUrl}/api/v2/sync/contacts/full`)
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
};

export const addToHealthSystem = async (healthSystemId, memberId) => {
	try {
		const { data } = await gatewayApi.post(`/v1/organizations/${companyId}/healthsystems/${healthSystemId}/vcp-members`, {
			Id: memberId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const deleteHealthSystemMember = async (healthSystemId, memberId) => {
	try {
		const { data } = await gatewayApi.delete(
			`/v1.1/organizations/${companyId}/healthsystems/${healthSystemId}/vcp-members/${memberId}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const resetTwoFactorAuth = async email => {
	try {
		const { data } = await legacyApi.post(`/v2.0/api/account/authenticator-reset`, {
			email,
			companyId: companyId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNurseHealthSystems = memberId => {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/organizations/${companyId}/vcp-members/${memberId}/healthsystems`)
			.then(response => res(response.data.healthSystems))
			.catch(e => rej(e));
	});
};

export const updateNurse = (values, userId, guidUserId, roleId) => {
	return new Promise((res, rej) => {
		legacyApi
			.put(`v2.1/api/profile/nurse/${userId}`, {
				FirstName: values.firstName,
				LastName: values.lastName,
				CompanyName: 'Banyan',
				JobTitle: values.role ? values.role : '',
				WorkExperienceId: roleId,
				NurseUserGuid: guidUserId,
			})
			.then(response => res(response))
			.catch(e => rej(e));
	});
};

export const getNurseProfileInfo = userId => {
	return new Promise((res, rej) => {
		legacyApi
			.get(`/v2.0/api/profiles/${userId}`)
			.then(response => res(response.data.Result))
			.catch(e => rej(e));
	});
};
export const checkUserAccount = async email => {
	try {
		const { data } = await gatewayApi.get(`/v1/users/${email}/exists`);
		return data.result.exists;
	} catch (error) {
		return { error };
	}
};

export const checkIfCompanyMemberExists = async email => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${companyId}/members/${email}/exists`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const acceptInvite = (inviteId, email, cid) => {
	return new Promise((res, rej) => {
		gatewayApi
			.put(`/v1/companies/${cid}/organization-invites/${inviteId}/accept`, {
				Email: email,
			})
			.then(response => res(response.data))
			.catch(e => rej(e));
	});
};

export const getUserPhotos = userId => {
	return new Promise((res, rej) => {
		gatewayApi
			.get(`/v1/healthcare/users/${userId}/photos`, {
				params: {
					userId,
				},
			})
			.then(response => {
				res(response.data.userPhotos);
			})
			.catch(e => rej(e));
	});
};

export const saveUserPhotos = (userId, userPhotos) => {
	return new Promise((res, rej) => {
		gatewayApi
			.post(`/v1/healthcare/users/${userId}/photos`, userPhotos)
			.then(response => {
				res(response);
			})
			.catch(e => rej(e));
	});
};

export const deleteUserPhoto = (userId, id) => {
	return new Promise((res, rej) => {
		gatewayApi
			.delete(`/v1/healthcare/users/${userId}/photos/${id}`)
			.then(response => {
				res(response.data.Id);
			})
			.catch(e => rej(e));
	});
};

export const signUpPatient = async (formData, id = companyId) => {
	try {
		const patientData = { ...formData };
		patientData.companyId = id;
		const response = await localApi.post(`/api/users/patient-signup`, patientData);
		return response;
	} catch (error) {
		return { error };
	}
};

export const checkUserEmailExists = async email => {
	try {
		const { data } = await localApi.get(`/api/users/${email}/exists`);
		return data.userExists;
	} catch (error) {
		return { error };
	}
};

export const activateUser = async (email, activationCode) => {
	try {
		const { data } = await localApi.post(`/api/users/${email}/activate/${activationCode}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserLanguage = async () => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${getUserId()}/preferences/app-language`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const changeUserLanguage = async language => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/users/${getUserId()}/preferences/app-language`, {
			appLanguageValue: language,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const updateUserProfileInformation = async userValues => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/users/${getUserId()}/profile`, userValues);
		return data;
	} catch (error) {
		return { error };
	}
};

export const changeUserPassword = async (oldPassword, newPassword) => {
	try {
		const { data } = await legacyApi.post(`v2.1/api/account/password`, {
			oldPassword,
			newPassword,
		});
		return data;
	} catch (error) {
		return { error: error.response.data };
	}
};

export const changeUserEmail = async ({ currentPassword, currentEmail, newEmail }) => {
	try {
		const { data } = await legacyApi.put(`v2.1/api/account/${getUserId()}/email`, {
			currentPassword,
			currentEmail,
			newEmail,
		});
		return data;
	} catch (error) {
		return { error: error.response.data };
	}
};

export const validateUserEmail = async ({ changeEmailCode, email }) => {
	try {
		const { data } = await localApi.put(`/api/users/validate-email`, {
			changeEmailCode,
			email,
		});
		return data;
	} catch (error) {
		return { error: error.response.data };
	}
};

export const getCurrencies = async () => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/currencies`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getCountries = async () => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/countries`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendRecoveryEmail = async (email, reCaptchaToken) => {
	try {
		const { data } = await localApi.post('/api/users/recover-password', {
			email,
			reCaptchaToken,
			domainUrl: window.location.origin,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendPasswordRecoveredEmail = async ({ email, password, code }) => {
	try {
		const { data } = await localApi.post('/api/users/password-reset', {
			email,
			password,
			code,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserProfiles = async userId => {
	try {
		const { data } = await legacyApi.get(`/v2.0/api/profiles/${userId}`);
		return data.Result;
	} catch (error) {
		return { error };
	}
};

export const dissociateUserById = async userId => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/users/${userId}/dissociate`, {
			userId: userId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getAllNurses = async (pageSize, pageIndex, search) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${companyId}/nurses/all`, {
			params: {
				pageIndex,
				pageSize,
				search,
			},
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const checkIfMemberExistsInCompany = async id => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${id}/members/exists`);
		return data.exists;
	} catch (error) {
		return { error };
	}
};

export const updateSession = async params => {
	const userId = getUserId();
	try {
		const { data } = await gatewayApi.put(`/v1.1/healthcare/users/${userId}/session`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemSubTreeBasic = async healthSystemId => {
	try {
		const { data } = await gatewayApi.get(`/v1/organizations/${companyId}/health-systems/${healthSystemId}/full`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserPreferences = async (preferenceTypeId, roomId) => {
	try {
		const url = `/v1/healthcare/users/${getUserId()}/preferences?${
			preferenceTypeId ? `preferenceTypeCategoryId=${preferenceTypeId}` : ''
		}${roomId ? `&roomId=${roomId}` : ''} `;
		const { data } = await gatewayApi.get(url);
		return data.preferences;
	} catch (error) {
		return { error };
	}
};

export const setUserPreference = async params => {
	try {
		const { data } = await gatewayApi.put(`/v1/healthcare/users/${getUserId()}/preferences`, params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientQueue = async userId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/patient-queue`);
		return data.result.queue;
	} catch (error) {
		return { error };
	}
};

export const getPatientQueueCount = async userId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/patient-queue/count`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const bulkUserInvite = async ({ healthSystemId, inviteTypeId, emails }) => {
	try {
		const { data } = await gatewayApi.post(`/v1/tasks/organizations/${companyId}/invites`, {
			healthSystemId,
			inviteTypeId,
			emails,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserHealthSystemPreferences = async healthSystemId => {
	try {
		const { data } = await gatewayApi.get(`/v1/companies/${companyId}/teams/${healthSystemId}/member-settings`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const setUserHealthSystemPreferences = async (healthSystemId, params) => {
	try {
		const { data } = await gatewayApi.post(`v1/companies/${companyId}/teams/${healthSystemId}/member-settings`, params);
		return data;
	} catch (error) {
		return { error };
	}
};
export const getIsAssignedToDoctors = async nurseId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/nurses/${nurseId}/doctors/is-assigned`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUserAddress = async userId => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/address`);
		return data;
	} catch (error) {
		return { error };
	}
};
