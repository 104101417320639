import styled, { css } from 'styled-components';
import DarkTheme from 'calls/styles/DarkTheme.js';
import LightTheme from 'calls/styles/LightTheme.js';

/**
 * @type {import('styled-components').StyledComponent<"aside", any, { $isDarkMode: boolean, $isMinimizedView: boolean, $isGridView: boolean, $isRightToLeft: boolean, $isParticipantsViewHidden: boolean, $isPatientMeasurementsOpen: boolean, $isMedicalInfoOpen: boolean, $isInviteParticipantsModalViewOpen: boolean, $isMoreParticipantsBoxDisplayed: boolean, $isMoreParticipantsViewOpen: boolean, $isWhiteboardVisible: boolean, $gridCount: number, }, never>}
 */
const StyledParticipantWrapper = styled.aside`
	${props =>
		!props.$isGridView &&
		props.$gridCount <= 2 &&
		css`
			position: absolute;
			z-index: 1;
			right: var(--spacing-m);
			top: var(--spacing-m);
			> section {
				> div {
					height: 130px;
					margin-bottom: var(--spacing-s);
				}
				@media (max-width: 992px) {
					display: none;
				}
			}
		`};

	${props =>
		(props.$isGridView || props.$gridCount > 2) &&
		css`
			> section {
				display: grid;
				grid-gap: var(--spacing-s);
			}
		`};

	${props =>
		props.$isRightToLeft &&
		css`
			left: ${props.$isPatientMeasurementsOpen ? `calc(50% + ${LightTheme.spacing[2]}px)` : `${LightTheme.spacing[5]}px`};
			right: auto;
		`};

	${props =>
		props.$gridCount > 2 &&
		!props.$isGridView &&
		css`
			${props.$isParticipantsViewHidden && 'display: none;'}

			> section {
				grid-template-columns: repeat(5, 1fr);
				margin-bottom: var(--spacing-s);

				${props.$isInviteParticipantsModalViewOpen ||
				props.$isMedicalInfoOpen ||
				props.$isPatientMeasurementsOpen ||
				props.$isMoreParticipantsViewOpen ||
				props.$isWhiteboardVisible
					? css`
							> div {
								height: calc(
									9 / 16 * ${props.$isPatientMeasurementsOpen && !props.$isInviteParticipantsModalViewOpen ? 16.6 : 14}vw
								);
							}
							&:not(.grid-1):not(.grid-2):not(.grid-3):not(.grid-4):not(.grid-5):not(.grid-6) {
								> div {
									height: calc(
										9 / 16 * ${props.$isPatientMeasurementsOpen && !props.$isInviteParticipantsModalViewOpen ? 12.5 : 14}vw
									);
								}
							}
					  `
					: css`
							> div {
								width: auto;
								height: 100px;
								@media (min-height: 700px) {
									height: 120px;
								}
								@media (min-height: 800px) {
									height: 130px;
								}
							}
					  `}
				@media (max-width: 992px) {
					display: none;
				}
			}
		`}

	${props =>
		!props.$isGridView &&
		(props.$isInviteParticipantsModalViewOpen ||
			(!props.$isMedicalInfoOpen && !props.$isPatientMeasurementsOpen && !props.$isWhiteboardVisible)) &&
		css`
			> section {
				${props.$isMoreParticipantsBoxDisplayed
					? css`
							> div:not(:nth-of-type(-n + 4)):not(.more-participants) {
								display: none;
							}
					  `
					: css`
							> div:not(:nth-of-type(-n + 5)):not(.more-participants) {
								display: none;
							}
					  `}
			}
		`}

	${props =>
		!props.$isGridView &&
		!props.$isInviteParticipantsModalViewOpen &&
		props.$isPatientMeasurementsOpen &&
		css`
			> section {
				grid-template-columns: repeat(3, 1fr);

				&:not(.grid-1):not(.grid-2):not(.grid-3):not(.grid-4):not(.grid-5):not(.grid-6) {
					grid-template-columns: repeat(4, 1fr);
				}
			}
		`}

		${props =>
		!props.$isGridView &&
		props.$isRightToLeft &&
		!props.$isPatientMeasurementsOpen &&
		css`
			left: ${LightTheme.spacing[5]}px;
			right: auto;
		`}

	${props =>
		props.$isGridView &&
		css`
			z-index: 1;
			align-self: center;
			> section {
				${!props.$isMinimizedView &&
				css`
					@media (min-width: 992px) {
						&.grid-1,
						&.grid-2 {
							grid-template-columns: repeat(1, 1fr);
						}

						&.grid-1 {
							> div {
								width: calc(16 / 9 * (100vh - 60px));
								max-width: 100vw;
								height: calc(9 / 16 * 100vw);
								max-height: calc(100vh - 60px);
							}
						}

						&.grid-2,
						&.grid-3,
						&.grid-4 {
							> div {
								width: calc((16 / 9) * (50vh - 30px));
								max-width: 50vw;
								height: calc((9 / 16) * 100vw);
								max-height: calc(50vh - 30px);
							}
						}

						&.grid-3,
						&.grid-4 {
							grid-template-columns: repeat(2, 1fr);
						}

						&.grid-3 > div:last-of-type {
							left: 50%;
						}

						&.grid-5,
						&.grid-6,
						&.grid-7,
						&.grid-8,
						&.grid-9 {
							grid-template-columns: repeat(3, 1fr);
						}

						&.grid-5,
						&.grid-6 {
							> div {
								width: calc(16 / 9 * 50vh);
								max-width: 33vw;
								height: calc(9 / 16 * 33vw);
								max-height: 50vh;
							}
						}

						&.grid-7,
						&.grid-8,
						&.grid-9 {
							> div {
								width: calc(16 / 9 * (33vh - 20px));
								max-width: 35vw;
								height: calc(9 / 16 * 70vw);
								max-height: calc(33vh - 20px);
							}
						}

						&.grid-10,
						&.grid-11,
						&.grid-12,
						&.grid-13,
						&.grid-14,
						&.grid-15,
						&.grid-16 {
							grid-template-columns: repeat(4, 1fr);
						}

						&.grid-10,
						&.grid-11,
						&.grid-12 {
							> div {
								width: calc(16 / 9 * 33vh);
								max-width: 25vw;
								height: calc(9 / 16 * 25vw);
								max-height: 33vh;
							}
						}

						&.grid-13,
						&.grid-14,
						&.grid-15,
						&.grid-16 {
							> div {
								width: calc(16 / 9 * (25vh - 15px));
								max-width: 25vw;
								height: calc(9 / 16 * 25vw);
								max-height: calc(25vh - 15px);
							}
						}
					}
				`}

				${props.$isMinimizedView &&
				css`
					width: 100%;
					grid-template-columns: repeat(2, 1fr);
					margin: auto;
					grid-gap: var(--spacing-s);
				`}

				@media (max-width: 992px) {
					width: 100%;
					grid-template-columns: repeat(2, 1fr);
					padding: 20px;
					grid-gap: ${LightTheme.spacing[3]}px;
				}
			}

			@media (max-width: 992px) {
				position: absolute;
				top: 0px;
				left: 0px;
				z-index: 1;
				height: 100%;
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				overflow: auto;
				${props.$gridCount >= 6 &&
				css`
					height: calc(100% - 130px);
				`}
			}

			${props.$isMinimizedView &&
			css`
				overflow: auto;
			`}

			${(props.$isInviteParticipantsModalViewOpen ||
				props.$isMedicalInfoOpen ||
				props.$isPatientMeasurementsOpen ||
				props.$isWhiteboardVisible) &&
			css`
				margin: 0;
				> section {
					&.grid-3,
					&.grid-4,
					&.grid-5,
					&.grid-6 {
						grid-template-columns: repeat(2, 1fr);
					}

					&.grid-7,
					&.grid-8,
					&.grid-9,
					&.grid-10,
					&.grid-11,
					&.grid-12 {
						grid-template-columns: repeat(3, 1fr);
					}

					&.grid-13,
					&.grid-14,
					&.grid-15,
					&.grid-16 {
						grid-template-columns: repeat(
							${props.$isPatientMeasurementsOpen && !props.$isInviteParticipantsModalViewOpen ? 3 : 4},
							1fr
						);
					}
				}
			`}

			${(props.$isInviteParticipantsModalViewOpen || props.$isMedicalInfoOpen || props.$isWhiteboardVisible) &&
			css`
				> section {
					&.grid-3,
					&.grid-4 {
						> div {
							width: calc(16 / 9 * 50vh);
							max-width: 35vw;
							height: calc(9 / 16 * 35vw);
							max-height: 50vh;
						}
					}

					&.grid-5,
					&.grid-6 {
						> div {
							width: calc(16 / 9 * (33vh - 20px));
							max-width: 35vw;
							height: calc(9 / 16 * 70vw);
							max-height: calc(33vh - 20px);
						}
					}

					&.grid-7,
					&.grid-8,
					&.grid-9 {
						> div {
							width: calc(16 / 9 * 33vh);
							max-width: 23vw;
							height: calc(9 / 16 * 23vw);
							max-height: 33vh;
						}
					}

					&.grid-10,
					&.grid-11,
					&.grid-12 {
						> div {
							width: calc(16 / 9 * (25vh - 15px));
							max-width: 23vw;
							height: calc(9 / 16 * 23vw);
							max-height: calc(25vh - 15px);
						}
					}

					&.grid-13,
					&.grid-14,
					&.grid-15,
					&.grid-16 {
						> div {
							width: calc(16 / 9 * (25vh - 15px));
							max-width: 17.3vw;
							height: calc(9 / 16 * 17.3vw);
							max-height: calc(25vh - 15px);
						}
					}
				}
			`}
			
			${!props.$isInviteParticipantsModalViewOpen &&
			props.$isPatientMeasurementsOpen &&
			css`
				> section {
					&.grid-3,
					&.grid-4,
					&.grid-5,
					&.grid-6 {
						> div {
							width: calc(16 / 9 * 33vh);
							max-width: 25vw;
							height: calc(9 / 16 * 25vw);
							max-height: 33vh;
						}
					}

					&.grid-7,
					&.grid-8,
					&.grid-9,
					&.grid-10,
					&.grid-11,
					&.grid-12 {
						> div {
							width: calc(16 / 9 * 25vh);
							max-width: 16.66vw;
							height: calc(9 / 16 * 16.66vw);
							max-height: 25vh;
						}
					}

					&.grid-13,
					&.grid-14,
					&.grid-15,
					&.grid-16 {
						> div {
							width: calc(16 / 9 * (20vh - 12px));
							max-width: 16.6vw;
							height: calc(9 / 16 * 16.6vw);
							max-height: calc(20vh - 12px);
						}
					}
				}
			`}
		`}

	.more-participants {
		background: ${props => (props.$isDarkMode ? DarkTheme.colors.blueFive : LightTheme.colors.blueFive)};
		padding: var(--spacing-m);
		justify-content: center;
		align-items: center;
		display: flex;
		h1,
		p,
		span {
			color: ${LightTheme.colors.blueFour};
		}
		h1 {
			padding: 0;
			margin: 0;
			padding-right: var(--spacing-m);
			font-size: 50px;
			font-weight: 500;
		}
		p {
			font-weight: 500;
			font-size: 14px;
			padding: 0;
			margin: 0;
		}
		span {
			font-weight: 300;
			font-size: 12px;
		}
		&:hover {
			cursor: pointer;
			background: var(--blue-light-5-o30);
			transition: all 0.2s linear;
		}
	}
`;

export default StyledParticipantWrapper;
