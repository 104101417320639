import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { getUserRole, isAuthenticated } from 'infrastructure/auth.js';
import { HealthSystemType, UserRoles } from 'constants/enums.js';
import Unauthorized from 'components/Unauthorized.jsx';
import {
	getConfigurationMenu,
	getConfigurationValue,
	getNursePoolingStatus,
	getProperUrl,
	getStorage,
	isStandAloneAppsUserAuthorized,
	isRedirectVirtualCareSession,
	isSessionEhr,
} from 'infrastructure/helpers/commonHelpers.js';
import CallPatientOptions from 'views/ehr/CallPatientOptions';
import { GeneralSettings } from 'constants/configurationEnums';

const Home = () => {
	const userSession = useSelector(state => state.user.userSession);
	const configurations = useSelector(state => state.configurations);
	const isNewExperience = useSelector(state => state.configurations.isNewExperience);

	const isPoolingFlow = () => {
		if (isRedirectVirtualCareSession() && !getStorage().getItem('ref_token')) {
			return false;
		}
		const adminConfigurableMenu = getConfigurationMenu(configurations.adminConfigurableMenu, getUserRole());
		const configurableMenu = getConfigurationMenu(configurations.configurableMenu, getUserRole());
		return (
			getUserRole() === UserRoles.NURSE &&
			getConfigurationValue(adminConfigurableMenu[GeneralSettings.PoolingFlow]) &&
			getConfigurationValue(configurableMenu[GeneralSettings.PoolingFlow]) &&
			!getNursePoolingStatus()
		);
	};

	const getComponentToRender = () => {
		const result = { component: null, url: '/' };
		const userRole = getUserRole();
		if (!isAuthenticated() && window.location.href.includes('banyan')) {
			return <Redirect to='/login' />;
		}

		if (isSessionEhr()) {
			return <CallPatientOptions />;
		}

		result.url = getProperUrl(configurations, userSession.healthSystem.workflowTypeId === HealthSystemType.PRIMARY_CARE);

		switch (userRole) {
			default: {
				result.component = <Unauthorized />;
			}
		}

		window.history.replaceState(null, 'healthcare', result.url);
		return <Redirect to={result.url} />;
	};

	return getComponentToRender();
};

export default Home;
