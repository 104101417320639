import { gatewayApi } from 'constants/global-variables.js';

export const getRequests = async ({ userId, pageIndex = 0, pageSize = 10, status }) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/users/${userId}/requests`, {
			params: {
				as: 'patient',
				pageIndex,
				pageSize,
				status,
			},
		});
		return data.requests;
	} catch (error) {
		return { error };
	}
};

export const getSymptoms = async () => {
	try {
		const url = '/v1/healthcare/symptoms';
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const requestDoctorV1 = async request => {
	try {
		const { data } = await gatewayApi.post('/v1.1/healthcare/requests', request);
		return data;
	} catch (error) {
		return { error };
	}
};

export const reassignRequestsToDoctor = async (requestId, doctorId) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/assigned-requests/${requestId}/reassign`, {
			doctorId,
		});
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMedicalQuestions = async () => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/medical-questions`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getMedicAssignedRequests = async ({ doctorGuidId, pageIndex = 0, pageSize = 10, status = 2, type = null }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1.1/healthcare/users/${doctorGuidId}/assigned-requests?pageIndex=${pageIndex}&pageSize=${pageSize}&status=${status}&type=${type}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const reassignRequestNextMedic = async assignedRequestId => {
	try {
		const { data } = await gatewayApi.patch(`v1/healthcare/assigned-requests/${assignedRequestId}/reassign/next-medic`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendDoctorRequest = async (request, companyId) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/organizations/${companyId}/requests`, request);
		return data;
	} catch (error) {
		return { error };
	}
};

export const sendAppointmentRequest = async (request, companyId) => {
	try {
		const { data } = await gatewayApi.post(`/v1/healthcare/organizations/${companyId}/requests/appointment`, request);
		return data;
	} catch (error) {
		return { error };
	}
};
