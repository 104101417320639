import { gatewayApi } from 'constants/global-variables.js';

/**
 * @param {string} conferenceId
 * @description Endpoint for getting SIP information of conference
 */
export const getSipInfo = async conferenceId => {
	try {
		const { data } = await gatewayApi.get(`/v1/rooms/${conferenceId}/sip-info`);
		return data;
	} catch (error) {
		return { error };
	}
};

/**
 * @param {string} phoneNumber
 * @param {string} conferenceId
 * @returns
 */
export const dialOut = async (phoneNumber, conferenceId) => {
	try {
		const { data } = await gatewayApi.post(`/v1/rooms/${conferenceId}/dial-out`, { phoneNumber });
		return data;
	} catch (error) {
		return { error };
	}
};

/**
 * @param {string} phoneNumber
 * @param {string} conferenceId
 * @returns
 */
export const dialOutTranslator = async (phoneNumber, conferenceId) => {
	try {
		const { data } = await gatewayApi.post(`/v1/rooms/${conferenceId}/dial-out-translator`, { phoneNumber });
		return data;
	} catch (error) {
		return { error };
	}
};
