import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enums } from '@solaborate/calls';
import { useHistory } from 'react-router';
import { MediaPermissionsErrorType, requestMediaPermissions } from 'mic-check';
import { isChrome, isEdgeChromium } from 'react-device-detect';
import HelloFeatureBlock from 'components/HelloFeatureBlock.jsx';
import {
	buildTree,
	checkIfMediaDevicesPlugged,
	checkForPermission,
	isSessionEhr,
	isSmartLauncher,
	getHealthSystemDevices,
	getStorage,
} from 'infrastructure/helpers/commonHelpers.js';
import translate from 'i18n-translations/translate.jsx';
// import EditSectorModal from "containers/EditSectorModal.jsx";
import { getRegionSubTree } from 'api/tree.js';
import { StartQueryStringKeys, UserRoles } from 'calls/enums/index.js';
import { actionCreators as healthSystemsActionCreators } from 'state/healthSystems/actions.js';
import {
	StreamError,
	MediaPermissions,
	MediaTypes,
	UserPermissionDeniedErrors,
	ErrorComponentTypes,
	SectorTypes,
} from 'constants/enums.js';
import { RoomTypes } from 'constants/visitEnums.js';
import Alert from 'components/Alert.jsx';
import Grid from 'components/Grid.jsx';
import Loader from 'components/Loader.jsx';
import Modal from 'components/Modal.jsx';
import { assignVCPtoBabyRoom, unAssignVCPtoBabyRoom } from 'api/messenger.js';
import { getUserId, getUserRole } from 'infrastructure/auth.js';
import { StreamSettingsView } from 'calls/views/index.js';
import { getDevicesFromMRN } from 'api/devices.js';
import { actionCreators as devicesActionCreators } from 'state/devices/actions.js';
import { getCurrentSessionPatientData, getCurrentSessionPractitionerData } from 'api/ehr.js';
import CardInfo from 'calls/components/CardInfo.jsx';

const CallPatientOptions = () => {
	const [isRoomModalOpen, setIsRoomModalOpen] = useState(false);
	const [isStreamSettingsModalOpen, setIsStreamSettingsModalOpen] = useState(false);
	const [error, setError] = useState(null);
	const [takeCareOfRoomModal, toggleTakeCareOfRoomModal] = useState(false);
	const [patientData, setPatientData] = useState(null);
	const healthSystems = useSelector(state => state.healthSystems);
	const userSession = useSelector(state => state.user.userSession);
	const dispatch = useDispatch();
	const history = useHistory();

	const [assignedDevices, setAssignedDevices] = useState(true);

	const [currentRoom] = useState({
		sectorName: '',
		roomName: '',
		hospitalId: '',
		departmentId: '',
		floorId: '',
		roomId: '',
		sectorType: '',
		parentSectorName: '',
		parentSectorType: SectorTypes.DEPARTMENT,
		isHelloDevice: false,
		roomType: null,
	});

	const camStatus = useRef(null);
	const micStatus = useRef(null);
	const isAllowPermissionPrompt = useRef(false);
	const [isNurseAssigned, setIsNurseAssigned] = useState(false);
	const [deviceId, setDeviceId] = useState(null);

	useEffect(() => {
		const bindMediaEvents = async () => {
			camStatus.current = await checkForPermission(MediaTypes.CAMERA);
			micStatus.current = await checkForPermission(MediaTypes.MICROPHONE);
			camStatus.current.onchange = onDevicePermissionChange;
			micStatus.current.onchange = onDevicePermissionChange;
		};
		bindMediaEvents();

		const getFHIRAccount = async () => {
			const client = await window.FHIR.oauth2.ready();
			const patientPromise = client.patient.read();
			const practitionerPromise = client.request(client.user.fhirUser);
			const values = await Promise.all([patientPromise, practitionerPromise]);
			// find patient mrn
			const [patient] = values;
			const identifierArray = patient.identifier.filter(id => {
				const coding = id.type?.coding;
				if (coding) {
					return coding[0].code === 'MR';
				}
				return false;
			});

			let mrn = '';
			if (identifierArray) {
				mrn = identifierArray[0]?.value;
			}

			const devicesRes = await getDevicesFromMRN(mrn);
			if (devicesRes?.patientDevices?.length === 0) {
				setAssignedDevices(false);
				return;
			}

			const buildFullName = patient => {
				if (!patient) return '';
				const fullName = [patient.family, patient.given].filter(Boolean).join(' ');
				return fullName || '';
			};

			setPatientData({
				patientName: buildFullName(patient?.name[0]),
				dateOfBirth: patient?.birthDate,
				mrn,
			});

			const id = devicesRes?.patientDevices?.at(0).solHelloDeviceId;
			setDeviceId(id);
		};

		const getSessionPatientData = async () => {
			const response = await getCurrentSessionPatientData();
			if (!response.error) {
				setPatientData(() => ({
					patientName: `${response.firstName} ${response.lastName}`,
					mrn: response.mrn,
					dateOfBirth: response.dateOfBirth,
				}));
				if (response?.mrn) {
					getStorage().setItem('ehr-mrn', response.mrn);
				}

				if (response?.csn) {
					getStorage().setItem('ehr-csn', response.csn);
				}

				setDeviceId(response.deviceId);
			}
		};

		if (isSessionEhr() && !isSmartLauncher()) {
			getSessionPatientData();
		} else {
			getFHIRAccount();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateTree = async () => {
		const subTreeResponse = await getRegionSubTree(userSession.healthSystem.id, userSession.regionId);
		if (subTreeResponse.error) {
			setError(subTreeResponse.error.message);
		} else {
			const { healthSystem } = subTreeResponse.organization;
			const treeData = buildTree(healthSystem);
			const { online, busy, privacy, pairedRemote } = getHealthSystemDevices(healthSystem);
			dispatch(devicesActionCreators.setBulkDevicesBusy(busy));
			dispatch(devicesActionCreators.setBulkDevicesOnline(online));
			dispatch(devicesActionCreators.setBulkDevicesPrivacy(privacy));
			dispatch(devicesActionCreators.setBulkPairedRemoteDevice(pairedRemote));
			dispatch(healthSystemsActionCreators.setHealthSystem(healthSystem));
			dispatch(healthSystemsActionCreators.setTreeData(treeData));
		}
		setIsRoomModalOpen(prevState => !prevState);
	};

	const showAllowPermissionModal = () => {
		isAllowPermissionPrompt.current = true;
		setTimeout(() => {
			if (!isAllowPermissionPrompt.current) {
				return;
			}

			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
				})
			);
		}, 500);
	};

	const handlePermissionErrors = async callType => {
		const { camera, microphone } = await checkIfMediaDevicesPlugged();
		if ([enums.CallTypes.VIDEO, enums.CallTypes.AUDIO].includes(callType) && (!camera || !microphone)) {
			dispatch(
				healthSystemsActionCreators.setStreamPermissionMessage({
					component: ErrorComponentTypes.Modal,
					type: microphone ? StreamError.MICROPHONE_NOT_FOUND.type : StreamError.CAMERA_NOT_FOUND.type,
				})
			);
			return;
		}

		const { type, name } = error;
		if (type === MediaPermissionsErrorType.UserPermissionDenied) {
			if (name === UserPermissionDeniedErrors.NotAllowedError) {
				dispatch(
					healthSystemsActionCreators.setStreamPermissionMessage({
						component: isChrome || isEdgeChromium ? ErrorComponentTypes.Popup : ErrorComponentTypes.Modal,
						type: isChrome || isEdgeChromium ? StreamError.MICROPHONE_BLOCKED.type : StreamError.MICROPHONE_BLOCKED_GENERIC.type,
					})
				);
			}
		}
	};

	const onDevicePermissionChange = res => {
		if (res.target.state === MediaPermissions.GRANTED || res.target.state === MediaPermissions.PROMPT) {
			clearPermissions();
		}
	};

	const clearPermissions = () => {
		dispatch(healthSystemsActionCreators.setStreamPermissionMessage(null));
	};

	const talkToPatient = async () => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			const response = await getCurrentSessionPractitionerData();
			if (!response.error) {
				localStorage.setItem('practitioner', response.alias ?? '');
			}
			openCallViewUrl(enums.CallTypes.VIDEO);
		} catch (e) {
			handlePermissionErrors(enums.CallTypes.VIDEO);
		}
		isAllowPermissionPrompt.current = false;
	};

	const viewPatient = async () => {
		try {
			showAllowPermissionModal();
			await requestMediaPermissions({ audio: true, video: false });
			// eslint-disable-next-line no-empty
		} catch (e) {}
		isAllowPermissionPrompt.current = false;
		openCallViewUrl(enums.CallTypes.SECURITYCAM);
	};

	const openCallViewUrl = callType => {
		const queryParams = new URLSearchParams({
			[StartQueryStringKeys.OBJECT_ID]: deviceId,
			[StartQueryStringKeys.OBJECT_TYPE]: enums.ObjectTypes.HELLO_DEVICE,
			[StartQueryStringKeys.CONFERENCE_NAME]: 'Patient',
			[StartQueryStringKeys.CALL_TYPE]: callType,
		});
		clearPermissions();
		const url = callType === enums.CallTypes.SECURITYCAM ? 'patient-feed' : 'call';
		history.push(`/${url}?${queryParams.toString()}`);
	};

	const handleSubmit = async () => {
		let response;
		if (isNurseAssigned) {
			response = await unAssignVCPtoBabyRoom(getUserId(), currentRoom.roomId);
		} else {
			response = await assignVCPtoBabyRoom(getUserId(), currentRoom.roomId);
		}
		if (response.error) {
			setError(response.error.message);
		} else {
			setIsNurseAssigned(prevState => !prevState);
			toggleTakeCareOfRoomModal(prevState => !prevState);
		}
	};

	if (!assignedDevices) {
		return (
			<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
				<div>No assigned devices found</div>
			</Grid>
		);
	}

	if (deviceId === null) {
		return (
			<Grid width='100%' stretch='100vh' vertAlign='center' horizAlign='center' rows='auto'>
				<Loader />
			</Grid>
		);
	}

	const getButtonsLength = () => {
		const buttonLength = 145;
		const minimumLength = 2;
		let result = 0;
		if (!isSessionEhr()) {
			result += 1;
		}
		if (currentRoom.roomType === RoomTypes.BABY_ROOM.type && UserRoles.NURSE === getUserRole()) {
			result += 1;
		}
		const gaps = (Math.max(minimumLength, result) - 1) * 10;
		const buttonsWidth = Math.max(minimumLength, result) * buttonLength;
		return buttonsWidth + gaps;
	};

	return (
		<div className='ehr-room-wrapper'>
			<div className='room-actions ehr-room'>
				<div style={{ width: `${getButtonsLength()}px` }}>
					{isSessionEhr() && (
						<>
							<div className='flex hello-device-description flex-justify-center'>
								<h3>{translate('patientInformation')}</h3>
								<br /> <br />
							</div>
							<CardInfo isInConference={false} metaData={patientData} />
						</>
					)}

					{deviceId === 0 && <p>There are no HelloCare devices currently assigned to this location</p>}
					{deviceId > 0 && (
						<>
							<div className='hello-feature-grid'>
								<h3>{currentRoom ? currentRoom.sectorName : ''}</h3>
								<h3>Call Patient</h3>
								<p className='no-margin'>{healthSystems.description}</p>
							</div>
							<div className='hello-feature-grid'>
								{!isSessionEhr() && (
									<HelloFeatureBlock
										onClick={() => history.push(`/monitor-device/${deviceId}`)}
										title='eSitter'
										className='hello-feature-monitoring'
									/>
								)}
								<HelloFeatureBlock onClick={talkToPatient} title='Talk to Patient' className='hello-feature-audio' />
								{currentRoom.roomType === RoomTypes.BABY_ROOM.type && UserRoles.NURSE === getUserRole() && (
									<HelloFeatureBlock
										title={translate(isNurseAssigned ? 'nicuCheckOut' : 'nicuCheckIn')}
										onClick={() => toggleTakeCareOfRoomModal(prevState => !prevState)}
										className='baby-room'
									/>
								)}
							</div>
							<button className='stream-settings-button' type='button' onClick={() => setIsStreamSettingsModalOpen(true)}>
								<span className='material-icons-outlined'>settings</span>
								{translate('streamSettingsModalTitle')}
							</button>
						</>
					)}

					{isStreamSettingsModalOpen && <StreamSettingsView onDismiss={() => setIsStreamSettingsModalOpen(false)} />}
					{/* {isRoomModalOpen && (
            <EditSectorModal
              onEditSector={updateTree}
              onModalClose={() => setIsRoomModalOpen((prevState) => !prevState)}
              isEditSectorModalOpen={true}
              sectorData={currentRoom}
            />
          )} */}
					{takeCareOfRoomModal && (
						<Modal
							display={true}
							position='center'
							onModalSubmit={handleSubmit}
							onModalClose={() => toggleTakeCareOfRoomModal(false)}
							primaryButtonLabel={translate('confirm')}
						>
							<form>
								<h3>{translate('takingCareOfbaby')}</h3>
								<p>{translate(isNurseAssigned ? 'takingCareOfbabyConfirmUnAssigned' : 'takingCareOfbabyConfirm')}</p>
							</form>
							<Alert display={error} message={error} variant='dark' fixed={true} onClose={() => setError(null)} />
						</Modal>
					)}
				</div>
			</div>
		</div>
	);
};

export default CallPatientOptions;
