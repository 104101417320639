import { LanguageActionTypes } from 'constants/action-types.js';

export const actionCreators = {
	setUserLanguage: language => dispatch => {
		dispatch({
			type: LanguageActionTypes.SET_USER_LANGUAGE,
			payload: language,
		});
	},
};
