import { ConditionType } from 'constants/enums.js';
import { gatewayApi } from 'constants/global-variables.js';
import { getCompanyId } from 'infrastructure/auth.js';

const CompanyId = getCompanyId();

export const getDeviceOwner = async deviceId => {
	try {
		const url = `v1/devices/${deviceId}/owner`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDeviceOwnerPatient = async deviceId => {
	try {
		const url = `v1/healthcare/organizations/${CompanyId}/devices/${deviceId}/patient`;
		const { data } = await gatewayApi.get(url);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getDevices = async patientId => {
	try {
		const url = `/v1/companies/${getCompanyId()}/owners/${patientId}/devices`;
		const { data } = await gatewayApi.get(url);
		return data.devices;
	} catch (error) {
		return { error };
	}
};

export const getMyPatients = async ({ pageIndex = 0, pageSize = 20, search = '' } = {}) => {
	try {
		const url = `v1.2/healthcare/patients/mine?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`;
		const { data } = await gatewayApi.get(url);
		return data.patients;
	} catch (error) {
		return { error };
	}
};

export const getPatientPrivacyModeImage = async (objectTypeId, objectId) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/devices/object-types/${objectTypeId}/object-ids/${objectId}/privacy-mode-image`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const searchPatients = async email => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/patients?search=${email}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const createPatient = async ({ firstName, lastName, email, channelTypeId, healthSystemId }) => {
	try {
		const { status, data } = await gatewayApi.post(`/v1/healthcare/patients`, {
			companyId: CompanyId,
			firstName,
			lastName,
			email,
			channelTypeId,
			healthSystemId,
		});
		return { status, data };
	} catch (error) {
		return { error };
	}
};

export const addPatient = async params => {
	try {
		const { data } = await gatewayApi.post('v1.2/healthcare/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const searchPatient = async (searchText = '', pageIndex = 0, pageSize = 10) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${CompanyId}/patients?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${searchText}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientProfile = async patientId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/patients/${patientId}/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const editPatientProfile = async params => {
	try {
		const { data } = await gatewayApi.put('v1/healthcare/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientLastCondition = async userId => {
	try {
		const { data } = await gatewayApi.get(`v1/fhir/patient/${userId}/last-condition`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientsAiSettings = async ({ patientId, deviceId, roomId }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/alerts/companies/${getCompanyId()}/rooms/${roomId}/devices/${deviceId}/patients/${patientId}/ai-settings`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientAiSettings = async ({ roomId, deviceId, patientId, sendData }) => {
	try {
		const { data } = await gatewayApi.patch(
			`/v1/alerts/companies/${getCompanyId()}/rooms/${roomId}/devices/${deviceId}/patients/${patientId}/ai-settings`,
			sendData
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatientAiSetting = async ({ roomId, deviceId, patientId, fieldToUpdate, value, id = null }) => {
	try {
		const sendData = { [fieldToUpdate]: value };
		const { data } = await gatewayApi.patch(
			`v1/alerts/companies/${getCompanyId()}/rooms/${roomId}/devices/${deviceId}/patients/${patientId}/ai-settings${
				id ? `/${id}` : ''
			}`,
			sendData
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientByIdCard = async idCard => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/organizations/${getCompanyId()}/patients/${idCard}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const registerPatient = async params => {
	try {
		const { data } = await gatewayApi.post('/v1.1/tasks/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientByUserId = async userId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/patient/${userId}/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientDetails = async () => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/patient/profile`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const updatePatient = async params => {
	try {
		const { data } = await gatewayApi.put('/v1.1/healthcare/patients', params);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientById = async patientId => {
	try {
		const { data } = await gatewayApi.get(`/v1.1/healthcare/patients/${patientId}/profile`);
		return data.patient;
	} catch (error) {
		return { error };
	}
};

export const getHealthSystemPatients = async ({ healthSystemId, search = '', pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/healthcare/organizations/${getCompanyId()}/health-systems/${healthSystemId}/patients?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getUnassignedPatients = async ({ search = '', pageIndex = 0, pageSize = 10 }) => {
	try {
		const { data } = await gatewayApi.get(
			`v1/organizations/${getCompanyId()}/patients/unassigned-requests?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getNursePatients = async ({ healthSystemId, userId, pageIndex = 0, pageSize = 10, search = '' }) => {
	try {
		const { data } = await gatewayApi.get(
			`/v1/healthcare/organizations/${getCompanyId()}/care-members/${userId}/health-systems/${healthSystemId}/patients?pageIndex=${pageIndex}&pageSize=${pageSize}&search=${search}`
		);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getOwnerMrn = async deviceId => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/organizations/${getCompanyId()}/devices/${deviceId}/owner-mrn`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientObservations = async (patientId, observationType) => {
	try {
		const { data } = await gatewayApi.get(`/v1/healthcare/patients/${patientId}/observations?observationType=${observationType}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientConditions = async (patientId, conditionType = ConditionType.PRECAUTION) => {
	try {
		const { data } = await gatewayApi.get(`v1/healthcare/patients/${patientId}/conditions?conditionType=${conditionType}`);
		return data;
	} catch (error) {
		return { error };
	}
};

export const getPatientMobilityHistory = async patientId => {
	try {
		const { data } = await gatewayApi.get(`v1/alerts/patients/${patientId}/analytics/mobility-summary`);
		return data;
	} catch (error) {
		return { error };
	}
};
