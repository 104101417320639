import React from 'react';
import styled, { css } from 'styled-components';

/**
 * @typedef {{ $position: string, $background: string, $offset: number, $layoutPosition: string }} props
 * @type {import('styled-components').StyledComponent<"div", any, props, never>}
 */
const StyledNotification = styled.div`
	position: ${props => props.$layoutPosition};
	display: flex;
	align-items: center;
	background: ${props => props.$background};
	color: ${props => props.$color};
	padding: 12px;
	border-radius: 5px;
	z-index: 1;
	max-width: 100%;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

	${props =>
		props.$position === 'top-left' &&
		css`
			top: ${props.$offset}px;
			left: ${props.$offset}px;
		`}

	${props =>
		props.$position === 'top-center' &&
		css`
			top: ${props.$offset}px;
			left: 50%;
			transform: translateX(-50%);
		`}

  	${props =>
		props.$position === 'top-right' &&
		css`
			top: ${props.$offset}px;
			right: ${props.$offset}px;
		`}
`;

/**
 * @param {object} props
 * @param {any} [props.children]
 * @param {string} props.background
 * @param {string} props.color
 * @param {string} props.position
 * @param {number} props.offset
 * @param {string} props.layoutPosition
 */
const Notification = ({ children, background, color, position, offset, layoutPosition }) => (
	<StyledNotification
		$background={background}
		$color={color}
		$position={position}
		$offset={offset}
		$layoutPosition={layoutPosition}>
		{children}
	</StyledNotification>
);

export default Notification;
