import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';

import TooltipPortal from 'calls/portals/TooltipPortal.jsx';
import LightTheme from 'calls/styles/LightTheme.js';

/**
 * @typedef {{ $properties?: any, $position?: string, $text?: string, $isPortal?: boolean }} props
 * @type {import('styled-components').StyledComponent<"div", any, props, never>}
 */
const StyledTooltipChildren = styled.div`
	display: flex;

	${props =>
		!props.$isPortal &&
		props.$text &&
		css`
			position: relative;

			&::before,
			&::after {
				opacity: 0;
				visibility: hidden;
				transition: 0.1s;
			}

			&:hover::before,
			&:hover::after {
				opacity: 1;
				visibility: visible;
			}

			&::before {
				content: '${props.$text}';
				position: absolute;
				padding: 5px 10px;
				background: ${LightTheme.colors.graySix};
				color: ${LightTheme.colors.grayZero};
				top: 50%;
				transform: translateY(-50%);
				white-space: nowrap;
				left: calc(100% + 4px);
				font-size: 12px;
				box-shadow: 0 1px 2px var(--gray-8-o10);
				border-radius: ${LightTheme.borderRadius.base}px;
				font-weight: 500;
				max-width: 300px;
				text-transform: none;
				z-index: 1;
			}

			&::after {
				content: '';
				background: ${LightTheme.colors.graySix};
				padding: 4px;
				position: absolute;
				left: 100%;
				top: 50%;
				transform: translateY(-50%) rotate(45deg);
			}

			// Define positions
			&::before {
				bottom: calc(100% + 6px);
				top: auto;
				left: 50%;
				transform: translateX(-50%);

				${props.$position === 'top-left' &&
				css`
					transform: none;
					left: 0;
				`}

				${props.$position === 'top-right' &&
				css`
					transform: none;
					left: auto;
					right: 0;
				`}

				${props.$position === 'bottom-left' &&
				css`
					bottom: auto;
					top: calc(100% + 6px);
					transform: none;
					right: 0;
					left: unset;
				`}
			}

			&::after {
				bottom: calc(100% + 3px);
				top: auto;
				left: 50%;
				transform: translateX(-50%) rotate(45deg);

				${props.$position === 'bottom-left' &&
				css`
					top: calc(100% + 3px);
					bottom: auto;
				`}
			}
		`}
`;

/**
 * @type {import('styled-components').StyledComponent<"div", any, props, never>}
 */
const StyledTooltip = styled.div`
	${props =>
		props.$isPortal &&
		css`
			display: flex;
			position: fixed;
			z-index: 20;
			background: ${LightTheme.colors.graySix};
			color: ${LightTheme.colors.grayZero};
			border-radius: ${LightTheme.borderRadius.base}px;
			padding: ${LightTheme.spacing[1]}px ${LightTheme.spacing[2]}px;
			font-size: 14px;
			top: ${({ $properties }) => $properties.top}px;
			left: ${({ $properties }) => $properties.left + $properties.width / 2}px;
			transform: translate(-50%, calc(-100% - 10px));
			box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
			white-space: nowrap;

			> span {
				&:last-of-type {
					position: absolute;
					left: 50%;
					top: 100%;
					transform: translateX(-50%);
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 5px solid ${LightTheme.colors.graySix};
				}
			}

			${props.$position === 'top-right' &&
			css`
				right: auto;
				left: ${({ $properties }) => $properties.right}px;
				transform: translate(-100%, calc(-100% - 10px));

				> span {
					&:last-of-type {
						left: auto;
						right: ${LightTheme.spacing[2]}px;
						transform: none;
					}
				}
			`}

			${props.$position === 'top-left' &&
			css`
				background: red;
				left: ${({ $properties }) => $properties.left}px;
				transform: none;

				> span {
					&:last-of-type {
						left: 5px;
						right: auto;
						transform: none;
					}
				}
			`}
		`}
`;

/**
 * @param {object} props
 * @param {any} props.children
 * @param {string} props.text
 * @param {string} [props.position]
 * @param {boolean} [props.isPortal]
 */
const Tooltip = ({ children, text, position, isPortal = false }) => {
	const [tooltip, showTooltip] = useState(false);

	const childrenRef = useRef(null);

	const properties = childrenRef.current?.getBoundingClientRect();

	return (
		<>
			<StyledTooltipChildren
				$text={text}
				$position={position}
				onMouseEnter={() => showTooltip(true)}
				onMouseLeave={() => showTooltip(false)}
				ref={node => {
					childrenRef.current = node;
				}}>
				{children}
			</StyledTooltipChildren>
			{isPortal && tooltip && text && (
				<TooltipPortal>
					<StyledTooltip $properties={properties} $position={position} $isPortal={isPortal}>
						{text}
						<span />
					</StyledTooltip>
				</TooltipPortal>
			)}
		</>
	);
};

export default Tooltip;
