import { ConfigurationActionTypes } from 'constants/action-types.js';

export const actionCreators = {
	setConfigurableMenu: configurableMenu => async dispatch => {
		dispatch({
			type: ConfigurationActionTypes.SET_USER_CONFIGURABLE_MENU,
			payload: configurableMenu,
		});
	},
	setAdminConfigurableMenu: configurableAdminSettings => async dispatch => {
		dispatch({
			type: ConfigurationActionTypes.SET_ADMIN_CONFIGURABLE_MENU,
			payload: configurableAdminSettings,
		});
	},
	setHealthSystemConfigs: configurableSettings => async dispatch => {
		dispatch({
			type: ConfigurationActionTypes.SET_HEALTH_SYSTEM_CONFIGURATIONS,
			payload: configurableSettings,
		});
	},
	setHealthSystemUnboundConfigs: configurableSettings => async dispatch => {
		dispatch({
			type: ConfigurationActionTypes.SET_HEALTH_SYSTEM_UNBOUND_CONFIGURATIONS,
			payload: configurableSettings,
		});
	},
	setHealthSystemUnboundSettings: configurableSettings => async dispatch => {
		dispatch({
			type: ConfigurationActionTypes.SET_HEALTH_SYSTEM_UNBOUND_SETTINGS,
			payload: configurableSettings,
		});
	},
	setUserSettings: settings => async dispatch => {
		dispatch({
			type: ConfigurationActionTypes.SET_USER_SETTINGS,
			payload: settings,
		});
	},
	setIsNewExperience: value => async dispatch => {
		dispatch({
			type: ConfigurationActionTypes.SET_IS_NEW_EXPERIENCE,
			payload: value,
		});
	},
	setRoleRoundingConfigurations: configs => async dispatch => {
		dispatch({
			type: ConfigurationActionTypes.SET_ROLE_ROUNDING_CONFIGURATIONS,
			payload: configs,
		});
	},
};
