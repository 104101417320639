import React, { useState, useRef, useEffect, createContext, useContext } from 'react';
import styled, { css } from 'styled-components';
import _ from 'lodash';

import { useConferenceConfigurations } from 'calls/hooks/index.js';
import { DropdownDirection } from 'calls/enums/index.js';
import DropdownPortal from 'calls/portals/DropdownPortal.jsx';
import { Icon } from 'calls/components/index.js';
import LightTheme from 'calls/styles/LightTheme.js';

import { getCallsButtonColor } from 'infrastructure/helpers/commonHelpers.js';

/**
 * @typedef {typeof DropdownDirection[keyof typeof DropdownDirection]} Direction
 * @type {import('styled-components').StyledComponent<"div", any, { direction: Direction, $offset: number }, never>}
 */
const StyledDropdown = styled.div`
	position: relative;
	display: inline-flex;
	z-index: 2;

	.default-button {
		background: none;
		border: none;
		padding: 0;
		color: ${props => getCallsButtonColor(props.$isDarkMode)};

		span {
			padding: var(--spacing-s);
			border-radius: 100%;
			background: rgba(52, 52, 52, 0.5);
			color: ${LightTheme.colors.grayZero};
		}
	}

	main {
		position: absolute;
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
		border-radius: ${LightTheme.borderRadius.base}px;

		${props => {
			switch (props.$direction) {
				case DropdownDirection.BOTTOM_RIGHT:
					return css`
						top: calc(100% + ${props.$offset}px);
						right: 0;
					`;
				case DropdownDirection.TOP_RIGHT:
					return css`
						bottom: calc(100% + ${props.$offset}px);
						right: 0;
					`;
				default:
					return css`
						right: 0;
					`;
			}
		}}

		${props =>
			props.$isRightToLeft &&
			css`
				right: auto;
				left: 0;
			`}
			
		@media (max-width: 992px) {
			position: fixed;
			width: 100%;
			left: 0;
			bottom: 0;

			ul {
				width: 100%;
			}
		}
	}
`;

const DropdownCtx = createContext({
	showItems: false,
	setShowItems: null,
	buttonRef: null,
	itemsRef: null,
});

/**
 * @param {object} props
 * @param {any} props.children
 * @param {boolean} [props.isOpen] Is dropdown items container open. Provided if needs to be handled by state change on usage.
 * @param {()=>void} [props.onClickOutside] Click outside action handler. Needs to be provided if you pass isOpen from parent component.
 * @param {string} [props.direction=DropdownDirection.BOTTOM_RIGHT] Dropdown items position
 * @param {number} [props.offset]
 */
const Dropdown = ({ children, isOpen, onClickOutside, direction = DropdownDirection.BOTTOM_RIGHT, offset = 10 }) => {
	const [showItems, setShowItems] = useState(false);
	const dropdownRef = useRef(null);
	const buttonRef = useRef(null);
	const itemsRef = useRef(null);
	const conferenceConfigurations = useConferenceConfigurations();

	useEffect(() => {
		setShowItems(isOpen);
	}, [isOpen]);

	useEffect(() => {
		const clickOutsideHandler = evt => {
			const { current: dropdownElem } = dropdownRef;
			const { current: itemsElem } = itemsRef;

			if (dropdownElem && !dropdownElem.contains(evt.target) && itemsElem && !itemsElem.contains(evt.target)) {
				if (_.isNil(isOpen) && !onClickOutside) {
					setShowItems(false);
				} else if (onClickOutside && !_.isNil(isOpen) && showItems) {
					onClickOutside();
				}
			}
		};

		document.addEventListener('mousedown', clickOutsideHandler);
		return () => {
			document.removeEventListener('mousedown', clickOutsideHandler);
		};
	}, [dropdownRef, itemsRef, showItems, isOpen, onClickOutside]);

	return (
		<DropdownCtx.Provider value={{ showItems, setShowItems, buttonRef, itemsRef }}>
			<StyledDropdown
				$direction={direction}
				$offset={offset}
				ref={dropdownRef}
				$isRightToLeft={conferenceConfigurations.isRightToLeft}
				$isDarkMode={conferenceConfigurations.isDarkMode}>
				{children}
			</StyledDropdown>
		</DropdownCtx.Provider>
	);
};

/**
 * Defaults to HTML button element with default onClick implementation. You can pass button HTML attributes to extend and override attributes.
 * When children are being provided default button will not render and functionality needs to be handled externally.
 * @param {{ children?: any; icon?: import('react').ReactNode } & React.PropsWithRef<React.ButtonHTMLAttributes<HTMLButtonElement>> } props
 */
const DropdownButton = ({ children, icon, ...buttonHtmlAttributes }) => {
	const { setShowItems, buttonRef } = useContext(DropdownCtx);

	return (
		<>
			{children || (
				<button
					className='default-button'
					ref={buttonRef}
					type='button'
					{...buttonHtmlAttributes}
					onClick={ev => {
						ev.stopPropagation();
						if (buttonHtmlAttributes.onClick) {
							buttonHtmlAttributes.onClick(ev);
						} else {
							setShowItems(prevState => !prevState);
						}
					}}>
					{icon && icon}
					{!icon && <Icon name='more_horiz' size={20} />}
				</button>
			)}
		</>
	);
};

/**
 * @param {object} props
 * @param {any} props.children
 * @param {boolean} [props.renderIntoPortal=false] Will render dropdown items into a child element outside the DOM hierarchy.
 *  A typical use case for this is when a parent component has an overflow: hidden or z-index style
 */
const DropdownItems = ({ children, renderIntoPortal = false }) => {
	const { showItems, buttonRef, itemsRef } = useContext(DropdownCtx);
	const conferenceConfigurations = useConferenceConfigurations();

	const buttonRefProperties = buttonRef.current?.getBoundingClientRect();
	/** @type {import('react').CSSProperties} */
	const styleObj = {
		zIndex: conferenceConfigurations.isEmbeddedView ? 1000 : 3,
		position: 'absolute',
		boxShadow: '0 4px 8px rgba(0,0,0,.15)',
		borderRadius: LightTheme.borderRadius.base,
		...(conferenceConfigurations.isRightToLeft
			? { left: buttonRefProperties?.left }
			: { right: window.innerWidth - buttonRefProperties?.right }),
		...(buttonRefProperties?.top < window.innerHeight / 2 && {
			top: buttonRefProperties?.top + 30,
		}),
		...(buttonRefProperties?.top > window.innerHeight / 2 && {
			bottom: window.innerHeight - buttonRefProperties?.bottom + 30,
		}),
	};

	return (
		<>
			{showItems && (
				<>
					{renderIntoPortal ? (
						<DropdownPortal>
							<main ref={itemsRef} style={styleObj}>
								{children}
							</main>
						</DropdownPortal>
					) : (
						<main ref={itemsRef}>{children}</main>
					)}
				</>
			)}
		</>
	);
};

Dropdown.Button = DropdownButton;
Dropdown.Items = DropdownItems;

export default Dropdown;
